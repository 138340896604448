<template>
  <div>
    <p>{{ title }}</p>
  </div>
  <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3">
    <div
    v-for="stat in stats"
    :key="stat.name"
    class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-2 sm:px-6 xl:px-8">
      <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
      <dd :class="[stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700', 'text-xs font-medium']">
        {{ stat.change }}
      </dd>
      <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ stat.value }}</dd>
    </div>
  </dl>
</template>

<script>
  export default {
  props: ['stats', 'title'],
  };
</script>
