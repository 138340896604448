<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getLocations', payload);
      }

      const locations = computed(() => store.getters.locations);
      // table
      const structure = {
        title: 'views.locations.title',
        creationLink: {
          title: 'buttons.createLocation',
          namePath: 'menu.sharing.createLocation',
        },
        data: [
          {
            title: 'user.name.first',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/sharing/locations/:id' },
          },
          {
            title: 'address.body',
            field: 'address',
            span: 1,
          },
          {
            title: 'address.latitude',
            field: 'latitude',
            span: 1,
          },
          {
            title: 'address.longitude',
            field: 'longitude',
            span: 1,
          },
          {
            title: 'user.email',
            field: 'email',
            span: 1,
          },
          {
            title: 'user.types.manager',
            field: 'manager',
            span: 1,
          },
          {
            title: 'status',
            toTranslate: true,
            field: 'open',
            boolean: {
              if_true: 'opened',
              if_false: 'closed',
            },
            span: 1,
          },
        ],
      };
      return {
        data: locations,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
