<template>
  <div class="relative mx-auto">
    <!-- HEADER -->
    <div class="relative flex flex-col items-start justify-between py-3 mb-4" :class="{ 'md:flex-row md:items-center': !compact }">
      <div class="flex flex-col items-start">
        <template v-if="structure && structure.title && dataView">
          <h1 class="font-bold text-2xl my-2 md:my-0 whitespace-nowrap text-gray-900 dark:text-white">
            {{ t(structure.title) }}
          </h1>
          <h3 class="font-medium text-gray-400 text-sm my-2 md:my-0 whitespace-nowrap leading-none">{{ dataLength }} {{ t('results') }}</h3>
        </template>
        <slot name="header" />
      </div>

      <span class="flex flex-row w-full items-center justify-between md:justify-end">
        <div
          class="select-none flex w-10 h-10 justify-center items-center bg-white dark:bg-gray-900 shadow-sm rounded-lg cursor-pointer mr-4 border dark:border-2 border-gray-100 dark:border-gray-800"
          @click="downloadTSV(true)">
          <span v-if="downloading" class="material-icons text-2xl text-gray-400 dark:text-gray-300 animate-pulse">downloading</span>
          <span
            v-else
            class="material-icons text-2xl text-primary-600 dark:text-white bg-white dark:bg-gray-900 hover:text-primary-400 dark:hover:text-gray-300">
            download
          </span>
        </div>

        <div class="flex justify-end">
          <div class="flex my-2 w-full" :class="{ 'md:w-96': !compact }">
            <base-search-bar v-model:search-string="searchString" v-model:case-sensitive="searchCaseSensitive" />
          </div>
        </div>

        <div v-if="structure && structure.creationLink && structure.creationLink.namePath && checkRouteByName(structure.creationLink.namePath)" class="ml-4">
          <base-router-link :name="structure.creationLink.namePath">
            <base-button @click="$emit('add')">
              <span class="material-icons">add</span>
              <span v-show="structure.creationLink.title" class="ml-2 hidden whitespace-nowrap" :class="{ 'md:block': !compact }">
                {{ t(structure.creationLink.title) }}
              </span>
            </base-button>
          </base-router-link>
        </div>

        <div hidden>
          <div
            v-if="filterOpen"
            class="select-none flex w-10 h-10 justify-center items-center text-white bg-primary-600 hover:bg-primary-800 shadow-sm rounded-lg cursor-pointer ml-4 border border-gray-300 dark:border-gray-700">
            <span class="material-icons text-2xl" @click="filterOpen = false">filter_alt</span>
          </div>
          <div
            v-else
            class="select-none flex w-10 h-10 justify-center items-center bg-white dark:bg-gray-900 text-primary-600 hover:text-primary-400 shadow-sm rounded-lg cursor-pointer ml-4 border border-gray-300 dark:border-gray-700">
            <span class="material-icons text-2xl" @click="filterOpen = true">filter_alt</span>
          </div>
        </div>
      </span>

      <base-table-filter v-show="filterOpen && filters" :filters="filters"></base-table-filter>
    </div>
    <!-- END HEADER -->

    <!-- SUMMARY -->
    <div v-if="summaryData && dataLength" class="flex w-full h-min mb-8 space-x-4 items-center justify-between">
      <div
        v-for="(data, name, i) in summaryData"
        :key="i"
        class="flex flex-col justify-center items-start w-full text-xs px-4 py-6 bg-gray-50 dark:bg-gray-900 rounded-lg border border-gray-300 dark:border-gray-700">
        <template v-if="name && parseInt(data) != 0">
          <h1 class="text-center font-medium text-gray-900/80 dark:text-white/80 leading-none whitespace-nowrap mb-1">
            {{ t(name) }}
          </h1>
          <h3 class="text-center font-medium text-gray-900 dark:text-white leading-none whitespace-nowrap text-xl">
            {{ data }}
          </h3>
        </template>
      </div>
    </div>
    <!-- END SUMMARY -->

    <base-temporal-filter v-if="structure.timeFields" :time-fields="structure.timeFields" @timestamps="timestamps = $event"></base-temporal-filter>

    <div>
      <div class="relative h-full overflow-y-scroll overflow-x-visible no-scrollbar">
        <base-table class="w-full divide-y">
          <template v-slot:header>
            <base-row-header :structure="structure" v-model:orderKey="orderKey" v-model:order="order" />
          </template>
          <slot>
            <div v-if="structure && dataView">
              <base-row
                v-for="(el, i) in dataView"
                :key="i"
                :index="i"
                :el="el"
                :border="i < paginationNum - 1"
                :structure="structure"
                @click="$emit('rowClick', el)" />
              <tr
                v-for="j in paddingElementsCount"
                :key="j"
                class="w-full overflow-hidden px-4 py-3 items-center grid h-20 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800" />
            </div>
          </slot>
        </base-table>
      </div>
    </div>

    <!-- FOOTER -->

    <div class="mt-10 flex flex-wrap justify-between items-center pb-36 px-2" :class="{ 'md:grid md:grid-cols-3 md:px-0': !compact }">
      <div class="flex justify-start order-1 grow-0 items-center text-sm" :class="{ 'md:w-full': !compact }">
        <h3 class="justify-center items-center text-gray-600 dark:text-gray-300 mx-2 hidden" :class="{ 'md:flex': !compact }">
          <span>{{ t('paginator.show') }}</span>
        </h3>
        <base-pagination-button :class="{ 'md:mx-2': !compact }">
          <select class="bg-white dark:bg-gray-900 text-sm font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none" v-model="paginationNum">
            <option class="w-24" :value="parseInt(5)">5</option>
            <option class="w-24" :value="parseInt(7)">7</option>
            <option class="w-24" :value="parseInt(8)">8</option>
            <option class="w-24" :value="parseInt(10)">10</option>
            <option class="w-24" :value="parseInt(12)">12</option>
            <option class="w-24" :value="parseInt(15)">15</option>
            <option class="w-24" :value="parseInt(20)">20</option>
          </select>
        </base-pagination-button>

        <h3 class="justify-center items-center text-gray-600 dark:text-gray-300 mx-4 hidden" :class="{ 'md:flex': !compact }">
          <span>{{ t('paginator.results') }}</span>
        </h3>
      </div>

      <div
        v-if="refreshFunction"
        class="flex items-center order-4 grow w-full justify-start mt-8"
        :class="{ 'md:order-2 md:mt-0 md:justify-center': !compact }">
        <base-pagination-button @click="refreshData" class="mr-3">
          <p class="material-icons">refresh</p>
        </base-pagination-button>
        <h3 class="justify-center items-center text-sm text-gray-600 dark:text-gray-300 mx-4 whitespace-nowrap" :class="{ 'md:text-sm': !compact }">
          <span>
            {{ t('paginator.lastUpdate') }}:
            <b>{{ updateTimestamp }}</b>
          </span>
        </h3>
      </div>
      <div v-else class="order-4" :class="{ 'md:order-2 md:mt-0 md:justify-center': !compact }"></div>

      <div class="flex justify-end items-center order-3 grow-0 text-sm" :class="{ 'md:w-full': !compact }">
        <h3 v-if="dataLength > 0" class="flex justify-center items-center text-gray-600 dark:text-gray-300 mx-4 whitespace-nowrap">
          <span>
            <b>{{ firstEntryIndex }}</b>
            -
            <b>{{ lastEntryIndex }}</b>
            {{ t('paginator.of') }}
            <b>{{ dataLength }}</b>
          </span>
        </h3>
        <h3 v-else class="flex justify-center items-center text-gray-600 dark:text-gray-300 mx-4 whitespace-nowrap">
          <span>
            <b>{{ t('paginator.noResults') }}</b>
          </span>
        </h3>

        <base-pagination-button :class="{ 'opacity-30': firstEntryIndex == 1 }" @click="previous">
          <p class="material-icons">navigate_before</p>
          <p hidden>{{ t('paginator.previous') }}</p>
        </base-pagination-button>
        <base-pagination-button :class="{ 'opacity-30': lastEntryIndex == dataLength }" @click="next" class="ml-3">
          <p hidden>{{ t('paginator.next') }}</p>
          <p class="material-icons">navigate_next</p>
        </base-pagination-button>
      </div>
    </div>

    <!-- END FOOTER -->
  </div>
</template>

<script>
  import { computed, ref, watch, onMounted } from 'vue';
  import BasePaginationButton from './Rows/BasePaginationButton.vue';
  import BaseRow from './Rows/BaseRow.vue';
  import BaseRowHeader from './Rows/BaseRowHeader.vue';
  import BaseTemporalFilter from './BaseTemporalFilter';
  import BaseTableFilter from './Filters/BaseTableFilter';
  import BaseSearchBar from './BaseSearchBar.vue';
  import BaseTable from './BaseTable.vue';

  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Utils } from '@/Utils/utils';
  import { useI18n } from 'vue-i18n';

  export default {
    emits: ['add', 'timestamps', 'rowClick', 'toggleFilters'],
    components: {
      BaseRow,
      BaseRowHeader,
      BasePaginationButton,
      BaseSearchBar,
      BaseTable,
      BaseTemporalFilter,
      BaseTableFilter,
    },
    props: {
      data: {
        type: Array,
      },
      structure: {
        type: Object,
        required: false,
      },
      refreshFunction: {
        type: Function,
        required: false,
      },
      compact: {
        type: Boolean,
        required: false,
        default: false,
      },
      forcePaginationNum: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    setup(props) {
      const store = useStore();
      const router = useRouter();

      const filterOpen = ref(false);
      const filters = [
        {
          type: 'SELECT',
          label: 'Stazione',
          field: 'station_name',
          options: ['SN100002536', 'SN100006547', 'SN100007345', 'SN100002656'],
        },
        {
          type: 'CHECKBOX',
          label: 'Stato',
          field: 'status',
          options: ['AVAILABLE', 'INSTALLED', 'PLANNED', 'MAINTENANCE'],
        },
        {
          type: 'RANGE',
          label: 'Potenza',
          field: 'power',
          unit: 'kW',
          max: 39,
          min: 0,
        },
      ];

      //////////////////////////////// REFRESH DATA ////////////////////////////////
      const updateTimestamp = ref('');
      function refreshUpdateTimestamp() {
        const event = new Date();
        updateTimestamp.value = event.toISOString().replace('T', ' ').split('.')[0];
      }
      refreshUpdateTimestamp();

      const timestamps = ref({});
      watch(timestamps, () => refreshData());

      const platform = computed(() => store.getters.currentPlatform.db); // Platform
      watch(platform, () => refreshData());
      const pendingRefresh = computed(() => store.getters.pendingRefresh); // PendingRefresh
      watch(pendingRefresh, (newVal) => {
        if (newVal) refreshData();
      });
      const refreshData = () => {
        if (!props.refreshFunction) return;
        refreshUpdateTimestamp();
        const payload = {
          ...timestamps.value,
        };
        console.log('REFRESHO I DATI NELLA TABELLA', payload);
        props.refreshFunction(payload);
        store.dispatch('refreshDone');
      };

      const data = computed(() => props.data);

      // search bar
      const orderKey = ref({ key: '', format: '' });
      const order = ref('ASC');
      const dataFiltered = computed(() => {
        if (!data.value) return [];

        let string = searchString.value;

        if (!searchCaseSensitive.value) string = String(string).toLowerCase();

        let newArray = [];
        const list = data.value;

        if (string === '') {
          newArray = list;
        } else {
          for (const el in list) {
            let toSearch = list[el].searchString;
            if (!searchCaseSensitive.value) toSearch = String(toSearch).toLowerCase();
            if (toSearch.includes(string)) newArray.push(list[el]);
          }
        }

        if (orderKey.value.key != '') {
          const ok = orderKey.value.key;
          const of = orderKey.value.format;

          newArray = newArray.sort((a, b) => {
            switch (of) {
              case 'euro':
              case 'kwh':
                a[ok] = parseFloat(a[ok]);
                break;
            }

            switch (typeof a[ok]) {
              case 'number':
                if (order.value == 'ASC') return a[ok] - b[ok];
                else return b[ok] - a[ok];
              case 'string':
              default:
                if (order.value == 'ASC') return String(a[ok]).localeCompare(String(b[ok]));
                else return String(b[ok]).localeCompare(String(a[ok]));
            }
          });
        }

        return newArray;
      });

      const searchString = ref('');
      const searchCaseSensitive = ref(false);

      const paddingElementsCount = computed(() => {
        const diff = parseInt(dataLength.value - offset.value);
        if (diff > paginationNum.value) return 0;
        return paginationNum.value - diff;
      });

      //////////////////////////////// PAGINATION ////////////////////////////////
      const paginationNum = ref(5);
      onMounted(() => {
        let pn = localStorage.paginationNum;
        if (parseInt(pn) > 0) paginationNum.value = parseInt(pn);
        if (props.forcePaginationNum) paginationNum.value = props.forcePaginationNum;
        refreshData();
      });
      const offset = ref(0);
      const dataLength = computed(() => parseInt(dataFiltered.value.length));
      const dataView = computed(() => {
        const tmpData = dataFiltered.value;
        let newObj = [];

        let l = offset.value + paginationNum.value;
        if (l > dataLength.value) l = dataLength.value;

        for (let i = offset.value; i < l; i++) {
          newObj.push(tmpData[i]);
        }
        return newObj;
      });
      // Al cambio dei dati l'offset si azzera e torni alla prima pagina
      watch(dataLength, () => (offset.value = 0));
      // Salvi la preferenza di righe per pagina in localStorage
      watch(paginationNum, (newVal) => (localStorage.paginationNum = newVal));
      function next() {
        const temp_offset = parseInt(offset.value + paginationNum.value);
        if (temp_offset >= dataLength.value) return; // Se l'offset supererebbe la lunghezza dei dati
        offset.value = temp_offset;
      }
      function previous() {
        let temp_offset = offset.value - paginationNum.value;
        if (temp_offset < 0) temp_offset = 0;
        offset.value = temp_offset;
      }
      const firstEntryIndex = computed(() => offset.value + 1);
      const lastEntryIndex = computed(() => {
        const limit = offset.value + paginationNum.value;
        if (limit > dataLength.value) return dataLength.value;
        return limit;
      });
      //////////////////////////////// END PAGINATION ////////////////////////////////

      // Download TSV
      const downloading = ref(false);
      const downloadTSV = (...args) => Utils.downloadTSV(props.structure.title, dataFiltered, downloading, ...args);

      // Summary Row
      const summaryData = computed(() => Utils.getSummaryData(dataFiltered.value, props.structure.summaryFields));

      const checkRouteByName = (routeName) => {
        try {
          router.resolve({ name: routeName });
          return true;
        } catch (error) {
          return false;
        }
      };

      return {
        dataView,
        dataLength,
        searchString,
        searchCaseSensitive,
        lastEntryIndex,
        firstEntryIndex,
        paginationNum,
        paddingElementsCount,
        updateTimestamp,
        downloading,
        orderKey,
        order,
        timestamps,
        summaryData,
        filterOpen,
        filters,
        refreshData,
        next,
        previous,
        downloadTSV,
        t: useI18n().t,
        checkRouteByName,
      };
    },
  };
</script>

<style></style>
