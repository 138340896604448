<template>
  <div class="flex justify-start items-center text-gray-600 dark:text-gray-300 font-semibold w-full mx-auto">
    <div v-if="selectedSection" class="flex justify-start items-center">
      <router-link
        class="text-lg text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 material-icons"
        :to="returnPath(selectedSection.path)">
        {{ selectedSection.icon }}
      </router-link>
      <div class="mx-2">
        <p class="hidden material-icons text-gray-300 text-base">arrow_forward_ios</p>
      </div>
    </div>
    <div class="flex justify-start items-center" v-for="(el, index) in path" :key="el">
      <router-link v-if="index < path.length - 1" class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200" :to="returnPath(el)">
        {{ capitalize($t(parseString(el, index))) }}
      </router-link>
      <router-link v-else :to="path" class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200">
        {{ capitalize($t(parseString(el, index))) }}
      </router-link>
      <div class="mx-4">
        <p v-if="index < path.length - 1" class="material-icons text-gray-300 text-base">arrow_forward_ios</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router';
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      //init router
      const route = useRoute();
      const path = computed(() => {
        let fullPath = route.path;
        if (fullPath === undefined || fullPath === null) return;
        fullPath = fullPath.split('/');
        fullPath.shift();
        return fullPath;
      });

      const store = useStore();
      const selectedSection = computed(() => store.getters.selectedSection);

      //return path
      const fullPath = computed(() => route.fullPath);

      function returnPath(point) {
        let path = fullPath.value.split(point);
        if (path[1] === '') return '';
        return path[0] + point;
      }

      const capitalize = computed(() => (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });

      //for take fist in uppercase
      function parseString(string, index) {
        let newString = string;
        switch (newString) {
          case 'user':
            newString = 'paths.users';
            break;
          case 'station':
            newString = 'paths.stations';
            break;
          case 'detail':
            newString = 'paths.details';
            break;
          case 'general':
            newString = 'paths.overall';
            break;
          case 'createEvses':
            newString = 'paths.EVSECreation';
            break;
          case 'Recharge':
            newString = 'paths.recharges';
            break;
          default:
            newString = index === 0 || !string.includes('_') ? 'paths.' + string : string;
            break;
        }
        return newString;
      }
      return { selectedSection, path, capitalize, parseString, returnPath };
    },
  };
</script>

<style></style>
