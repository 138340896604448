<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { ref, reactive } from 'vue';
  export default {
    setup() {
      const intervalCountArray = ['', 'day', 'week', 'month', 'year'];
      const recurringBillingTimeArray = ref(['']);

      let recurringTime = (val) => {
        if (!val) recurringBillingTimeArray.value = [''];
        const test = val.target.value;

        let count = 0;
        if (test == 'day') count = 30;
        else if (test == 'week') count = 52;
        else if (test == 'month') count = 12;
        else if (test == 'year') count = 1;

        let tmp = [];
        for (let i = 0; i <= count; i++) {
          tmp.push(i);
        }
        recurringBillingTimeArray.value = tmp;
      };

      //structure of page
      const insertData = {
        redirect_path: '/recharge/subscriptions/products/list',
        create_endpoint: 'createProduct',
        creation_title: 'views.subscriptions.creation',
        data: [
          {
            title: 'views.subscriptions.id',
            name: 'name',
            required: true,
          },
          {
            title: 'views.subscriptions.amount',
            name: 'unit_amount_decimal',
            placeholder: 'ex 100',
            required: true,
          },
          {
            title: 'views.subscriptions.recurring_billing_type',
            name: 'interval',
            select: intervalCountArray,
            on_change: recurringTime,
            required: true,
          },
          reactive({
            title: 'views.subscriptions.recurring_billing_time',
            name: 'interval_count',
            select: recurringBillingTimeArray,
            required: true,
          }),
          {
            title: 'views.subscriptions.description',
            name: 'description',
          },
          {
            title: 'views.subscriptions.active',
            name: 'active',
            boolean: true,
          },
        ],
      };

      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
