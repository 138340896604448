<template>
  <button class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center" type="button">Dropdown button</button>

  <div id="dropdown" class="z-10 hidden bg-white dark:bg-gray-900 divide-y divide-gray-100 rounded shadow w-44" data-popper-placement="bottom" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(583px, 829px)">
    <ul class="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefault">
      <li>
        <base-router-link v-for="el in list" :key="el" :path="el.pathName" class="block px-4 py-2 hover:bg-gray-100">el.name</base-router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style></style>
