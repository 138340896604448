import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    devices: [],
    selectedDevice: [],
    deviceEvents: [],
    deviceMessages: [],
    deviceCommands: [],
    deviceTrips: [],
  },
  getters: {
    devices: (state) => state.devices,
    selectedDevice: (state) => (state.selectedDevice !== null ? state.selectedDevice[0] : state.selectedDevice),
    deviceEvents: (state) => state.deviceEvents,
    deviceMessages: (state) => state.deviceMessages,
    deviceCommands: (state) => state.deviceCommands,
    deviceTrips: (state) => state.deviceTrips,
  },
  mutations: {
    setDevices: (state, payload) => (state.devices = payload),
    setSelectedDevice: (state, payload) => (state.selectedDevice = payload),
    setDeviceEvents: (state, payload) => (state.deviceEvents = payload),
    setDeviceMessages: (state, payload) => (state.deviceMessages = payload),
    setDeviceCommands: (state, payload) => (state.deviceCommands = payload),
    setDeviceTrips: (state, payload) => (state.deviceTrips = payload),
  },
  actions: {
    saveDeviceConfiguration: (_, { id, values }) => {
      return ApiCaller.PUT(`/deviceConfiguration/${id}/save`, { values });
    },
    getDevices: (ctx, payload) => {
      ApiCaller.GET(`/device`, payload).then((data) => ctx.commit('setDevices', Utils.createFiltrableData(data)));
    },
    getSelectedDevice: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}`, payload).then((data) => ctx.commit('setSelectedDevice', Utils.createFiltrableData(data))),
    getDeviceEvents: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}/events`).then((data) => ctx.commit('setDeviceEvents', Utils.createFiltrableData(data))),
    getDeviceMessages: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}/messages`).then((data) => ctx.commit('setDeviceMessages', Utils.createFiltrableData(data))),
    getDeviceCommands: (ctx, payload) => {
      ctx.commit('setDeviceCommands', []),
        ApiCaller.GET(`/device/${payload.id}/deviceCommands`).then((data) => ctx.commit('setDeviceCommands', Utils.createFiltrableData(data)));
    },
    getDeviceTrips: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}/trips`).then((data) => ctx.commit('setDeviceTrips', Utils.createFiltrableData(data))),

    //QUERIES
    updateDevice: (_, payload) => ApiCaller.PUT(`/device/${payload.id}`, payload.body),
    createDevice: (_, payload) => ApiCaller.POST('/device', payload),
    sendCommand: (_, payload) => ApiCaller.POST(`/device/${payload.id}/sendCommand/${payload.command}`),
  },
};
