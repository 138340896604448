<template>
  <div v-if="selectedScooter" class="px-16 pt-8">
    <base-details-header :el="selectedScooter" :structure="headerStructure" />
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      const selectedDevice = { device: route.params.id };
      onMounted(() => {
        store.dispatch('getManagers');
        store.dispatch('setSelectedScooter', selectedElID);
        store.dispatch('getScooterTypes');
        store.dispatch('getRentals', {
          ...selectedDevice,
        });
        store.dispatch('getZoneGroups');
      });
      const selectedScooter = computed(() => {
        return store.getters.selectedScooter;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Generali',
          name: 'menu.sharing.scooterGenerals',
        },
        {
          text: 'Noleggi associati',
          name: 'menu.sharing.scooterRentals',
        },
      ];
      const headerStructure = {
        title: [
          {
            title: 'plate',
          },
          {
            class: 'text-sm font-normal text-gray-700',
            placeholder: 'Livello batteria: ',
            title: 'battery_level',
            footer: '%',
          },
        ],
        data: [
          {
            title: 'Prezzo giornaliero ',
            name: 'price_per_day',
            footer: ' €',
          },
          {
            title: 'Prezzo al minuto ',
            name: 'price_per_minute',
            footer: ' €',
          },
          {
            title: 'Prezzo 1-3h ',
            name: 'price_1_3',
            footer: ' €',
          },
          {
            title: 'Prezzo 3-6h ',
            name: 'price_3_6',
            footer: ' €',
          },
          {
            title: 'Prezzo al km ',
            name: 'price_per_km',
            footer: ' €',
          },
          {
            title: 'Posizione ',
            placeholder: 'lat',
            name: 'lng',
            separator: ', ',
          },
        ],
      };
      return {
        selectedScooter,
        headers,
        headerStructure,
      };
    },
  };
</script>
<style></style>
