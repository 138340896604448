<template>
  <div class="p-3 border border-gray-300 dark:border-gray-700 rounded-full shadow-md bg-gradient-to-r from-cyan-500 to-primary-500">
    <div class="grid grid-cols-7 mb-2 font-bold text-lg text-gray-700">
      <p>Serial&ID</p>
      <p class="col-span-2">{{ t('views.stations.stationInfos') }}</p>
      <p>{{ t('views.stations.rechargeStart') }}</p>
      <p>{{ t('station.socket') }}</p>
      <p>kWh</p>
      <p>Penalty</p>
    </div>
    <div class="grid grid-cols-7 text-xs text-gray-600 dark:text-gray-300">
      <base-router-link :path="{ name: 'stationGenerals', params: { id: `${el.station_id}` } }" class="flex text-gray-600 dark:text-gray-300">
        <p class="pr-2">{{ el.plate }}</p>
        <p>@{{ el.station_id }}</p>
      </base-router-link>
      <div class="col-span-2">
        <p>{{ el.indirizzo }}</p>
      </div>
      <div>{{ el.inizio_ricarica }}</div>
      <div>{{ el.numero_presa }}</div>
      <div>{{ el.consumed_energy }}</div>
      <div>{{ el.penalty_time }}</div>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      el: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return { t: useI18n().t };
    },
  };
</script>

<style></style>
