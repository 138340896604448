import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    selectedType: null,
    typeVehicles: [],
  },
  getters: {
    selectedType: (state) => {
      if (state.selectedType) return state.selectedType[0];
      return state.selectedType;
    },
    typeVehicles: (state) => state.typeVehicles,
  },
  mutations: {
    setSelectedType: (state, data) => (state.selectedType = data),
    //bikes
    setSelectedTypeVehicles: (state, data) => (state.typeVehicles = data),
  },
  actions: {
    getSelectedType: (ctx, payload) => {
      ctx.commit("setSelectedType", []);

      ApiCaller.GET(`/vehicle_types/${payload.id}`, {
        vehicleType: payload.type,
      }).then((data) => {
        ctx.commit("setSelectedType", Utils.createFiltrableData(data));
      });
    },

    //bikes
    setSelectedBikeTypeVehicles: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/bike`, {
        type: "bike",
        typeId: payload.id,
      }).then((data) => {
        ctx.commit("setSelectedTypeVehicles", Utils.createFiltrableData(data));
      }),
    //scooters
    setSelectedScooterTypeVehicles: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/scooter`, {
        type: "scooter",
        typeId: payload.id,
      }).then((data) => {
        ctx.commit("setSelectedTypeVehicles", Utils.createFiltrableData(data));
      }),
    //cars
    setSelectedCarTypeVehicles: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/car`, {
        type: "car",
        typeId: payload.id,
      }).then((data) => {
        ctx.commit("setSelectedTypeVehicles", Utils.createFiltrableData(data));
      }),
    //QUERIES
    updateType: (_, payload) =>
      ApiCaller.PUT(`/vehicle_type/update/${payload.id}`, payload.body),
  },
  modules: {},
};
