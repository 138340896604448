import { Utils, ApiCaller } from '@/Utils/utils';
export default {
  state: {
    siemensStations: [],
    selectedSiemensStation: null,
  },
  getters: {
    siemensStations: (state) => state.stations,
    selectedSiemensStation: (state) => state.selectedSiemensStation,
  },
  mutations: {
    setSiemensStations: (state, data) => (state.stations = Utils.createFiltrableData(data)),
    setSelectedSiemensStation: (state, data) => (state.selectedSiemensStation = data),
  },
  actions: {
    getSiemensStations: (ctx, payload) => {
      ctx.commit('setSiemensStations', []);
      ApiCaller.GET('/siemens/cu/listAll', payload).then((data) => {
        ctx.commit('setSiemensStations', data);
      });
    },
    getSelectedSiemensStation: (ctx, payload) => {
      ApiCaller.GET(`/siemens/cu/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedSiemensStation', data);
      });
    },
    readParameters: (_, payload) => ApiCaller.GET(`/siemens/cu/plugs/readParameters/${payload.id}`),
    changeVisibility: (_, payload) => ApiCaller.PUT(`/siemens/cu/plugs/changeVisibilty`, payload),
    unlockPlug: (_, payload) => ApiCaller.PUT(`/siemens/cu/plugs/unlockPlug`, payload),
    rebootStation: (_, payload) => ApiCaller.PUT('/siemens/cu/rebootStation', payload),
  },
  modules: {},
};
