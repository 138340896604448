<template>
  <div v-if="el && structure" class="mb-4 mx-auto w-full">
    <div class="my-4 items-center">
      <div v-if="structure.image" class="flex">
        <img
          :src="el[structure.image.picture]"
          class="h-12 w-12 rounded-full border border-gray-300 dark:border-gray-700" />
        <div class="flex flex-col justify-center items-start ml-3 pt-1">
          <h2 class="leading-none capitalize text-gray-700 font-semibold text-lg">
            {{ el[structure.image.nickname] }}
          </h2>
          <h3 class="leading-none lowercase text-gray-400 font-medium text-base">
            {{ el[structure.image.email] }}
          </h3>
        </div>
      </div>
      <div class="text-xl font-bold" v-for="title in structure.title" :key="title">
        <div v-if="title.isArray">
          <div v-if="title.subTitle">
            <div v-for="dataEl in el[title.title]" :key="dataEl">
              <p v-if="dataEl[title.subTitle]">{{ title.placeholder }}{{ dataEl[title.subTitle] }}{{ title.footer }}</p>
            </div>
          </div>
          <div v-else>
            <div v-for="dataEl in el[title.title]" :key="dataEl">
              <p v-if="dataEl">{{ title.placeholder }}{{ dataEl }}{{ title.footer }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="el[title.title]">
          <p :class="title.class">
            {{ title.placeholder ? t(title.placeholder) : '' }}{{ t(el[title.title]) }}{{ title.footer }}
          </p>
        </div>
      </div>
    </div>
    <div class="text-gray-800 flex" :class="structure.class">
      <div>
        <div v-for="data in structure.data" :key="data" class="mb-2">
          <div v-if="data.title">
            <h1
              class="font-bold text-gray-900 dark:text-white bg-gray-200 w-max rounded-md px-1 mr-2"
              :class="data.title_class">
              {{ t(data.title) }}
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div v-for="data in structure.data" :key="data" class="mb-2 flex">
          <div v-if="data.link && el[data.name] != undefined">
            <router-link :to="linkCreation(data.link)">
              <div
                :class="data.class"
                class="font-bold"
                v-html="
                  (el[data.placeholder]
                    ? parseField(el[data.placeholder], data.type)
                    : parseField(data.placeholder)
                    ? parseField(data.placeholder, data.type)
                    : '') +
                  (data.separator ? data.separator : '') +
                  parseField(
                    data.subName && el[data.name][data.subName] ? el[data.name][data.subName] : el[data.name],
                    data.type,
                  ) +
                  (data.footer ? data.footer : '')
                " />
            </router-link>
          </div>
          <div v-else>
            <div
              :class="data.class"
              v-html="
                (el[data.placeholder]
                  ? parseField(el[data.placeholder], data.type)
                  : parseField(data.placeholder)
                  ? parseField(data.placeholder, data.type)
                  : '') +
                (data.separator ? data.separator : '') +
                parseField(
                  data.subName && el[data.name][data.subName] ? el[data.name][data.subName] : el[data.name],
                  data.type,
                ) +
                (data.footer ? data.footer : '')
              " />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Utils } from '../../../Utils/utils';
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      el: {
        type: Object,
      },
      structure: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const parseField = (field, format) => Utils.formatData(field, format);
      const linkCreation = (itm) => Utils.linkCreation(props.el, itm);
      return { linkCreation, parseField, t: useI18n().t };
    },
  };
</script>

<style></style>
