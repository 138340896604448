import { createStore } from 'vuex';

import Device from '@/modules/device/store';
import Mobility from '@/modules/mobility/store';
import Recharge from '@/modules/recharge/store';
import Sharing from '@/modules/sharing/store';
import User from '@/modules/user/store';
import Auth0 from '@/modules/auth0/store';
import UI from '@/modules/UI/store';
import Alarms from '@/modules/alarm/store';
import Tickets from '@/modules/ticket/store';
import Billing from '@/modules/billing/store';

// Old Modules
import headerIndex from './header/headerIndex';
import loaderIndex from './loader/loaderIndex';
import alertIndex from './alert/alertIndex';
import assistenceIndex from './assistance/assistanceIndex';
import localesIndex from './locales/locales';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Billing,
    Device,
    Mobility,
    Recharge,
    Sharing,
    User,
    Auth0,
    UI,
    Alarms,
    Tickets,

    // Old Modules
    headerIndex,
    loaderIndex,
    alertIndex,
    assistenceIndex,
    localesIndex,
  },
});
