import { Utils, ApiCaller } from '@/Utils/utils';

export default {
  state: {
    organisations: [],
    roles: [],
    consumers: [],
  },
  getters: {
    organisations: (state) => state.organisations,
    roles: (state) => state.roles,
    consumers: (state) => state.consumers,
  },
  mutations: {
    setOrganisations: (state, payload) => (state.organisations = payload),
    setRoles: (state, payload) => (state.roles = payload),
    setConsumers: (state, payload) => (state.consumers = payload),
  },
  actions: {
    getOrganisations: (ctx, payload) => {
      ctx.commit('setOrganisations', []),
        ApiCaller.GET(`/organisation`, payload).then((data) =>
          ctx.commit('setOrganisations', Utils.createFiltrableData(data)),
        );
    },
    getRoles: (ctx, payload) => {
      ctx.commit('setRoles', []), ApiCaller.GET(`/roles`, payload).then((data) => ctx.commit('setRoles', data));
    },
    getConsumers: (ctx, payload) => {
      ctx.commit('setConsumers', []),
        ApiCaller.GET(`/consumers`, payload).then((data) =>
          ctx.commit('setConsumers', Utils.createFiltrableData(data)),
        );
    },
    //APIES
    createConsumer: (_, payload) => {
      ApiCaller.POST(`/consumers`, payload);
    },
  },
  modules: {},
};
