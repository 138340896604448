<template>
  <div class="z-10 relative overflow-y-hidden flex flex-col items-center justify-start w-full h-screen overflow-hidden bg-white dark:bg-gray-900">
    <section class="relative w-full h-full overflow-y-hidden flex flex-col items-center justify-start">
      <div class="flex h-full w-full mx-auto overflow-y-hidden">
        <div class="h-full relative flex flex-col justify-between border-r-0 border-gray-300 dark:border-gray-700">
          <slot name="sideBar" />
        </div>
        <div class="h-full relative no-scrollbar overflow-y-scroll w-full bg-white dark:bg-gray-900">
          <div class="h-full relative no-scrollbar overflow-y-scroll w-full">
            <div class="sticky top-0 left-0 w-full z-20">
              <the-header />
            </div>
            <div class="relative w-full router-view-container bg-white dark:bg-gray-900 z-10 p-8">
              <slot name="content" />
              <slot name="widgets" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import TheHeader from '@/modules/UI/components/NavBar/TheHeader.vue';
  export default {
    components: { TheHeader },
    setup() {
      return {};
    },
  };
</script>

<style scoped>
  .router-view-container {
    height: calc(100vh - 4rem) !important;
  }
</style>
