import { ApiCaller, Utils } from '@/Utils/utils';
import rechargeInProgress from './subpages/rechargeInProgress';
import anomalies from './subpages/anomalies';
import analytics from './subpages/analytics';

export default {
  state: {
    rechargeList: null,
    selectedRecharge: null,
    completeRechargeList: [],
    userRechargeList: null,
    userRechargeInProgress: null,
    stationRechargeList: null,
    stationRechargeInProgress: null,
    stationRechargesAnalytics: null,
    stationForRechargesAnalytics: [],
    rechargesWithoutCDR: null,
    monthStatistics: null,
  },
  getters: {
    selectedRecharge: (state) => (state.selectedRecharge ? state.selectedRecharge[0] : null),
    completeRechargeList: (state) => state.completeRechargeList,
    rechargeList: (state) => state.rechargeList,
    userRechargeList: (state) => state.userRechargeList,
    userRechargeInProgress: (state) => state.userRechargeInProgress,
    stationRechargeList: (state) => state.stationRechargeList,
    stationRechargeInProgress: (state) => state.stationRechargeInProgress,
    stationRechargesAnalytics: (state) => state.stationRechargesAnalytics,
    rechargesWithoutCDR: (state) => state.rechargesWithoutCDR,
    stationForRechargesAnalytics: (state) => state.stationForRechargesAnalytics,
    monthStatistics: (state) => state.monthStatistics,
  },
  mutations: {
    setSelectedRecharge: (state, data) => (state.selectedRecharge = data),
    getCompleteRechargesList: (state, data) => (state.completeRechargeList = data),
    getRechargesList: (state, data) => (state.rechargeList = data),
    getUserRechargeList: (state, data) => (state.userRechargeList = data),
    getUserRechargeInProgress: (state, data) => (state.userRechargeInProgress = data),
    //station
    getStationRechargeList: (state, data) => (state.stationRechargeList = data),
    getStationRechargeInProgress: (state, data) => (state.stationRechargeInProgress = data),
    setStationRechargesAnalytics: (state, data) => (state.stationRechargesAnalytics = data),
    setRechargesWithoutCDR: (state, data) => (state.rechargesWithoutCDR = data),
    setStationForRechargesAnalytics: (state, data) => (state.stationForRechargesAnalytics = data),
    setMonthStatistics: (state, data) => (state.monthStatistics = data),
  },
  actions: {
    setSelectedRecharge: (ctx, payload) =>
      ApiCaller.GET(`/recharge/${payload.id}`).then((data) => ctx.commit('setSelectedRecharge', Utils.createFiltrableData(data))),
    getCompleteRechargesList: (ctx, payload) =>
      ApiCaller.GET(`/recharges`, payload).then((data) => ctx.commit('getCompleteRechargesList', Utils.createFiltrableData(data))),
    getRechargesList: (ctx, payload) => ApiCaller.GET(`/recharges`, payload).then((data) => ctx.commit('getRechargesList', data)),
    getUserRechargeList: (ctx, payload) =>
      ApiCaller.GET(`/user/recharges/${payload}`, payload).then((data) => ctx.commit('getUserRechargeList', Utils.createFiltrableData(data))),
    getUserRechargeInProgress: (ctx, payload) =>
      ApiCaller.GET(`/user/recharges/${payload}/inProgress`, payload).then((data) => ctx.commit('getUserRechargeInProgress', data)),
    //stations
    getStationRechargeList: (ctx, payload) =>
      ApiCaller.GET(`/station/recharges/${payload}`, payload).then((data) => ctx.commit('getStationRechargeList', Utils.createFiltrableData(data))),
    getStationRechargeInProgress: (ctx, payload) =>
      ApiCaller.GET(`/station/recharges/${payload}/inProgress`, payload).then((data) => ctx.commit('getStationRechargeInProgress', data)),
    getStationRechargesAnalytics: (ctx, payload) =>
      ApiCaller.PUT(`/siemens/cu/getRecharges`, payload).then((data) => ctx.commit('setStationRechargesAnalytics', data)),
    getRechargesWithoutCDR: (ctx, payload) =>
      ApiCaller.GET(`/siemens/getRechargesWithoutCDR`, payload).then((data) => ctx.commit('setRechargesWithoutCDR', data)),
    getStationForRechargesAnalytics: (ctx, payload) =>
      ApiCaller.GET(`/siemens/cu/stationForRechargesAnalytics`, payload).then((data) => ctx.commit('setStationForRechargesAnalytics', data)),
    getMonthStatistics: (ctx, payload) =>
      ApiCaller.GET(`/siemens/cu/${payload.id}/monthStatistics`, payload).then((data) => ctx.commit('setMonthStatistics', data)),
    getYearStatistics: (_, payload) => ApiCaller.GET(`/stations/report/${payload.year}`, payload.data),
    sendFakeCDR: (_, payload) => ApiCaller.POST(`/station/recharges/sendFakeCDR`, payload),
  },
  modules: {
    rechargeInProgress,
    anomalies,
    analytics,
  },
};
