<template>
  <!-- <div class="flex items-center">
    <p class="mr-2">{{ $t('billings.actualSuffix') }}</p>
    <button @click="addSuffixField = !addSuffixField" class="material-icons rounded-full bg-primary-700 text-white">
      add
    </button>
  </div>
  <div class="flex items-center mt-2">
    <div class="rounded-md px-3 mr-1 bg-primary-700 text-white text-xl flex items-center">
      <p>{{ selectedSuffix?.suffix }}</p>
    </div>
    <div class="mr-2 self-end">
      <base-select :data="selectData" @change="changeSelectedSuffix" />
    </div>
  </div>
  <div
    v-if="addSuffixField"
    class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75"
    @click="addSuffixField = !addSuffixField">
    <div class="bg-white rounded-lg px-10 pb-10 pt-5" @click.stop>
       Contenuto del pannello al centro -->
  <!-- <p class="mb-8 text-center font-bold">{{ $t('billing.addSuffix') }}</p>
      <div class="flex justify-center">
        <base-input-field v-model="newSuffix" size="small" class="mr-2" />
        <button class="px-1 material-icons rounded-full bg-primary-700 text-white" @click="createSuffix">add</button>
      </div>
    </div>
  </div> -->
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => {
        store.dispatch('getSuffixes', payload);
        store.dispatch('getPayments', payload);
      };
      //prendo i dati grezzi
      const suffixes = computed(() => store.getters.suffixes);
      const payments = computed(() => store.getters.payments);
      const addSuffixField = ref(false);
      const newSuffix = ref('');

      //li trasformo in dati utilizzabili
      const selectData = computed(() => {
        return {
          name: 'suffix',
          elReturned: 'id',
          array: suffixes?.value,
        };
      });
      const selectedSuffix = computed(() => suffixes.value.find((suffix) => suffix.selected == true));

      //funzioni
      const changeSelectedSuffix = (event) => store.dispatch('setSelectedSuffix', { id: event.target.value });
      const createSuffix = () => {
        if (newSuffix.value == '') return;
        store.dispatch('createSuffix', { suffix: newSuffix.value });
        newSuffix.value = '';
        addSuffixField.value = false;
      };

      // table
      const structure = {
        title: 'views.billings.payments',
        data: [
          {
            title: 'views.billings.id',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'id',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/billings/payments/:id' },
          },
          {
            title: 'views.billings.name',
            fields: [
              {
                name: 'user',
                subName: 'name',
              },
            ],
            span: 1,
          },
          {
            title: 'views.billings.description',
            field: 'description',
            span: 1,
          },
          {
            title: 'views.billings.invoiced',
            field: 'invoiced',
            boolean: {
              if_true: 'views.billings.invoiced',
              if_false: 'views.billings.notInvoiced',
            },
            span: 1,
          },
          {
            title: 'views.billings.total',
            field: 'total',
            format: 'euro',
            span: 1,
          },
          {
            title: 'views.billings.updated_at',
            field: 'updated_at',
            format: 'datetime',
          },
          {
            title: 'views.billings.created_at',
            field: 'created_at',
            format: 'datetime',
          },
        ],
      };

      return {
        data: payments,
        structure,
        selectData,
        selectedSuffix,
        addSuffixField,
        newSuffix,
        changeSelectedSuffix,
        refreshPage,
        createSuffix,
      };
    },
  };
</script>

<style></style>
