<template>
  <!---------------------------------------------------------------->
  <div v-if="selectedVehicle && selectedVehicle.id && selectedVehicle.organisation" class="flex flex-col">
    <div class="w-full">
      <div class="w-full flex flex-col">
        <h3 class="font-semibold leading-none col-span-1 my-4 w-full text-left mt-4">{{ $t('vehicle.info') }}</h3>
        <div class="w-full relative flex flex-row h-min flex-nowrap justify-start">
          <div class="h-full w-full flex flex-col justify-center items-start order-2">
            <div class="w-full flex justify-between text-left space-x-6 bg-gray-900 rounded-2xl border border-gray-200">
              <!-- ORGANIZZAZIONE -->
              <span class="rounded-2xl flex flex-col shadow-sm w-full px-5 py-6 order-1">
                <h3 class="leading-none uppercase text-white text-xs font-bold">Organizzazione</h3>
                <h1 class="flex flex-col w-full text-left font-semibold mt-1 leading-none">
                  <router-link :to="`/organisation/${selectedVehicle.organisation.id}`">
                    <text class="text-primary-400 text-xs font-bold">@{{ selectedVehicle.organisation.id }}</text>
                  </router-link>
                  <text class="text-gray-100 text-lg leading-none mt-1">{{ selectedVehicle.organisation.name }}</text>
                  <text class="text-gray-100 text-sm leading-none">{{ selectedVehicle.organisation.address }}</text>
                </h1>
              </span>
              <!-- ORGANIZZAZIONE FINE -->
              <!-- REFERENTE -->
              <span class="rounded-2xl flex flex-col shadow-sm w-full px-5 py-6 order-4">
                <h3 class="leading-none uppercase text-white text-xs font-bold mb-1">Referente</h3>
                <p class="flex justify-start items-center h-4 w-full text-left text-sm font-semibold mt-1 leading-none">
                  <text class="text-sm text-primary-400 pr-1 material-icons">person</text>
                  <text class="text-white font-bold">{{ selectedVehicle.organisation.referent.name }}</text>
                </p>
                <p class="flex justify-start items-center h-4 w-full text-left text-sm font-semibold leading-none">
                  <text class="text-sm text-primary-400 pr-1 material-icons">alternate_email</text>
                  <a :href="`mailto:${selectedVehicle.organisation.referent.email}`" class="text-white font-bold leading-none">
                    <text>{{ selectedVehicle.organisation.referent.email }}</text>
                    <text class="material-icons text-white ml-1 text-xs">open_in_new</text>
                  </a>
                </p>
                <p class="flex justify-start items-center h-4 w-full text-left text-sm font-semibold leading-none">
                  <text class="text-sm text-primary-400 pr-1 material-icons">phone</text>
                  <a :href="`tel:${selectedVehicle.organisation.referent.cellphone}`" class="text-white font-bold leading-none">
                    <text>{{ selectedVehicle.organisation.referent.cellphone }}</text>
                    <text class="material-icons text-white ml-1 text-xs">open_in_new</text>
                  </a>
                </p>
              </span>
              <!-- REFERENTE FINE -->
              <!-- CENTRALINA -->
              <span class="rounded-2xl flex flex-col shadow-sm w-full px-5 py-6 order-2">
                <h3 class="leading-none uppercase text-white text-xs font-bold">Centralina</h3>
                <div class="w-full flex flex-col text-left font-semibold mt-1 leading-none">
                  <router-link :to="`/device/devices/${selectedVehicle.device.id}/general`">
                    <text class="text-primary-400 text-xs font-bold">@{{ selectedVehicle.device.id }}</text>
                  </router-link>
                  <text class="text-gray-100 text-lg leading-none mt-1">{{ selectedVehicle.device.imei }}</text>
                  <text class="text-gray-100 text-sm leading-none">{{ selectedVehicle.device.sim_owner }} ({{ selectedVehicle.device.sim_operator }})</text>
                </div>
              </span>
              <!-- CENTRALINA FINE -->
              <!-- MODELLO -->
              <span class="rounded-2xl flex flex-col shadow-sm w-full px-5 py-6 order-3">
                <h3 class="leading-none uppercase text-white text-xs font-bold">Modello</h3>
                <h1 class="flex flex-col w-full text-left font-semibold mt-1 leading-none">
                  <router-link :to="`/mobility/models/${selectedVehicle.model.id}/details`">
                    <text class="text-primary-400 text-xs font-bold">@{{ selectedVehicle.model.id }}</text>
                  </router-link>
                  <text class="text-gray-100 text-lg leading-none mt-1">{{ selectedVehicle.model.name }}</text>
                  <text class="text-gray-100 text-sm leading-none">{{ selectedVehicle.model.producer }}</text>
                </h1>
              </span>
              <!-- MODELLO FINE -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="relative grid grid-cols-1 w-full">
      <!-- MAP -->
      <template v-if="selectedVehicle.last_message && selectedVehicle.last_message && selectedVehicle.last_message.gps">
        <h3 class="font-semibold leading-none col-span-1 my-4 w-full text-left mt-12">{{ $t('vehicle.map') }}</h3>
        <div class="rounded-2xl overflow-hidden col-span-1 border border-gray-200 w-full h-96">
          <GMapMap
            :disableDefaultUI="true"
            :center="{
              lat: parseFloat(selectedVehicle.last_message.gps.latitude),
              lng: parseFloat(selectedVehicle.last_message.gps.longitude),
            }"
            :zoom="13"
            map-type-id="roadmap"
            :options="{ mapId: 'roadmap' }">
            <GMapMarker
              :position="{
                lat: parseFloat(selectedVehicle.last_message.gps.latitude),
                lng: parseFloat(selectedVehicle.last_message.gps.longitude),
              }"
              :icon="{
                url: 'https://filippo.onme.cloud/applicazione/CARRARO/assets/imgs/tractors/tractor_icon.png',
                scaledSize: { width: 77, height: 77 },
                labelOrigin: { x: 16, y: -10 },
              }" />
          </GMapMap>
        </div>
      </template>
      <div v-else>Dati Device non disponibili...</div>
      <!-- END MAP -->
      <DeviceMessageDetails v-if="selectedVehicle && selectedVehicle?.last_message" :message="{ message: selectedVehicle.last_message }" />
      <div class="my-12"></div>
    </section>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';

  import DeviceMessageDetails from './DeviceMessageDetails.vue';

  export default {
    components: { DeviceMessageDetails },
    setup() {
      const store = useStore();
      const router = useRouter();
      const selectedVehicle = computed(() => store.getters.selectedVehicle);

      const parseDate = (date) => {
        if (date) return date.replace('T', ' ').split('.')[0];
        return '-';
      };

      return {
        selectedVehicle,
        router,
        parseDate,
      };
    },
  };
</script>

<style scoped>
  .vue-map {
    border-radius: 0rem;
  }
</style>