<template>
  <the-languages></the-languages>
  <main-page>
    <template #sideBar>
      <side-bar-menu />
    </template>
    <template v-if="isAuthenticated && selectedPlatform" #content>
      <router-view />
    </template>
    <template v-else #content>
      <section class="h-full w-full flex flex-col justify-start items-end text-right pr-4 text-gray-600 dark:text-gray-300 dark:text-white">
        <span class="animate-bounce material-icons text-gray-900 dark:text-white mb-2">north</span>
        <text>
          Ops... non sei autorizzato.
          <br />
          Prova a effettuare il login con un diverso account.
        </text>
      </section>
    </template>
    <template #widgets>
      <base-alert />
      <base-loader />
    </template>
  </main-page>
</template>

<!-- Definizione di un componente Vue.js -->
<script>
  // Importa le librerie e i componenti necessari
  import { onMounted, computed } from 'vue';
  import BaseAlert from './components/Standard/Alert/BaseAlert.vue';
  import MainPage from '@/modules/UI/components/MainPage/MainPage.vue';
  import SideBarMenu from '@/modules/UI/components/SideBarMenu/SideBarMenu.vue';
  import TheLanguages from '@/modules/languages/TheLanguages.vue';
  import { useStore } from 'vuex';

  // Esporta il componente Vue.js
  export default {
    // Elenco dei componenti importati
    components: { TheLanguages, BaseAlert, MainPage, SideBarMenu },
    // Definizione della funzione setup()
    setup() {
      // Definizione della funzione handleTitleFavIcon() che imposta il titolo della pagina web
      const handleTitleFavIcon = (title = 'OnHub | Pannello di Gestione') => (document.title = title);
      // Esegue la funzione handleTitleFavIcon() quando il componente viene montato
      onMounted(handleTitleFavIcon);

      // Ottiene lo store di Vuex
      const store = useStore();
      // Crea una costante isAuthenticated che calcola in modo reattivo il valore di store.getters.isAuthenticated
      const isAuthenticated = computed(() => store.getters.isAuthenticated);
      // Crea una costante selectedPlatform che calcola in modo reattivo il valore di store.getters.selectedPlatform
      const selectedPlatform = computed(() => store.getters.selectedPlatform);

      // Restituisce un oggetto con le costanti appena create, che possono essere utilizzate nel template del componente
      return { isAuthenticated, selectedPlatform };
    },
  };
</script>

<style>
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: 0rem;
    /* Firefox */
  }
</style>
