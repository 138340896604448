<template>
  <div class="flex flex-row space-x-6">
    <base-button @click="syncEcar">Sincronizza con Zeus</base-button>
    <base-button @click="updateEvseStatus">Aggiorna stato prese con stato più vecchio di 10 minuti</base-button>
  </div>
  <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import router from '@/router/router';

  export default {
    components: {},
    setup() {
      const store = useStore();
      // init station page
      function refreshPage(payload) {
        store.dispatch('getManagers');
        store.dispatch('getStationTypes');
        store.dispatch('getCompleteStationList', payload);
      }

      //change station value
      const completeStationList = computed(() => store.getters.completeStationList);

      // table
      const structure = {
        title: 'views.stations.title',

        creationLink: {
          title: 'buttons.addStation',
          namePath: 'menu.recharge.stationsCreation',
        },
        summaryFields: [
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'Su ECAR',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'environment',
            value: 'ECAR',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'Su ZEUS',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'environment',
            value: 'ZEUS_PROD',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.available',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'stato',
            value: 'AVAILABLE',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.installed',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'stato',
            value: 'INSTALLED',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.planned',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'stato',
            value: 'PLANNED',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.maintenance',
            field: 'id',
            unit: 'station.plurals',
            valueField: 'stato',
            value: 'MAINTENANCE',
          },
        ],
        data: [
          {
            title: 'views.stations.idAndSerial',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'plate',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/recharge/stations/:id' },
          },
          {
            title: 'station.cuCode',
            field: 'cuCode',
            span: 1,
          },
          {
            title: 'station.cpo',
            field: 'environment',
            span: 1,
          },
          {
            title: 'address.body',
            field: 'indirizzo',
            span: 1,
          },
          {
            title: 'latitude',
            field: 'lat',
            span: 1,
          },
          {
            title: 'longitude',
            field: 'lng',
            span: 1,
          },
          {
            title: 'type',
            field: 'station_type',
            span: 1,
          },
          {
            title: 'status',
            field: 'stato',
            span: 1,
          },
          {
            title: 'recharges.createdAt',
            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.stations.link',
            link: {
              link: 'https://oc.zeus.on-charge.it/OCWeb2/#/cuDetails/',
              name: 'cuCode',
              placeholder: 'views.stations.zeusLink',
            },
            span: 1,
          },
        ],
      };

      const syncEcar = () => store.dispatch('syncEcar').then(() => router.go());
      const updateEvseStatus = () => store.dispatch('updateEvseStatus').then(() => router.go());

      return {
        completeList: completeStationList,
        structure,
        refreshPage,
        syncEcar,
        updateEvseStatus,
      };
    },
  };
</script>

<style></style>
