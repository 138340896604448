<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import BaseGeneralsPage from "@/components/Standard/GeneralsPage/BaseGeneralsPage.vue";
export default {
  components: {
    BaseGeneralsPage,
  },
  setup() {
    //  Init vue components
    const store = useStore();
    const selectedStation = computed(() => {
      return store.getters.selectedStation;
    });
    const structure = {
      title: "views.stations.stationInfos",

      edit_endpoint: "updateStation",
      edit_title: "Modifica stazione",
      data: [
        {
          title: "address.body",

          name: "indirizzo",
          statusOK: "indirizzo",
          editable: true,
          placeholder: "--",
        },
        {
          title: "address.latitude",

          name: "lat",
          statusOK: "lat",
          editable: true,
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
        },
        {
          title: "address.longitude",

          name: "lng",
          statusOK: "lng",
          editable: true,
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
        },
        {
          title: "user.types.manager",

          name: "manager",
          statusOK: "manager",
          placeholder: "--",
        },
        {
          title: "views.stations.type",

          name: "station_type",
          statusOK: "station_type",
          placeholder: "--",
        },
        {
          title: "IMEI",
          name: "IMEI",
          editable: true,
          placeholder: "--",
        },
        {
          title: "Environment",
          name: "environment",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.stations.protocolNumber",

          name: "bollato_interno",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.stations.installationDate",

          name: "data_installazione",
          type: "date",
          placeholder: "--",
        },

        {
          title: "views.stations.SIMNumber",

          name: "numero_sim",
          editable: true,
          placeholder: "--",
        },
        {
          title: "PIN",
          name: "pin",
          editable: true,
          placeholder: "--",
        },
        {
          title: "PUK",
          name: "puk",
          editable: true,
          placeholder: "--",
        },
        {
          title: "user.notes",

          name: "description",
          placeholder: "--",
          editable: true,
        },
        {
          title: "views.stations.antennaAvailable",

          name: "additional_antenna",
          boolean: {
            if_true: "views.stations.installedAntenna",
            if_false: "views.stations.notInstalledAntenna",
          },
          editable: true,
          placeholder: "--",
        },
      ],
    };
    return { el: selectedStation, structure };
  },
};
</script>

<style></style>
