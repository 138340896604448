<template>
  <tr
    class="w-full overflow-x-hidden px-4 py-0 grid items-center h-16 bg-white dark:bg-gray-900 border-b hover:bg-gray-100 dark:hover:bg-gray-800 text-xs text-gray-700 dark:text-gray-200"
    :class="structureClass"
    :style="structureStyle">
    <td
      v-for="itm in structure.data"
      :key="itm"
      class="overflow-x-hidden hover:overflow-x-visible hover:pr-2 hover:bg-gradient-to-r to-white dark:to-gray-900 from-gray-100 dark:from-gray-800 z-10 hover:z-20 hover:w-max hover:py-2"
      :class="customClass(itm)">
      <div v-if="itm.fields" class="truncate pr-2 z-10 hover:overflow-visible">
        <div v-if="itm.link" class="flex font-bold capitalize">
          <router-link
            v-if="el[itm.link.idField]"
            :to="linkCreation(el, itm.link)"
            class="flex flex-nowrap font-bold capitalize">
            <base-row-field :el="el" :fields="itm.fields" />
          </router-link>
        </div>
        <div v-else>
          <base-row-field :el="el" :fields="itm.fields" />
        </div>
      </div>

      <div v-else-if="itm.button && itm.button.function">
        <base-button
          @click="itm.button.function(itm.button.field_returned ? el[itm.button.field_returned] : el)"
          :class="itm.button.class"
          :icon="itm.button.icon"
          :onlyicon="true">
          <p v-if="itm.button.title">
            {{ t(itm.button.title) }}
          </p>
        </base-button>
      </div>

      <row-button v-else-if="itm.buttons" :buttons="itm.buttons" :el="el" />

      <div v-else-if="itm.boolean" class="truncate pr-2 z-10 hover:overflow-visible">
        <p v-if="itm.subField ? el[itm.field][itm.subField] : el[itm.field]">
          {{ t(itm.boolean.if_true) }}
        </p>
        <p v-else>{{ t(itm.boolean.if_false) }}</p>
      </div>

      <div v-else-if="itm.isArray">
        <div
          v-for="row in el[itm.field]"
          :key="row"
          class="truncate pr-2 z-10 hover:overflow-visible hover:z-20"
          v-html="itm.placeholder ? itm.placeholder : formatData(row[itm.subField])" />
        <div v-for="row in el[itm.field]" :key="row"></div>
      </div>

      <div v-else-if="itm.link">
        <button v-if="itm.link.function" @click="itm.link.function(el)">
          {{ itm.link.placeholder ? t(itm.link.placeholder) : t('view.clickHere') }}
        </button>
        <a
          v-else
          :href="itm.link.link ? itm.link.link + el[itm.link.name] : el[itm.link.name]"
          target="_blank"
          class="text-blue-600">
          {{ itm.link.placeholder ? t(itm.link.placeholder) : t('view.clickHere') }}
        </a>
      </div>

      <div v-else-if="itm.parseField">
        {{ parseFields(itm) }}
      </div>

      <div
        v-else
        class="truncate pr-2 z-10 hover:overflow-visible hover:z-20"
        v-html="
          formatData(
            itm.subField
              ? el[itm.field]
                ? el[itm.field][itm.subField]
                  ? el[itm.field][itm.subField]
                  : el[itm.field]
                : itm.placeholder
              : el[itm.field]
              ? el[itm.field]
              : itm.placeholder,
            itm.format,
          )
        " />
    </td>
  </tr>
</template>

<script>
  import BaseRowField from './BaseRowField.vue';
  import RowButton from './rowComponents/RowButton.vue';
  import { computed } from 'vue';
  import { Utils } from '@/Utils/utils';
  import { useI18n } from 'vue-i18n';
  export default {
    components: {
      BaseRowField,
      RowButton,
    },
    props: {
      el: { type: Object, required: true },
      structure: { type: Object, required: true },
      index: { type: Number, required: true },
      border: { type: Boolean, required: true },
    },
    setup(props) {
      const formatData = Utils.formatData;
      const linkCreation = Utils.linkCreation;

      const structureClass = computed(() => {
        let cc = props.structure.class === 'undefined' ? '' : `${props.structure.class}`;

        if (props.border) cc += ' border-b border-gray-300 dark:border-gray-700';

        return `${cc}`;
      });
      const structureStyle = computed(() => Utils.countTableColumns(props.structure.data));
      function customClass(itm) {
        if (!itm) return '';
        if (itm.hidden) return 'hidden';
        if (!itm.span) itm.span = 1;
        return `col-span-${itm.span} ${itm.class ? itm.class : ''}`;
      }

      const parseFields = (data) => {
        const field = data.subField
          ? props.el[data.field]
            ? props.el[data.field][data.subField]
              ? props.el[data.field][data.subField]
              : props.el[data.field]
            : data.placeholder
          : props.el[data.field]
          ? props.el[data.field]
          : data.placeholder;

        return data.parseField[field];
      };

      return {
        structureClass,
        structureStyle,
        customClass,
        linkCreation,
        formatData,
        parseFields,
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
