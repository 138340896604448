const messageDuration = 5 * 1000; // in milliseconds
export default {
  state: {
    messages: [],
    lastId: 0,
  },
  getters: {
    getMessages: (state) => state.messages,
  },
  mutations: {
    pushMessage: (state, payload) => {
      state.lastId++;
      state.messages.push({
        id: state.lastId,
        ...payload,
      });
    },
    dismissMessage: (state, payload) => {
      const idx = state.messages.findIndex((obj) => obj.id == payload.id);
      state.messages.splice(idx, 1);
    },
  },
  actions: {
    pushMessage: (ctx, payload) => {
      ctx.commit('pushMessage', payload);
      setTimeout(() => ctx.commit('dismissMessage', { id: ctx.state.lastId }), messageDuration);
    },
    dismissMessage: (ctx, payload) => ctx.commit('dismissMessage', { id: payload.id }),
  },
};
