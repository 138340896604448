<template>
  <div class="flex flex-col justify-center items-start relative p-2 w-full">
    <div
      class="bg-white dark:bg-gray-900 text-left text-gray-400 font-medium text-sm w-full leading-none mb-1"
      :class="returnBg()">
      {{ t(name) }}
    </div>
    <div
      class="w-full bg-white dark:bg-gray-900 text-left px-0 text-base font-semibold border-b border-gray-300 dark:border-gray-700 leading-none pb-3 text-gray-900 dark:text-white"
      :class="returnBg()">
      <slot></slot>
      <span v-if="!statusOK">--</span>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      name: String,
      statusOK: {
        default: true,
      },
    },
    setup(props) {
      const returnBg = () => (props.statusOK ? 'text-gray-400' : 'border-red-400 text-red-800');
      return { returnBg, t: useI18n().t };
    },
  };
</script>

<style></style>
