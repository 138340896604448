<template>
  <base-list-table
    :data="data"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  components: {},
  setup() {
    const store = useStore();

    function refreshPage(payload) {
      store.dispatch("getAuth0Users", payload);
    }

    const authUsers = computed(() => store.getters.authUsers);

    const structure = {
      title: "Lista operatori",
      timeFields: [
        { label: "Data creazione", key: "created_at" },
        { label: "Ultimo aggiornamento", key: "updated_at" },
      ],
      data: [
        {
          title: "Nome",
          fields: [
            {
              placeholder: "@",
              name: "nickname",
              class: "text-blue-300",
            },
            {
              name: "name",
            },
          ],
          span: 1,
          link: {
            idField: "user_id",
            path: "/user/auth/users/:id",
          },
        },
        {
          title: "email",
          field: "email",
          span: 1,
        },
        {
          title: "ultimo accesso",
          field: "last_login",
          format: "datetime",
          span: 1,
        },
        {
          title: "numero di login",
          field: "logins_count",
          span: 1,
        },
        {
          title: "Data creazione",
          field: "created_at",
          format: "datetime",
          span: 1,
        },

        {
          title: "Ultima modifica",
          field: "updated_at",
          format: "datetime",
          span: 1,
        },
      ],
    };

    return {
      data: authUsers,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>