<template>
  <div v-if="el" class="flex justify-center">
    <div class="text-center">
      <p class="font-bold text-xl">{{ el.name }}</p>
      <p class="text-gray-400 text-sm">{{ el.type }}</p>
    </div>
  </div>
  <base-subpage-bar :headers="headers" />
  <router-view></router-view>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  export default {
    setup() {
      const store = useStore();
      const route = useRoute();

      store.dispatch('getReleaseStation', { id: route.params.id });
      const el = computed(() => store.getters.releaseStation);
      const headers = [
        {
          text: 'paths.generals',
          name: 'menu.sharing.releaseStationGenerals',
        },
        {
          text: 'paths.stalls',
          name: 'menu.sharing.releaseStationStalls',
        },
      ];
      return { headers, el };
    },
  };
</script>

<style></style>
