<template>
  <div class="h-20 overflow-y-visible w-full bg-white dark:bg-gray-900">
    <div class="flex h-full justify-between items-center relative px-6">
      <base-route-bar />
      <router-manager></router-manager>
      <view-selector />
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import ViewSelector from './ViewSelector.vue';
  import RouterManager from './RouterManager.vue';
  export default {
    components: { ViewSelector, RouterManager },
    setup() {
      const store = useStore();
      /* const headerList = computed(() => store.getters.menu);
      console.log('HEADER:', headerList.value); */
      const isAuthenticated = computed(() => store.getters.isAuthenticated);
      return { /* headerList, */ isAuthenticated };
    },
  };
</script>
