import { Utils, ApiCaller } from '@/Utils/utils';

export default {
  state: {
    users: null,
    completeUsersList: [],
    selectedUser: null,
    //updaid
    unpaid: [],
    coupons: [],
    userDocuments: [],
  },
  getters: {
    users: (state) => state.users,
    selectedUser: (state) => {
      if (state.selectedUser !== null) return state.selectedUser[0];
      return state.selectedUser;
    },
    completeUsersList: (state) => state.completeUsersList,
    unpaid: (state) => state.unpaid,
    coupons: (state) => state.coupons,
    userDocuments: (state) => state.userDocuments,
  },
  mutations: {
    setSelectedUser: (state, payload) => (state.selectedUser = payload),
    setCompleteUsersList: (state, payload) => (state.completeUsersList = payload),
    setUnpaid: (state, data) => (state.unpaid = data),
    setCoupon: (state, data) => (state.coupons = data),
    setUserDocuments: (state, payload) => (state.userDocuments = payload),
  },
  actions: {
    getSelectedUser: (ctx, payload) => {
      ctx.commit('setSelectedUser', Utils.createFiltrableData([]));
      ApiCaller.GET(`/userDetails/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedUser', data);
      });
    },
    getCompleteUsersList: (ctx, payload) => {
      ctx.commit('setCompleteUsersList', Utils.createFiltrableData([]));
      ApiCaller.GET('/userList', payload).then((data) => {
        ctx.commit('setCompleteUsersList', Utils.createFiltrableData(data));
      });
    },
    getUnpaid: (ctx, payload) => ApiCaller.GET('/billing/unpaid', payload).then((data) => ctx.commit('setUnpaid', Utils.createFiltrableData(data))),
    getCoupon: (ctx, payload) => ApiCaller.GET('/user/coupon', payload).then((data) => ctx.commit('setCoupon', Utils.createFiltrableData(data))),
    getUserDocuments: (ctx, payload) => {
      ctx.commit('setUserDocuments', []);
      ApiCaller.GET(`/user/documents/${payload.id}`, payload).then((data) => ctx.commit('setUserDocuments', data));
    },
    createUser: (_, payload) => ApiCaller.POST('/user', payload),
    updateUser: (_, payload) => ApiCaller.PUT(`/updateUser/${payload.id}`, payload.body),
    sendDocumentsResponse: (_, payload) => ApiCaller.PUT(`/user/documents/${payload.id}/setStatus`, payload),
    resetAccess: (_, payload) => ApiCaller.POST('/resetAccess', { id: payload }),
    deleteUser: (_, payload) => ApiCaller.DELETE(`/user/${payload.id}`, payload),
    forcePayment: (_, payload) => ApiCaller.POST(`/billing/${payload.id}/force-payment`, payload.body),

    //cupons
    saveCoupon: (_, payload) => ApiCaller.POST('/user/coupon', payload),
    updateCoupon: (_, payload) => ApiCaller.PUT(`/user/coupon/${payload.id}`, payload),
    deleteCoupon: (_, payload) => ApiCaller.DELETE(`/user/coupon/${payload.id}`, payload),
    generateResetPasswordLink: (_, payload) => ApiCaller.GET('/user/resetpassword/'+payload.email, payload),
  },
};
