<template>
  <div v-if="vehicle && vehicle.id" class="w-full my-4 flex justify-between items-center">
    <div v-if="vehicle.model" class="w-full flex justify-start items-center">
      <div class="relative flex flex-col items-start justify-start">
        <div class="flex h-min text-left items-center justify-start space-x-6">
          <span hidden class="text-primary-400 text-xs">@{{ vehicle.id }}</span>
          <h1 class="text-left font-bold text-gray-900 dark:text-white leading-none p-0 text-2xl">
            <span class="mr-4">{{ vehicle.plate }}</span>
          </h1>
        </div>
        <div class="hidden flex flex-col h-min justify-center w-full">
          <h1 class="text-left font-bold text-gray-400 leading-none p-0 text-base">{{ vehicle.model.name }}</h1>
        </div>
        <div class="text-base text-gray-400 flex flex-row h-min items-center justify-center space-x-6">
          {{ vehicle?.last_message?.timestamp ? formatDateTime(vehicle.last_message.timestamp) : '' }}

        </div>
      </div>
    </div>
    <div v-if="vehicle?.device?.online" class="text-4xl text-green-500 flex items-center justify-start">
      <span>●</span>
      <span class="text-lg ml-2">Online</span>
    </div>
    <div v-else class="text-4xl text-red-500 flex items-center justify-center">
      <span>●</span>
      <span class="text-lg ml-2">Offline</span>
    </div>
    <span class="w-4"></span>
    <base-button icon="refresh" @click="updateVehicleInfo">Aggiorna</base-button>
  </div>
</template>

<script>
  import { onMounted, onUnmounted, computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { Utils } from '@/Utils/utils';
  export default {
    emits: ['change', 'heartbeat', 'refresh'],
    setup(props, { emit }) {
      const i18n = useI18n();
      const store = useStore();
      const route = useRoute();

      const interval = ref([]);

      const updateVehicleInfo = () => store.dispatch('getSelectedVehicle', { id: route.params.vehicleId });
      onMounted(() => {
        updateVehicleInfo();
        interval.value.push(
          setInterval(() => {
            console.log('INTERVAL', interval.value);
            updateVehicleInfo();
          }, 45000),
        );
      });

      onUnmounted(() => {
        interval.value.forEach((el) => clearInterval(el));
      });

      const selectedVehicle = computed(() => store.getters.selectedVehicle);

      const lastUpdate = ref();
      watch(
        // Verifico se cambio l'ultimo heartbeat
        () => (selectedVehicle.value ? selectedVehicle.value.last_heartbeat : null),
        (nv) => {
          if (nv) {
            emit('heartbeat', selectedVehicle.value);
            console.log('Emetto VEHICLE HEARTBEAT', nv);
          }
        },
        { immediate: true },
      );

      watch(
        // Verifico se cambia l'id del veicolo selezionato
        () => (selectedVehicle.value ? selectedVehicle.value.id : null),
        (nv) => {
          if (nv) {
            console.log('Emetto VEHICLE CHANGE', nv);
            emit('change', selectedVehicle.value);
          }
        },
        { immediate: true },
      );

      watch(selectedVehicle, (nv) => {
        console.log('Emetto VEHICLE REFRESH', nv);
        emit('refresh', selectedVehicle.value);
        lastUpdate.value = Utils.formatDate(store.getters.lastHeartbeat, new Date().toLocaleString(i18n.locale), { locale: i18n.locale });
      });

      const lastHeartbeat = computed(() => {
        if (selectedVehicle.value) return Utils.formatDate(store.getters.lastHeartbeat, { locale: i18n.locale });
        else return '-';
      });

      return { vehicle: selectedVehicle, lastHeartbeat, lastUpdate, updateVehicleInfo, t: i18n.t, formatDateTime: Utils.formatDate };
    },
  };
</script>
