import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    mapAnalytics: [],
    summary: [],
    perUser: [],
  },
  getters: {
    mapAnalytics: (state) => state.mapAnalytics,
    summary: (state) => state.summary,
    perUser: (state) => state.perUser,
  },
  mutations: {
    setMapAnalytics: (state, data) => (state.mapAnalytics = data),
    setSummary: (state, data) => (state.summary = data),
    setPerUser: (state, data) => (state.perUser = data),
  },
  actions: {
    getMapAnalytics: (ctx, payload) => ApiCaller.GET(`/recharges/analytics/summary`, payload).then((data) => ctx.commit('setMapAnalytics', data)),
    getSummary: (ctx, payload) =>
      ApiCaller.GET(`/recharges/analytics/summary`, payload).then((data) => ctx.commit('setSummary', Utils.createFiltrableData(data))),
    getPerUser: (ctx, payload) =>
      ApiCaller.GET(`/recharges/analytics/perUser`, payload).then((data) => ctx.commit('setPerUser', Utils.createFiltrableData(data))),
  },
};
