<template>
  <base-list-table :data="trips" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();
      function refreshPage() {
        if (selectedVehicle.value && selectedVehicle.value.device) store.dispatch('getTrips', { id: selectedVehicle.value.device.id });
      }
      const selectedVehicle = computed(() => store.getters.selectedVehicle);
      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);

      const trips = computed(() => store.getters.trips);

      const structure = {
        title: 'vehicles.trips',
        creationLink: {
          title: 'Avvia Lavorazione',
          namePath: '',
        },
        data: [
          {
            title: 'ID',
            fields: [
              {
                placeholder: '@',
                name: '_id',
                class: 'text-primary-400',
              },
              {
                name: 'IMEI',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: {
              idField: '_id',
              path: '/mobility/vehicles/' + (selectedVehicle.value ? selectedVehicle.value.id : '') + '/trips/:id',
            },
          },
          {
            title: 'Tipologia',
            field: 'type',
            span: 1,
          },
          {
            title: 'In Corso',
            field: 'ongoing',
            boolean: {
              if_true: 'In corso',
              if_false: 'Terminata',
            },
            span: 1,
          },
          {
            title: 'Inizio',
            field: 'started_at',
            format: 'datetime',
            span: 1,
            class: 'text-style',
          },
          {
            title: 'Fine',
            field: 'ended_at',
            format: 'datetime',
            span: 1,
            class: 'text-style',
          },
        ],
      };

      return {
        trips,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
