<template>
  <div>
    <button @click="resetLogin" class="text-primary-400 hover:text-primary-700">
      {{ t('button.forceLogout') }}
    </button>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  export default {
    props: ['id'],
    setup(props) {
      // reset login
      const store = useStore();
      function resetLogin() {
        store.dispatch('resetAccess', props.id);
      }
      return { resetLogin, t: useI18n().t };
    },
  };
</script>

<style></style>
