<template>
  <div>
    <div>
      <div class="flex">
        <base-checkbox class="mr-4" :title="$t('notification.all')" :checked="true" v-model="formObj.all" />
        <base-checkbox
          class="mr-4"
          :title="$t('notification.marketing')"
          v-model="formObj.marketing"
          v-show="!formObj.all" />
        <base-checkbox
          class="mr-4"
          :title="$t('notification.recharges')"
          v-model="formObj.recharges"
          v-show="!formObj.all" />
        <base-checkbox
          class="mr-4"
          :title="$t('notification.rentals')"
          v-model="formObj.rentals"
          v-show="!formObj.all" />
        <base-checkbox-dropdown
          class="content-center"
          :title="$t('notification.users')"
          :data="users"
          @change="addUser"
          v-if="!formObj.all" />
      </div>
    </div>
    <div>
      <div class="flex mt-4">
        <base-checkbox class="mr-4" :title="$t('notification.promo')" v-model="formObj.promo" />
        <base-checkbox class="mr-4" :title="$t('HTML')" v-model="formObj.isHtml" />
      </div>
    </div>
    <div>
      <base-input-field :placeholder="$t('notification.title')" v-model="formObj.title" customClass="text-center" />
      <base-input-field :placeholder="$t('notification.body')" v-model="formObj.body" size="extreme" />
      <base-input-field v-show="!formObj.isHtml" :placeholder="$t('notification.link')" v-model="formObj.link" customClass="text-center" />
    </div>
    <div class="flex mt-4">
      <base-button class="mx-2" @click="sendForm()">{{ $t('buttons.send') }}</base-button>
      <base-button class="mx-2" @click="$router.back()">{{ $t('buttons.cancel') }}</base-button>
    </div>
  </div>
</template>

<script>
  import { reactive, computed } from 'vue';
  import { useStore } from 'vuex';
  export default {
    setup() {
      const store = useStore();

      store.dispatch('getSimpleUserList', {});
      const users = computed(() => store.getters.usersForSelect);

      const formObj = reactive({
        all: true,
        marketing: false,
        recharges: false,
        rentals: false,
        title: '',
        body: '',
        users: [],
        promo: false,
        isHtml: false,
        link: '',
      });

      const addUser = (event) => {
        if (event.target.checked) formObj.users.push(event.target.value);
        else {
          let newList = [];
          for (let i in formObj.users) {
            if (formObj.users[i] != event.target.value) newList.push(formObj.users[i]);
          }
          formObj.users = newList;
        }
      };

      const sendForm = () => store.dispatch('sendNotifications', { ...formObj });

      return { formObj, users, sendForm, addUser };
    },
  };
</script>

<style></style>
