<template>
  <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
    title
  }}</label>
  <textarea
    id="message"
    rows="4"
    class="
      block
      p-2.5
      w-full
      text-sm text-gray-900 dark:text-white
      bg-gray-50 dark:bg-gray-800
      rounded-lg
      border border-gray-300 dark:border-gray-700
      focus:ring-primary-500 focus:border-primary-500
    "
    placeholder="Il testo qui..."
    :value="modelValue"
    @input="updateModel"
  ></textarea>
</template>

<script>
export default {
  props: {
    title: { type: String, require: false, default: "Messaggio" },
    modelValue: {},
  },
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    function updateModel(event) {
      emit("update:modelValue", event.target.value);
    }
    return { updateModel };
  },
};
</script>

<style>
</style>