import { useI18n } from 'vue-i18n';
class UtilsClass {
  constructor() {}
  createFiltrableData(data) {
    function objToString(el) {
      let tempVal = '';
      for (let val in el) {
        let t = '';
        if (typeof el[val] === 'object') t = objToString(el[val]);
        else {
          if (typeof el[val] === 'string') t = el[val].replace(' ', '');
          else t = el[val];
        }

        if (val === 'uid' || val === 'id' || val === 'session_id') tempVal += '@' + t;
        else tempVal += t;
        tempVal += ' ';
      }

      return tempVal;
    }

    const dataResponse = data;
    let newObj = [];

    if (dataResponse && dataResponse.length > 0)
      dataResponse.forEach((el) => {
        el['searchString'] = objToString(el);
        newObj.push(el);
      });

    return newObj;
  }

  getProducerArrayFromData(data, field = 'producer') {
    let finalArray = [''];
    for (const el in data) {
      finalArray.push(data[el][field]);
    }
    return finalArray;
  }

  getNameArrayFromData(data, field = 'name') {
    let finalArray = [''];
    for (const el in data) {
      finalArray.push(data[el][field]);
    }
    return finalArray;
  }

  getIdArrayFromData(data, field = 'id') {
    let finalArray = [''];
    for (const el in data) {
      finalArray.push(data[el][field]);
    }
    return finalArray;
  }

  getImeiArrayFromData(data) {
    let finalArray = [''];
    for (const el in data) {
      finalArray.push(data[el].imei);
    }
    return finalArray;
  }

  linkCreation(el, itm) {
    let string = '';
    if (itm.type) {
      let tmpString = '';
      switch (itm.type) {
        case 'vehicle':
          if (itm.subField) tmpString = el[itm.subField][itm.idField];
          else tmpString = el[itm.idField];
          tmpString = tmpString.split('_')[0];
          if (tmpString === 'bik') string = '/sharing/vehicles/bikes/:id';
          else if (tmpString === 'sct') string = '/sharing/vehicles/scooter/:id';
          else if (tmpString === 'car') string = '/sharing/vehicles/car/:id';
          break;
        case 'vehicle_type':
          if (itm.subField) tmpString = el[itm.subField][itm.idField];
          else tmpString = el[itm.idField];
          tmpString = tmpString.split('_')[0];
          if (tmpString === 'btp') string = '/sharing/vehicles/bikes/types/:id';
          else if (tmpString === 'sctt') string = '/sharing/vehicles/scooter/types/:id';
          else if (tmpString === 'crtp') string = '/sharing/vehicles/cars/types/:id';
          break;
        default:
          break;
      }
    } else string = itm.path;
    if (itm.idField) {
      let id;
      if (itm.subField) id = el[itm.subField][itm.idField];
      else id = el[itm.idField];
      string = string.replace(':id', id);
    }
    return string;
  }

  formatDate(field, options = {}) {
    try {
      var { hour12 = true, timeZone = 'Europe/Amsterdam' } = JSON.parse(localStorage.getItem('timeFormat')) || {};
    } catch (err) {
      (hour12 = true), (timeZone = 'Europe/Amsterdam');
    }
    const defaultOptions = {
      locale: 'it-IT',
      timeZone: timeZone,
      dateStyle: 'full',
      timeStyle: 'long',
      hour12: hour12,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    console.log('DEFAULT:', defaultOptions);

    options = { ...defaultOptions, ...options };
    try {
      const date = new Date(field);
      const timeString = date.toLocaleString(options.locale, {
        timeZone: options.timeZone,
        hour12: options.hour12,
        year: options.year,
        month: options.month,
        day: options.day,
        hour: options.hour,
        minute: options.minute,
        second: options.second,
      });
      const formattedDate = `${timeString}`;
      return formattedDate;
    } catch (err) {
      console.error(err);
      return field;
    }
  }

  formatData(field, format) {
    if (field === undefined) return field;

    const formatGPS = (gps) => {
      if (!gps.latitude) return '';
      let color = 'green';
      return /* html */ `
        <a href="https://www.google.it/maps/place/${gps.latitude},${gps.longitude}/@${gps.latitude},${
        gps.longitude
      },15z" target="_blank" class="mt-1 flex justify-center items-center cursor-pointer bg-${color}-100 rounded-lg px-3 py-1 w-min font-bold text-${color}-900">
          <text class="material-icons mr-2 text-base">map</text>
          <text>${parseFloat(gps.latitude).toFixed(7) + ', ' + parseFloat(gps.longitude).toFixed(7)}</text>
        </a>`;
    };

    const getChipComponent = (value, color = 'blue') => {
      return `<div class="bg-${color}-100 rounded-lg px-3 py-1 w-min font-bold text-${color}-900">${value}</div>`;
    };

    const getPriorityChip = (field) => {
      let icon = 'low_priority';
      let text = 'BASSA';
      let color = 'green';
      if (field < 1) color = 'gray';
      else if (field < 3) {
        text = 'MEDIA';
        color = 'yellow';
        icon = 'warning';
      } else {
        text = 'ALTA';
        color = 'red';
        icon = 'report';
      }
      return /* html */ `
      <div class="flex justify-center items-center bg-${color}-100 px-2.5 py-0.5 rounded w-min text-xs font-bold text-${color}-900">
      <text class="material-icons mr-2 text-sm">${icon}</text>
      <text>${text}</text>
      </div>
      `;
    };

    const getCommandStatusChip = (field) => {
      let icon = 'low_priority';
      let text = 'STATO SCONOSCIUTO';
      let color = 'gray';

      switch (field) {
        case 'ERROR':
        case 'FAILED':
          icon = 'close';
          color = 'red';
          text = 'FALLITO';
          break;
        case 'RECEIVED':
          icon = 'done_all';
          color = 'green';
          text = 'RICEVUTO';
          break;
        case 'PENDING':
        case 'WAITING':
          icon = 'pending';
          color = 'red';
          text = 'IN ATTESA';
          break;
        case 'SENT':
        case 'SENDING':
          icon = 'send';
          color = 'orange';
          text = 'INVIATO';
          break;
      }

      return /* html */ `
        <div class="flex justify-center items-center bg-${color}-100 rounded-lg px-3 py-1 w-min font-bold text-${color}-900">
        <text class="material-icons mr-2 text-base">${icon}</text>
        <text>${text}</text>
        </div>
        `;
    };

    const getOnlineChip = (field) => {
      if (field == 'ONLINE') return getChipComponent('● ONLINE', 'green');
      else return getChipComponent('● OFFLINE', 'red');
    };

    const getVehicleTypeChip = (field) => {
      let icon = '';
      let color = 'blue';
      switch (field) {
        case 'TRACTOR':
          icon = 'agriculture';
          color = 'red';
          break;
        case 'CAR':
          icon = 'directions_car';
          color = 'orange';
          break;
        case 'SCOOTER':
          icon = 'delivery_dining';
          color = 'indigo';
          break;
        case 'BIKE':
          icon = 'pedal_bike';
          color = 'green';
          break;
        case 'BOAT':
          icon = 'directions_boat';
          color = 'violet';
          break;
      }
      return /* html */ `
        <div class="flex justify-center items-center bg-${color}-100 rounded-lg px-3 py-1 w-min font-bold text-${color}-900">
          <text class="material-icons mr-2 text-base">${icon}</text>
          <text>${field}</text>
        </div>
      `;
    };

    const tc = useI18n().t;
    let finalString = '',
      color = '';
    let unixParse = [];
    if (format) unixParse = format.toString().split('unix_to_');
    if (unixParse.length > 1) {
      format = unixParse[1];
      if (field.length && field.length < 13) field = field * 1000;
    }

    switch (format) {
      case 'online':
        return getOnlineChip(field);
      case 'gps':
        return formatGPS(field);
      case 'millis':
        if (!field) return '-';
        field = parseInt(field) * 1000;
        finalString = new Date(field).toISOString();
        var temp1 = finalString.split('T');
        finalString = '<b>' + temp1[0].replaceAll('-', '/') + '</b>';
        if (format == 'datetime') finalString += '<br>' + temp1[1].split('.')[0];
        return finalString;
      case 'timePassed':
        var time = toUnixTimestamp(field);
        var hours = Math.floor(time / 3600);
        time = time - hours * 3600;
        var minutes = Math.floor(time / 60);
        var finalTime = str_pad_left(hours, '0', 2) + 'h ' + str_pad_left(minutes, '0', 2) + 'm ';
        return finalTime;
      case 'datetime_ignore&parse':
      case 'datetime_ignore':
      case 'datetime':
      case 'date': {
        if (!field) return '-';

        // Converti la stringa di input in un oggetto Date
        const date = new Date(field);

        // Opzioni di formattazione per la data
        const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // Opzioni di formattazione per l'ora
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

        // Formatta la data
        const formattedDate = date.toLocaleDateString('it-IT', optionsDate);

        // Formatta l'ora
        const formattedTime = date.toLocaleTimeString('it-IT', optionsTime);

        // Ritorna il risultato a seconda del valore di `format`
        if (format === 'datetime_ignore&parse') return `${formattedDate} ${formattedTime}`;
        else if (format === 'datetime_ignore') return field;
        else if (format === 'datetime')
          return `
          <span class="font-bold" style="font-weight: bold!important;">${formattedDate}</span>
          <br/>
          <span class="font-medium" style="font-weight: medium!important;">${formattedTime}</span>
          <span class="font-medium hidden" style="font-size: 0.6rem!important; font-weight: medium!important;">${field} (UTC)</span>
        `;
        else return `<span class="font-bold" style="font-weight: bold!important;">${formattedDate}</span>`;
      }
      case 'euro':
        color = parseFloat(field) <= 0 ? (parseFloat(field) < 0 ? 'pink' : 'white') : 'green';
        return getChipComponent(`${field ? String(field).replace('.', ',') : '0,00'} €`, color);
      case 'stripe/euro':
        field /= 100;
        field = parseFloat(field).toFixed(2);
        color = parseFloat(field) <= 0 ? (parseFloat(field) < 0 ? 'pink' : 'white') : 'green';
        return getChipComponent(`${field ? String(field).replace('.', ',') : '0,00'} €`, color);
      case 'invoiceStatus':
        if (field == 'Attesa Esito') color = 'yellow';
        else if (field === 'Scartata') color = 'red';
        else if (field === 'Consegnata') color = 'green';
        else color = 'blue';
        return getChipComponent(field, color);
      case 'power':
        color = parseFloat(field) <= 0 ? 'amber' : 'green';
        return getChipComponent(`${field ? field : '0'} kW`, color);
      case 'chip':
        return getChipComponent(`${tc(field)}`, 'indigo');
      case 'kwh':
        color = parseFloat(field) <= 0.1 ? 'pink' : 'sky';
        return getChipComponent(`${field ? String(field).replace('.', ',') : '0'} kWh`, color);
      case 'priority':
        return getPriorityChip(field);
      case 'commandStatus':
        return getCommandStatusChip(field);
      case 'percent':
      case 'percentage':
        return getChipComponent(`${field} %`, 'blue');
      case 'rental_status':
        if (field == 'free') return 'Libero';
        if (field == 'booked') return 'Prenotato';
        if (field == 'rented') return 'Noleggiato';
        if (field == 'unpaid') return 'Mancato pagamento';
        if (field == 'charging completed') return 'Ricarica completata';
        if (field == 'waiting for brick') return 'In attesa di risposta dal brick';
        return;
      case 'vehicleType':
        return getVehicleTypeChip(field);
      case 'decimal_euro':
        return field.toString().slice(0, -2) + ',' + field.toString().slice(field.toString().length - 2, field.toString().length) + '€';
      case 'watt':
        return field + 'W';
      default:
        if (!field || field == undefined || field == 'undefined' || field == null || field == 'null') return '';
        return field;
    }
  }

  countTableColumns(data) {
    let cols = 0;
    for (const el in data) {
      if (data[el].span) cols += parseInt(data[el].span);
      else cols++;
    }
    if (!cols) return '';
    return `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`;
  }
  getSummaryData(dataFiltered, summaryFields) {
    let temp = {};
    let distinct = {};
    if (!summaryFields) return null;

    for (const field of summaryFields) {
      temp[field.label] = 0;
      distinct[field.label] = [];
    }

    for (const el of dataFiltered)
      for (const field of summaryFields) {
        let val = el[field.field];
        switch (field.type) {
          case 'SUM':
            val = String(val);
            val.toLowerCase();
            val.replace(' ', '');
            val.replace('kwh', '');
            val.replace('€', '');
            temp[field.label] += parseFloat(val);
            break;
          case 'COUNT_DISTINCT':
            if (!distinct[field.label].includes(val)) {
              distinct[field.label].push(val);
              temp[field.label]++;
            }
            break;
          case 'COUNT_DISTINCT_WITH_VALUE':
            if (!distinct[field.label].includes(val) && String(el[field.valueField]) == field.value) {
              distinct[field.label].push(val);
              temp[field.label]++;
            }
            break;
        }
      }

    const tc = useI18n().t;
    for (const field of summaryFields) {
      switch (String(field.unit).toLowerCase().replace(' ', '')) {
        case '€':
        case 'kwh':
        case 'kw':
          temp[field.label] = String(parseInt(temp[field.label])) + (field.unit ? ` ${field.unit}` : '');
          break;
        default:
          temp[field.label] = tc(field.unit, parseFloat(temp[field.label]));
          break;
      }
    }
    return temp;
  }

  formatAlarmArray(alarmArray) {
    const alarms = [];
    alarmArray.forEach((al) => {
      const alarm = al;
      alarm.currentStatusType = al.activeStatus.statusType;
      alarms.push(alarm);
    });
    return alarms;
  }

  cleanupString(str) {
    let name = str.replaceAll(' ', '_').toUpperCase();
    name = name.replaceAll('\n', '').toUpperCase();
    name = name.replaceAll('\t', '').toUpperCase();
    name = name.replaceAll('\r', '').toUpperCase();
    while (name.charAt(name.length - 1) === '_') {
      name = name.slice(0, -1);
    }
    while (name.charAt(0) === '_') {
      name = name.slice(1);
    }
    while (name.includes('__')) {
      name = name.replaceAll('__', '_');
    }
    return name;
  }

  downloadTSV(title, dataFiltered, downloading, isCSV = true) {
    var HEADER = '';
    var SEPARATOR = '\t';
    var TERMINATOR = '\r\n';
    var EXTENSION = '.tsv';
    var CSV = '';

    if (isCSV) {
      HEADER = 'sep=,' + '\r\n\n';
      SEPARATOR = ',';
      TERMINATOR = '\r\n';
      EXTENSION = '.csv';
    }

    if (downloading.value) return;
    console.log('Inizio conversione');

    let ReportTitle = title;
    if (Object.prototype.hasOwnProperty.call(localStorage, 'platformObj')) ReportTitle += ' ' + localStorage.platformObj;

    const table = dataFiltered.value;

    if (table.length < 1) return;
    if (table[0].length < 1) return;

    downloading.value = true;

    function objToString(h, el) {
      let tempVal = '';
      for (let val in h) {
        let t = '';
        val = h[val];
        console.log(val);
        if (!el[val]) t = '';
        else {
          if (typeof el[val] === 'object') t = JSON.stringify(el[val]).replaceAll(SEPARATOR, '');
          else t = String(el[val]).replaceAll(SEPARATOR, '');
        }
        tempVal += t + SEPARATOR;
      }
      return tempVal.slice(0, -1);
    }

    function getHeaderArray(el) {
      let ret = [];
      for (const k in el) {
        if (k == 'searchString') continue;
        HEADER += k + SEPARATOR;
        if (typeof el[k] == 'object') ret.push(getHeaderArray(el[k]));
        else ret.push(String(k));
      }
      return ret;
    }

    setTimeout(() => {
      let r;

      let h = [];
      table.forEach((row, i) => {
        if (i == 0) h = getHeaderArray(row);
        r = objToString(h, row);
        r = r.replaceAll(TERMINATOR, '');
        r += TERMINATOR;
        CSV += r;
      });

      HEADER = HEADER.slice(0, -1);
      HEADER += '\r\n';
      CSV = HEADER + CSV;
      console.log('HEADER', HEADER);
      var fileName = 'Report_';
      fileName += ReportTitle.replace(/ /g, '_');
      var uri = 'data:text/tab-separated-values;charset=utf-8,' + escape(CSV);
      var link = document.createElement('a');
      link.href = uri;
      link.style = 'visibility:hidden';
      link.download = fileName + EXTENSION;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      downloading.value = false;
    }, 1000);
  }
  interpolator(object) {
    const ret = {};
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const element = object[key];
        const type = typeof element;
        if (type === 'object') {
          ret[key] = this.interpolator(element);
        } else if (type === 'boolean') {
          ret[key] = element;
        } else {
          /* eslint-disable */
          const fn = function (ctx) {
            return element;
          };
          ret[key] = fn;
          /* eslint-enable */
        }
      }
    }
    return ret;
  }
}
function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}
function toUnixTimestamp(dateString) {
  const timestamp = parseInt(dateString);
  if (!isNaN(timestamp) && timestamp.toString().length === dateString.length) {
    // Il parametro è un Unix timestamp valido
    return timestamp;
  } else {
    // Il parametro non è un Unix timestamp, lo converto
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      // La data non è valida
      return null;
    } else {
      return Math.round(date.getTime() / 1000);
    }
  }
}
export const Utils = new UtilsClass();

// API CALLER

import axios from 'axios';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

class ApiCallerClass {
  constructor() {
    this.initialized = false;
  }

  init() {
    this.store = useStore();
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    let self = this;
    try {
      self.serverUrl = process.env.VUE_APP_SERVER_PATH;
      self.loginWithRedirect = loginWithRedirect;
      self.getAccessTokenSilently = getAccessTokenSilently;
      self.initialized = true;
    } catch (e) {
      self.initialized = false;
    }
  }

  callbackResponse = async (url, response, resolve, reject, showOnSuccess = true) => {
    let result = this.checkResponse(response);
    let message = 'Chiamata avvenuta con successo!';
    console.log(result);
    if (result.error_code == 0) {
      message = result.error_desc ? result.error_desc : 'Successo';
      this.displaySuccess(message, showOnSuccess);
      resolve(result.response);
    } else {
      message = result.error_desc ? result.error_desc : 'Errore chiamata';
      this.displayError(message);
      resolve();
    }
  };

  // axios.get
  aux = async (method, url, payload) => {
    if (!this.initialized) this.init();
    let self = this;
    if (url.includes('undefined')) return;
    if (method === 'get') self.store.dispatch('loaderShow', 0);
    else self.store.dispatch('loaderShow');
    const token = await self.getAuth0Token(self.store);
    return new Promise(function (resolve, reject) {
      if (!token) resolve([]);

      const PlatformId = self.store.getters.selectedPlatform;
      const OrganisationId = self.store.getters.selectedOrganisation;
      const RoleId = self.store.getters.selectedRole;

      const tmp = method == 'get' ? 'params' : 'data';
      let body = {};
      body[tmp] = { ...payload };

      // Append backend url if relative path is provided
      if (!url.includes('http://') && !url.includes('https://')) url = self.serverUrl + url;

      console.log(url, PlatformId, OrganisationId, RoleId);

      var config = {
        method: method,
        url,
        headers: {
          Authorization: token,
          PlatformId,
          OrganisationId,
          RoleId,
        },
        ...body,
      };

      axios(config)
        .then((response) => {
          if (method != 'get') self.store.dispatch('pendingRefresh');
          self.callbackResponse(url, response, resolve, reject, method !== 'get');
        })
        .catch((err) => {
          if (method != 'get') self.store.dispatch('pendingRefresh');
          if (err && err.response && err.response.status) {
            switch (err.response.status) {
              case 401:
                self.displayError('Non sei autenticato');
                resolve();
                break;
              case 403:
                self.displayError("Non disponi di autorizzazioni sufficienti ad effettuare l'operazione!");
                resolve();
                break;
              default:
                self.displayError('Errore generico API!');
                resolve();
                break;
            }
          } else {
            self.displayError(url + ' ' + err);
            resolve();
          }
        });
    });
  };

  GET = (...args) => this.aux('get', ...args);
  POST = (...args) => this.aux('post', ...args);
  PUT = (...args) => this.aux('put', ...args);
  PATCH = (...args) => this.aux('patch', ...args);
  DELETE = (...args) => this.aux('delete', ...args);

  async getAuth0Token(store) {
    try {
      const APIToken = store.getters.APIToken;
      let tkn;
      if (!APIToken || APIToken === '') {
        await this.getAccessTokenSilently().then((data) => {
          store.dispatch('setAPIToken', data);
          tkn = data;
        });
      } else tkn = APIToken;

      return 'Bearer ' + tkn;
    } catch (err) {
      this.displayError(this.store, err);
      this.loginWithRedirect();
      return false;
    }
  }
  checkResponse(response) {
    if (response.statusText === 'OK' || response.statusText === '') return response.data;
    return false;
  }

  displayError(err) {
    this.store.dispatch('loaderHide');
    this.store.dispatch('pushMessage', {
      title: 'Errore',
      description: err,
      type: 'error',
    });
  }

  displaySuccess(success, showOnSuccess) {
    this.store.dispatch('loaderHide');
    if (showOnSuccess)
      this.store.dispatch('pushMessage', {
        title: 'Successo',
        description: success,
        type: 'success',
      });
  }
}

export const ApiCaller = new ApiCallerClass();
