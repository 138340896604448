<template>
  <base-details-header :el="selectedFleet" :structure="headerStructure" />
  <base-subpage-bar :headers="headers" />
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.fleetId };
      onMounted(() => {
        store.dispatch('getSelectedFleet', selectedElID);
      });

      const selectedFleet = computed(() => store.getters.selectedFleet);
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        { text: 'Dettagli', name: 'menu.mobility.fleetGenerals' },
        { text: 'Veicoli', name: 'menu.mobility.fleetVehicles' },
      ];
      const headerStructure = {
        title: [
          {
            title: 'name',
          },
        ],
        data: [
          {
            name: 'id',
          },
          {
            name: 'description',
          },
        ],
      };

      return {
        selectedFleet,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
