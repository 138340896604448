<template>
  <base-page>
    <div class="flex justify-around">
      <div>
        <div class="flex">
          <base-input-field title="Seriale colonnina" v-model="bodyObj.plate" :statusOK="status(bodyObj.plate)" />
          <base-select
            class="ml-4"
            title="Tipo stazione"
            :data="station_types"
            v-model="bodyObj.station_type"
            :statusOK="status(bodyObj.station_type)" />
        </div>
        <div class="w-64">
          <base-input-field title="POD" v-model="bodyObj.pod" />
        </div>
        <div>
          <base-button @click="showModal = true">Inserisci</base-button>
        </div>
      </div>
      <base-error-list :missingFields="missingFields" />
    </div>
  </base-page>
  <Teleport to="body">
    <base-confirm-field :show="showModal" @close="showModal = false">
      <template #header>
        <h3>Inserimento prese</h3>
      </template>
      <template #body>
        <p>
          {{ bodyModel() }}
        </p>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <p>Continuare?</p>
          <div>
            <button class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2" type="button" @click="sendForm">
              Si
            </button>
            <button class="w-6 h-6 hover:bg-red-200 text-sm rounded-full" type="button" @click="showModal = false">
              No
            </button>
          </div>
        </div>
      </template>
    </base-confirm-field>
  </Teleport>
</template>

<script>
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import { onMounted, computed, reactive, ref } from 'vue';
  export default {
    setup() {
      //init vue elements
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      //on mounted init fields
      onMounted(() => {
        store.dispatch('getStationTypes');
      });
      // selects
      const station_types = computed(() => {
        const st = store.getters.stationTypes;
        return getNameArray(st);
      });
      //selectUtils:
      function getNameArray(data) {
        let finalArray = [''];
        for (const el in data) {
          finalArray.push(data[el].name);
        }
        return finalArray;
      }
      //model parameters
      const id = route.params.id;
      const bodyObj = reactive({
        station_type: '',
        pod: '',
        plate: '',
      });
      //send button
      const missingFields = ref([]);
      function sendForm() {
        showModal.value = false;
        const fieldsArray = stationCheckFields();
        if (fieldsArray.length > 0) missingFields.value = fieldsArray;
        else {
          store
            .dispatch('createEvses', {
              id: id,
              bodyObj: { ...bodyObj },
            })
            .then(() => {
              setTimeout(() => {
                router.go();
                store.dispatch('getEvses', route.params.id);
              }, 1000);
            });
        }
      }
      //status input check
      function status(val) {
        if (val === '') return false;
        return true;
      }
      function stationCheckFields() {
        let fieldArray = [];
        if (bodyObj.station_type === '') fieldArray.push('Tipo di stazione');
        if (bodyObj.plate === '') fieldArray.push('Seriale colonnina');

        return fieldArray;
      }
      // show allert modal
      const showModal = ref(false);
      function bodyModel() {
        let finalString = 'Stai inserendo le prese per una colonnina di tipo: ';
        if (bodyObj.station_type) finalString += `'${bodyObj.station_type}'`;
        else finalString += '!!! Tipologia non indicata !!!';
        ////////////////////////////////
        finalString += ' con numero di serie: ';
        if (bodyObj.plate) finalString += `'${bodyObj.plate}'`;
        else finalString += '!!! Numero di serie non indicato !!!';
        return finalString;
      }
      return {
        station_types,
        bodyObj,
        missingFields,
        showModal,
        sendForm,
        status,
        bodyModel,
      };
    },
  };
</script>

<style></style>
