<template>
  <p v-for="itm in fields" :key="itm" class="truncate pr-2 z-10 text-xs" :class="customClass(itm)">
    <template v-if="itm.placeholder && itm.placeholder == '@'">
      <span class="material-icons text-xs text-blue-500 font-bold">open_in_new</span>
    </template>
    <template v-else>
      <div v-if="itm.subName && el[itm.name][itm.subName]">
        {{ itm.placeholder }}{{ formatData(el[itm.name][itm.subName], itm.format) }}
      </div>
      <div v-else-if="el[itm.name]">{{ itm.placeholder }}{{ formatData(el[itm.name], itm.format) }}</div>
      <div else>
        {{ '' }}
      </div>
    </template>
  </p>
  <!--p v-for="itm in fields" :key="itm" class="truncate pr-2 z-10 text-xs" :class="customClass(itm)">{{ formatData(itm.subName ? el[itm.name][itm.subName] : el[itm.name], itm.format) ? itm.placeholder : '' }}{{ formatData(itm.subName ? el[itm.name][itm.subName] : el[itm.name], itm.format) }}</p-->
</template>

<script>
  import { Utils } from '@/Utils/utils';
  export default {
    props: {
      fields: { type: Object, required: true },
      el: { type: Object, required: true },
    },
    setup(props) {
      function customClass(itm) {
        if (!itm) return '';
        if (itm.classTest) return eval(itm.classTest);
        return itm.class;
      }
      const formatData = Utils.formatData;
      return { customClass, formatData, props };
    },
  };
</script>

<style></style>
