import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    selectedTrip: null,
    tripEvents: [],
    tripMessages: [],
    tripCommands: [],
  },
  getters: {
    selectedTrip: (state) => state.selectedTrip,
    tripEvents: (state) => state.tripEvents,
    tripMessages: (state) => state.tripMessages,
    tripCommands: (state) => state.tripCommands,
  },
  mutations: {
    setSelectedTrip: (state, data) => (state.selectedTrip = data),
    setTripEvents: (state, data) => (state.tripEvents = data),
    setTripMessages: (state, data) => (state.tripMessages = data),
    setTripCommands: (state, data) => (state.tripCommands = data),
  },
  actions: {
    getSelectedTrip: (ctx, payload) => ApiCaller.GET(`/trip/${payload.id}`).then((data) => ctx.commit('setSelectedTrip', data)),
    getTripEvents: (ctx, payload) => ApiCaller.GET(`/trip/${payload.id}/events`).then((data) => ctx.commit('setTripEvents', Utils.createFiltrableData(data))),
    getTripMessages: (ctx, payload) => ApiCaller.GET(`/trip/${payload.id}/messages`).then((data) => ctx.commit('setTripMessages', Utils.createFiltrableData(data))),
    getTripCommands: (ctx, payload) => ApiCaller.GET(`/trip/${payload.id}/commands`).then((data) => ctx.commit('setTripCommands', Utils.createFiltrableData(data))),
  },
};
