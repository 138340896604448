<template>
  <base-page>
    <base-list-table :data="data" :structure="structure" />
  </base-page>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    setup() {
      const store = useStore();

      const vehicles = computed(() => store.getters.typeVehicles);

      const structure = {
        title: 'Tipologie',
        data: [
          {
            title: 'Nome',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-blue-300',
              },
              {
                name: 'plate',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'id', type: 'vehicle' },
          },
          {
            title: 'Tracker',
            fields: [
              {
                placeholder: '@',
                name: 'tracker_imei',
                class: 'text-blue-300',
              },
              {
                name: 'tracker_imei',
              },
            ],
            span: 1,
            link: { idField: 'tracker_imei', path: '/sharing/trackers/:id' },
          },
          {
            title: 'Gruppo zone',
            fields: [
              {
                placeholder: '@',
                name: 'zone_group_id',
                class: 'text-blue-300',
              },
              {
                name: 'zone_group_name',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'zone_group_id', path: '/sharing/zones/:id' },
          },
          {
            title: 'Gestore associato',
            fields: [
              {
                placeholder: '@',
                name: 'manager_id',
                class: 'text-blue-300',
              },
              {
                name: 'manager_name',
                class: 'font-bold ',
              },
              {
                name: 'location',
                class: 'text-sm ',
              },
            ],
            span: 1,
            link: { idField: 'manager_id', path: '/sharing/managers/:id' },
          },
          {
            title: 'Stato',
            field: 'maintenance',
            boolean: {
              if_false: 'Disponibile',
              if_true: 'In manutenzione',
            },
            span: 1,
          },
        ],
      };

      return {
        data: vehicles,
        structure,
      };
    },
  };
</script>

<style></style>
