<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
export default {
  setup() {
    //structure of page
    const insertData = {
      title: "Inserimento nuovo gruppo",
      redirect_path: "/sharing/zones/list",
      create_endpoint: "createZoneGroup",
      creation_title: "Creazione gruppo",
      dispatchName: "getZoneGroups",
      data: [
        {
          title: "Nome zona",
          name: "name",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Descrizione",
          name: "description",
          class: "col-span-2",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>