import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    issues: [],
    requestTypes: [],
  },
  getters: {
    issues: (state) => state.issues,
    requestTypes: (state) => state.requestTypes,
  },
  mutations: {
    setIssues: (state, data) => (state.issues = Utils.createFiltrableData(data)),
    setRequestTypes: (state, data) => (state.requestTypes = data),
  },
  actions: {
    getIssues: (ctx, payload) =>
      ApiCaller.GET(`/tickets/issues`, payload).then((data) => ctx.commit('setIssues', data)),
    getRequestTypes: (ctx, payload) =>
      ApiCaller.GET(`/tickets/issues/requestTypes`, payload).then((data) => ctx.commit('setRequestTypes', data)),
    //apis
    createIssue: (_, payload) => ApiCaller.POST(`/tickets/issues`, payload),
  },
};
