<template>
  <div class="h-full flex flex-row">
    <div class="overflow-visible py-4 px-3 bg-primary-900 dark:bg-gray-900 flex flex-col h-full justify-start items-center z-30">
      <ui-platform />
      <ul v-if="sections && isAuthenticated" class="flex flex-col justify-start items-center space-y-2 select-none mt-7">

        <!-- SECTIONS -->
        <li v-for="el in sections" :key="el.name"  class="cursor-pointer">
          <a :title="$t(el.name)" 
            v-if="el.icon"
            @click="select(el)"
            class="  flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-primary-600 dark:hover:bg-gray-800 hover:text-white dark:hover:text-gray-200 hover:opacity-100 transition-all duration-500"
            :class="{ 'capitalize opacity-100 bg-primary-600': $route.path.includes(el.path) }"> 
            <span class="w-6 h-6 text-primary-50 dark:text-gray-50 hover:text-white dark:hover:text-gray-200 transition duration-75 material-icons">
              {{ el.icon }}
            </span> 
          </a>
        </li>
      </ul>
      
      <!-- UTILITY TO TEST THE RESPONSIVE DESIGN  -->
      <!-- <span class="text-yellow-400 text-lg  sm:hidden md:hidden lg:hidden">XS</span>
      <span class="text-red-300 text-lg hidden  sm:block md:hidden lg:hidden">SM</span>
      <span class="text-purple-400 text-lg hidden  md:block lg:hidden">MD</span>
      <span class="text-green-400 text-lg hidden  lg:block ">LG</span> -->

      <span class="h-full"></span>


      <!-- DARK MODE -->
      <ul class="space-y-2 select-none">
        <li class="cursor-pointer"  :title="capitalize($t('element.title.dark.theme'))"    @click="darkMode = !darkMode" >
          <a
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-primary-600 dark:hover:bg-gray-800 hover:text-white dark:hover:text-gray-200 hover:opacity-100 transition-all duration-500"
            :class="{ 'opacity-100 bg-primary-600': darkMode }"> 
            <span class="w-6 h-6 text-primary-50 dark:text-gray-50 hover:text-white dark:hover:text-gray-200 transition duration-75 material-icons">
              {{ darkMode ? 'light_mode' : 'dark_mode' }}
            </span>
          </a>
        </li>        
      </ul>

      <!-- SUPPORT  -->
      <ul class="space-y-2 select-none my-6 py-4 border-y-2 border-primary-800 dark:border-gray-700">
        <li class="cursor-pointer"   :title="capitalize($t('element.title.support'))"  v-if="isAuthenticated && user">
          <a 
            @click="
              expandUser = false;
              expandLocales = false;
              expandSupport = !expandSupport;
              open = expandSupport;"
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-primary-600 dark:hover:bg-gray-800 hover:text-white dark:hover:text-gray-200 hover:opacity-100 transition-all duration-500"
            :class="{ 'opacity-100 bg-primary-600': expandSupport }"> 
            <span class="w-6 h-6 text-primary-50 dark:text-gray-50 hover:text-white dark:hover:text-gray-200 transition duration-75 material-icons">
              support
            </span>
          </a>
        </li>
        <li class="cursor-pointer "   :title="capitalize($t('element.title.setting'))">
          <a
            class="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-primary-600 dark:hover:bg-gray-800 hover:text-white dark:hover:text-gray-200 hover:opacity-100 transition-all duration-500"
            :class="{ 'opacity-100 bg-primary-600': expandLocales }"> 
            <span
              @click="
                expandUser = false;
                expandLocales = !expandLocales;
                expandSupport = false;
                open = expandLocales; "
              class="w-6 h-6 text-primary-50 dark:text-gray-50 hover:text-white dark:hover:text-gray-200 transition duration-75 material-icons">              
              settings
            </span>
          </a>
        </li>
      </ul>

      <!-- USER  -->
      <ul class="space-y-2 select-none pb-8 "   :title="capitalize((isAuthenticated && user) ? user.nickname : $t('unknown.user'))">
        <li>
          <template v-if="isAuthenticated && user">
            <button
              class="h-12 w-12 rounded-full"
              @click="
                expandUser = !expandUser;
                expandLocales = false;
                expandSupport = false;
                open = expandUser;  
              ">
              <img class="w-full h-full rounded-full border-2 border-gray-300 dark:border-gray-700" :src="user.picture" />
            </button>
          </template>
        </li>
      </ul>
    </div>


    <div class="relative h-full z-20 transition-all duration-500" :class="{ 'w-64': showSideBar, 'w-0': !showSideBar  }">
      <section
        class="absolute top-0 overflow-hidden w-64 py-4 px-3 bg-primary-800 dark:bg-gray-800 flex flex-col h-full justify-between items-start transition-all duration-500"
        :class="{ 'left-0': showSideBar, '-left-64': !showSideBar }">
        <span v-if="  showSideBar && selectedSection && selectedSection.name" class="flex flex-col justify-start items-start h-full w-full">
          <span
            class="material-icons text-white font-medium text-xl pl-2 py-4 w-8 text-right cursor-pointer z-10 absolute right-4 top-4 h-min flex flex-col justify-center items-center"
            @click="forceClose = true">
            arrow_back_ios
          </span>
          <span class="whitespace-nowrap text-primary-50 dark:text-white text-lg h-10 font-semibold my-4 mt-12 flex justify-start items-center pl-2">
            {{ $t(selectedSection.name) }}
          </span>
          <ul class="space-y-2 select-none w-full relative z-50">

            <!-- SUB SECTIONS  -->
            <li v-for="(el, i) in selectedSection.children"   :key="i" class="w-5/6 cursor-pointer ml-4">
              <a :title="$t(el.name)"
                v-if="el.name && !el.hidden"
                @click="setRoute(el)"
                class="w-full flex items-center p-2 text-base font-medium text-primary-50 dark:text-white 
                rounded-lg hover:bg-primary-600 dark:hover:bg-gray-800 hover:text-white dark:hover:text-gray-200  
                hover:opacity-100 transition-all _duration-500"
                :class="{ 'opacity-100 bg-primary-600': $route.path.includes(el.path) }">
                <span class="w-6 h-6 text-primary-50 dark:text-gray-50 hover:text-white dark:hover:text-gray-200 transition duration-75 material-icons">
                  {{ el.icon ? el.icon : 'home' }}
                </span>
                <span class="px-3 text-ellipsis overflow-hidden leading-none whitespace-nowrap capitalize">{{ $t(el.name) }}</span>
              </a>
            </li>

          </ul>
        </span>


        <!-- USER BOX  -->
        <div v-if="expandUser && user" class="absolute bottom-0 left-0 flex flex-col justify-end pb-12 h-min items-end p-4 w-full">
          <div class="h-20 w-20 hidden rounded-full">
            <img class="w-full h-full rounded-xl border-2 border-gray-300 dark:border-gray-700" :src="user.picture" />
          </div>

          <span class="w-full text-left text-white dark:text-white text-lg font-semibold flex justify-start leading-none items-center capitalize mt-2">
            {{ user.nickname }}
          </span>
          <span class="w-full text-left text-gray-400 dark:text-gray-400 text-base font-semibold flex justify-start leading-none items-center">
            {{ user.email }}
          </span>
        </div>


        <!-- LOCALES BOX  -->
        <div v-else-if="expandLocales" class="absolute bottom-0 left-0 flex flex-col justify-end pb-12 h-min items-center p-4 w-full"> 
          <locale-select />
          <time-zone-select />
        </div>

        
        <!-- SUPPORT BOX  -->
        <div v-else-if="expandSupport && user" class="absolute bottom-0 left-0 flex flex-col justify-end pb-12 h-min items-center p-4 w-full">
          <ul class="space-y-2 select-none w-full relative">
            <ul>
              <h3 class="w-full text-left text-base font-semibold text-white dark:text-white">Contatti</h3>
            </ul>
            <ul>
              <h3 class="w-full text-left text-sm font-semibold text-white dark:text-white mt-3">{{ user.organisation.name }}</h3>
            </ul>
            <ul v-if="user.organisation" class="flex justify-start space-x-2 font-semibold text-gray-300 dark:text-gray-300 leading-none text-sm">
              <a target="_blank" :href="`mailto:${user.organisation.referent_email}`">{{ user.organisation.referent_email }}</a>
              <span class="text-white material-icons text-xs">open_in_new</span>
            </ul>
            <ul v-if="user.organisation" class="flex justify-start space-x-2 font-semibold text-gray-300 dark:text-gray-300 leading-none text-sm">
              <a target="_blank" :href="`tel:${user.organisation.referent_cellphone}`">{{ user.organisation.referent_cellphone }}</a>
              <span class="text-white material-icons text-xs">open_in_new</span>
            </ul>
            <ul class="flex justify-start space-x-2 font-semibold text-gray-300 dark:text-gray-300 leading-none mt-1 text-sm">
              <a target="_blank" href="https://ongroup.cloud/">oncharge.it</a>
              <span class="text-white material-icons text-xs">open_in_new</span>
            </ul>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
  import { onMounted, ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { 
    useRoute, 
    useRouter } from 'vue-router';
  import UiPlatform from './UiPlatform.vue';
  import TimeZoneSelect from '@/modules/settings/TimeZoneSelect.vue';
  import LocaleSelect from '@/modules/languages/LocaleSelect.vue';
  import { useAuth0 } from '@auth0/auth0-vue';
  import { ApiCaller } from '@/Utils/utils';

  export default {
    components: { UiPlatform, LocaleSelect, TimeZoneSelect },
    setup() {
      const store = useStore();
      const sections = computed(() => store.getters.menu);
      const selectedSection = computed(() => store.getters.selectedSection);
      const selectedSubSection = computed(() => store.getters.selectedSubSection);

      const route = useRoute();
      const router = useRouter(); 

      const capitalize = computed(() => (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });

      // click on SECTION icon
      const open = ref(false);
      const forceClose = ref(false);
      const select = (section) => { 
        store.dispatch('setSelectedSection', { section: section });  
        expandUser.value = expandLocales.value = expandSupport.value = forceClose.value = false;
        open.value = (selectedSubSection.value && route.path.includes(selectedSubSection.value.path));
        router.push(section.path);
      };
 
      
      // click on SUB SECTIN icon
      const setRoute = (ss) => {   
        store.dispatch('setSelectedSection', { section: selectedSection.value, subSection: ss }); 
        open.value = (selectedSubSection.value && route.path.includes(selectedSubSection.value.path));  
        router.push(ss.path);
      }; 

      const showSideBar = computed(() => {    
        if(forceClose.value) return false;

        return (selectedSubSection.value && route.path.includes(selectedSubSection.value.path) ) || open.value;   
      }); 



      ////////////////////////// DARK mode ////////////////////////////////

      const darkMode = ref(localStorage.getItem('theme') == 'dark');
      watch(
        darkMode,
        (nv) => {
          if (nv) { 
            document.getElementsByTagName('html')[0].classList.add('dark');  
          } else {
            document.getElementsByTagName('html')[0].classList.remove('dark');
          }

          localStorage.setItem('theme', nv ? 'dark' : 'light');
        },
        { immediate: true },
      );

      ////////////////////////// USER ////////////////////////////////

      const { isAuthenticated } = useAuth0();

      onMounted(() => ApiCaller.init());
      watch(
        isAuthenticated,
        (nv) => {
          if (nv) { 
            store.dispatch('setIsAuthenticated', nv); 
            store.dispatch('setUser');
          } else { 
            store.dispatch('setIsAuthenticated', nv);
          }
        },
        { immediate: true },
      );

      const expandUser = ref(false);
      const expandLocales = ref(false);
      const expandSupport = ref(false);

      // USER
      const user = computed(() => store.getters.user);

      return {
        user,
        expandUser,
        expandLocales,
        expandSupport,
        open,
        forceClose,
        sections,
        isAuthenticated,
        selectedSection,
        selectedSubSection,
        showSideBar,
        darkMode,
        select,
        setRoute,
        capitalize
      };
    },
  };
</script>
