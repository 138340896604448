<template>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    //get selected fleet
    const selectedStation = computed(() => {
      return store.getters.selectedFleet;
    });
    const structure = {
      title: "Info flotta",
      edit_endpoint: "updateFleet",
      edit_id_route_name: "fleetId",
      edit_title: "Modifica flotta",
      data: [
        {
          title: "Nome",
          name: "name",
          editable: true,
          placeholder: "--",
        },
        {
          title: "Descrizione",
          name: "description",
          editable: true,
          placeholder: "--",
        },
        {
          title: "Organizzazione",
          name: "organisation",
          sub_name: "name",
          placeholder: "--",
        },
        {
          title: "Referent nome",
          name: "referent",
          sub_name: "name",
          editable: true,
          placeholder: "--",
        },
        {
          title: "Referent email",
          name: "referent",
          sub_name: "email",
          editable: true,
          placeholder: "--",
        },
        {
          title: "Referent cellphone",
          name: "referent",
          sub_name: "cellphone",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.timeEntries.creationDate",

          name: "created_at",
          format: "datetime",
          placeholder: "--",
        },
        {
          title: "views.timeEntries.lastUpdateDate",

          name: "updated_at",
          format: "datetime",
          placeholder: "--",
        },
      ],
    };
    return {
      el: selectedStation,
      structure,
    };
  },
};
</script>

<style>
</style>