<template>
  <transition name="fade">
    <div class="absolute -right-4 top-24 w-72 h-min px-4 py-6 rounded-2xl bg-white dark:bg-gray-900 shadow-lg border border-gray-300 dark:border-gray-700 z-30 flex flex-col justify-start items-start">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-white mb-3">Filtri</h1>
      <div v-for="(filter, i) in filters" :key="i" class="flex flex-col w-full mb-3 border-b pb-4 border-gray-300 dark:border-gray-700">
        <h3 v-html="filter.label" class="text-lg font-semibold text-gray-400 mt-1"></h3>
        <template v-if="filter.type == 'SELECT' && fv['SELECT'][filter.field] && fv['SELECT'][filter.field] != ''">
          <select v-model="fv['SELECT'][filter.field]" class="mt-1 bg-white dark:bg-gray-900 text-lg font-semibold px-3 border border-gray-300 dark:border-gray-700 py-2 rounded-lg focus:ring-0 focus:outline-none">
            <option value="default" selected>Nessun filtro</option>
            <option v-for="(option, i) in filter.options" :key="i" class="w-full flex items-center mt-2 mb-1 select-none" :value="option">
              {{ option }}
            </option>
          </select>
        </template>
        <template v-if="filter.type == 'CHECKBOX' && fv['CHECKBOX'][filter.field]">
          <div v-for="(option, i) in filter.options" :key="i" class="w-full flex items-center mt-2 mb-1 select-none">
            <input v-model="fv['CHECKBOX'][filter.field][option]" :id="filter.field + '_' + i" :name="filter.field" type="checkbox" class="w-4 h-4 text-primary-600 bg-gray-100 rounded-lg border-gray-300 dark:border-gray-700 focus:ring-primary-500" />
            <label :for="filter.field + '_' + i" class="ml-2 text-sm font-semibold text-gray-900 dark:text-white cursor-pointer">{{ option }}</label>
          </div>
        </template>
        <template v-if="filter.type == 'RANGE'">
          <div v-if="fv['RANGE'][filter.field] && fv['RANGE'][filter.field]['max']" class="w-full flex flex-col items-center mt-2 mb-1 select-none">
            <div class="w-full mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between items-center">
              <div v-html="fv['RANGE'][filter.field]['min'] + ' ' + filter.unit" />
              <div v-html="fv['RANGE'][filter.field]['max'] + ' ' + filter.unit" />
            </div>
            <div class="relative w-full">
              <input :name="filter.field" type="range" v-model="fv['RANGE'][filter.field]['min']" @change="checkMinMax(filter.field)" :min="filter.min" :max="filter.max" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
              <input :name="filter.field" type="range" v-model="fv['RANGE'][filter.field]['max']" @change="checkMinMax(filter.field)" :min="filter.min" :max="filter.max" class="mt-1 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import { onMounted, ref, computed } from 'vue';
  export default {
    props: {
      filters: { type: Object, required: true },
    },
    setup(props) {
      const fv = ref({
        SELECT: {},
        CHECKBOX: {},
        RANGE: {},
      });

      const result = computed(() => {
        let temp = {};
        for (const [key, value] of Object.entries(fv.value.CHECKBOX)) {
          temp[key] = [];
          for (const [key2] of Object.entries(value)) temp[key].push(key2);
        }
        return {
          SELECT: fv.value.SELECT,
          RANGE: fv.value.RANGE,
          CHECKBOX: temp,
        };
      });

      const checkMinMax = (field) => {
        if (fv.value['RANGE'][field]['min'] && fv.value['RANGE'][field]['max']) {
          if (parseInt(fv.value['RANGE'][field]['min']) > parseInt(fv.value['RANGE'][field]['max'])) fv.value['RANGE'][field]['min'] = fv.value['RANGE'][field]['max'];
        }
      };

      onMounted(() => {
        for (const el in props.filters) {
          let filter = props.filters[el];
          fv.value[filter.type][filter.field] = {};
          switch (filter.type) {
            case 'SELECT':
              fv.value['SELECT'][filter.field] = 'default';
              break;
            case 'RANGE':
              fv.value['RANGE'][filter.field]['min'] = filter.min;
              fv.value['RANGE'][filter.field]['max'] = filter.max;
              break;
          }
        }
      });
      return { fv, result, checkMinMax };
    },
  };
</script>
