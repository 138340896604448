<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
import { Utils } from "@/Utils/utils";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //init vue stuffs
    const store = useStore();
    //init selects
    store.dispatch("getAssignableTrackers");
    const trackers = computed(() =>
      Utils.getNameArrayFromData(store.getters.assignableTrackers, "imei")
    );

    store.dispatch("getBikeTypes");
    const bikeTypes = computed(() => {
      return Utils.getNameArrayFromData(store.getters.bikeTypes);
    });
    store.dispatch("getManagers");
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    const locations = computed(() => {
      return Utils.getNameArrayFromData(store.getters.locations);
    });
    store.dispatch("getZoneGroups");
    const zoneGroups = computed(() => {
      return Utils.getNameArrayFromData(store.getters.zoneGroups);
    });
    function changeLocationByManager(event) {
      store.dispatch("getLocations", {
        manager: event.target.value,
      });
    }
    const trackerPosition = [
      "",
      "INTERNO_CENTRALINA",
      "ESTERNO_SOTTO_CENTRALINA",
      "ESTERNO_TELAIO",
    ];
    //structure of page
    const insertData = {
      title: "Inserimento nuova bicicletta",
      redirect_path: "/sharing/vehicles/bikes",
      create_endpoint: "createBike",
      creation_title: "Creazione nuova bicicletta",
      dispatchName: "getBikes",
      data: [
        reactive({
          title: "Tipo",
          name: "type",
          select: bikeTypes,
          required: true,
        }),
        reactive({
          title: "Numero tracker",
          name: "tracker",
          select: trackers,
          required: true,
          class: "col-span-2",
        }),
        reactive({
          title: "Gestore",
          name: "manager",
          select: managers,
          on_change: changeLocationByManager,
        }),
        reactive({
          title: "Struttura",
          name: "location",
          select: locations,
        }),
        reactive({
          title: "Gruppo di zone",
          name: "zone_group",
          select: zoneGroups,
        }),
        {
          title: "Posizione tracker",
          name: "tracker_slot",
          select: trackerPosition,
          required: true,
        },
        {
          title: "Targa",
          name: "plate",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Telaio",
          name: "telaio",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Pin",
          name: "pin",
        },
        {
          title: "Manutenzione",
          name: "maintenance",
          boolean: true,
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>