import { ApiCaller } from '@/Utils/utils';
import Bikes from './bikes/bike';
import Scooters from './scooters/scooter';
import Cars from './cars/car';
import Type from './type/type';
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    maintenanceAll: (_, payload) => ApiCaller.POST(`/vehicles/maintenanceAll`, payload),
  },
  modules: { Bikes, Scooters, Cars, Type },
};
