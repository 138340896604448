import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    bikes: [],
    selectedBikes: null,
    bikeTypes: [],
  },
  getters: {
    bikes: (state) => state.bikes,
    selectedBike: (state) => {
      if (state.selectedBike) return state.selectedBike[0];
      return state.selectedBike;
    },
    bikeTypes: (state) => state.bikeTypes,
  },
  mutations: {
    getBikes: (state, payload) => (state.bikes = payload),
    setSelectedBike: (state, payload) => (state.selectedBike = payload),
    getBikeTypes: (state, payload) => (state.bikeTypes = payload),
  },
  actions: {
    getBikes: (ctx, payload) =>
      ApiCaller.GET('/vehicles/bike', payload).then((data) => {
        ctx.commit('getBikes', Utils.createFiltrableData(data));
      }),
    setSelectedBike: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/bike/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedBike', data);
      }),
    getBikeTypes: (ctx, payload) =>
      ApiCaller.GET('/vehicle_types', {
        vehicleType: 'bike',
        ...payload,
      }).then((data) => {
        ctx.commit('getBikeTypes', Utils.createFiltrableData(data));
      }),
    //QUERIES
    updateBike: (_, payload) => ApiCaller.PUT(`/vehicles/bike/${payload.id}`, payload.body),
    createBike: (_, payload) => ApiCaller.POST(`/vehicles/bike`, payload),
    createBikeType: (_, payload) => ApiCaller.POST(`/vehicles/bike_type`, { type: 'bike', ...payload }),
    updateBikeType: (_, payload) => ApiCaller.PUT(`/vehicles/type/${payload.id}`, { type: 'bike', ...payload.body }),
  },
};
