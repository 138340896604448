<template>
  <template v-if="el">
    <div class="w-full h-full flex flex-col font-medium pb-48" style="width: 100%">
      <div v-for="(configuration, i) in el.configurations" :key="i">
        <div class="grid grid-cols-12 justify-start items-center">
          <div class="col-span-12 text-2xl font-bold py-4 px-3 mt-6">{{ configuration.title }}</div>
          <div class="col-span-1 text-gray-400 py-0 px-3 text-left font-bold">#</div>
          <div class="col-span-4 text-gray-400 py-0 px-3 text-left font-bold">Etichetta</div>
          <div class="col-span-2 text-gray-400 py-0 px-3 text-left font-bold">Lunghezza</div>
          <div class="col-span-2 text-gray-400 py-0 px-3 text-left font-bold">R/W</div>
          <div class="col-span-3 text-gray-400 py-0 px-3 text-left font-bold">Default</div>
        </div>

        <div v-for="(conf, j) in configuration.fields" :key="j" class="grid grid-cols-12 border-b border-gray-200 py-6 justify-start items-center">
          <div class="col-span-1 text-gray-400 py-0 px-3 text-left font-bold">{{ conf.id / 1000 }}</div>
          <div class="col-span-4 px-3 font-semibold flex flex-col justify-center items-start">
            <span class="text-base">{{ conf.label }}</span>
            <span class="text-sm text-gray-500">{{ conf.desc }}</span>
          </div>
          <div class="col-span-2 px-3 font-semibold">
            <b>{{ conf.type.length }}</b>
            {{ conf.type.unit }}
          </div>
          <div class="col-span-2 px-3 font-semibold">
            <input type="checkbox" disabled v-model="conf.read" />
            <span class="px-2">/</span>
            <input type="checkbox" disabled v-model="conf.write" />
          </div>
          <div class="col-span-3 px-3 font-semibold cursor-pointer text-gray-800" @click="values[conf.id].value = conf.default" v-html="conf.default"></div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    setup() {
      const store = useStore();
      const selectedDeviceConfiguration = computed(() => store.getters.selectedDeviceConfiguration);
      return { el: selectedDeviceConfiguration };
    },
  };
</script>

<style></style>
