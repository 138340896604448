<template>
  <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage() {
        store.dispatch('getAlarmTypes', store.getters.currentPlatform.db);
      }

      const alarmTypesList = computed(() => store.getters.alarmTypes);

      const structure = {
        creationLink: {
          title: 'buttons.createAlarmType',
          namePath: 'menu.alarm.alarmTypesCreation',
        },
        data: [
          {
            title: 'Id',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/alarm/alarmTypes/:id' },
          },
          {
            title: 'user.name.first',
            fields: [
              {
                name: 'name',
              },
            ],
            span: 1,
          },
        ],
      };

      return {
        completeList: alarmTypesList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
