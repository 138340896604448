<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  export default {
    setup() {
      const producer = [' ', 'ON', 'BTE', '3CE'];

      const insertData = {
        title: 'deviceConfiguration.create',
        redirect_path: '/device/deviceConfigurations',
        create_endpoint: 'createDeviceConfiguration',
        dispatchName: 'getDeviceConfigurations',
        data: [
          {
            title: 'name',
            name: 'name',
            class: 'col-span-2',
            required: true,
          },
          {
            title: 'description',
            name: 'description',
            class: 'col-span-2',
            editable: true,
          },
          {
            title: 'deviceConfiguration.productor',
            name: 'producer',
            required: true,
            select: producer,
          },
          {
            title: 'deviceConfiguration.firmwareVersion',
            name: 'firmware_version',
            required: true,
          },
        ],
      };

      return {
        insertData,
      };
    },
  };
</script>

<style></style>
