import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    invoices: [],
    documentsThree: {}
  },
  getters: {
    invoices: (state) => state.invoices,
    documentsThree: (state) => state.documentsThree
  },
  mutations: {
    setInvoices: (state, data) => state.invoices = Utils.createFiltrableData(data),
    setDocumentsThree: (state, data) => state.documentsThree = data,
  },
  actions: {
    getObjForDownload: (_, payload) => ApiCaller.POST("/billing/invoices/download", payload),
    getInvoices: (ctx, payload) => ApiCaller.GET("/billing/invoices", payload).then((data) => ctx.commit("setInvoices", data)),
    getDocumentsThree: (ctx, payload) => ApiCaller.GET("/billing/invoices/folders", payload).then((data) => ctx.commit("setDocumentsThree", data)),
  },
};
