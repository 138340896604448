<template>
  <div class="p-6 w-full flex flex-col justify-start items-start max-w-7xl">
    <span class="px-2 font-semibold text-gray-700 text-xl mb-6" :class="textClass">{{ t(name) }}</span>
    <slot></slot>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      textClass: {
        type: String,
        default: '',
      },
    },
    setup() {
      return { t: useI18n().t };
    },
  };
</script>

<style></style>
