<template>
  <Combobox as="div" v-model="selectedElement">
    <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">{{ title }}</ComboboxLabel>
    <div class="relative mt-2">
      <ComboboxInput
        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        @change="query = $event.target.value"
        :display-value="(el) => el" />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredData.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ComboboxOption
          v-for="el in filteredData"
          :key="el.secondaryField"
          :value="el[returnedField]"
          as="template"
          v-slot="{ active, selected }">
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            ]">
            <div class="flex relative">
              <span
                :class="['truncate group', selected && 'font-semibold']"
                @mouseenter="showTooltip(el.primaryField, $event)"
                @mouseleave="hideTooltip">
                {{ el.primaryField }}
                <Teleport to="body">
                  <div
                    v-show="isHovering"
                    class="absolute z-50 group-hover:block w-auto bg-black p-2 pt-3 mt-2 text-white text-xs rounded-lg shadow-md"
                    :style="{ top: tooltipPosition?.value?.y + 'px', left: tooltipPosition?.value?.x + 'px' }">
                    {{ tooltipContent }}
                  </div>
                  {{ tooltipContent }}
                </Teleport>
              </span>
              <span :class="['ml-2 text-gray-500', active ? 'text-indigo-200' : 'text-gray-500']">
                {{ el.secondaryField }}
              </span>
            </div>

            <span
              v-if="selected"
              :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
  import { computed, ref, reactive, defineProps } from 'vue';
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
  } from '@headlessui/vue';

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
      validator: (data) =>
        data.every((el) => typeof el.primaryField === 'string' && typeof el.secondaryField === 'string'),
    },
    returnedField: {
      type: String,
      default: 'primaryField',
    },
  });

  const query = ref('');
  const selectedElement = ref('');
  const filteredData = computed(() =>
    query.value === ''
      ? props.data
      : props.data.filter((el) => {
          return (
            el.primaryField.toLowerCase().includes(query.value.toLowerCase()) ||
            el.secondaryField.toLowerCase().includes(query.value.toLowerCase())
          );
        }),
  );

  const isHovering = ref(false);
  const tooltipContent = ref('');
  const tooltipPosition = reactive({ x: 0, y: 0 });

  const showTooltip = (content, event) => {
    tooltipPosition.value = {
      x: event.clientX,
      y: event.clientY,
    };

    tooltipContent.value = content;
    isHovering.value = true;
  };
  const hideTooltip = () => {
    isHovering.value = false;
  };
</script>
