import { ApiCaller } from "@/Utils/utils";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sendEmailRequest: (_, payload) => {
      console.log("PAYLOAD", payload);
      ApiCaller.POST("/assistance/send-request", payload);
    },
  },
  modules: {},
};
