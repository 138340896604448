<template>
  <div class="mb-4 w-full mx-auto">
    <div class="flex justify-between">
      <base-button v-if="headerType !== 'language'" icon="delete" class-name="bg-red-300 text-red-900 hover:bg-red-200 mr-4" @click="deleteLocale">
        {{ t('buttons.deleteLanguage') }}
      </base-button>
      <base-button v-else icon="delete" class-name="bg-red-300 text-red-900 hover:bg-red-200" @click="deleteElem">
        {{ t('buttons.deleteLocaleCustomizations') }}
      </base-button>
    </div>
    <div class="flex flex-col justify-center items-start py-6">
      <div>
        <div class="text-3xl font-bold mb-3 mt-2 leading-none order-1">
          {{ $t(el.title) }}
        </div>
        <template v-if="headerType !== 'language'">
          <h3 class="text-primary-400 order-1 text-xl">Locale</h3>
          <div class="text-2xl mb-3 mt-2 leading-none order-1">
            {{ t(el.locale) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      el: {
        type: Object,
        required: true,
      },
      headerType: {
        type: String,
        default: 'language',
      },
    },
    setup(props) {
      /* const i18n = useI18n(); */
      const store = useStore();
      const deleteElem = () =>
        store.dispatch('deleteLocaleCustomizations', props.headerType === 'language' ? { id: props.el.id } : { id: props.el.id, locale: props.el.locale });
      const deleteLocale = () => store.dispatch('deleteLocale', { id: props.el.id, locale: props.el.locale });
      const deleteText = computed(() => (props.headerType === 'language' ? 'buttons.deleteLanguage' : 'buttons.deleteLocaleCustomizations'));
      return {
        deleteElem,
        deleteText,
        deleteLocale,
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
