<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { reactive, computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      store.dispatch('getOrganisations');
      const organisations = computed(() => Utils.getNameArrayFromData(store.getters.organisations));

      store.dispatch('getRoles');
      const roles = computed(() => Utils.getNameArrayFromData(store.getters.roles, 'label'));

      const insertData = {
        title: 'consumers.createConsumer',
        redirect_path: '/user/consumers/consumers',
        create_endpoint: 'createConsumer',
        creation_title: 'Creazione Utente',
        dispatchName: 'getConsumers',
        data: [
          {
            title: 'consumers.nickname',
            name: 'nickname',
            required: true,
          },
          {
            title: 'consumers.name',
            name: 'given_name',
            required: true,
          },
          reactive({
            title: 'consumers.organisation',
            name: 'organisation',
            select: organisations,
            required: true,
          }),
          reactive({
            title: 'consumers.roles',
            name: 'role',
            select: roles,
            required: true,
          }),
          {
            title: 'consumers.surname',
            name: 'family_name',
            required: true,
          },
          {
            title: 'consumers.email',
            name: 'email',
            placeholder: 'consumers.requiredForLogin',
            required: true,
            class: '',
          },
          {
            title: 'consumers.password',
            name: 'password',
            required: true,
          },
        ],
      };
      return { insertData };
    },
  };
</script>

<style></style>
