<template>
  <div v-if="el && structure" class="w-full mx-auto justify-start items-center flex flex-col">
    <div v-if="structure.edit_endpoint" class="flex justify-end py-3 w-full">
      <base-button icon="edit" @click="changeEdit()">{{ editButtonString() }}</base-button>
    </div>
    <base-subpage>
      <keep-alive>
        <div v-if="!editModel">
          <base-list-title :name="structure.title" />
          <base-list-element class="w-full grid grid-cols-2">
            <base-list-field
              v-for="data in structure.data"
              :key="data"
              :name="data.title"
              :statusOK="isOk(el, data.statusOK)"
              :class="data.class">
              <span v-html="customField(data)"></span>
            </base-list-field>
          </base-list-element>
        </div>
        <base-generals-edit v-else :editableFields="editableFields" />
      </keep-alive>
    </base-subpage>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { Utils } from '@/Utils/utils';
  import { i18n } from '@/i18n';
  import BaseGeneralsEdit from './BaseGeneralsEdit.vue';
  export default {
    components: {
      BaseGeneralsEdit,
    },
    props: {
      el: {
        required: true,
      },
      structure: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const t = i18n.global.t;

      const extractText = (el, ref, text) => {
        if (el === undefined || text === undefined) return '';
        if (typeof text === 'string') return el[ref.name][text];
        let ret = '';
        text.forEach((elmt) => {
          ret += el[ref.name][elmt] + ' ';
        });
        ret = ret.slice(0, -1);
        return ret;
      };

      const isOk = computed(() => {
        return (elmt, statusOK) => {
          if (statusOK === undefined) return statusOK;
          if (!statusOK.includes('.')) return elmt[statusOK];
          let ret = elmt;
          const fieldsArray = statusOK.split('.');

          fieldsArray.forEach((el) => {
            if (!ret[el]) {
              ret = null;
            } else {
              ret = ret[el];
            }
          });
          return ret;
        };
      });
      function customField(data) {
        if (data.mapper !== undefined && data.mapper !== null) return extractText(props.el, data, data.mapper.text);
        let finalFirstString = '';
        let finalSecondString = '';
        //init first and second fields
        let field = '';
        let toFormat = '';
        if (data.sub_name) {
          toFormat = props.el[data.name][data.sub_name];
          field = formatData(toFormat, data.format);
          //field = formatData(props.el[data.name][data.sub_name], data.format);
        } else {
          toFormat = props.el[data.name];
          field = formatData(toFormat, data.format);
        }

        toFormat = props.el[data.concat];
        let secondField = formatData(toFormat);

        //create second string
        if (data.concat) {
          if (data.concat.format) {
            toFormat = props.el[data.concat.name];
            finalSecondString = formatData(toFormat, data.concat.format);
          } else {
            if (secondField) {
              finalSecondString = secondField;
            } else finalSecondString = data.concatPlaceholder ? data.concatPlaceholder : '';
          }
        }
        //create first string
        if (data.boolean) {
          if (field) finalFirstString = data.boolean.if_true;
          else finalFirstString = data.boolean.if_false;
          //return string
          return t(`${finalFirstString}`) + `${finalSecondString}`;
        }
        if (field) finalFirstString = field;
        else finalFirstString = data.placeholder ? data.placeholder : '';
        return `${finalFirstString} ${finalSecondString}`;
      }
      const formatData = Utils.formatData;
      //edit field
      const editModel = ref(false);
      function changeEdit() {
        editModel.value = !editModel.value;
      }
      function editButtonString() {
        return editModel.value ? t('buttons.backToDetails') : t('buttons.enableEditing');
      }
      //take editable field
      let editableFields = computed(() => {
        let newObj = {
          edit_endpoint: props.structure.edit_endpoint,
          edit_title: props.structure.edit_title,
          edit_id_route_name: props.structure.edit_id_route_name,
          selects: props.structure.selects,
          data: [],
        };
        for (let itm in props.structure.data) {
          if (!props.structure.data[itm].editable) continue;
          newObj.data.push({
            title: props.structure.data[itm].title,

            name: props.structure.data[itm].db_name
              ? props.structure.data[itm].db_name
              : props.structure.data[itm].sub_name
              ? props.structure.data[itm].sub_name
              : props.structure.data[itm].name,

            placeholder: props.structure.data[itm].sub_name
              ? props.el[props.structure.data[itm].name][props.structure.data[itm].sub_name]
              : props.el[props.structure.data[itm].name],

            boolean: props.structure.data[itm].boolean,
            select: props.structure.data[itm].select,
            on_change: props.structure.data[itm].on_change,
            mapper: props.structure.data[itm].mapper ? props.structure.data[itm].mapper : null,
          });
        }
        return newObj;
      });

      return {
        editModel,
        editableFields,
        editButtonString,
        changeEdit,
        customField,
        isOk,
      };
    },
  };
</script>

<style></style>
