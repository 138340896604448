<template>
  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
  <input
    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
    id="file_input"
    ref="file"
    @change="handleFileUpload()"
    type="file" />
  <button @click="onSend(file)">Send</button>
</template>
<script>
  import { ref } from 'vue';

  export default {
    props: {
      onSend: { type: Function },
    },
    setup() {
      const file = ref(null);

      const handleFileUpload = async () => {
        // debugger;
        console.log('selected file', file.value.files);
        //Upload to server
      };

      return {
        handleFileUpload,
        file,
      };
    },
  };
</script>
