<template>
  <base-details-header :structure="headerStructure" :el="selectedAuthUser" />
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedAuthUser', selectedElID);

      const selectedAuthUser = computed(() => {
        return store.getters.selectedAuthUser;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Ruoli',
          name: 'menu.consumers.consumersGenerals',
        },
      ];

      const headerStructure = {
        image: {
          picture: 'picture',
          email: 'email',
          nickname: 'nickname',
        },
        title: [
          {
            title: 'identities',
            subTitle: 'connection',
            isArray: true,
          },
        ],
        data: [
          {
            title: 'Ultimo login',
            type: 'datetime',
            name: 'last_login',
          },
        ],
      };

      return {
        selectedAuthUser,
        headerStructure,
        headers,
      };
    },
  };
</script>

<style></style>
