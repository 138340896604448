<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();

    //get selected manager
    const selectedZoneGroup = computed(() => store.getters.selectedZoneGroup);

    const structure = {
      title: "Info gruppo",
      edit_endpoint: "updateZoneGroup",
      edit_id_route_name: "zoneId",
      edit_title: "Modifica gruppo",
      data: [
        {
          title: "user.name.first",

          name: "name",
          editable: true,
          placeholder: "-",
          statusOK: "name",
        },
        {
          title: "description",

          name: "description",
          editable: true,
          placeholder: "-",
        },
        {
          title: "views.timeEntries.creationDate",

          name: "created_at",
          format: "datetime",
        },
        {
          title: "views.timeEntries.lastUpdateDate",

          name: "updated_at",
          format: "datetime",
          placeholder: "-",
        },
      ],
    };
    return {
      el: selectedZoneGroup,
      structure,
    };
  },
};
</script>

<style>
</style>