<template>
  <div v-if="selectedBike" class="px-16 pt-8">
    <base-details-header :el="selectedBike" :structure="headerStructure" />
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { computed, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      //  init sources
      const selectedElID = { id: route.params.id };
      const selectedDevice = { device: route.params.id };
      onMounted(() => {
        store.dispatch('setSelectedBike', selectedElID);
        store.dispatch('getBikeTypes');
        store.dispatch('getRentals', {
          ...selectedDevice,
        });
        store.dispatch('getZoneGroups');
      });
      const selectedBike = computed(() => {
        return store.getters.selectedBike;
      });
      watch(selectedBike, (newVal) => {
        console.log(newVal.manager);
        if (!newVal || !newVal.manager) store.dispatch('getLocations');
        store.dispatch('getLocations', {
          manager: newVal.manager,
        });
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Generali',
          name: 'menu.sharing.bikeGenerals',
        },
        {
          text: 'Noleggi',
          name: 'menu.sharing.bikeRentals',
        },
      ];
      const headerStructure = {
        title: [
          {
            title: 'plate',
          },
          {
            class: 'text-sm font-normal text-gray-700',
            placeholder: 'Livello batteria: ',
            title: 'battery_level',
            footer: '%',
          },
        ],
        data: [
          {
            title: 'Prezzo giornaliero ',
            name: 'price_per_day',
            footer: ' €',
          },
          {
            title: 'Prezzo al minuto ',
            name: 'price_per_minute',
            footer: ' €',
          },
          {
            title: 'Prezzo 1-3h ',
            name: 'price_1_3',
            footer: ' €',
          },
          {
            title: 'Prezzo 3-6h ',
            name: 'price_3_6',
            footer: ' €',
          },
          {
            title: 'Prezzo al km ',
            name: 'price_per_km',
            footer: ' €',
          },
          {
            title: 'Posizione ',
            placeholder: 'lat',
            name: 'lng',
            separator: ', ',
          },
        ],
      };
      return {
        selectedBike,
        headers,
        headerStructure,
      };
    },
  };
</script>
<style></style>
