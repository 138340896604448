<template>
  <base-list-table
    :data="data"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = () =>
      store.dispatch("getDeviceConfigurationsVehicles", {
        device_configuration: route.params.id,
      });

    const vehicles = computed(() => store.getters.deviceConfigurationsVehicles);

    const structure = {
      title: "vehicles.list",

      summaryFields: [
        {
          type: "COUNT_DISTINCT",
          label: "vehicles.vehicles",
          field: "plate",
          unit: "summary.vehicles",
        },
        {
          type: "COUNT_DISTINCT",
          label: "vehicles.types",
          field: "type",
          unit: "summary.types",
        },
        {
          type: "COUNT_DISTINCT",
          label: "Modelli diversi",
          field: "vehicle_model_id",
          unit: "summary.models",
        },
        {
          type: "COUNT_DISTINCT_WITH_VALUE",
          label: "vehicles.tractors",
          field: "plate",
          valueField: "type",
          value: "TRACTOR",
          unit: "summary.tractors",
        },
      ],
      data: [
        {
          title: "vehicles.table.plate",
          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-300",
            },
            {
              name: "plate",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: { idField: "id", path: "/mobility/vehicles/:id/" },
        },
        {
          title: "vehicles.table.frame",
          field: "vin",
          span: 1,
        },
        {
          title: "vehicles.table.type",
          field: "type",
          span: 1,
          format: "vehicleType",
        },
        {
          title: "vehicles.table.device",
          fields: [
            {
              placeholder: "@",
              name: "device_id",
              class: "text-primary-300",
            },
            {
              name: "imei",
              class: "font-bold",
            },
          ],
          span: 1,
          link: { idField: "device_id", path: "/device/devices/:id" },
        },

        {
          title: "vehicles.table.latitude",
          field: "latitude",
          span: 1,
        },
        {
          title: "vehicles.table.longitude",
          field: "longitude",
          span: 1,
        },
        {
          title: "vehicles.table.heartbeat",
          field: "last_heartbeat",
          format: "datetime",
          span: 1,
        },
      ],
    };

    return {
      data: vehicles,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>