<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { computed, reactive } from 'vue';
  export default {
    setup() {
      const store = useStore();
      //structure of page
      const types = ['', 'Box', 'Lock'];

      store.dispatch('getManagers');
      const managers = computed(() => Utils.getNameArrayFromData(store.getters.managers));

      const insertData = {
        title: '',
        redirect_path: '/sharing/releaseStations',
        create_endpoint: 'createReleaseStation',
        creation_title: '',
        data: [
          {
            title: 'bricks.name',
            name: 'name',
          },
          reactive({
            title: 'bricks.manager',
            name: 'manager',
            select: managers,
          }),
          //remote id, id che ti da boarin - remote_id
          {
            title: 'bricks.remote_id',
            name: 'remote_id',
          },
          //numero di slots - slots
          {
            title: 'bricks.slots',
            name: 'slots',
            required: true,
          },
          //tipo di slots che deve avere la release station - type
          {
            title: 'bricks.type',
            name: 'type',
            select: types,
            required: true,
          },
          //latitude
          {
            title: 'bricks.latitude',
            name: 'latitude',
            required: true,
          },
          //longitude
          {
            title: 'bricks.longitude',
            name: 'longitude',
            required: true,
          },
        ],
      };
      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
