<template>
  <base-details-header :structure="headerStructure" :el="selectedItm" />
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      onMounted(() => store.dispatch('getSelectedZoneGroup', { id: route.params.zoneId }));

      const selectedZoneGroup = computed(() => {
        return store.getters.selectedZoneGroup;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'paths.overall',
          name: 'menu.sharing.zoneDetails',
        },
        {
          text: 'Zone',
          name: 'menu.sharing.zoneManagement',
        },
      ];
      const headerStructure = {
        title: [
          {
            title: 'name',
          },
        ],
        data: [
          {
            name: 'id',
            placeholder: '@',
          },
          {
            name: 'description',
          },
        ],
      };

      return {
        selectedItm: selectedZoneGroup,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
