<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed, reactive, onBeforeMount } from 'vue';

  export default {
    components: {},
    setup() {
      //init store
      const store = useStore();
      const route = useRoute();
      const selectedElID = { id: route.params.id };
      //init selects
      onBeforeMount(async () => {
        await store.dispatch('getManagers', selectedElID);
        await store.dispatch('setSelectedAlarm', selectedElID);
        await store.dispatch('getAlarmStatusTypes', selectedElID);
      });

      const managers = computed(() => {
        return store.getters.managers;
      });
      //return selectedUser
      const selectedAlarm = computed(() => {
        return store.getters.selectedAlarm;
      });
      const alarmTypes = computed(() => {
        return Utils.getNameArrayFromData(store.getters.alarmTypes);
      });
      const alarmStatusTypes = computed(() => {
        return store.getters.alarmStatusTypes;
      });
      //create generalsPage structure
      const structure = {
        title: 'Info Allarme',
        edit_endpoint: 'updateAlarm',
        edit_title: 'Modifica allarme',
        data: [
          {
            title: 'description',
            name: 'body',
            statusOK: 'body',
            editable: false,
            placeholder: '--',
          },
          reactive({
            title: 'views.alarms.assignedTo',
            name: 'assignedTo',
            sub_name: 'id',
            mapper: reactive({ value: 'id', text: ['name', 'surname'], current: computed(() => (selectedAlarm.value !== undefined ? selectedAlarm.value.assignedTo.id : '')) }),
            statusOK: 'assignedTo.id',
            select: managers,
            editable: true,
            class: 'col-span-2',
            placeholder: '--',
          }),
          {
            title: 'views.alarms.type',
            name: 'alarmType',
            sub_name: 'name',
            statusOK: 'alarmType.id',
            editable: false,
            placeholder: '--',
          },
          reactive({
            title: 'Stato Attuale',
            name: 'currentStatusType',
            sub_name: 'name',
            mapper: { value: 'id', text: 'name', current: computed(() => (selectedAlarm.value !== undefined ? selectedAlarm.value.currentStatusType.id : '')) },
            statusOK: 'currentStatusType.id',
            select: alarmStatusTypes,
            editable: true,
            class: 'col-span-2',
            placeholder: '--',
          }),
        ],
      };
      return {
        alarmStatusTypes,
        el: selectedAlarm,
        alarmTypes,
        structure,
      };
    },
  };
</script>

<style></style>
