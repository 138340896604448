<template>
<div class="p-10">
  <form @submit="submitForm" enctype="multipart/form-data">
    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">Upload Image</label>
    <input 
      style="display: none"
      type="file"
      @change="onFileSelected" 
      ref="fileInput"
    />

    <button
      @click="$refs.fileInput.click()"
      class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   focus:outline-none "
    > 
      Pick File
    </button>
    <button
      class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   focus:outline-none "
      type="submit"
    >
      Upload
    </button>
  </form>
</div>
</template>

<script>
import { ref } from "vue";
import { useAuth0 } from '@auth0/auth0-vue';


export default {
  name: 'uploadImage',
  setup() {

    const { getAccessTokenSilently } = useAuth0();
    
    const file = ref("");

    const onFileSelected = (e) => {
      file.value = e.target.files[0];
    };

   async function submitForm(e) {
     e.preventDefault();
      const token = await getAccessTokenSilently();
      //const fileType = encodeURIComponent(file.value.type)
      //const fileName = encodeURIComponent(file.value.name) //`${fileType} ${fileName}`

      const fd = new FormData();
      fd.append('fileType', 'image/png')
      fd.append('fileName', 'cat.png');
      fd.append('folder', 'AC/9');
      fd.append('baseFolder', 'pictures/vehicles_pictures'); //Folder inside s3
      fd.append('file', file.value);

      //look at all the contents
        for (let key of fd.keys()) {
          console.log(key, fd.get(key));
        }

      //send the request with the formdata
      let url = 'https://filesservice.onme.cloud/file';
      let h = new Headers();
      h.append('Access-Control-Allow-Origin','*')
      h.append('Authorization', 'Bearer ' + token)
      //console.log(typeof `${fileType}`)
      //console.log('hello token::', token)


      let req = new Request(url, {
          method: 'POST',
          headers: h,
          body: fd,
        });
        console.log(req);
        fetch(req)
          .then((res) => res.json())
          .then((data) => {
            console.log('Response from server');
            console.log(data);
          })
          .catch(console.warn);
    }
    return {
      onFileSelected,
      submitForm,
    };
  }
}
</script>


<style scoped>

</style>
