<template>
  <base-page>
    <base-list-table
      :data="data"
      :structure="structure"
      :refreshFunction="refreshPage"
    />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = () =>
      store.dispatch("getManagerLocations", { id: route.params.managerId });

    const locations = computed(() => store.getters.managerLocations);

    const structure = {
      title: "paths.locations",

      data: [
        {
          title: "user.name.first",

          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-400",
            },
            {
              name: "name",
            },
          ],
          span: 1,
          link: { idField: "id", path: "/sharing/locations/:id" },
        },
        {
          title: "address.body",

          field: "address",
          span: 2,
        },
        {
          title: "user.cellphone",

          field: "cellphone",
          span: 1,
        },
        {
          title: "user.email",

          field: "email",
          span: 1,
        },
        {
          title: "address.zipcode",

          field: "zipcode",
          span: 1,
        },
      ],
    };

    return {
      data: locations,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>