<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
export default {
  setup() {
    //structure of page
    const insertData = {
      title: "Inserimento nuova flotta",
      redirect_path: "/fleet",
      create_endpoint: "createFleet",
      creation_title: "Creazione flotte",
      dispatchName: "getFleets",
      data: [
        {
          title: "user.name.first",
          
          name: "name",
          class: "col-span-2",
          required: true,
        },
        {
          title: "description",
          
          name: "description",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Organizzazione",
          name: "organisation",
          subField: "name",
        },
        {
          title: "Organizzazione Type",
          name: "organisation",
          subField: "type",
        },
        {
          title: "address.body",
          
          name: "organisation",
          subField: "address",
        },
        {
          title: "Organizzazione VAT",
          name: "organisation",
          subField: "vat",
        },
        {
          title: "Referent nome",
          field: "referent",
          subField: "name",
        },
        {
          title: "Referent email",
          field: "referent",
          subField: "email",
        },
        {
          title: "Referent cellphone",
          field: "referent",
          subField: "cellphone",
        },
        /*{
          title: "Username per il login",
          name: "username",
          required: true,
        },
        {
          title: "Password per il login",
          name: "password",
          placeholder: "NB. Non sarà più visibile una volta creata la flotta.",
          required: true,
        },*/
        {
          title: "user.notes",
          
          name: "notes",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>