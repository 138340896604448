<template>
  <base-subpage-bar :headers="headers" />
  <router-view></router-view>
</template>

<script>
  export default {
    setup() {
      const headers = [
        { text: 'billings.documents', name: 'menu.billings.documentsList' },
        { text: 'billings.invoices', name: 'menu.billings.invoicesList' },
      ];

      return {
        headers,
      };
    },
  };
</script>

<style></style>
