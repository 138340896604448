import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    zoneGroups: [],
    selectedZoneGroup: null,
  },
  getters: {
    zoneGroups: (state) => state.zoneGroups,
    selectedZoneGroup: (state) => {
      if (state.selectedZoneGroup !== null) return state.selectedZoneGroup[0];
      return state.selectedZoneGroup;
    },
  },
  mutations: {
    setZoneGroups: (state, data) => (state.zoneGroups = data),
    setSelectedZoneGroup: (state, data) => (state.selectedZoneGroup = data),
  },
  actions: {
    getZoneGroups: (ctx, payload) =>
      ApiCaller.GET("/zone_group", payload).then((data) => {
        ctx.commit("setZoneGroups", Utils.createFiltrableData(data));
      }),
    getSelectedZoneGroup: (ctx, payload) =>
      ApiCaller.GET(`/zone_group/${payload.id}`, null).then((data) => {
        ctx.commit("setSelectedZoneGroup", data);
      }),
    //QUERIES
    updateZoneGroup: (_, payload) =>
      ApiCaller.PUT(`/zone_group/${payload.id}`, payload.body),
    createZoneGroup: (_, payload) => ApiCaller.POST("/zone_group", payload),
    createZone: (_, payload) =>
      ApiCaller.PUT(`/zone_group/${payload.id}/zone/addZone`, payload),
    removeZone: (_, payload) =>
      ApiCaller.PUT(
        `/zone_group/${payload.id}/zone/${payload.zone_id}/removeZone`,
        payload
      ),
    updateZone: (_, payload) =>
      ApiCaller.PUT(
        `/zone_group/${payload.id}/zone/${payload.zone_id}`,
        payload
      ),
    cloneZone: (_, payload) =>
      ApiCaller.PUT(
        `/zone_group/${payload.id}/zone/${payload.zone_id}/cloneZone`,
        payload
      ),
  },
};
