<template>
  <div v-if="selectedItm" class="px-16 pt-8">
    <base-details-header :structure="headerStructure" :el="selectedItm" />
    <base-subpage>
      <div class="mb-4">
        <base-button icon="refresh" @click="updateStatus">Aggiorna dati</base-button>
      </div>
      <div>
        <h1 class="text-2xl font-bold mb-6">Pannello di controllo noleggio</h1>
      </div>
      <div class="mb-4">
        <div>
          <div class="mb-2 text-xl">
            <h1>Termina noleggio</h1>
          </div>
          <div class="flex">
            <base-button icon="block" @click="endRental">Termina</base-button>
            <base-button icon="block" @click="endSlotRental" class="ml-4">
              {{ $t('sharing.endRentalWithoutPay') }}
            </base-button>
          </div>
        </div>
        <div v-if="selectedItm.status_id == 2 && selectedItm.device_type != 'BIKE'">
          <div class="mb-2 text-xl">
            <h1>Forza inizio noleggio</h1>
          </div>
          <div class="flex">
            <base-button icon="play_circle" @click="startRental">Forza inizio</base-button>
          </div>
        </div>
      </div>
    </base-subpage>
  </div>
  <div v-else>
    <base-subpage class="flex justify-center">
      <div>
        <p>Il noleggio potrebbe essere terminato, controllare tra i noleggi conclusi</p>
        <base-router-link path="/rental/concluded" class="flex justify-center mt-4">Torna indietro</base-router-link>
      </div>
    </base-subpage>
  </div>
</template>

<script>
  import { computed, onMounted, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.rentalId };
      onMounted(() => {
        store.dispatch('getSelectedRental', selectedElID);
      });
      const selectedItm = computed(() => {
        return store.getters.selectedRental;
      });
      const headerStructure = {
        title: [
          {
            title: 'device_id',
          },
        ],
        data: [
          {
            title: 'user.title',
            toTranslate: true,
            name: 'user_name',
            link: {
              idField: 'user_id',
              path: '/user/users/:id',
            },
          },
          {
            title: 'vehicle',

            name: 'device_plate',
            link: {
              idField: 'device_id',
              type: 'vehicle',
            },
          },
          {
            title: 'Modello',
            name: 'model_name',
            link: {
              idField: 'model_id',
              type: 'vehicle_type',
            },
          },
          {
            title: 'Tracker',
            name: 'tracker_imei',
            link: {
              idField: 'tracker_imei',
              path: '/sharing/trackers/:id',
            },
          },
          reactive({
            title: 'Stazione di partenza',
            name: 'start_release_station_id',
            link: {
              idField: 'start_release_station_id',
              path: `/sharing/bricks/:id/stalls/${selectedItm?.value?.start_slot_remote}`,
            },
          }),
          reactive({
            title: 'Stazione presunta di rilascio',
            name: 'end_release_station_id',
            link: {
              idField: 'end_release_station_id',
              path: `/sharing/bricks/:id/stalls/${selectedItm?.value?.end_slot_remote}`,
            },
          }),
          {
            title: 'status',
            name: 'status',
            type: 'rental_status',
          },
          {
            class: 'text-sm font-normal text-gray-700',
            title: 'views.rentals.rentalStart',
            name: 'start_timestamp',
            type: 'datetime',
          },
        ],
      };

      const updateStatus = () => store.dispatch('getSelectedRental', selectedElID).then(() => router.go());
      const endRental = () => {
        const brickId = selectedItm.value?.end_release_station_id
          ? selectedItm.value?.end_release_station_id
          : selectedItm.value?.start_release_station_id;
        const slotId = selectedItm.value?.end_slot_remote
          ? selectedItm.value?.end_slot_remote
          : selectedItm.value?.start_slot_remote;

        store
          .dispatch('endRental', {
            user: selectedItm.value.user_id,
            device_type: selectedItm.value.device_type,
            brickId,
            slotId,
          })
          .then(() => router.go());
      };

      const startRental = () =>
        store
          .dispatch('startRental', {
            device: selectedItm.value.device_id,
            type: selectedItm.value.device_type,
          })
          .then(() => router.go());

      const endSlotRental = () => {
        const id = selectedItm.value?.end_release_station_id
          ? selectedItm.value?.end_release_station_id
          : selectedItm.value?.start_release_station_id;
        const slotId = selectedItm.value?.end_slot_remote
          ? selectedItm.value?.end_slot_remote
          : selectedItm.value?.start_slot_remote;
        store
          .dispatch('endSlotRental', {
            user: selectedItm.value.user_id,
            manager: selectedItm.value.device_manager_id,
            bike_id: selectedItm.value?.private_id,
            id,
            slotId,
            start_slot_id: selectedItm.value?.start_slot_remote,
            end_slot_id: selectedItm.value?.end_slot_remote,
            start_timestamp: selectedItm?.value?.start_timestamp,
          })
          .then(() => router.go());
      };
      return {
        selectedItm,
        headerStructure,
        endSlotRental,
        updateStatus,
        endRental,
        startRental,
      };
    },
  };
</script>
<style></style>
