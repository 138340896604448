<template>
  <div class="mt-6" v-if="items && itemsFiltered">
    <div class="bg-gray-100 p-4 rounded-md border">
      <div class="flex justify-between">
        <div class="flex p-2">
          <p
            @click="pathArray.length > 0 ? removePath(_, true) : null"
            :class="{ 'text-blue-600 cursor-pointer hover:underline': pathArray.length > 0 }">
            folders
          </p>
          <div v-for="(path, i) in pathArray" :key="path" class="flex">
            <p class="mx-2">/</p>
            <p
              @click="i != pathArray.length - 1 ? removePath(path) : null"
              :class="{ 'text-blue-600 cursor-pointer hover:underline': i != pathArray.length - 1 }">
              {{ path }}
            </p>
          </div>
        </div>
        <div v-if="pathArray.length > 0 && enableDownloadView" class="flex justify-end">
          <button
            class="text-gray-200 bg-primary-600 p-2 rounded-md text-sm"
            @click="downloadObjectAsZip(itemsFiltered)">
            Download vista
          </button>
        </div>
      </div>

      <div class="bg-gray-300 rounded-sm mt-4">
        <span v-for="(value, key) in itemsFiltered" :key="key">
          <div
            class="bg-gray-50 rounded-sm px-3 py-2 border border-gray-300"
            :class="{ 'hover:bg-gray-100 hover:cursor-pointer': !checkType(key) }">
            <div class="grid grid-cols-2" @click="checkType(key) ? null : addPath(key)">
              <div class="flex">
                <span class="material-icons mr-6" :class="returnType(key)">
                  {{ checkType(key) ? 'description' : 'folder' }}
                </span>
                <p class="">{{ key }}</p>
              </div>
              <div class="flex justify-end" v-if="enableDownloadFile">
                <div>
                  <button @click.stop="toggleOption(key)" class="material-icons">more_horiz</button>
                  <div
                    v-if="showOptions[key] && selectedOption === key"
                    class="absolute z-10 py-2 px-4 bg-gray-50 rounded-lg shadow-lg transform -translate-x-full"
                    @click.stop>
                    <button class="text-sm" @click="downloadObjectAsZip(value)">Download</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div v-else class="mt-6">Empty</div>
</template>

<script>
  import JSZip from 'jszip';
  import { reactive, ref, computed, watch, watchEffect } from 'vue';
  export default {
    name: 'FolderThree',
    props: {
      items: {
        type: [Object, Array],
        required: true,
      },
      getDownloadObjFunction: {
        type: Function,
        required: true,
      },
      enableDownloadView: {
        type: Boolean,
        default: false,
      },
      enableDownloadFile: {
        type: Boolean,
        default: false,
      },
      zipName: {
        type: String,
        default: 'files',
      },
    },
    setup(props) {
      const showOptions = reactive({});
      const selectedOption = ref(null);
      const checkType = (val) => {
        if (val.toString().includes('.pdf')) return true;
        else if (val.toString().includes('.xml')) return true;
        else return false;
      };
      const returnType = (val) => {
        if (val.toString().includes('.pdf')) return 'text-red-600';
        else if (val.toString().includes('.xml')) return 'text-green-600';
        else return 'text-black';
      };

      const pathArray = ref([]);
      const addPath = (key) => {
        pathArray.value.push(key);
      };
      const removePath = (key = null, reset = false) => {
        if (reset) pathArray.value = [];

        if (key) pathArray.value?.splice(pathArray.value?.indexOf(key) + 1, pathArray.value?.length);
        else pathArray.value.pop();
      };

      const itemsFiltered = computed(() => {
        const items = props.items;
        const paths = pathArray.value;
        if (paths.length <= 0) return items;

        let filteredItems = items;
        for (let i = 0; i < paths.length; i++) {
          filteredItems = filteredItems[paths[i]];
        }
        return filteredItems;
      });

      const toggleOption = (key) => {
        if (showOptions[key] && selectedOption.value === key) {
          showOptions[key] = false;
          selectedOption.value = null;
        } else {
          showOptions[key] = true;
          selectedOption.value = key;
        }
      };

      watch(
        pathArray,
        () => {
          Object.keys(showOptions).forEach((key) => {
            showOptions[key] = false;
          });
          selectedOption.value = null;
        },
        { deep: true },
      );
      watchEffect(() => {
        const handleOutsideClick = (event) => {
          if (!event.target.closest('.options-button')) {
            Object.keys(showOptions).forEach((key) => {
              showOptions[key] = false;
            });
            selectedOption.value = null;
            document.removeEventListener('click', handleOutsideClick);
          }
        };

        if (selectedOption.value) {
          document.addEventListener('click', handleOutsideClick);
        }
      });

      const buildZip = async (obj, folder) => {
        if (!folder) {
          const zip = new JSZip();
          folder = zip.folder('root');
        }

        if (obj !== null && !obj.key) {
          // Cicla l'oggetto
          for (const key in obj) {
            // Verifica se l'obj è un file o una cartella
            if (checkType(key)) {
              if (isPDF(key)) {
                const pdfFile = await props.getDownloadObjFunction(obj[key].key);
                folder.file(key, pdfFile, { binary: true });
              } else {
                const file = await props.getDownloadObjFunction(obj[key].key);
                folder.file(key, file);
              }
            } else {
              const subfolder = folder.folder(key);
              await buildZip(obj[key], subfolder);
            }
          }
        } else if (obj.key) {
          const zip = new JSZip();
          if (isPDF(obj.key)) {
            const pdfFile = await props.getDownloadObjFunction(obj.key);
            const fileName = obj.key.split('/').pop();
            zip.file(fileName, pdfFile, { binary: true });
          } else {
            const file = await props.getDownloadObjFunction(obj.key);
            const fileName = obj.key.split('/').pop();
            zip.file(fileName, file);
          }

          return zip.generateAsync({ type: 'blob' });
        }

        return folder.generateAsync({ type: 'blob' });
      };

      const isPDF = (key) => {
        if (key.toString().includes('.pdf')) return true;
        else return false;
      };
      const downloadObjectAsZip = async (obj) => {
        // Genera il file zip
        const content = await buildZip(obj);
        const url = URL.createObjectURL(content);
        // Effettua il download
        const link = document.createElement('a');
        link.href = url;
        link.download = props.zipName + '.zip';
        document.body.appendChild(link);
        link.click();
      };

      return {
        showOptions,
        selectedOption,
        itemsFiltered,
        pathArray,
        downloadObjectAsZip,
        addPath,
        removePath,
        returnType,
        checkType,
        toggleOption,
      };
    },
  };
</script>
