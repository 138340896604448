<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => store.dispatch('getIssues', payload);

      const issues = computed(() => store.getters.issues);

      // table
      const structure = {
        creationLink: {
          title: '',
          namePath: 'menu.tickets.issueCreation',
        },
        data: [
          {
            title: 'views.tickets.type',
            field: 'type',
            span: 1,
          },
          {
            title: 'views.tickets.summary',
            field: 'summary',
            span: 1,
          },
          {
            title: 'views.tickets.createdat',
            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.tickets.assignee',
            field: 'assignee',
            span: 1,
          },
          {
            title: 'views.tickets.link',
            link: { name: 'link', placeholder: 'views.tickets.openIssue' },
            span: 1,
          },
        ],
      };

      return {
        data: issues,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
