<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
  export default {
    setup() {
      //structure of page
      const insertData = {
        title: 'Inserimento nuovo tipo di allarme',
        redirect_path: '/alarm/alarmStates',
        create_endpoint: 'createAlarmType',
        creation_title: 'Creazione tipo di allarme',
        data: [
          {
            title: 'user.name.first',
            name: 'name',
            required: true,
          },
        ],
      };
      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
