<template>
  <base-page>
    <base-list-table
      :data="data"
      :structure="structure"
      :refreshFunction="refreshPage"
    />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const vehicleId = { device: route.params.id };

    function refreshPage(payload) {
      store.dispatch("getRentals", {
        ...payload,
        ...vehicleId,
      });
    }

    const rentals = computed(() => store.getters.rentals);

    const structure = {
      title: "Noleggi",
      data: [
        {
          title: "Utente",
          fields: [
            {
              placeholder: "@",
              name: "user",
              subName: "id",
              class: "text-blue-300",
            },
            {
              name: "user",
              subName: "name",
            },
          ],
          link: {
            idField: "id",
            subField: "user",
            path: "/user/users/:id",
          },
          span: 1,
        },
        {
          title: "Gestore",
          fields: [
            {
              placeholder: "@",
              name: "manager",
              subName: "id",
              class: "text-blue-300",
            },
            {
              name: "manager",
              subName: "name",
            },
          ],
          span: 1,
          link: {
            idField: "id",
            subField: "manager",
            path: "/sharing/managers/:id",
          },
        },
        {
          title: "Inizio noleggio",
          field: "start_timestamp",
          format: "datetime",
          span: 1,
        },
        {
          title: "Fine noleggio",
          field: "end_timestamp",
          format: "datetime",
          span: 1,
        },
        {
          title: "Costo",
          field: "total_amount",
          format: "euro",
          span: 1,
        },
      ],
    };
    return {
      data: rentals,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>