<template>
  <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      // init station page
      const refreshPage = (payload) => store.dispatch('getCompleteStationList', { ...payload, dismissed: true });

      //change station value
      const completeStationList = computed(() => store.getters.completeStationList);

      // table
      const structure = {
        title: 'views.stations.title',
        data: [
          {
            title: 'views.stations.idAndSerial',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'plate',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/recharge/stations/:id' },
          },
          {
            title: 'address.body',
            field: 'indirizzo',
            span: 2,
          },
          {
            title: 'latitude',
            field: 'lat',
            span: 1,
          },
          {
            title: 'longitude',
            field: 'lng',
            span: 1,
          },
          {
            title: 'type',
            field: 'station_type',
            span: 1,
          },
          {
            title: 'status',
            field: 'stato',
            span: 1,
          },
          {
            title: 'recharges.dismissedAt',
            field: 'dismissed_at',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      return {
        completeList: completeStationList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
