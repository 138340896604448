import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    vehicleModels: [],
    vehicleModelsTypeList: [],
    vehicleModelsVehicles: [],
    selectedVehicleModel: null,
  },
  getters: {
    vehicleModels: (state) => state.vehicleModels,
    vehicleModelsTypeList: (state) => state.vehicleModelsTypeList,
    vehicleModelsVehicles: (state) => state.vehicleModelsVehicles,
    selectedVehicleModel: (state) => {
      if (state.selectedVehicleModel) return state.selectedVehicleModel[0];
      return state.selectedVehicleModel;
    },
  },
  mutations: {
    setVehicleModels: (state, data) => (state.vehicleModels = data),
    setVehicleModelsTypeList: (state, data) =>
      (state.vehicleModelsTypeList = data),
    setVehicleModelsVehicles: (state, data) =>
      (state.vehicleModelsVehicles = data),
    setSelectedVehicleModel: (state, data) =>
      (state.selectedVehicleModel = data),
  },
  actions: {
    getVehicleModelsTypeList: (ctx, payload) => {
      ctx.commit("setVehicleModelsTypeList", []);
      ApiCaller.GET("/vehicleModel/getVehicleModelsHeaderList", payload).then(
        (data) => ctx.commit("setVehicleModelsTypeList", data)
      );
    },
    getVehicleModels: (ctx, payload) => {
      ctx.commit("setVehicleModels", []);
      ApiCaller.GET("/vehicleModel", payload).then((data) =>
        ctx.commit("setVehicleModels", Utils.createFiltrableData(data))
      );
    },
    getVehicleModelsVehicles: (ctx, payload) => {
      ctx.commit("setVehicleModelsVehicles", []);
      ApiCaller.GET("/vehicle", payload).then((data) =>
        ctx.commit("setVehicleModelsVehicles", Utils.createFiltrableData(data))
      );
    },
    getSelectedVehicleModel: (ctx, payload) => {
      ctx.commit("setSelectedVehicleModel", []);
      ApiCaller.GET(`/vehicleModel/${payload.id}`, payload).then((data) =>
        ctx.commit("setSelectedVehicleModel", data)
      );
    },
    //APIES
    updateVehicleModel: (_, payload) =>
      ApiCaller.PUT(`/vehicleModel/${payload.id}`, payload.body),

    createVehicleModel: (_, payload) =>
      ApiCaller.POST(`/vehicleModel`, payload),
  },
  modules: {},
};
