<template>
  <base-subpage-bar :headers="headers" />
  <router-view></router-view>
</template>

<script>
  export default {
    setup() {
      const headers = [{ name: 'menu.recharge.productsList' }, { name: 'menu.recharge.subscriptionsList' }];
      return { headers };
    },
  };
</script>
