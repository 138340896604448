<template>
  <base-list-table
    :data="data"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const refreshPage = () => {
      if (selectedDevice.value)
        store.dispatch("getDeviceMessages", { id: selectedDevice.value.id });
      console.log("IMEIIII", selectedDevice.value.id);
    };
    const selectedDevice = computed(() => store.getters.selectedDevice);

    const messages = computed(() => store.getters.deviceMessages);

    const structure = {
      title: "Lista Messaggi",
      data: [
        {
          title: "Creazione",
          field: "created_at",
          span: 1,
          class: "text-style",
          format: "datetime",
        },
        {
          title: "Viaggi",
          field: "trips",
          span: 2,
        },
        {
          title: "Dati GPS",
          field: "message",
          subField: "gps",
          format: "gps",
          span: 2,
        },
        {
          title: "Priorità",
          field: "message",
          subField: "priority",
          format: "priority",
          span: 1,
        },
        {
          title: "timestamp",
          field: "message",
          subField: "timestamp",
          format: "datetime",
          span: 1,
        },
      ],
    };

    return {
      data: messages,
      refreshPage,
      structure,
    };
  },
};
</script>

<style></style>
