<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  export default {
    setup() {
      return {
        headers: [
          { text: 'Mappa', name: 'recharge.rechargeMapAnalytics' },
          { text: 'Totale', name: 'recharge.rechargeSummaryAnalytics' },
          { text: 'Per Utente', name: 'recharge.rechargePerUser' },
        ],
      };
    },
  };
</script>

<style></style>
