import { ApiCaller } from '@/Utils/utils';

export default {
  state: {
    usersForSelect: [],
  },
  getters: {
    usersForSelect: (state) => state.usersForSelect,
  },
  mutations: {
    setSimpleUserList: (state, payload) => (state.usersForSelect = payload),
  },
  actions: {
    sendNotifications: (_, payload) => ApiCaller.POST(`/sendNotifications`, payload),
    getSimpleUserList: (ctx, payload) => {
      ctx.commit('setSimpleUserList', []);
      ApiCaller.GET('/simpleUserList', payload).then((data) => ctx.commit('setSimpleUserList', data));
    },
  },
};
