<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { reactive, computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const sex = [' ', 'M', 'F', 'N'];
      const languages = [' ', 'it', 'en'];

      store.dispatch('getManagers');
      const managers = computed(() => Utils.getNameArrayFromData(store.getters.managers));

      const insertData = {
        title: 'user.createUser',
        redirect_path: '/user/users/list',
        create_endpoint: 'createUser',
        dispatchName: 'getCompleteUsersList',
        data: [
          {
            title: 'user.name.first',
            name: 'name',
            required: true,
          },
          {
            title: 'user.name.second',
            name: 'surname',
            required: true,
          },
          reactive({
            title: 'user.manager',
            select: managers,
            name: 'manager',
          }),
          {
            title: 'user.sex',
            select: sex,
            name: 'sesso',
          },
          {
            title: 'user.email',
            name: 'email',
            required: true,
          },
          {
            title: 'user.prefix',
            name: 'prefix',
            placeholder: 'ex: +39',
            required: true,
          },
          {
            title: 'user.cellphone',
            name: 'number',
            required: true,
          },
          {
            title: 'user.username',
            placeholder: 'username for login',
            name: 'username',
            required: true,
          },
          {
            title: 'user.password',
            placeholder: 'password for login',
            name: 'password',
            required: true,
          },
          {
            title: 'user.language',
            select: languages,
            name: 'language',
          },
          {
            title: 'user.creditCard',
            boolean: true,
            name: 'createCreditCard',
          },
        ],
      };
      return { insertData };
    },
  };
</script>

<style></style>
