<template>
  <base-subpage-bar :headers="headers" />
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, watch } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const route = useRoute();
      const vehicleType = computed(() => {
        if (route.params.vehicleType) return route.params.vehicleType.toUpperCase();
        return 'ALL';
      });
      const vehicleModels = computed(() => store.getters.vehicleModels);

      const refreshPage = (payload) => {
        store.dispatch('getVehicleModelsTypeList', payload);
        store.dispatch('getVehicleModels', {
          ...payload,
          type: vehicleType.value,
        });
      };
      watch(vehicleType, refreshPage);

      const headers = computed(() => store.getters.vehicleModelsTypeList);
      // table
      const structure = {
        title: 'Lista modelli',
        creationLink: {
          title: 'Aggiungi nuovo modello',
          namePath: 'menu.mobility.createModel',
        },
        data: [
          {
            title: 'user.name.first',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-300',
              },
              {
                name: 'name',
                class: 'font-bold',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/mobility/models/:id' },
          },
          {
            title: 'Tipo',
            field: 'vehicle_type',
            span: 1,
            format: 'vehicleType',
          },
          {
            title: 'Livello massimo batteria',
            field: 'max_battery_level',
            span: 1,
          },
          {
            title: 'Livello minimo batteria',
            field: 'min_battery_level',
            span: 1,
          },
          {
            title: 'Livello batteria unit',
            field: 'battery_level_unit',
            span: 1,
          },
        ],
      };

      return {
        headers,
        data: vehicleModels,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
