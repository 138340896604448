<template>
    <recharge-inprogress-row v-if="rechargeInProgress && rechargeInProgress.length > 0" :el="rechargeInProgress[0]" class="mb-4" />
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import RechargeInprogressRow from './RechargeInprogressRow.vue';

  export default {
    components: {
      RechargeInprogressRow,
    },
    setup() {
      // INIT VUE THINGS
      const router = useRoute();
      const store = useStore();
      //  PREPARE ELEMENTS

      function refreshPage() {
        store.dispatch('getUserRechargeList', userID);
        store.dispatch('getUserRechargeInProgress', userID);
      }
      //  GET ELEMENTS
      const userID = router.params.id;
      const rechargeList = computed(() => store.getters.userRechargeList);
      const rechargeInProgress = computed(() => store.getters.userRechargeInProgress);
      //structure
      const structure = {
        text: 'views.recharges.name',

        data: [
          {
            title: 'views.recharges.session',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'session_id',
              },
              {
                placeholder: 'Presa: ',
                name: 'numero_presa',
              },
            ],
            link: { idField: 'id', path: '/recharge/recharges/:id' },
            span: 1,
          },
          {
            title: 'views.recharges.stationDetails',
            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-primary-400',
              },
              {
                name: 'indirizzo',
              },
            ],
            span: 1,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'station.serial',
            field: 'plate',
            span: 1,
          },
          {
            title: 'views.stations.rechargeStart',
            field: 'inizio_ricarica',
            span: 1,
          },
          {
            title: 'views.stations.rechargeEnd',
            field: 'fine_ricarica',
            span: 1,
          },
          {
            title: 'station.socket',
            field: 'numero_presa',
            span: 1,
          },
          {
            title: 'payments.totalAmount',
            field: 'amount',
            span: 1,
          },
          {
            title: 'KWh',
            label: 'views.recharges.providedKWH',
            field: 'kw_consumati',
            span: 1,
          },
        ],
      };
      //  RETURN NECESSARY
      return {
        data: rechargeList,
        structure,
        rechargeInProgress,
        refreshPage,
      };
    },
  };
</script>

<style></style>
