<template>
  <base-subpage v-if="documents && documents.length > 0">
    <div class="flex justify-center">
      <button
        v-if="user?.is_confirmed == 0"
        class="bg-green-200 p-2 flex items-center rounded-xl m-2 hover:animate-bounce cursor-pointer duration-500"
        title="Approva documenti"
        @click="sendResponse('accept')">
        <span class="material-icons text-green-600">recommend</span>
      </button>
      <div class="flex my-2">
        <button
          class="bg-red-200 p-2 flex items-center rounded-xl ml-2"
          :class="enableDelete ? 'text-red-600' : 'text-red-300'"
          :disabled="!enableDelete"
          title="Rifiuta documenti"
          @click="sendResponse('reject')">
          <span class="material-icons">delete_forever</span>
        </button>
        <button class="w-8 h-8 self-end" title="Attiva eliminazione" @click="enableDelete = true" v-if="!enableDelete">
          <span class="material-icons">lock</span>
        </button>
        <button class="w-8 h-8 self-end" title="Attiva eliminazione" @click="enableDelete = false" v-if="enableDelete">
          <span class="material-icons">lock_open</span>
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-32">
      <div class="flex justify-center" v-for="doc in documents" :key="doc">
        <img
          class="w-40 h-48 md:w-64 md:h-80 hover:scale-125 md:hover:scale-150 transition-all duration-500 cursor-pointer m-6"
          :src="doc"
          @click="openWindow(doc)" />
      </div>
    </div>
  </base-subpage>
</template>

<script>
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed, ref } from 'vue';
  import router from '@/router/router';
  export default {
    setup() {
      const store = useStore();
      const route = useRoute();

      const user = computed(() => store.getters.selectedUser);

      store.dispatch('getUserDocuments', { id: route.params.id });
      const documents = computed(() => store.getters.userDocuments);

      const openWindow = (doc) => {
        var image = new Image();
        image.src = doc;

        var w = window.open('');
        w.document.write(image.outerHTML);
      };

      const sendResponse = (response) =>
        store.dispatch('sendDocumentsResponse', { id: route.params.id, command: response }).then(() => router.go());

      const enableDelete = ref(false);
      return { documents, user, enableDelete, openWindow, sendResponse };
    },
  };
</script>
