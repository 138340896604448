<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  export default {
    setup() {
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Biciclette',
          name: 'menu.sharing.bikesList',
        },
        {
          text: 'Scooters',
          name: 'menu.sharing.scooterList',
        },
        {
          text: 'Automobili',
          name: 'menu.sharing.carList',
        },
      ];
      return {
        headers,
      };
    },
  };
</script>
<style></style>
