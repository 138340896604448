<template>
  <div ref="wrapperRef">
    <label v-if="title" id="listbox-label" class="block text-sm font-medium leading-6 text-gray-900">{{ title }}</label>
    <div class="relative mt-2 w-40">
      <button
        type="button"
        @click="isOpen = !isOpen"
        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        aria-haspopup="listbox"
        :aria-expanded="isOpen"
        aria-labelledby="listbox-label">
        <span class="block truncate">{{ selectedValue.label || 'Seleziona...' }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <svg
            class="h-5 w-5 text-gray-400 transform transition-transform duration-200"
            :class="{ 'rotate-180': isOpen }"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <!-- arrow SVG -->
          </svg>
        </span>
      </button>
      <ul
        v-show="isOpen"
        class="absolute z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label">
        <li
          v-for="(option, index) in options.array"
          :key="index"
          @click="selectOption(option)"
          class="text-gray-900 relative cursor-pointer select-none py-2 pl-3 pr-9"
          :class="{
            'bg-indigo-600 text-white': (option[options?.value] || option) === selectedValue,
          }"
          role="option">
          <span class="font-normal block truncate">
            {{ option[options?.label] || option }}
          </span>
          <span
            v-if="(option[options?.value] || option) === selectedValue"
            class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
            <!-- checkmark SVG -->
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

  export default {
    props: {
      title: { type: String },
      options: {
        type: Object,
        default: () => ({
          array: [],
          label: 'label',
          value: 'value',
        }),
      },
    },
    setup(props, { emit }) {
      const selectedValue = reactive({
        value: '',
        label: '',
      });
      const isOpen = ref(false);

      const selectOption = (option) => {
        selectedValue.value = props.options.value ? option[props.options.value] : option;
        selectedValue.label = props.options.label ? option[props.options.label] : option;
        isOpen.value = false;
      };
      // Aggiunge un watcher per gestire le modifiche al valore selezionato
      watch(selectedValue, (newValue) => {
        console.log('Test new vehicle', newValue);
        emit('update:modelValue', newValue?.value);
      });
      const displayedOptions = computed(() => {
        return props.options.array.map((item) => item[props.options.label || item]);
      });

      //gestione del click fuori dalla select
      const wrapperRef = ref(null);

      const handleClickOutside = (event) => {
        if (wrapperRef.value && !wrapperRef.value.contains(event.target)) {
          isOpen.value = false;
        }
      };

      onMounted(() => {
        document.addEventListener('click', handleClickOutside);
      });

      onBeforeUnmount(() => {
        document.removeEventListener('click', handleClickOutside);
      });

      return {
        selectedValue,
        isOpen,
        selectOption,
        displayedOptions,
        wrapperRef,
      };
    },
  };
</script>
