<template>
  <base-details-header :structure="headerStructure" :el="selectedItm" />
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedSubscription', selectedElID);

      const selectedSubscription = computed(() => store.getters.selectedSubscription);

      const headers = [
        {
          text: 'subscriptions.generals',
          name: 'menu.recharge.subscriptionGenerals',
        },
      ];
      const headerStructure = {
        data: [
          {
            title: 'subscription.user',
            name: 'user',
            subName: 'name',
            link: {
              idField: 'id',
              subField: 'user',
              path: '/user/users/:id',
            },
          },
          {
            title: 'subscription.tot_amount',
            name: 'tot_amount',
            placeholder: '0',
            footer: ' €',
          },
          {
            title: 'subscription.tot_kw',
            name: 'tot_kw',
            placeholder: '0',
            footer: ' kW',
          },
          {
            title: 'subscription.tot_recharges',
            name: 'tot_recharges',
            placeholder: '0',
          },
        ],
      };
      return {
        selectedItm: selectedSubscription,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
