<template>
  <select class="w-4 h-4 bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500">
    <option v-for="el in data" :key="el" :value="el">
      {{ t(el) }}
    </option>
  </select>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      data: { type: Object, required: true },
    },
    setup() {
      return { t: useI18n().t };
    },
  };
</script>

<style></style>
