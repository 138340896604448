import { Utils, ApiCaller } from '@/Utils/utils';

export default {
  state: {
    deviceConfigurations: [],
    deviceConfigurationsVehicles: [],
    deviceConfigurationsDevices: [],
    selectedDeviceConfiguration: null,
  },
  getters: {
    deviceConfigurations: (state) => state.deviceConfigurations,
    deviceConfigurationsVehicles: (state) => state.deviceConfigurationsVehicles,
    deviceConfigurationsDevices: (state) => state.deviceConfigurationsDevices,
    selectedDeviceConfiguration: (state) => (state.selectedDeviceConfiguration ? state.selectedDeviceConfiguration : null),
  },
  mutations: {
    setDeviceConfigurations: (state, payload) => (state.deviceConfigurations = payload),
    setDeviceConfigurationsVehicles: (state, payload) => (state.deviceConfigurationsVehicles = payload),
    setSelectedDeviceConfiguration: (state, payload) => (state.selectedDeviceConfiguration = payload),
    setDeviceConfigurationsDevices: (state, payload) => (state.deviceConfigurationsDevices = payload),
  },
  actions: {
    getDeviceConfigurations: (ctx, payload) => {
      ctx.commit('setDeviceConfigurations', Utils.createFiltrableData([]));
      ApiCaller.GET(`/deviceConfiguration`, payload).then((data) => ctx.commit('setDeviceConfigurations', Utils.createFiltrableData(data)));
    },
    getDeviceConfigurationsVehicles: (ctx, payload) => {
      ctx.commit('setDeviceConfigurationsVehicles', []);
      ApiCaller.GET(`/vehicle`, payload).then((data) => ctx.commit('setDeviceConfigurationsVehicles', data));
    },
    getDeviceConfigurationsDevices: (ctx, payload) => {
      ctx.commit('setDeviceConfigurationsDevices', []);
      ApiCaller.GET(`/device`, payload).then((data) => ctx.commit('setDeviceConfigurationsDevices', data));
    },
    getSelectedDeviceConfiguration: (ctx, payload) => {
      ctx.commit('setSelectedDeviceConfiguration', []);
      ApiCaller.GET(`/deviceConfiguration/${payload.id}`, payload).then((data) => ctx.commit('setSelectedDeviceConfiguration', data));
    },
    //QUERIES
    updateDeviceConfiguration: (_, payload) => ApiCaller.PUT(`/deviceConfiguration/${payload.id}`, payload.body),
    createDeviceConfiguration: (_, payload) => ApiCaller.POST('/deviceConfiguration', payload),
  },
  modules: {},
};
