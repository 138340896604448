<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    //get selected location
    const selectedRecharge = computed(() => {
      return store.getters.selectedRecharge;
    });
    const structure = {
      title: "views.recharges.infos",
      
      cols: "3",
      data: [
        {
          title: "views.recharges.providedKWH",
          
          name: "kw_consumati",
          placeholder: "0",
        },
        {
          title: "payments.walletPaymentAmount",
          
          name: "wallet_amount",
          placeholder: "0",
        },
        {
          title: "payments.creditCardAmount",
          
          name: "card_amount",
          placeholder: "0",
        },
        {
          title: "payments.totalAmount",
          
          name: "total_amount",
          placeholder: "0",
        },
      ],
    };
    return {
      el: selectedRecharge,
      structure,
    };
  },
};
</script>

<style>
</style>