<template>
  <ul>
    <li v-for="(route, i) in routes" :key="idx + i" class="flex flex-col">
      <div class="flex justify-start itmes-center">
        <router-link v-if="route.path" :to="route.path">
          <h1 class="text-lg font-semibold capitalize mr-4">{{ route.path }}</h1>
        </router-link>
        <h1 class="text-lg font-normal opacity-20 capitalize" v.if="route.name">{{ route.name }}</h1>
      </div>
      <div class="ml-8">
        <route-list v-if="route.children" :routes="route.children" :idx="idx + i" />
      </div>
    </li>
  </ul>
</template>
<script>
  import RouteList from './RouteList.vue';
  export default {
    name: 'RouteList',
    components: {
      RouteList,
    },
    props: {
      routes: { type: Array, required: true },
      idx: { type: String, required: false, default: '' },
    },
  };
</script>
