<template>
  <div class="flex flex-col justify-center items-start relative p-2 w-full">
    <div v-if="hasTitle" :class="[returnBg(), inputClass]" class="bg-white dark:bg-gray-900 text-left text-gray-400 font-medium w-full leading-none mb-1">{{ t(name) }}</div>
    <div class="w-full text-left px-0 text-base font-semibold leading-none pb-3 text-gray-900 dark:text-white" :class="[returnBg(), border ? 'border-b border-gray-200' : '']">
      <slot></slot>
      <span v-if="!statusOK">--</span>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      name: String,
      statusOK: {
        default: true,
      },
      inputClass: {
        type: String,
        default: 'text-sm',
      },
      hasTitle: {
        type: Boolean,
        default: true,
      },
      border: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      function returnBg() {
        return props.statusOK ? 'text-gray-400' : 'border-red-400 text-red-800';
      }
      return { returnBg, t: useI18n().t };
    },
  };
</script>

<style></style>
