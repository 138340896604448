<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
  export default {
    setup() {
      const typeSelect = ['', 'TRACTOR'];
      //structure of page
      const insertData = {
        title: 'Inserimento nuovo Modello',
        redirect_path: '/mobility/models',
        create_endpoint: 'createVehicleModel',
        creation_title: 'Creazione nuovo modello',
        dispatchName: 'getVehicleModels',
        data: [
          {
            title: 'Nome',
            name: 'name',
            required: true,
            class: 'col-span-2',
          },
          {
            title: 'Tipo',
            name: 'type',
            select: typeSelect,
            required: true,
            class: 'col-span-2',
          },
          {
            title: 'Produttore',
            name: 'producer',
            required: true,
            class: 'col-span-2',
          },
          {
            title: 'Descrizione',
            name: 'description',
            class: 'col-span-2',
          },
          {
            title: 'Path immagine',
            name: 'cars_picture',
            class: 'col-span-2',
          },
          {
            title: 'Livello massimo batteria',
            name: 'max_battery_level',
            class: 'col-span-2',
          },
          {
            title: 'è elettrico',
            boolean: true,
            class: 'col-span-2',
          },
          {
            title: 'Livello minimo batteria',
            name: 'min_battery_level',
            class: 'col-span-2',
          },
          {
            title: 'Livello batteria unit',
            name: 'battery_level_unit',
            class: 'col-span-2',
          },
          {
            title: 'Note',
            name: 'additional_information',
            class: 'col-span-4',
          },
        ],
      };
      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
