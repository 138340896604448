<template>
  {{ userStatistics?.info?.resume?.totRecharges }}
  <div>
    <div class="flex p-4">
      <div class="p-4">
        <div class="md:grid md:grid-cols-3 md:gap-4">
          <div class="">
            <p class="text-gray-500">Tariffa Base</p>
            <div class="flex p-2">
              <base-inputField title="Tetto kWh" class="mr-4" v-model="module.base.limit"></base-inputField>
              <base-inputField title="Prezzo €/kWh" v-model="module.base.cashback"></base-inputField>
            </div>
          </div>
          <div class="border-t md:border-l md:border-t-transparent border-gray-500 p-2">
            <p class="text-gray-500">Tariffa Plus</p>
            <div class="flex">
              <base-inputField title="Tetto kWh" class="mr-4" v-model="module.plus.limit"></base-inputField>
              <base-inputField title="Prezzo €/kWh" v-model="module.plus.cashback"></base-inputField>
            </div>
          </div>
          <div class="border-t md:border-l md:border-t-transparent border-gray-500 p-2">
            <p class="text-gray-500">Tariffa Premium</p>
            <div class="flex">
              <base-inputField title="Tetto kWh" class="mr-4" v-model="module.premium.limit"></base-inputField>
              <base-inputField title="Prezzo €/kWh" v-model="module.premium.cashback"></base-inputField>
            </div>
          </div>
        </div>
        <div class="flex border-t-2 border-gray-500 mt-8 p-4">
          <base-select
            :data="['', '2021', '2022', '2023']"
            title="Scegli l'anno"
            class="mr-4"
            v-model="module.year"></base-select>
          <base-select
            title="Scegli il mese"
            :data="{ array: months, elReturned: 'id', name: 'name' }"
            v-model="module.month" />
        </div>
        <div v-if="checkState" class="flex justify-evenly border-t-4 border-gray-500 p-2 mt-8">
          <base-button @click="sendModule">Vedi Statistiche</base-button>
          <base-button @click="downloadReport">Scarica xlsx</base-button>
        </div>
      </div>
    </div>
    <!-- Info statistiche -->
    <div>
      <div v-if="userStatistics">
        <p class="text-gray-300 text-lg underline">
          Riepilogo statistiche per il mese {{ module.month }} dell'anno {{ module.year }}
        </p>
        <p>
          Il totale delle ricariche analizzate è di
          <strong>
            {{ userStatistics?.info?.resume?.totRecharges }}
          </strong>
          . Tolte le ricariche a 0 e quelle in controllo anomalie.
        </p>
        <p>
          In totale per
          <strong>{{ userStatistics?.data?.length }} utenti</strong>
          di cui
          <strong>{{ userStatistics?.info?.resume?.totUsers }}</strong>
          aventi diritto al cashback, sarebbero stati accreditati
          <strong>{{ (userStatistics?.info?.resume?.totCashback).toFixed(2) }}€</strong>
          su un profitto di
          <strong>{{ (userStatistics?.info?.resume?.totAmount).toFixed(2) }}€</strong>
        </p>
        <p class="mt-4">
          Per il mese
          <strong>{{ module.month }}</strong>
          dell'anno
          <strong>{{ module.year }}</strong>
          sono stati trovati:
        </p>
        <p>
          -
          <strong>{{ userStatistics?.info?.plan1?.users }} utenti</strong>
          per il piano Base
        </p>
        <p>
          -
          <strong>{{ userStatistics?.info?.plan2?.users }} utenti</strong>
          per il piano Plus
        </p>
        <p>
          -
          <strong>{{ userStatistics?.info?.plan3?.users }} utenti</strong>
          per il piano Premium
        </p>
        <div class="mt-8">
          <div>
            <p class="text-gray-300 text-lg underline">Piano Base</p>
            <p class="text-sm">
              Con il limite di
              <strong>{{ module.base.limit }}kWh</strong>
              e un cashback di
              <strong>{{ module.base.cashback }}€</strong>
              sono stati stimati
              <strong>{{ (userStatistics?.info?.plan1?.cashback).toFixed(2) }}€</strong>
              di cashback su una spesa totale di
              <strong>{{ (userStatistics?.info?.plan1?.amount).toFixed(2) }}€</strong>
            </p>
          </div>
          <div>
            <p class="text-gray-300 text-lg underline">Piano Plus</p>
            <p class="text-sm">
              Con il limite di
              <strong>{{ module.plus.limit }} kWh</strong>
              e un cashback di
              <strong>{{ module.plus.cashback }}€</strong>
              sono stati stimati
              <strong>{{ (userStatistics?.info?.plan2?.cashback).toFixed(2) }}€</strong>
              di cashback su una spesa totale di
              <strong>{{ (userStatistics?.info?.plan2?.amount).toFixed(2) }}€</strong>
            </p>
          </div>
          <div>
            <p class="text-gray-300 text-lg underline">Piano Premium</p>
            <p class="text-sm">
              Con il limite di
              <strong>{{ module.premium.limit }} kWh</strong>
              e un cashback di
              <strong>{{ module.premium.cashback }}€</strong>
              sono stati stimati
              <strong>{{ (userStatistics?.info?.plan3?.cashback).toFixed(2) }}€</strong>
              di cashback su una spesa totale di
              <strong>{{ (userStatistics?.info?.plan3?.amount).toFixed(2) }}€</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- lista utenti -->
    <div class="border-t border-r border-l rounded-lg p-4 mt-6">
      <!-- header -->
      <div class="grid grid-cols-3 gap-4 font-bold md:grid-cols-6">
        <p>Nome</p>
        <p class="hidden md:block">Spesa totale</p>
        <p>kWh erogati</p>
        <p class="hidden md:block">Totale ricariche</p>
        <p class="hidden md:block">Piano applicato</p>
        <p>Totale cashback</p>
      </div>
      <div class="h-96 overflow-y-scroll mt-6">
        <div v-for="user in userStatistics?.data" :key="user">
          <!-- nome - total - totKwh - tot recharges - nome del piano - tot cashback -->
          <div class="grid grid-cols-3 md:grid-cols-6 gap-4 border-t py-4">
            <p>{{ user.name }}</p>
            <p class="hidden md:block">{{ user.total }}€</p>
            <p>{{ user.totKwh.toFixed(2) }}kWh</p>
            <p class="text-center hidden md:block">{{ user.totRecharges }}</p>
            <p class="text-center hidden md:block">{{ user.plan?.name }}</p>
            <p class="text-center">{{ user.totCashback.toFixed(2) }}€</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { reactive, computed } from 'vue';
  const ExcelJS = require('exceljs');

  export default {
    setup() {
      const store = useStore();
      const userStatistics = computed(() => store.getters.userCashbackStatistics);

      //creare l'oggetto che conterrà i dati da passare al backend
      const module = reactive({
        year: '',
        month: '01',
        base: {
          limit: '',
          cashback: '',
        },
        plus: {
          limit: '',
          cashback: '',
        },
        premium: {
          limit: '',
          cashback: '',
        },
      });

      const months = [
        {
          name: 'Gennaio',
          id: '01',
        },
        {
          name: 'Febbraio',
          id: '02',
        },
        {
          name: 'Marzo',
          id: '03',
        },
        {
          name: 'Aprile',
          id: '04',
        },
        {
          name: 'Maggio',
          id: '05',
        },
        {
          name: 'Giugno',
          id: '06',
        },
        {
          name: 'Luglio',
          id: '07',
        },
        {
          name: 'Agosto',
          id: '08',
        },
        {
          name: 'Settembre',
          id: '09',
        },
        {
          name: 'Ottobre',
          id: '10',
        },
        {
          name: 'Novembre',
          id: '11',
        },
        {
          name: 'Dicembre',
          id: '12',
        },
      ];

      //far vedere la stima di utenti che rientrano in quelle fasce in quell'anno. Se c'è il mese, solo per quello
      // scaricare xlsx con i dati

      const sendModule = () => store.dispatch('getUserCashbackStatistics', module);
      const checkState = computed(() => {
        if (
          module.year &&
          module.month &&
          module.base.limit &&
          module.base.cashback &&
          module.plus.limit &&
          module.plus.cashback &&
          module.premium.limit &&
          module.premium.cashback
        ) {
          return true;
        } else return false;
      });

      //download xlsx
      const downloadReport = () => {
        console.log('download report');
        const workbook = new ExcelJS.Workbook();

        //creo il foglio di riepilogo
        let tmpWorksheet = workbook.addWorksheet('Riepilogo');
        //creo il data
        const data = {
          tetto: [
            module.base.limit.replace('.', ','),
            module.plus.limit.replace('.', ','),
            module.premium.limit.replace('.', ','),
          ],
          prezzo: [
            module.base.cashback.replace('.', ','),
            module.plus.cashback.replace('.', ','),
            module.premium.cashback.replace('.', ','),
          ],
          utenti: [
            userStatistics?.value?.info?.plan1?.users,
            userStatistics?.value?.info?.plan2?.users,
            userStatistics?.value?.info?.plan3?.users,
          ],
          cashback: [
            userStatistics?.value?.info?.plan1?.cashback.toFixed(2).replace('.', ','),
            userStatistics?.value?.info?.plan2?.cashback.toFixed(2).replace('.', ','),
            userStatistics?.value?.info?.plan3?.cashback.toFixed(2).replace('.', ','),
          ],
          senzaCashback: [
            userStatistics?.value?.info?.plan1?.amount.toFixed(2).replace('.', ','),
            userStatistics?.value?.info?.plan2?.amount.toFixed(2).replace('.', ','),
            userStatistics?.value?.info?.plan3?.amount.toFixed(2).replace('.', ','),
          ],
        };
        // Aggiunta delle intestazioni
        tmpWorksheet.addRow(['', 'Piano base', 'Piano Plus', 'Piano Premium']);
        tmpWorksheet.addRow(['Tetto kWh']);
        tmpWorksheet.addRow(['€/kWh']);
        tmpWorksheet.addRow(['Utenti']);
        tmpWorksheet.addRow(['Totale cashback']);
        tmpWorksheet.addRow(['Totale senza cashback']);

        // Impostazione della larghezza delle colonne
        tmpWorksheet.getColumn('A').width = 25; // Larghezza della colonna A
        tmpWorksheet.getColumn('B').width = 15; // Larghezza della colonna B
        tmpWorksheet.getColumn('C').width = 15; // Larghezza della colonna C
        tmpWorksheet.getColumn('D').width = 15; // Larghezza della colonna D

        // Inserimento dei dati negli spazi corretti
        tmpWorksheet.getCell('B2').value = data.tetto[0];
        tmpWorksheet.getCell('C2').value = data.tetto[1];
        tmpWorksheet.getCell('D2').value = data.tetto[2];

        tmpWorksheet.getCell('B3').value = data.prezzo[0];
        tmpWorksheet.getCell('C3').value = data.prezzo[1];
        tmpWorksheet.getCell('D3').value = data.prezzo[2];

        tmpWorksheet.getCell('B4').value = data.utenti[0];
        tmpWorksheet.getCell('C4').value = data.utenti[1];
        tmpWorksheet.getCell('D4').value = data.utenti[2];

        tmpWorksheet.getCell('B5').value = data.cashback[0];
        tmpWorksheet.getCell('C5').value = data.cashback[1];
        tmpWorksheet.getCell('D5').value = data.cashback[2];

        tmpWorksheet.getCell('B6').value = data.senzaCashback[0];
        tmpWorksheet.getCell('C6').value = data.senzaCashback[1];
        tmpWorksheet.getCell('D6').value = data.senzaCashback[2];

        //header del foglio con gli utenti
        const columns = [
          { header: 'Nome', key: 'name', width: 20 },
          { header: 'Spesa totale', key: 'amount', width: 15 },
          { header: 'kWh erogati', key: 'kWh', width: 15 },
          { header: 'Totale ricariche', key: 'recharges', width: 15 },
          { header: 'Piano applicato', key: 'plan', width: 15 },
          { header: 'Totale cashback', key: 'cashback', width: 15 },
        ];
        tmpWorksheet = workbook.addWorksheet('Utenti');
        tmpWorksheet.columns = columns;

        //creo il foglio degli utenti
        userStatistics?.value?.data.forEach((user) => {
          tmpWorksheet.addRow({
            name: user?.name,
            amount: parseFloat(user?.total).toFixed(2).replace('.', ','),
            kWh: parseFloat(user?.totKwh)?.toFixed(2).replace('.', ','),
            recharges: user?.totRecharges,
            plan: user?.plan?.name,
            cashback: parseFloat(user?.totCashback)?.toFixed(2).replace('.', ','),
          });
        });

        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            //assegno il nome del file
            a.download = `Estimate ${module.month}/${module.year}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error(error);
          });
      };

      return {
        module,
        userStatistics,
        months,
        checkState,
        sendModule,
        downloadReport,
      };
    },
  };
</script>
