<template>
  <router-view></router-view>
</template>

<script>
  import { useRoute } from 'vue-router';

  import { useStore } from 'vuex';

  export default {
    setup() {
      const route = useRoute();
      const store = useStore();

      store.dispatch('getSelectedPayment', { id: route.params?.id });
    },
  };
</script>
