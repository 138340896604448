<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    //get selected manager
    const selectedStation = computed(() => {
      return store.getters.selectedManager;
    });
    const structure = {
      title: "views.user.managerInfo",
      
      edit_endpoint: "updateManager",
      edit_title: "Modifica gestore",
      data: [
        {
          title: "user.name.first",
          
          name: "name",
          editable: true,
          placeholder: "--",
          statusOK: "name",
        },
        {
          title: "address.body",
          
          name: "address",
          editable: true,
          placeholder: "--",
        },
        {
          title: "user.email",
          
          name: "email",
          editable: true,
          placeholder: "--",
        },
        {
          title: "user.cellphone",
          
          name: "cellphone",
          editable: true,
          placeholder: "--",
        },
        {
          title: "payments.billingProfile",
          
          name: "stripe_account",
          editable: true,
          placeholder: "--",
        },
        {
          title: "address.zipcode",
          
          name: "zipcode",
          editable: true,
          placeholder: "--",
        },
        {
          title: "user.vat",
          
          name: "vat",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.timeEntries.creationDate",
          
          name: "created_at",
          format: "datetime",
          placeholder: "--",
        },
        {
          title: "views.timeEntries.lastUpdate",
          
          name: "updated_at",
          format: "datetime",
          placeholder: "--",
        },
        {
          title: "user.notes",
          
          name: "notes",
          placeholder: "--",
          editable: true,
        },
      ],
    };
    return {
      el: selectedStation,
      structure,
    };
  },
};
</script>

<style>
</style>