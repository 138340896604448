<template>
  <base-generals-page v-if="el" :el="el" :structure="structure" />
</template>

<script>
import { Utils } from "@/Utils/utils";
import { useStore } from "vuex";
import { computed, reactive, onMounted } from "vue";
export default {
  components: {},
  setup() {
    //init store
    const store = useStore();
    //init selects
    onMounted(() => store.dispatch("getManagers"));
    //return selectedUser
    const selectedUser = computed(() => {
      return store.getters.selectedUser;
    });
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    //create generalsPage structure
    const structure = {
      title: "views.user.userInfo",

      edit_endpoint: "updateUser",
      edit_title: "Modifica utente",
      data: [
        {
          title: "user.name.first",

          name: "name",
          statusOK: "name",
          editable: true,
          placeholder: "--",
        },
        {
          title: "user.name.second",

          name: "surname",
          statusOK: "surname",
          editable: true,
          placeholder: "--",
        },
        reactive({
          title: "user.types.manager",

          name: "manager",
          statusOK: "manager",
          select: managers,
          editable: true,
          class: "col-span-2",
        }),
        {
          title: "user.email",

          name: "email",
          sub_name: "email",
          statusOK: "email",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.user.emailVerificationCode",

          name: "email",
          sub_name: "validation_code",
          placeholder: "--",
        },
        {
          title: "user.cellphone",

          name: "cellphone",
          sub_name: "cellphone",
          placeholder: "--",
          statusOK: "cellphone",
          editable: true,
        },
        {
          title: "views.user.cellphoneVerificationCode",

          name: "cellphone",
          sub_name: "validation_code",
          placeholder: "--",
        },
        {
          title: "views.user.accountCreationDate",

          name: "timestamp_creation",
          format: "date",
        },
        {
          title: "views.user.documentsConfirmationLabel",

          name: "is_confirmed",
          boolean: {
            if_true: "views.user.documentsApproved",
            if_false: "views.user.documentsToApprove",
          },
        },
        {
          title: "views.user.documentsUploadedLabel",

          name: "skip_document",
          boolean: {
            if_true: "views.user.documentsToUpload",
            if_false: "views.user.documentsUploaded",
          },
        },
        {
          title: "terms.termsAndConditionsLabel",

          name: "terms_and_conditions",
          boolean: {
            if_true: "terms.termsAcceptanceDate",
            if_false: "terms.termsDeclined",
          },
          statusOK: "terms_and_conditions",
          concat: {
            name: "terms_and_conditions_timestamp",
            format: "date",
          },
        },
        {
          title: "terms.marketingAcceptanceLabel",

          name: "marketing",
          boolean: {
            if_true: "terms.termsAcceptanceDate",
            if_false: "terms.termsDeclined",
          },
        },
        {
          title: "payments.wallet",

          name: "wallet_balance",
          db_name: "balance",
          format: "euro",
          editable: true,
        },
        {
          title: "user.notes",

          name: "notes",
          editable: true,
          placeholder: "--",
        },
      ],
    };
    return {
      el: selectedUser,
      structure,
    };
  },
};
</script>

<style></style>
