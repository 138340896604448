import { Utils, ApiCaller } from "@/Utils/utils";
import managerLocation from "./location/managerLocation";

export default {
  state: {
    managers: [],
    selectedManager: null,
    managerVehicles: null,
  },
  getters: {
    managers: (state) => state.managers,
    selectedManager: (state) => {
      if (state.selectedManager !== null) return state.selectedManager[0];
      return state.selectedManager;
    },
    managerVehicles: (state) => state.managerVehicles,
  },
  mutations: {
    setManagers: (state, payload) => (state.managers = payload),
    setSelectedManager: (state, payload) => (state.selectedManager = payload),
    setManagerVehicles: (state, payload) => (state.managerVehicles = payload),
  },
  actions: {
    getManagers: (ctx, payload) =>
      ApiCaller.GET(`/manager`, payload).then((data) =>
        ctx.commit("setManagers", Utils.createFiltrableData(data))
      ),
    getSelectedManager: (ctx, payload) =>
      ApiCaller.GET(`/manager/${payload.id}`, null).then((data) =>
        ctx.commit("setSelectedManager", data)
      ),
    getManagerVehicles: (ctx, payload) =>
      ApiCaller.GET("/manager/list/vehicle", payload).then((data) => {
        ctx.commit("setManagerVehicles", Utils.createFiltrableData(data));
      }),
    //QUERIES
    updateManager: (_, payload) =>
      ApiCaller.PUT(`/manager/${payload.id}`, payload.body),
    createManager: (_, payload) => ApiCaller.POST("/manager", payload),
  },

  modules: {
    managerLocation,
  },
};
