<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';
  export default {
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getTrackers', payload);
      }

      const trackers = computed(() => store.getters.trackers);

      //change testable field
      const setTestableField = (id) => {
        store.dispatch('updateTracker', { id: id, body: { testable: 1 } });
      };

      const unsetTestableField = (id) => {
        store.dispatch('updateTracker', { id: id, body: { testable: 0 } });
      };

      // table
      const structure = {
        title: 'views.trackers.title',

        creationLink: {
          title: 'buttons.createTracker',

          namePath: 'menu.sharing.createTracker',
        },
        data: [
          {
            title: 'ID',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'IMEI',
              },
            ],
            span: 1,
            link: { idField: 'IMEI', path: '/sharing/trackers/:id' },
          },
          {
            title: 'views.trackers.testingEnabled',

            field: 'testable',
            boolean: {
              if_true: 'enabled',
              if_false: 'notEnabled',
            },
            span: 1,
          },
          {
            title: 'views.timeEntries.creationDate',

            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.timeEntries.lastUpdateDate',

            field: 'updated_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'user.notes',

            field: 'notes',
            span: 1,
          },
          {
            title: 'views.rfid.actions',

            buttons: {
              fields: [
                {
                  title: 'buttons.addToTests',

                  function: setTestableField,
                  returned_val: 'id',
                  condition_val: 0,
                },
                {
                  title: 'buttons.removeFromTests',

                  function: unsetTestableField,
                  returned_val: 'id',
                  condition_val: 1,
                },
              ],
              condition: 'testable',
            },
            span: 1,
          },
        ],
      };

      //other stuffs
      const showInsertForm = ref(false);
      function showForm() {
        showInsertForm.value = !showInsertForm.value;
      }
      function createTracker(body) {
        store.dispatch('createTracker', {
          ...body,
        });
      }

      return {
        data: trackers,
        structure,
        showInsertForm,
        showForm,
        refreshPage,
        createTracker,
      };
    },
  };
</script>

<style></style>
