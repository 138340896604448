<template>
  <div>
    <base-button
      id="dropdownSearchButton"
      @click="showMenu = !showMenu"
      data-dropdown-toggle="dropdownSearch"
      class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      type="button">
      {{ title }}
      <svg
        class="ml-2 w-4 h-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </base-button>

    <!-- Dropdown menu -->
    <div v-show="showMenu" id="dropdownSearch" class="absolute z-10 w-60 bg-white rounded shadow dark:bg-gray-700">
      <div class="p-3">
        <label for="input-group-search" class="sr-only">{{ $t('menu.search') }}</label>
        <div class="relative">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <input
            type="text"
            id="input-group-search"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            @input="changeData" />
        </div>
      </div>

      <ul
        class="overflow-y-auto px-3 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownSearchButton">
        <div v-for="el in filteredData" :key="el.id">
          <li v-if="el.name != '' && el.surname != ''">
            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input
                id="checkbox-item-11"
                @click="vmodel"
                type="checkbox"
                :value="el.id"
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
              <label
                for="checkbox-item-11"
                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                {{ el.name + ' ' + el.surname }}
              </label>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  export default {
    props: ['modelValue', 'title', 'data', 'returnedObj'],
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const showMenu = ref(false);
      const searchQuery = ref(''); // Nuova proprietà reattiva per la query di ricerca

      // Aggiornato per modificare searchQuery
      const changeData = (event) => {
        searchQuery.value = event.target.value;
      };

      // Proprietà computata per filtrare i dati
      const filteredData = computed(() => {
        return props.data.filter((el) => {
          // Converti tutto in minuscolo per un confronto case-insensitive
          const nameLower = el.name.toLowerCase();
          const surnameLower = el.surname.toLowerCase();
          const fullNameLower = nameLower + ' ' + surnameLower;
          const fullNameInvertedLower = surnameLower + ' ' + nameLower;
          const searchLower = searchQuery.value.toLowerCase();

          // Controlla se la query di ricerca è presente nel nome, nel cognome o nel nome completo
          return (
            nameLower.includes(searchLower) || surnameLower.includes(searchLower) || fullNameLower.includes(searchLower) || fullNameInvertedLower.includes(searchLower)
          );
        });
      });

      function vmodel(event) {
        emit('update:modelValue', event.target.value.id);
      }

      return { showMenu, vmodel, changeData, filteredData };
    },
  };
</script>

<style></style>
