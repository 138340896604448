<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();
      function refreshPage() {
        if (selectedVehicle.value && selectedVehicle.value.device) store.dispatch('getOngoingTrips', { id: selectedVehicle.value.device.id });
      }
      const selectedVehicle = computed(() => store.getters.selectedVehicle);
      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);

      const ongoingTrips = computed(() => store.getters.ongoingTrips);

      const structure = {
        title: 'vehicles.ongoingTrips',
        data: [
          {
            title: 'ID',
            fields: [
              {
                placeholder: '@',
                name: '_id',
                class: 'text-primary-300',
              },
              {
                name: 'IMEI',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: {
              idField: '_id',
              path: '/mobility/vehicles/' + (selectedVehicle.value ? selectedVehicle.value.id : '') + '/trips/:id',
            },
          },
          {
            title: 'ongoing',
            field: 'ongoing',
            span: 1,
          },
          {
            title: 'Tipo',
            field: 'type',
            span: 1,
            class: 'text-style',
          },
          {
            title: 'Inizio',
            field: 'started_at',
            format: 'datetime',
            span: 1,
            class: 'text-style',
          },
        ],
      };

      return {
        data: ongoingTrips,
        structure,
        refreshPage,
      };
    },
  };
</script>
