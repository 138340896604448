<template>
  <div>
    <vehicle-box />
    <base-subpage-bar :headers="headers" />
    <base-page v-if="selectedVehicle">
      <router-view />
    </base-page>
    <TransitionRoot as="template" :show="!(selectedMessage === null)">
      <Dialog as="div" class="relative z-10" @close="deselectMessage">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              :show="!(selectedMessage === null) && selectedMessage.IMEI"
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <DeviceMessageDetails :message="selectedMessage" />
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    @click="deselectMessage"
                    ref="cancelButtonRef">
                    Annulla
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import VehicleBox from './VehicleBox';

  import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

  import DeviceMessageDetails from './DeviceMessageDetails';

  export default {
    components: {
      Dialog,
      DialogPanel,
      TransitionChild,
      TransitionRoot,
      DeviceMessageDetails,
      ExclamationTriangleIcon,
      VehicleBox,
    },
    setup() {
      //  INIT
      const store = useStore();
      onMounted(() => store.dispatch('getVehicleModels'));
      const selectedVehicle = computed(() => store.getters.selectedVehicle);
      const selectedMessage = computed(() => store.getters.selectedMessage);

      const deselectMessage = () => {
        store.dispatch('selectMessage', null);
      };

      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Dettagli',
          name: 'menu.mobility.vehicleDetails',
        },
        {
          text: 'Eventi',
          name: 'menu.mobility.vehicleEvents',
        },
        {
          text: 'Messaggi',
          name: 'menu.mobility.vehicleMessages',
        },
        {
          text: 'Comandi',
          name: 'menu.mobility.vehicleCommands',
        },
        {
          text: 'Lavorazioni',
          name: 'menu.mobility.vehicleTrips',
        },
        {
          text: 'Lavorazioni in corso',
          name: 'menu.mobility.vehicleOngoingTrips',
        },
      ];

      return {
        selectedVehicle,
        selectedMessage,
        deselectMessage,
        headers,
      };
    },
  };
</script>
