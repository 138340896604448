<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      const refreshPage = (payload) => {
        store.dispatch('getRentalInProgress', payload);
      };

      const rentals = computed(() => store.getters.rentalInProgress);
      // table
      const structure = {
        title: 'views.rentals.rentalsList',
        toTranslate: true,
        timeFields: [
          {
            label: 'views.rentals.rentalEnd',
            key: 'end_timestamp',
            toTranslate: true,
          },
          {
            label: 'views.rentals.rentalStart',
            key: 'start_timestamp',
            toTranslate: true,
          },
        ],
        data: [
          {
            title: 'dettaglio noleggio',
            fields: [
              {
                placeholder: '@',
                class: 'text-primary-400',
                name: 'device_id',
              },
              {
                class: 'text-primary-400',
                name: 'device_id',
              },
            ],
            link: {
              idField: 'device_id',
              path: '/sharing/rentals/inProgress/:id',
            },
            span: 1,
          },
          {
            title: 'user.title',
            toTranslate: true,
            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-400',
              },
              {
                name: 'user_name',
              },
            ],
            link: { idField: 'user_id', path: '/user/users/:id' },
            span: 1,
          },
          {
            title: 'views.vehicles.plate',
            toTranslate: true,
            fields: [
              {
                placeholder: '@',
                class: 'text-primary-400',
                name: 'device_id',
              },
              {
                name: 'device_plate',
              },
            ],
            link: {
              idField: 'device_id',
              type: 'vehicle',
            },
            span: 1,
          },
          {
            title: 'user.types.manager',
            toTranslate: true,
            fields: [
              {
                placeholder: '@',
                name: 'device_manager_id',
                class: 'text-primary-400',
              },
              {
                name: 'device_manager_name',
              },
            ],
            span: 1,
            link: {
              idField: 'device_manager_id',
              path: '/sharing/managers/:id/',
            },
          },
          {
            title: 'views.rentals.rentalStart',
            field: 'start_timestamp',
            format: 'datetime',
            span: 1,
          },

          {
            title: 'status',
            field: 'status',
            format: 'rental_status',
            span: 1,
          },
        ],
      };
      return {
        data: rentals,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
