import sharingVehicleIndex from "@/modules/sharing/vehicles/store";
import zoneIndex from "@/modules/sharing/zone/store";
import rentalsIndex from "@/modules/sharing/rental/store";
import locationIndex from "@/modules/sharing/location/store";
import managerIndex from "@/modules/sharing/manager/store";
import trackerIndex from "@/modules/sharing/tracker/store";
import bricksIndex from "@/modules/sharing/bricks/store";

export default {
  modules: [
    sharingVehicleIndex,
    zoneIndex,
    rentalsIndex,
    locationIndex,
    managerIndex,
    trackerIndex,
    bricksIndex
  ],
};
