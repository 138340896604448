<template>
  <div class="relative flex flex-col w-full rounded-3xl overflow-visible p-6">
    <!--MAPPA -->
    <div class="rounded-2xl overflow-hidden w-full border border-gray-200" style="height: 60vh !important">
      <GMapMap
        v-if="lastPosition && lastPosition.latitude"
        ref="myMapRef"
        :center="lastPosition.latitude ? { lat: parseFloat(lastPosition.latitude), lng: parseFloat(lastPosition.longitude) } : {}"
        :zoom="19"
        map-type-id="roadmap"
        :options="{ mapId: 'roadmap' }">
        <GMapCluster v-for="(icon, index) in icons" :key="index">
          <GMapMarker :position="{ lat: parseFloat(lastPosition.latitude), lng: parseFloat(lastPosition.longitude) }" :icon="icon" />
        </GMapCluster>
        <GMapPolyline :path="filteredPath" :editable="true" ref="polyline" />
      </GMapMap>
    </div>
    <!--FINALE DELLA MAPPA-->
    <!-- PLAY CONSOLE -->
    <div class="w-full flex items-center justify-between">
      <span class="flex space-x-2 w-full p-3">
        <div class="w-full flex flex-row justify-start space-x-2">
          <template v-if="play">
            <base-button class="h-8 rounded-full px-0" icon="stop" onlyicon @click="play = false" />
            <base-button
              onlyicon
              class="h-8 rounded-full px-0 hover:bg-primary-800 hover:text-white"
              :class="{ 'bg-primary-100 text-primary-700': speedFactor != 1 }"
              @click="speedFactor = 1">
              1x
            </base-button>
            <base-button
              onlyicon
              class="h-8 rounded-full px-0 hover:bg-primary-800 hover:text-white"
              :class="{ 'bg-primary-100 text-primary-700': speedFactor != 2 }"
              @click="speedFactor = 2">
              2x
            </base-button>
            <base-button
              onlyicon
              class="h-8 rounded-full px-0 hover:bg-primary-800 hover:text-white"
              :class="{ 'bg-primary-100 text-primary-700': speedFactor != 3 }"
              @click="speedFactor = 3">
              3x
            </base-button>
          </template>
          <template v-else>
            <base-button onlyicon class="h-8 rounded-full px-0" icon="play_arrow" @click="play = true" />
            <base-button
              onlyicon
              class="h-8 rounded-full px-0"
              label="Rewind"
              icon="fast_rewind"
              @click="step = 1"
              :class="{ 'opacity-30 disabled': step == 1 }"></base-button>
          </template>
        </div>
        <div class="w-full flex flex-row justify-end items-center space-x-2">
          <base-button
            onlyicon
            class="h-8 rounded-full px-0 material-icons"
            @click="step - 10 < 1 ? (step = 1) : (step -= 10)"
            icon="first_page"
            :disabled="step < 2"
            :class="{ 'opacity-20 disabled': step < 2 }" />
          <base-button
            onlyicon
            class="h-8 rounded-full px-0 material-icons"
            @click="step < 1 ? (step = 1) : step--"
            icon="chevron_left"
            :disabled="step < 2"
            :class="{ 'opacity-20 disabled': step < 2 }" />
          <div class="font-bold text-sm text-center leading-none whitespace-nowrap w-24">{{ step }} / {{ pathsCount }}</div>
          <base-button
            onlyicon
            class="h-8 rounded-full px-0 material-icons"
            @click="step > pathsCount ? (step = pathsCount) : step++"
            icon="chevron_right"
            :disabled="step == pathsCount"
            :class="{ 'opacity-20 disabled': step == pathsCount }" />
          <base-button
            onlyicon
            class="h-8 rounded-full px-0 material-icons"
            @click="step + 10 > pathsCount ? (step = pathsCount) : (step += 10)"
            icon="last_page"
            :disabled="step == pathsCount"
            :class="{ 'opacity-20 disabled': step == pathsCount }" />
        </div>
      </span>
    </div>

    <div class="relative bg-white dark:bg-gray-900 flex flex-col w-full justify-between items-start rounded-3xl mx-auto h-full">
      <!-- Longitude latitude -->
      <DeviceMessageDetails v-if="lastMessage && lastMessage.message" :message="lastMessage" />
    </div>
  </div>
  <router-view />
</template>

<script>
  import { onMounted, computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { iconsLib } from '@/components/Icons/icon.js';

  // import VehicleCanStatus from '../vehicle/VehicleCanStatus.vue';
  import DeviceMessageDetails from '../vehicle/DeviceMessageDetails.vue';

  export default {
    components: { DeviceMessageDetails },
    setup() {
      const store = useStore();

      const route = useRoute();
      const anchor = ref(null);
      const myMapRef = ref(null);
      watch(myMapRef, (googleMap) => {
        console.log('MAPP', googleMap);
        if (googleMap) {
          googleMap.$mapPromise.then((map) => {
            console.log('MAPP', map);
            /* eslint-disable-next-line no-undef */
            anchor.value = new google.maps.Point(200, 300);
          });
        }
      });

      const refreshPage = () => store.dispatch('getTripMessages', { id: route.params.tripId });
      onMounted(refreshPage);

      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);

      watch(
        () => route.params.tripId,
        () => {
          store.dispatch('getTripMessages', { id: route.params.tripId });
          step.value = 1;
        },
      );

      const lastMessage = computed(() => (tripMessages.value && tripMessages.value.length > 0 ? tripMessages.value[step.value] : {}));
      const lastPosition = computed(() => (lastMessage.value && lastMessage.value.message ? lastMessage.value.message.gps : {}));

      const tripMessages = computed(() => store.getters.tripMessages);
      const path = computed(() => {
        let temp = [];
        if (tripMessages.value)
          for (const i in tripMessages.value)
            if (tripMessages.value[i].message && tripMessages.value[i].message.gps)
              temp.push({
                lat: parseFloat(tripMessages.value[i].message.gps.latitude),
                lng: parseFloat(tripMessages.value[i].message.gps.longitude),
              });
        return temp;
      });
      const pathsCount = computed(() => (path.value && path.value.length ? path.value.length - 1 : 0));

      // RIPRODUZIONE TRACCIATO
      const step = ref(1);
      const filteredPath = computed(() => path.value.slice(1, step.value + 1));

      const play = ref(false);
      const speedFactor = ref(1);
      const nextStep = () => {
        step.value + 1 > pathsCount.value ? (step.value = 1) : step.value++;
        if (play.value) setTimeout(nextStep, 600 / speedFactor.value);
      };
      watch(play, (newVal) => {
        if (newVal) nextStep();
      });

      // CALCOLO ANGOLO TRATTORE
      const degree = ref(0);
      watch(lastPosition, (newVal, oldVal) => {
        const delta_x = newVal.longitude - oldVal.longitude;
        const delta_y = newVal.latitude - oldVal.latitude;
        const theta_radians = Math.atan2(delta_y, delta_x);
        const d = theta_radians * (180 / Math.PI);
        degree.value = 90 - d;
      });

      const p = [
        { color: '#e22e38', path: iconsLib.tractor1 },
        { color: '#3e3b3c', path: iconsLib.tractor2 },
        { color: '#9f3b39', path: iconsLib.tractor3 },
        { color: '#7b8183', path: iconsLib.tractor4 },
      ];

      const icons = computed(() => {
        let temp = [];
        p.forEach((el) => {
          const symbol = {
            path: String(el.path),
            fillColor: el.color,
            fillOpacity: 1,
            anchor: anchor.value,
            strokeWeight: 0,
            scale: 0.15,
            rotation: degree.value,
          };
          temp.push(symbol);
        });
        return temp;
      });

      return {
        myMapRef,
        step,
        play,
        degree,
        speedFactor,
        pathsCount,
        filteredPath,
        path,
        icons,
        lastPosition,
        lastMessage,
        nextStep,
      };
    },
  };
</script>

<style scoped>
  .vue-map-container {
    width: 100% !important;
    height: 100% !important;
  }
  .vue-map {
    width: 100% !important;
    height: 100% !important;
  }
</style>

<style></style>
