<template>
  <base-generals-page v-if="el" :el="el" :structure="structure" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      //  Init vue components
      const store = useStore();
      const selectedSiemensStation = computed(() => {
        return store.getters.selectedSiemensStation;
      });

      /* 
      const tmpData2 = {
        cuId: 21614,
        cuCode: 'ONS_00000034',
        statusId: 102,
        uptime: 22484378000,
        cuTypeId: 2,
        brandId: 23,
        brandCd: 'Siemens AG',
        commDate: 1646744628821,
        lastUpdate: 1662971220000,
        timeInMaintenance: 6.516994e9,
        name: 'Via Ovidio 5',
        sn: 'TE100003029',
        mgmtCode: null,
        cuModelId: 164,
        cuModelDesc: 'SICHARGE CC AC22',
        defaultLanguageId: 1,
        areaId: -1,
        voltValue: '400',
        iVal: '16 AC',
        numRecharge: 350,
        usage: 14,
        totwatt: 0,
        ipAddress: '10.14.0.33',
        feIpAddress: 'DEFAULT - VPN',
        totWattRecharge: 3454454,
        selfConsumption: 0,
        polling: true,
        intest: false,
        counterInCU: true,
        connected: true,
        reserved: false,
        modifiable: true,
        antennaId: 4,
        dso: '--',
        owner: 'ONS',
        apActorId: 186,
        imageName: 'SICHARGE CC AC22',
        cuModelTypeId: 1,
        faultsAmount: 0,
        cuLabel: '',
        pwmAvailable: 44000,
        pwmPOC: 44000,
        pin: '000000',
        alias: 'Roma222676',
        project: '',
        partner: '',
        workforce: '',
        targetUse: '',
        location: {
          countryId: 1,
          regionId: 4,
          zoneId: 21,
          cityId: 2170,
          address: 'Via Ovidio, 5, 00193 Roma RM',
          latitude: '41.906776769304464',
          longitude: '12.464398515955914',
          postalCode: '',
        },
        actualSw: { swVersion: '11-v11ocpp16-73', swCuCh: '-1', swCuEv: '-1', swCpIo: '-1', versionSeq: 34 },
        latestSw: { swVersion: '11-v11ocpp16-73', swCuCh: '-1', swCuEv: '-1', swCpIo: '-1', versionSeq: 34 },
        milestoneSw: { swVersion: 'N.A.', swCuCh: 'N.A.', swCuEv: 'N.A.', swCpIo: 'N.A.', versionSeq: -1 },
        plugList: [
          {
            plugId: 1,
            plugTypeId: 2,
            plugTypeDesc: 'Type 2',
            userRfid: 'XXUID00000000000000000000ON*4533                                ',
            locked: false,
            rechargeSessionId: 3083740,
            meterRecharging: true,
            rechargeStatus: 'ACTIVE',
            rechargeTime: 1675782250000,
            sysRechargeTime: 1675782236453,
            extCd: null,
            contractCd: 'CONTRACT_129',
            customerContractCd: 'CONTRACT_129',
            spActorId: 129,
            meterCuId: 44673,
            meterCode: '1',
            meterCuSn: '',
            meterPod: '',
            meterType: 'T',
            iSession: 32000,
            smartRecharge: false,
            iMax: '32',
            iMin: '7',
            pMax: '22125',
            externalAuth: 1,
            authRemote: true, // penso se è abile a ricaricare e quindi abilitata
            reserved: false,
            faulted: 0,
          },
          {
            plugId: 2,
            plugTypeId: 2,
            plugTypeDesc: 'Type 2',
            userRfid: 'XXUID00000000000000000000ON*8169                                ',
            locked: false,
            rechargeSessionId: 3079753,
            meterRecharging: false,
            rechargeStatus: 'FINISHED',
            rechargeTime: 1675778001000,
            sysRechargeTime: 1675781468029,
            extCd: null,
            contractCd: 'CONTRACT_129',
            customerContractCd: 'CONTRACT_129',
            spActorId: 129,
            meterCuId: 44674,
            meterCode: '2',
            meterCuSn: '',
            meterPod: '',
            meterType: 'T',
            iSession: 32000,
            smartRecharge: false,
            iMax: '32',
            iMin: '7',
            pMax: '22125',
            externalAuth: 1,
            authRemote: true,
            reserved: false,
            faulted: 0,
          },
        ],
        extParams: [
          {
            id: 'HeartbeatInterval',
            acronym: 'HEARTBT',
            value: '60',
            units: 'sec',
            type: 'int',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 5,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'interval of KEEPALIVE messages in seconds (0(=off) - 60)',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'MeterValueSampleInterval',
            acronym: 'METRD',
            value: '120',
            units: 'sec',
            type: 'int',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 5,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Meter reading data interval in seconds (0-3600)',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'CRGNB',
            acronym: 'CRGNB',
            value: '2',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 0,
            mandatory: 1,
            description: 'max number of sessions',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'REMRC',
            acronym: 'REMRC',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Remote Recharge',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'Protocol',
            acronym: 'PROTO',
            value: 'OCPP16j',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Communication Protocol between charging unit and central system',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'AutoCommissioning',
            acronym: 'AUTOCOMM',
            value: 'Y',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'CU becomes operational without commissioning procedure with operator card',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'RESRV',
            acronym: 'RESRV',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'CU may be reserved',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'WLIST',
            acronym: 'WLIST',
            value: '0',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Supports whitelist',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'RESET',
            acronym: 'RESET',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 1,
            description: 'Soft reset',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'GETWL',
            acronym: 'GETWL',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 1,
            description: 'Get whitelist',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'READP',
            acronym: 'READP',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 1,
            description: 'Read parameters',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'SDLOG',
            acronym: 'SDLOG',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 1,
            description: 'Get Diagnostics',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'PWUID',
            acronym: 'PWUID',
            value: 'NOPWUID',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 32,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'UID Replacement',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'GRFTC',
            acronym: 'GRFTC',
            value: '1',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Group RFID two charge',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'AuthorizeRemoteTxRequests',
            acronym: 'AUTRM',
            value: 'true',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'AuthorizeRemoteTxRequests',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'TimeZone',
            acronym: 'TZONE',
            value: 'Europe/Berlin',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 128,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'TimeZone',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'ConnectionTimeOut',
            acronym: 'CTOUT',
            value: '90',
            units: 'sec',
            type: 'int',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 5,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'Connection timeout',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'SAVE_MSG',
            acronym: 'SAVE_MSG',
            value: '0',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'Save message from/to CU',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'AUTHK',
            acronym: 'AUTHK',
            value: 'gSeGOUngpXUbMa2mvXhr',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 64,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'the new authorization key to set',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'BASIC_AUTH',
            acronym: 'BASIC_AUTH',
            value: '0',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'whether BE should generate a new authorization key',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'INITK',
            acronym: 'INITK',
            value: '',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 64,
            sendCu: 0,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'the CU initial authorization key',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: '/hw/auth/device/Dummy/enabled',
            acronym: 'FREEC',
            value: 'false',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'Free charging enabled',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: '/hw/auth/device/Dummy/id/value',
            acronym: 'FREET',
            value: 'DummyToken',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 64,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'Free charge Token',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: '/hw/auth/device/Dummy/allowStop',
            acronym: 'FREES',
            value: 'true',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'Freecharge allow stop',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'WebsocketPingInterval',
            acronym: 'WSPING',
            value: '20',
            units: 'sec',
            type: 'int',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 5,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 1,
            description: 'Websocket ping interval',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'PWMAX',
            acronym: 'PWMAX',
            value: '44000',
            units: 'Watt',
            type: 'int',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 6,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 1,
            description: 'max power',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'PARAMMAXPWM',
            acronym: 'PARAMMAXPWM',
            value: '/power/station/mainsMaxCurrent;A;1000',
            units: '',
            type: 'string',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 128,
            sendCu: 0,
            visible: 0,
            changeable: 1,
            mandatory: 0,
            description: 'MaxPWM',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
          {
            id: 'StopTransactionOnInvalidId',
            acronym: 'STOPT',
            value: 'true',
            units: '',
            type: 'list',
            group: 'DEFAULT',
            ratio: 1,
            minlength: 1,
            maxlength: 1,
            sendCu: 1,
            visible: 1,
            changeable: 1,
            mandatory: 0,
            description: 'StopTransactionOnInvalidId',
            fromCuVersion: '-1',
            toCuVersion: '-1',
          },
        ],
        masterSlaveAssociations: [],
        historyCu: [
          {
            cuId: 21614,
            cuStateId: 2,
            changeTime: 1646744600355,
            actual: false,
            note: ' By insertion cu ',
            chOperatorId: 768,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 2,
            changeTime: 1646744600541,
            actual: false,
            note: ' This is a replacement of TE100003029 ',
            chOperatorId: 768,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 1,
            changeTime: 1646744628821,
            actual: false,
            note: 'Attiva',
            chOperatorId: 768,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 3,
            changeTime: 1647598249893,
            actual: false,
            note: 'configurata ma non ancora in produzione',
            chOperatorId: 714,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 1,
            changeTime: 1654099209754,
            actual: false,
            note: 'Operativa - Disponibile',
            chOperatorId: 925,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 3,
            changeTime: 1655800763595,
            actual: false,
            note: 'manutenzione',
            chOperatorId: 735,
            operatorDesc: ' ',
          },
          {
            cuId: 21614,
            cuStateId: 1,
            changeTime: 1655816797210,
            actual: true,
            note: 'test',
            chOperatorId: 735,
            operatorDesc: ' ',
          },
        ],
        groupList: [
          {
            cuGroupTypeId: 7,
            groupName: 'On Charge - Roma',
            groupId: 695,
            acronimType: 'PLACE',
            groupStatusId: 1,
            groupStatusDesc: null,
            chActorId: 186,
            apActorId: 186,
            dsoId: -1,
            chActorType: 'ap_actor_id',
            chActorDesc: 'OnSharing CPO',
            numCu: 0,
            groupMode: 'E',
            chargingUnits: null,
            chargingUnitsToDisassociate: null,
            groupParams: {},
            descriptionType: null,
            hasCusAlreadyAss: false,
          },
        ],
        noteList: [],
        scriptList: null,
        defRech: { hour: {}, configured: false, configurable: false },
        replaceConf: {
          cuCdReplacing: 'ONS_00000034',
          cuCdReplaced: 'ONS_00000033',
          snReplacing: 'TE100003029',
          snReplaced: 'TE100003029',
          cuStateReplacing: 1,
          cuStateReplaced: 4,
        },
        sharedMode: false,
        v2gEnabled: false,
        mrEnabled: false,
        cuClass: null,
        feFtpConfig: true,
        updating: false,
      }; */

      const structure = {
        data: [
          {
            title: 'views.stations.swVersion',
            name: 'actualSw',
            sub_name: 'swVersion',
            placeholder: '-',
          },
          {
            title: 'views.stations.address',
            name: 'location',
            sub_name: 'address',
            placeholder: '-',
          },
          {
            title: 'views.stations.latitude',
            name: 'location',
            sub_name: 'latitude',
            placeholder: '-',
          },
          {
            title: 'views.stations.longitude',
            name: 'location',
            sub_name: 'longitude',
            placeholder: '-',
          },
          {
            title: 'views.stations.numOfRecharges',
            name: 'numRecharge',
            placeholder: '0',
          },
          {
            title: 'views.stations.totWattRecharge',
            name: 'totWattRecharge',
            format: 'watt',
            placeholder: '-',
          },
          {
            title: 'views.stations.feIpAddress',
            name: 'feIpAddress',
            placeholder: '-',
          },
          {
            title: 'views.stations.ipAddress',
            name: 'ipAddress',
            placeholder: '-',
          },
        ],
      };
      return { el: selectedSiemensStation, structure };
    },
  };
</script>

<style></style>
