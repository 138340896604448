<template>
  <tr class="sticky top-0 w-full px-4 py-1 grid" :style="structureStyle">
    <th
      v-for="itm in structure.data"
      :key="itm"
      class="flex justify-start items-center cursor-pointer pr-2 select-none leading-none whitespace-nowrap w-full"
      :class="customClass(itm)"
      @click="
        updateOrderKey(itm.field, itm.format);
        toggleOrder();
      ">
      <span class="w-min max-w-full text-ellipsis overflow-hidden text-gray-700 dark:text-gray-300">
        <template v-if="itm.title">{{ t(itm.title) }}</template>
        <template v-else>{{ t('fields.' + itm.field) }}</template>
      </span>
      <div v-show="itm.field" class="w-4 flex flex-col justify-center items-center ml-2 text-xs text-gray-300 dark:text-gray-50 select-none">
        <span class="material-icons text-lg -mb-1 leading-none" :class="{ 'text-gray-900 dark:text-white': orderKey == itm.field && order == 'ASC' }">
          arrow_drop_up
        </span>
        <span class="material-icons text-lg -mt-1 leading-none" :class="{ 'text-gray-900 dark:text-white': orderKey == itm.field && order == 'DESC' }">
          arrow_drop_down
        </span>
      </div>
    </th>
  </tr>
</template>

<script>
  import { computed } from 'vue';
  import { Utils } from '@/Utils/utils';
  import { useI18n } from 'vue-i18n';

  export default {
    emits: ['toggleOrder', 'update:orderKey', 'update:order'],
    props: {
      structure: { type: Object, required: true },
      orderKey: {
        type: Object,
        required: true,
        default: () => {
          return { key: '', format: '' };
        },
      },
      order: { type: String, required: true, default: 'DESC' },
    },
    setup(props, { emit }) {
      const structureStyle = computed(() => Utils.countTableColumns(props.structure.data));
      function customClass(itm) {
        if (!itm) return '';
        if (!itm.span) itm.span = 1;
        return `col-span-${itm.span}`;
      }

      function updateOrderKey(key, format = '') {
        emit('update:orderKey', { key, format });
      }

      function toggleOrder() {
        if (props.order == 'ASC') emit('update:order', 'DESC');
        else emit('update:order', 'ASC');
      }

      return { customClass, structureStyle, updateOrderKey, toggleOrder, t: useI18n().t };
    },
  };
</script>

<style></style>
