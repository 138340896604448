<template>
  <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        console.log(payload);
        store.dispatch('getCompleteUsersList', {
          user_type: 'Cliente',
          confirmed: 'false',
          orderBy: 'updated_at',
          ...payload,
        });
      }

      const completeUserList = computed(() => store.getters.completeUsersList);

      const structure = {
        title: 'views.user.unconfirmedUsersList',

        timeFields: [
          { label: 'views.timeEntries.creationDate', key: 'created_at' },
          { label: 'views.timeEntries.lastUpdate', key: 'updated_at' },
          { label: 'views.timeEntries.termsAndConditionsAcceptance', key: 'terms_and_conditions_timestamp' },
        ],
        data: [
          {
            title: 'user.name.first',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/user/users/:id/documents' },
          },
          {
            title: 'user.name.second',
            field: 'surname',
            span: 1,
          },
          {
            title: 'user.cellphone',

            field: 'cellphone',
            span: 1,
          },
          {
            title: 'user.email',
            field: 'email',
            span: 1,
          },
          {
            title: 'user.updatedAt',
            field: 'updated_at',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      return {
        completeList: completeUserList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
