<template>
  <base-subpage-bar :headers="headers" />
  <router-view></router-view>
</template>

<script>
  export default {
    setup() {
      return {
        headers: [
          { text: 'views.consumers.consumersList', name: 'menu.consumers.consumersList' },
          { text: 'views.consumers.organisationsList', name: 'menu.consumers.organisationsList' },
        ],
      };
    },
  };
</script>

<style></style>
