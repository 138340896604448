<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
import { Utils } from "@/Utils/utils";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //init vue stuffs
    const store = useStore();
    //init selects
    store.dispatch("getManagers");
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    //structure of page
    const insertData = {
      title: "Inserimento nuova tipologia",
      redirect_path: "/sharing/vehicles/scooter/types",
      create_endpoint: "createScooterType",
      creation_title: "Creazione nuova tipologia",
      dispatchName: "getScooterTypes",
      data: [
        reactive({
          title: "Gestore",
          name: "manager",
          select: managers,
        }),
        {
          title: "Nome",
          name: "name",
          required: true,
        },
        {
          title: "Path immagine",
          name: "bike_picture",
          class: "col-span-2",
        },
        {
          title: "Voltaggio massimo batteria",
          name: "max_battery_voltage",
          class: "col-span-2",
        },
        {
          title: "Voltaggio minimo batteria",
          name: "min_battery_voltage",
          class: "col-span-2",
        },
        {
          title: "Prezzo al minuto",
          name: "per_minute",
        },
        {
          title: "Prezzo al km",
          name: "per_km",
        },
        {
          title: "Prezzo 1h-3h",
          name: "1_3",
        },
        {
          title: "Prezzo 3h-6h",
          name: "3_6",
        },
        {
          title: "Prezzo al giorno",
          name: "per_day",
        },
        {
          title: "Note",
          name: "additional_information",
          class: "col-span-4",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>