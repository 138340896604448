<template>
  <div
    v-if="headers && headers.length > 0"
    class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-6">
    <ul class="flex flex-wrap -mb-px">
      <template v-for="head in headers" :key="head">
        <base-router-link v-if="head.name" :path="{ name: head.name, params: head.params }" :selected="head.name == currentName" class="mr-8">
          <span v-if="head.text">{{ $t(head.text) }} {{ head.params }}</span>
          <span v-else>{{ $t(head.name) }} {{ head.params }}</span>
        </base-router-link>
        <base-router-link v-else-if="head.path" :path="head.path" class="mr-8" :selected="head.path == currentPath">
          <span v-if="head.text">{{ $t(head.text) }}</span>
          <span v-else>{{ $t(head.path) }}</span>
        </base-router-link>
      </template>
      <slot />
    </ul>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router';
  import { ref, watch } from 'vue';

  export default {
    props: {
      headers: { type: Object, required: true },
    },
    setup() {
      const route = useRoute();
      const currentName = ref('');
      const currentPath = ref('');
      watch(
        () => route.path,
        () => {
          console.log(`HEADER - watch route.path changed to ${route.path}`);
          currentName.value = route.name || '';
          currentPath.value = route.path || '';
          document.title = 'On | ' + (currentName.value || currentPath.value);
          //if (route.path.includes("/"))
        },
        { immediate: true },
      );

      return { currentPath, currentName };
    },
  };
</script>

<style></style>
