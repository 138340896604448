<template>
  <div class="mb-4 w-full mx-auto">
    <div class="flex justify-between">
      <base-button icon="delete" class="bg-red-300 text-red-900" @click="deleteUser">
        {{ t('buttons.delete') }}
      </base-button>
    </div>
    <div class="flex flex-col justify-center items-start py-6">
      <div>
        <h3 class="text-primary-400 order-1 text-xl" v-html="t(userType)"></h3>
        <div class="text-3xl font-bold mb-3 mt-2 leading-none order-1">{{ el.name }} {{ el.surname }}</div>
      </div>
      <user-unlock :id="el.id" />
      <div>
        <button  @click="generateLink" class="text-primary-400 hover:text-primary-700">
          {{ t('button.generateResetPasswordLink') }}
        </button>
        <p>{{ resetPasswordLink }}</p>
      </div>
      <base-data-field class="order-1" label="user.email" :value="el.email.email" icon="email" />
      <base-data-field class="order-1" label="user.cellphone" :value="el.cellphone.cellphone" icon="call" />

      <div
        v-if="el.billingProfiles && el.billingProfiles.length"
        class="flex flex-col justify-center items-start mt-4 order-2">
        <h3 class="text-gray-900 dark:text-white font-semibold order-1">
          {{ t('payments.billingData') }}
        </h3>
        <base-data-field
          v-for="(billingProfile, i) in el.billingProfiles"
          :key="i"
          label="payments.billingProfile"
          :value="`<b>${billingProfile.name}</b> - <strong>${billingProfile.fiscal_code}</strong> profilo di tipo ${billingProfile.profile_type}`"
          :icon="billingProfile.favourite ? 'radio_button_checked' : 'radio_button_unchecked'"
          :class="{
            'order-1': billingProfile.favourite,
            'order-2': !billingProfile.favourite,
          }" />
      </div>
      <div v-if="el.cards && el.cards.length" class="flex flex-col justify-center items-start mt-4 order-2">
        <h3 class="text-gray-900 dark:text-white font-semibold order-1">{{ t('payments.paymentMethods') }}</h3>
        <base-data-field
          v-for="(card, i) in el.cards"
          :key="i"
          label="payments.creditCard"
          :value="`Carta valida trovata con riferimento <strong>${card.pm_token}</strong>`"
          :icon="card.favourite ? 'radio_button_checked' : 'radio_button_unchecked'"
          :class="{ 'order-1': card.favourite, 'order-2': !card.favourite }" />
      </div>

      <div v-if="el.card_rfid && el.card_rfid.length" class="flex flex-col justify-center items-start mt-4 order-2">
        <h3 class="text-gray-900 dark:text-white font-semibold order-1">
          {{ t('payments.rfidCardTitle') }}
        </h3>
        <base-data-field
          v-for="(card_rfid, i) in el.card_rfid"
          :key="i"
          label="payments.rfidCard"
          :value="`Carta associata con codice <strong>${card_rfid.codice}</strong> e ID_TAG: <strong>${card_rfid.id_tag}</strong>`"
          :icon="card_rfid.attiva ? 'radio_button_checked' : 'radio_button_unchecked'"
          :class="{ 'order-1': card_rfid.attiva, 'order-2': !card_rfid.attiva }" />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed , ref} from 'vue';
  import { useStore } from 'vuex';
  import UserUnlock from './UserUnlock.vue';
  import { useI18n } from 'vue-i18n';
  export default {
    components: { UserUnlock },
    props: {
      el: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      //init vue stuffs
      const store = useStore();
      const t = useI18n().t;

      //    check registrazione
      const steps = computed(() => {
        let count = 0;
        const el = props.el;
        if (el !== null) {
          if (!el.terms_and_conditions) count += 1;
          if (!el.email.email) count += 1;
          if (!el.cellphone) count += 1;
        }
        return count;
      });

      function registrationStatus() {
        return steps.value > 0 ? t('user.registrationMissingSteps') : t('user.registrationCompleted');
      }
      function registrationClass() {
        let bgColor = steps.value > 0 ? 'bg-red-200' : 'bg-green-100';
        let textColor = steps.value > 0 ? 'text-red-800' : ' text-green-700';
        return `${textColor} ${bgColor}`;
      }

      const userType = computed(() => {
        switch (props.el.user_type) {
          case 2:
            return 'user.types.location';
          case 3:
            return 'user.types.customer';
          case 6:
            return 'user.types.administrator';
          case 7:
            return 'user.types.manager';
          default:
            return 'user.types.unknown';
        }
      });

      const deleteUser = () => store.dispatch('deleteUser', { id: props.el.id });
      const generateLink = () => store.dispatch('generateResetPasswordLink', { email: props.el.email.email }).then((response) => {
        resetPasswordLink.value = response
      });
      const resetPasswordLink = ref('');

      return { userType, resetPasswordLink, generateLink, registrationStatus, registrationClass, deleteUser, t: t };
    },
  };
</script>

<style></style>
