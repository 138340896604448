<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const refreshPage = (payload) => store.dispatch('getReleaseStations', payload);

      const releaseStations = computed(() => store.getters.releaseStations);

      // table
      const structure = {
        title: 'views.sharing.releaseStations',

        creationLink: {
          title: '',
          namePath: 'menu.sharing.createReleaseStation',
        },
        data: [
          {
            title: 'views.sharing.name',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/sharing/bricks/:id' },
          },
          {
            title: 'views.sharing.type',
            field: 'type',
            span: 1,
          },
          {
            title: 'views.sharing.remote_id',
            field: 'remote_id',
            span: 1,
          },
          {
            title: 'views.sharing.slots',
            field: 'slots',
            span: 1,
          },
          {
            title: 'views.sharing.latitude',
            field: 'latitude',
            span: 1,
          },
          {
            title: 'views.sharing.longitude',
            field: 'longitude',
            span: 1,
          },
          {
            title: 'views.timeEntries.creationDate',
            field: 'timestamp',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.timeEntries.lastUpdateDate',
            field: 'update_timestamp',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      return {
        data: releaseStations,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
