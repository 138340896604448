//const defaultTitle = ['Sto pensando...', 'Distrincando cavi...', 'Riavviando il modem...', 'Minando crypto...', 'Facendo partire razzi...', 'Giocando a LOL...'];
const defaultTitle = [''];

export default {
  state: {
    isLoading: 0,
    loaderTitle: '',
  },
  getters: {
    loader: (state) => ({
      pendingRefresh: false,
      loading: state.isLoading,
      title: state.loaderTitle,
    }),
    pendingRefresh: (state) => state.pendingRefresh,
  },
  mutations: {
    loaderToggle: (state, newLoaderState) => {
      if (newLoaderState.loading) state.isLoading++;
      else
        setTimeout(() => {
          state.isLoading--;
        }, newLoaderState.timeout);

      if (newLoaderState.title != '') state.loaderTitle = newLoaderState.title;
      else state.loaderTitle = defaultTitle[state.isLoading % 6];
    },
    togglePendingRefresh: (state, payload) => (state.pendingRefresh = payload),
  },
  actions: {
    loaderShow: (ctx, title = '', timeout = 1000) =>
      ctx.commit('loaderToggle', {
        loading: true,
        title: title,
        timeout: timeout,
      }),
    loaderHide: (ctx) =>
      ctx.commit('loaderToggle', {
        loading: false,
        title: '',
      }),
    pendingRefresh: (ctx) => setTimeout(() => ctx.commit('togglePendingRefresh', true), 1000),
    refreshDone: (ctx) => ctx.commit('togglePendingRefresh', false),
  },
};
