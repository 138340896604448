<template>
  <language-header :el="headerFields" :headerType="headerType"></language-header>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed, watch } from 'vue';
  import LanguageHeader from '../../CommonComponents/TheHeader';
  import { useI18n } from 'vue-i18n';
  export default {
    components: { LanguageHeader },
    setup() {
      const store = useStore();
      const route = useRoute();
      const platform = computed(() => {
        const list = store.getters.platformList.filter((platform) => platform.db === route.params.platformId);
        if (list[0] !== undefined) return list[0];
        return '';
      });
      const locales = computed(() => store.getters.sublocalesList);
      watch(platform, (nv) => {
        if (nv && nv.db) store.dispatch('retrieveLocales', { platform: nv.db, type: 'sub' });
      });
      const headers = computed(() => {
        const h = [];
        locales.value.forEach((locale) => {
          h.push({
            text: locale.toUpperCase(),
            path: `/languages/language/${route.params.platformId}/${locale}`,
            params: { platformId: route.params.platformId, locale: locale },
          });
        });
        return h;
      });
      const headerType = computed(() => (route.params.locale ? 'locale' : 'language'));
      return {
        headerFields: computed(() => {
          return {
            id: route.params.platformId,
            title: platform.value.name,
            locale: route.params.locale,
          };
        }),
        headers,
        platform,
        headerType,
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
