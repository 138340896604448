<template>
  <base-page class="h-screen">
    <div hidden v-for="(el, i) in mapAnalytics" :key="i" class="m-4">{{ el }}</div>

    <div class="flex flex-row h-full space-x-8 mt-10">
      <!-- MAPPA -->
      <div class="relative w-3/5 h-3/5 rounded-3xl overflow-hidden">
        <GMapMap
          :center="{ lat: 41.91485292548936, lng: 12.470854259356592 }"
          :zoom="6"
          map-type-id="roadmap"
          :options="{ mapId: 'roadmap' }">
          <!-- MARKER AND CIRCLE FOR SINGLE ZONE   -->
          <GMapMarker
            v-for="el in mapAnalytics"
            :key="el.station_id"
            :position="{ lat: parseFloat(el.latitude), lng: parseFloat(el.longitude) }"
            @click="selectedId = el.station_id">
            <GMapInfoWindow v-if="viewData || selectedId == el.station_id">
              <div class="select-none" v-show="selectedId == el.station_id">
                <h1 class="text-lg font-bold">{{ el.station_name }}</h1>
                <h2 class="text-lg font-black">{{ el.station_address }}</h2>
                <h2 class="text-base font-medium">{{ el.kwh_delivered }} kWh erogati</h2>
                <h2 class="text-base font-medium">{{ el.total_amount }} € incassati</h2>
                <h2 class="text-base font-medium">{{ el.count }} ricariche</h2>
                <h2 class="text-sm font-medium">
                  Prima ricarica:
                  <b>{{ el.charging_start }}</b>
                </h2>
                <h2 class="text-sm font-medium">
                  Ultima ricarica:
                  <b>{{ el.charging_end }}</b>
                </h2>
              </div>
              <div v-show="selectedId != el.station_id" class="w-min overflow-x-hidden select-none">
                <h2 hidden style="font-size: 0.5rem" class="font-black whitespace-nowrap overflow-x-hidden">
                  {{ el.station_address }}
                </h2>
                <h1 style="font-size: 0.5rem" class="font-bold">{{ el.station_name }}</h1>
                <h2 class="text-base font-medium whitespace-nowrap">{{ getValue(el) }}</h2>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapCircle
            v-for="e in mapAnalytics"
            :key="e.station_id"
            :center="{ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) }"
            :radius="factor * scaleRadius(parseInt(e[circleType.field]))" />
        </GMapMap>
      </div>
      <!-- FINE MAPPA -->

      <!-- INIZIO CONTROLLI -->
      <div class="flex flex-col justify-start items-start w-2/5">
        <div class="flex flex-row justify-between items-end w-full">
          <div class="w-3/5">
            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
              Dato del cerchio
            </label>
            <select
              v-model="circleType"
              id="countries"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option v-for="opt in options" :key="opt.field" :value="opt">{{ opt.title }}</option>
            </select>
          </div>

          <div class="pl-4 pb-3 w-2/5">
            <div class="flex items-center mt-4">
              <input
                checked
                id="checked-checkbox"
                v-model="viewData"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label for="checked-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Visualizza info
              </label>
            </div>
          </div>
        </div>

        <label for="default-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-4">
          Scala del cerchio
        </label>
        <input
          id="default-range"
          type="range"
          v-model="factor"
          step="0.1"
          min="0"
          max="3"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />

        <base-list-table
          compact
          :data="summary"
          :structure="structure"
          :refreshFunction="refreshPage"
          :force-pagination-num="5"></base-list-table>
      </div>
      <!-- FINE CONTROLLI -->
    </div>
  </base-page>
</template>
<script>
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';

  export default {
    name: 'MapAnalytics',
    setup() {
      const store = useStore();
      store.dispatch('getMapAnalytics');
      const mapAnalytics = computed(() => store.getters.mapAnalytics);
      const selectedId = ref(null);
      const viewData = ref(true);
      const factor = ref(0.3);

      const options = [
        { field: 'kwh_delivered', title: 'KWh erogati', format: 'kwh' },
        { field: 'total_amount', title: 'Totale incassato', format: 'euro' },
        { field: 'count', title: 'Numero ricariche', format: '' },
        { field: 'kw_on_count', title: 'Rendimento', format: 'kwh/recharges' },
      ];

      const circleType = ref(options[0]);

      const scaleRadius = (r) => {
        switch (circleType.value.field) {
          case 'kwh_delivered':
            return r * factor.value;
          case 'total_amount':
            return r * factor.value * 2;
          case 'kw_on_count':
            return r * 100 * factor.value;
          case 'count':
            return r * factor.value * 5;
          default:
            return r * factor.value;
        }
      };

      const getValue = (el) => {
        switch (circleType.value.field) {
          case 'kwh_delivered':
            return parseFloat(el.kwh_delivered).toFixed(3) + ' kWh';
          case 'total_amount':
            return parseFloat(el.total_amount).toFixed(2) + '€';
          case 'kw_on_count':
            return parseFloat(el.kw_on_count).toFixed(2) + ' kWh/ricarica';
          default:
            return el[circleType.value.field];
        }
      };

      // TABLE

      function refreshPage(payload) {
        store.dispatch('getSummary', payload);
      }
      const summary = computed(() => store.getters.summary);

      const structure = computed(() => {
        return {
          title: '',
          /* timeFields: [
          { label: "Inizio ricarica", key: "charging_start" },
          { label: "Fine ricarica", key: "charging_end" },
        ], */
          data: [
            {
              title: 'Colonnina',
              fields: [
                {
                  placeholder: '@',
                  name: 'station_id',
                  class: 'text-blue-300',
                },
                {
                  name: 'station_name',
                },
                {
                  name: 'station_address',
                },
              ],
              span: 1,
              link: { idField: 'station_id', path: '/recharge/stations/:id' },
            },
            {
              title: circleType.value.title,
              field: circleType.value.field,
              format: circleType.value.format,
              span: 1,
            },
          ],
        };
      });

      return {
        options,
        refreshPage,
        summary,
        structure,
        factor,
        scaleRadius,
        viewData,
        getValue,
        circleType,
        selectedId,
        mapAnalytics,
      };
    },
  };
</script>

<style scoped>
  .vue-map-container,
  .vue-map {
    width: 100% !important;
    height: 100% !important;
  }
</style>
