import { ApiCaller, Utils } from "@/Utils/utils";

export default {
  state: {
    releaseStations: [],
    releaseStation: null,
    selectedStall: null,
  },
  getters: {
    releaseStations: (state) => state.releaseStations,
    releaseStation: (state) => state.releaseStation,
    selectedStall: (state) => state.selectedStall,
  },
  mutations: {
    setReleaseStations: (state, data) => (state.releaseStations = data),
    setReleaseStation: (state, data) => (state.releaseStation = data),
    setSelectedStall: (state, data) => (state.selectedStall = data),
  },
  actions: {
    getSelectedStall: (ctx, payload) =>
      ApiCaller.GET(`/releaseStations/${payload.id}/${payload.stallId}`, payload).then((data) => ctx.commit("setSelectedStall", data)),
    getReleaseStations: (ctx, payload) =>
      ApiCaller.GET("/releaseStations", payload).then((data) => ctx.commit("setReleaseStations", Utils.createFiltrableData(data))),
    getReleaseStation: (ctx, payload) =>
      ApiCaller.GET(`/releaseStations/${payload.id}`, payload).then((data) => ctx.commit("setReleaseStation", data)),

    changeBike: (_, payload) => ApiCaller.PUT(`/releaseStations/slots/changeBike/${payload.id}/${payload.slotId}`, payload),
    updateSlot: (_, payload) => ApiCaller.PUT(`/releaseStations/slots/${payload.id}/${payload.slotId}`, payload),
    createReleaseStation: (_, payload) => ApiCaller.POST("/releaseStations", payload),
    sendStallOperation: (_, payload) => ApiCaller.POST("/releaseStations/operation", payload),
    endSlotRental: (_, payload) => ApiCaller.POST(`/releaseStations/endSlotRental/${payload.id}/${payload.slotId}`, payload)
  },
};
