<template>
  <div class="mr-8 hover:bg-black/25 font-bold">
    <router-link :to="pageName"> {{ title }} </router-link>
  </div>
</template>

<script>
export default {
  props: ["pageName", "title"],
};
</script>

<style>
</style>