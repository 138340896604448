<template>
  <div v-if="selectedAlarm" class="px-16 pt-8">
    <div class="mb-4 w-full mx-auto">
      <div class="flex justify-between">
        <base-button icon="delete" class="bg-red-300 text-red-900" @click="deleteAlarm">
          {{ t('buttons.delete') }}
        </base-button>
      </div>
      <div class="flex flex-col justify-center items-start py-6">
        <div>
          <h3 class="order-1 text-xl" v-html="'@' + selectedAlarm.id" :class="getIdClass"></h3>
          <div class="text-3xl font-bold mb-3 mt-2 leading-none order-1">
            {{ selectedAlarm.title }}
          </div>
          <h3 class="text-primary-400 order-1 text-xl">{{ t('views.alarms.assignedTo') }}</h3>
          <div class="text-2xl mb-3 mt-2 leading-none order-1">
            {{ selectedAlarm.assignedTo.name }} {{ selectedAlarm.assignedTo.surname }}
          </div>
        </div>
      </div>
    </div>
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('setSelectedAlarm', selectedElID);
        store.dispatch('getAlarmTypes');
      });
      const selectedAlarm = computed(() => {
        return store.getters.selectedAlarm;
      });

      const deleteAlarm = () => store.dispatch('deleteAlarm', { id: selectedAlarm.value.id });
      const getIdClass = computed(() => {
        const currentStatusName = selectedAlarm.value.currentStatusType.name;
        return currentStatusName === undefined
          ? 'text-gray-400'
          : currentStatusName === 'DELETED'
          ? 'text-red-400'
          : currentStatusName === 'UNSOLVED'
          ? 'text-orange-400'
          : currentStatusName === 'SOLVED'
          ? 'text-green-400'
          : 'text-primary-400';
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Allarme',
          name: 'menu.alarm.generals',
        },
        {
          text: 'Storico Stati',
          name: 'menu.alarm.stateData',
        },
      ];

      return {
        getIdClass,
        selectedAlarm,
        headers,
        deleteAlarm,
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
