<template>
  <div v-if="el && el.slots" class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6">
    <span v-for="slot in el.slots" :key="slot">
      <div class="flex justify-center rounded-2xl border border-gray-200 shadow-lg p-2">
        <div>
          <router-link
            class="text-center"
            :to="{ name: 'menu.sharing.stallGenerals', params: { id: el.id, stallId: slot.remote_id } }">
            <p>{{ $t('sharing.goToSlot') }}</p>
          </router-link>
          <div class="flex justify-center">
            <div class="mt-4 text-center">
              <p class="text-xs">Clicca per visualizzare</p>
              <base-qr-code :value="slot?.qrUrl" :imageName="slot?.name" />
            </div>
          </div>
          <div class="flex justify-center">
            <div>
              <div class="flex justify-left">
                <p class="text-xs text-gray-400 mr-2">name</p>
                <p class="text-sm font-bold">{{ slot.name }}</p>
              </div>
              <div class="flex justify-left">
                <p class="text-xs text-gray-400 mr-2">remote_id</p>
                <p class="text-sm font-bold">{{ slot.remote_id }}</p>
              </div>
              <div class="flex justify-left">
                <p class="text-xs text-gray-400 mr-2">stato</p>
                <p class="text-sm font-bold">{{ slot.short_name }}</p>
              </div>
              <div class="flex justify-left">
                <p class="text-xs text-gray-400 mr-2">bike</p>
                <p class="text-sm font-bold">{{ slot.bike_plate ? slot.bike_plate : 'none' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();
      const el = computed(() => store.getters.releaseStation);

      return { el };
    },
  };
</script>

<style></style>
