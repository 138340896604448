<template>
  <div
    v-if="missingFields.length > 0"
    class="mt-4 bg-red-300 rounded-lg p-4 text-center text-sm"
  >
    <p class="text-lg font-bold">Mancano i seguenti parametri obbligatori</p>
    <ul class="mt-4">
      <li v-for="field in missingFields" :key="field">
        <p>{{ field }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props:{
        missingFields:{ type: Object, required: true}
    }
};
</script>

<style>
</style>