<template>
  <div class="text-center">
    <vue-qrcode
      :value="value"
      @change="onDataUrlChange"
      :color="{ dark: '#000000ff', light: '#ffffffff' }"
      type="image/png"
      class="cursor-pointer" />
    <a v-if="dataUrl" :href="dataUrl" :download="imageName || 'qrCode.png'" class="flex p-1">
      <button type="button" class="material-icons">download</button>
      <p>Download png</p>
    </a>
  </div>
</template>

<script>
  import VueQrcode from 'vue-qrcode';

  export default {
    props: {
      value: {},
      imageName: {},
    },
    data() {
      return {
        dataUrl: null,
      };
    },
    components: {
      VueQrcode,
    },
    methods: {
      onDataUrlChange(dataUrl) {
        this.dataUrl = dataUrl;
      },
      downloadImage(evento) {
        console.log('click');
        const link = evento.target.getAttribute('src');
        const xhr = new XMLHttpRequest();
        xhr.open('GET', link, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            const blob = new Blob([xhr.response], { type: 'image/png' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = this.imageName || 'qrCode.png';
            a.href = url;
            a.click();
          }
        };
        xhr.send();
      },
    },
  };
</script>
