<template>
  <div class="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
    <input
      @click="vmodel"
      :checked="checked"
      id="bordered-checkbox-2"
      type="checkbox"
      name="bordered-checkbox"
      class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
    <label for="bordered-checkbox-2" class="py-4 mx-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">
      {{ $t(title) }}
    </label>
  </div>
</template>

<script>
  export default {
    props: ['modelValue', 'title', 'checked'],
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      function vmodel(event) {
        emit('update:modelValue', event.target.checked);
      }
      return { vmodel };
    },
  };
</script>

<style></style>
