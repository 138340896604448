<template>
  <div v-if="!documentsThree" class="flex">
    <BaseSpinLoader />
    <p class="ml-4 self-center">Loading...</p>
  </div>
  <BaseFolderThree
    v-else
    :items="documentsThree"
    :getDownloadObjFunction="downloadObjFunct"
    :enableDownloadView="false"
    zipName="doc" />
  <template></template>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    setup() {
      //inizializzo lo store, la variabile che mi abilita e disabilita il loader, chiamo lo store per ottenere il three dei documenti
      const store = useStore();

      store.dispatch('getMobilityDocumentsThree');

      const documentsThree = computed(() => store.getters.mobilityDocumentsThree);
      const downloadObjFunct = (key) => store.dispatch('getMobilityObjForDownload', { key: key });

      return { documentsThree, downloadObjFunct };
    },
  };
</script>
