<template>
  <div class="w-full flex justify-center">
    <div v-if="selectedStation" class="px-16 pt-8 w-full max-7-xl mx-auto">
      <station-details-header :el="selectedStation"></station-details-header>
      <base-subpage-bar :headers="headers">
        <base-router-link v-if="haveEvses" name="menu.recharge.stationEvses">Evses</base-router-link>
        <base-router-link v-else name="menu.recharge.stationCreateEvses">Aggiungi Evses</base-router-link>
      </base-subpage-bar>
    </div>
  </div>
  <router-view />
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import StationDetailsHeader from './StationDetailsHeader.vue';

  export default {
    components: {
      StationDetailsHeader,
    },
    setup() {
      //  INIT
      const store = useStore();
      const router = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: router.params.id };
      store.dispatch('setSelectedStation', selectedElID);
      const selectedStation = computed(() => {
        return store.getters.selectedStation;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'paths.overall',
          name: 'menu.recharge.stationGenerals',
        },
        {
          text: 'paths.recharges',
          name: 'menu.recharge.stationRecharges',
        },
      ];

      onMounted(() => {
        store.dispatch('getEvses', router.params.id);
      });
      const haveEvses = computed(() => {
        return store.getters.haveEvses;
      });
      return {
        selectedStation,
        headers,
        haveEvses,
      };
    },
  };
</script>

<style></style>
