<template>
  <div v-if="selectedItm" class="px-16 pt-8">
    <base-details-header :structure="headerStructure" :el="selectedItm" />
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('setSelectedRecharge', selectedElID);
      });
      const selectedRecharge = computed(() => {
        return store.getters.selectedRecharge;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'paths.overall',

          name: 'menu.recharge.rechargeGenerals',
        },
      ];
      const headerStructure = {
        title: [
          {
            placeholder: '@',
            title: 'id',
          },
          {
            placeholder: 'station.socket: ',
            title: 'numero_presa',
          },
        ],
        data: [
          {
            placeholder: 'views.recharges.session: ',
            name: 'session_id',
          },
          {
            title: 'user.title',
            name: 'user_name',
            link: { idField: 'user_id', path: '/user/users/:id' },
          },
          {
            title: 'views.stations.address',
            name: 'station_address',
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'views.stations.rechargeStart',
            name: 'inizio_ricarica',
          },
          {
            title: 'views.stations.rechargeEnd',
            name: 'fine_ricarica',
          },
        ],
      };
      return {
        selectedItm: selectedRecharge,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
