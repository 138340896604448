<template>
  <base-page>
    <!-- bottone aggiungi ruolo -->
    <div class="flex justify-end">
      <base-button @click="showRoleSelection = !showRoleSelection">Assegna ruolo</base-button>
    </div>
    <!-- gestione ruoli -->
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
    <!-- pannello assegnazione ruolo -->
    <Teleport to="body">
      <div v-show="showRoleSelection" class="absolute top-0 right-0 w-full h-full bg-black/60 z-50">
        <div class="w-full h-full flex items-center justify-center z-50">
          <div class="rounded-md bg-white dark:bg-gray-900 p-8">
            <div class="flex justify-end">
              <button
                @click="showRoleSelection = !showRoleSelection"
                class="rounded-full px-2 bg-gray-300 text-gray-600 dark:text-gray-300">
                X
              </button>
            </div>
            <h1 class="text-xl">Aggiungi ruolo</h1>
            <h3 class="mt-4 text-gray-600 dark:text-gray-300 text-sm">Seleziona il ruolo da assegnare</h3>
            <select
              @change="addRole"
              class="w-full border rounded-md border-gray-300 dark:border-gray-700 mt-8 text-sm text-gray-600 dark:text-gray-300">
              <option selected class="text-sm text-gray-600 dark:text-gray-300">Seleziona un ruolo</option>
              <option v-for="role in authRoles" :key="role" :value="role.id" class="p-2 whitespace-nowrap">
                <p>{{ role.name }} ({{ role.description }})</p>
              </option>
            </select>
          </div>
        </div>
      </div>
    </Teleport>
  </base-page>
</template>

<script>
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';
  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const selectedElID = { id: route.params.id };
      const showRoleSelection = ref(false);

      store.dispatch('getAuthRoles');
      //getters and dispatches
      const refreshPage = () => store.dispatch('getAuthRoles');

      const selectedAuthUser = computed(() => {
        if (store.getters.selectedAuthUser && store.getters.selectedAuthUser.roles)
          return store.getters.selectedAuthUser.roles;
        return [];
      });

      const authRoles = computed(() => {
        if (!selectedAuthUser.value) return [];
        let finalArray = [];

        const roles = store.getters.authRoles;

        let tmpBoolean = false;
        roles.forEach((authRole) => {
          tmpBoolean = false;
          selectedAuthUser.value.forEach((userRole) => {
            if (userRole.id == authRole.id) tmpBoolean = true;
          });

          if (!tmpBoolean) finalArray.push(authRole);
        });
        return finalArray;
      });

      //button functions
      const addRole = (event) => {
        store.dispatch('addRole', { ...selectedElID, roleId: event.target.value }).then(() => router.go());
      };

      const removeRole = (id) => {
        store.dispatch('removeRole', { ...selectedElID, roleId: id }).then(() => router.go());
      };

      const structure = {
        title: 'Ruoli assegnati',
        data: [
          {
            title: 'Nome',
            field: 'name',
            span: 1,
          },
          {
            title: 'Descrizione',
            field: 'description',
            span: 1,
          },
          {
            button: {
              icon: 'delete_forever',
              function: removeRole,
              field_returned: 'id',
            },
            span: 1,
          },
        ],
      };
      return {
        data: selectedAuthUser,
        authRoles,
        showRoleSelection,
        refreshPage,
        structure,
        addRole,
        removeRole,
      };
    },
  };
</script>

<style></style>
