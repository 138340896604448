<template>
  <div>
    <base-subpage-bar :headers="headers" />
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, watch } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const vehicleType = computed(() => {
        if (route.params.vehicleType) return route.params.vehicleType.toUpperCase();
        return 'ALL';
      });
      const vehicles = computed(() => store.getters.vehicles);

      const refreshPage = (payload) => {
        store.dispatch('getVehiclesTypeList');
        store.dispatch('getVehicles', { ...payload, type: vehicleType.value });
      };
      watch(vehicleType, refreshPage);

      const selectedOrganisation = computed(() => store.getters.selectedOrganisation);
      watch(selectedOrganisation, refreshPage);

      const headers = computed(() => store.getters.vehiclesTypeList);

      const structure = {
        title: 'vehicles.list',
        creationLink: {
          title: 'vehicles.addNewVehicle',
          namePath: 'menu.mobility.createVehicle',
        },
        summaryFields: [
          {
            type: 'COUNT_DISTINCT',
            label: 'vehicles.vehicles',
            field: 'plate',
            unit: 'summary.vehicles',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'vehicles.types',
            field: 'type',
            unit: 'summary.types',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'Modelli diversi',
            field: 'vehicle_model_id',
            unit: 'summary.models',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'Online',
            field: 'plate',
            valueField: 'online',
            value: 'ONLINE',
            unit: 'summary.vehicles',
          },
          /*
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'vehicles.scooter',
            field: 'plate',
            valueField: 'type',
            value: 'SCOOTER',
            unit: ' scooter',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'vehicles.boat',
            field: 'plate',
            valueField: 'type',
            value: 'BOAT',
            unit: ' barche',
          }, */
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'vehicles.tractors',
            field: 'plate',
            valueField: 'type',
            value: 'TRACTOR',
            unit: 'summary.tractors',
          },
        ],
        data: [
          {
            title: 'vehicles.table.plate',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-300',
              },
              {
                name: 'plate',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/mobility/vehicles/:id/' },
          },
          {
            title: 'vehicles.table.frame',
            field: 'vin',
            span: 1,
          },
          {
            title: 'vehicles.table.type',
            field: 'type',
            span: 1,
            format: 'vehicleType',
          },
          {
            title: 'vehicles.table.model',
            fields: [
              {
                placeholder: '@',
                name: 'vehicle_model_id',
                class: 'text-primary-300',
              },
              {
                name: 'model_name',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: {
              idField: 'vehicle_model_id',
              path: '/mobility/models/:id',
            },
          },
          {
            title: 'vehicles.table.device',
            fields: [
              {
                placeholder: '@',
                name: 'device_id',
                class: 'text-primary-300',
              },
              {
                name: 'imei',
                class: 'font-bold',
              },
            ],
            span: 1,
            link: { idField: 'device_id', path: '/device/devices/:id' },
          },
          /* 
          {
            title: 'vehicles.table.latitude',
            field: 'latitude',
            span: 1,
          },
          {
            title: 'vehicles.table.longitude',
            field: 'longitude',
            span: 1,
          }, */
          {
            title: 'vehicles.table.heartbeat',
            field: 'lastHeartbeat',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Online',
            field: 'online',
            span: 1,
            format: 'online',
          },
        ],
      };

      return {
        headers,
        vehicleType,
        data: vehicles,
        structure,
        refreshPage,
      };
    },
  };
</script>
<style></style>
