<template>
  <div class="flex justify-center items-center bg-white dark:bg-gray-900 rounded-2xl font-light text-gray-500">
    <div v-if="icon" class="material-icons mr-2 text-sm" v-html="icon"></div>
    <p v-if="myValue != ''" v-html="t(myValue)"></p>
    <p v-else class="rounded-full text-red-600 font-bold">{{ t('messages.missingField', { field: t(label) }) }}</p>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      label: { type: String, required: true },
      value: { required: true },
      icon: { type: String, required: false },
    },
    setup(props) {
      const myValue = computed(() => {
        if (props.value) if (props.value != null) if (props.value != '') if (props.value != 'undefined') return props.value;
        return '';
      });
      return { myValue, t: useI18n().t };
    },
  };
</script>

<style></style>
