<template>
  <base-page>
    <div v-if="rechargeInProgress && rechargeInProgress.length > 0">
      <recharges-inprogress-row
        v-for="el in rechargeInProgress"
        :key="el"
        :el="el"
        class="mb-4"
      />
    </div>
    <base-list-table
      :data="data"
      :structure="structure"
      :refreshFunction="refreshPage"
    />
  </base-page>
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";
import RechargesInprogressRow from "./StationRechargeInprogressRow.vue";

export default {
  components: {
    RechargesInprogressRow,
  },
  setup() {
    // INIT VUE THINGS
    const route = useRoute();
    const store = useStore();
    //  PREPARE ELEMENTS

    function refreshPage() {
      store.dispatch("getStationRechargeList", stationID);
      store.dispatch("getStationRechargeInProgress", stationID);
    }

    //  GET ELEMENTS
    const stationID = route.params.id;
    const rechargeList = computed(() => store.getters.stationRechargeList);
    const rechargeInProgress = computed(
      () => store.getters.stationRechargeInProgress
    );

    //structure
    const structure = {
      timeFields: [
        { label: "startDate", key: "timestamp" },
        { label: "endDate", key: "end_timestamp" },
      ],
      data: [
        {
          title: "views.recharges.session",

          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-400",
            },
            {
              name: "session_id",
            },
            {
              placeholder: "station.socket: ",
              name: "numero_presa",
            },
          ],
          link: { idField: "id", path: "/recharge/recharges/:id" },
          span: 1,
        },
        {
          title: "user.userDetails",
          fields: [
            {
              placeholder: "@",
              name: "user_id",
              class: "text-primary-400",
            },
            {
              name: "name",
            },

            {
              name: "surname",
            },
          ],
          span: 1,
          link: { idField: "user_id", path: "/user/users/:id" },
          class: "flex",
        },
        {
          title: "views.stations.rechargeStart",

          field: "inizio_ricarica",
          span: 1,
        },
        {
          title: "views.stations.rechargeEnd",

          field: "fine_ricarica",
          span: 1,
        },
        {
          title: "station.socket",

          field: "numero_presa",
          span: 1,
        },
        {
          label: "views.recharges.providedKWH",

          field: "kw_consumati",
          span: 1,
        },
        {
          title: "payments.totalAmount",

          field: "amount",
          format: "euro",
          span: 1,
        },
      ],
    };

    //  RETURN NECESSARY
    return {
      data: rechargeList,
      structure,
      rechargeInProgress,
      refreshPage,
    };
  },
};
</script>

<style></style>
