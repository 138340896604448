<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { computed, reactive } from 'vue';
  export default {
    setup() {
      const store = useStore();
      //get selected location
      const selectedType = computed(() => {
        return store.getters.selectedType;
      });
      const managers = computed(() => {
        return Utils.getNameArrayFromData(store.getters.managers);
      });
      const structure = {
        title: 'Info tipologia',
        edit_endpoint: 'updateScooterType',
        edit_title: '',
        data: [
          reactive({
            title: 'Gestore',
            name: 'manager',
            select: managers,
            placeholder: '--',
          }),
          {
            title: 'Nome',
            name: 'name',
            editable: true,
            placeholder: '--',
            statusOK: 'name',
          },
          {
            title: 'Prezzo al km',
            name: 'per_km',
            editable: true,
            placeholder: '0',
            format: 'euro',
          },
          {
            title: 'Prezzo al minuto',
            name: 'per_minute',
            editable: true,
            placeholder: '0',
            format: 'euro',
          },
          {
            title: 'Prezzo fascia 1-3 ore',
            name: '1_3',
            editable: true,
            placeholder: '0',
            format: 'euro',
          },
          {
            title: 'Prezzo fascia 3-6 ore',
            name: '3_6',
            editable: true,
            placeholder: '0',
            format: 'euro',
          },
          {
            title: 'Prezzo giornata completa',
            name: 'per_day',
            editable: true,
            placeholder: '0',
            format: 'euro',
          },
        ],
      };
      return {
        el: selectedType,
        structure,
      };
    },
  };
</script>

<style></style>
