<template>
  <base-page>
    <div v-if="el">
      <base-generals-page :el="el" :structure="structure" />
    </div>
  </base-page>
</template>

<script>
import { Utils } from "@/Utils/utils";
import { useStore } from "vuex";
import { computed, reactive } from "vue";

export default {
  setup() {
    const store = useStore();
    //get selected manager
    const selectedCar = computed(() => {
      return store.getters.selectedCar;
    });
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    const carTypes = computed(() => {
      return Utils.getNameArrayFromData(store.getters.carTypes);
    });
    const zoneGroups = computed(() => {
      return Utils.getNameArrayFromData(store.getters.zoneGroups);
    });
    //create structure
    const structure = {
      title: "Info auto",
      edit_endpoint: "updateCar",
      edit_title: "Modifica auto",
      data: [
        reactive({
          title: "Tipo",
          name: "model_name",
          select: carTypes,
          editable: true,
        }),
        reactive({
          title: "Gestore",
          name: "manager_name",
          db_name: "manager",
          select: managers,
          editable: true,
        }),
        reactive({
          title: "Gruppo di zone",
          name: "zone_group_name",
          db_name: "zone_group",
          select: zoneGroups,
          editable: true,
        }),
        {
          title: "Targa",
          name: "plate",
          editable: true,
        },
        {
          title: "Numero tracker",
          name: "tracker_imei",
          db_name: "id_veicolo",
          editable: true,
        },
        {
          title: "Latitudine",
          name: "lat",
        },
        {
          title: "Longitudine",
          name: "lng",
        },
        {
          title: "Manutenzione",
          name: "maintenance",
          editable: true,
          boolean: {
            if_false: "Disponibile",
            if_true: "In manutenzione",
          },
        },

        {
          title: "Stato motore",
          name: "engine_locked",
          boolean: {
            if_true: "Sbloccato",
            if_false: "Bloccato",
          },
          editable: true,
        },
        {
          title: "Data creazione",
          name: "created_at",
          format: "datetime",
        },
        {
          title: "Ultimo update",
          name: "updated_at",
          format: "datetime",
        },
      ],
    };

    return {
      el: selectedCar,
      structure,
    };
  },
};
</script>

<style>
</style>