export default {
  state: {
    currentPlatform: { db: null },
    menu: null,
  },
  getters: {
    currentPlatform: (state) => state.currentPlatform,
    menu: (state) => state.menu,
  },
  mutations: {
    setCurrentPlatform: (state, platform) => (state.currentPlatform = platform),
    setMenu: (state, menu) => (state.menu = menu),
  },
  actions: {
    setMenu: (ctx, payload) => ctx.commit('setMenu', payload),
    setCurrentPlatform: (ctx, platform) => {
      localStorage.platformObj = JSON.stringify(platform);
      ctx.commit('setCurrentPlatform', platform);
    },
  },
};
