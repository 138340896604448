<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage"></base-list-table>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();
      function refreshPage(payload) {
        store.dispatch('getAnomalies', payload);
      }
      const completeRechargeList = computed(() => store.getters.anomalies);

      const restoreAnomaly = (session_id) => store.dispatch('restoreAnomaly', { session_id: session_id });

      const structure = {
        title: 'views.recharges.anomaliesList',

        data: [
          {
            title: 'views.recharges.session',

            fields: [
              {
                name: 'session_id',
                class: 'text-primary-300',
              },
              {
                placeholder: 'Presa: ',
                name: 'numero_presa',
              },
            ],
            span: 1,
          },
          {
            title: 'views.recharges.chargingStation',

            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-primary-300',
              },
              {
                name: 'station_name',
              },
              {
                name: 'station_address',
              },
            ],
            span: 1,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'user.title',

            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-300',
              },
              {
                name: 'user_name',
              },
            ],
            span: 1,
            link: { idField: 'user_id', path: '/user/users/:id' },
          },
          {
            title: 'startDate',

            field: 'inizio_ricarica',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'kWh',
            field: 'consumed_energy',
            format: 'kwh',
            span: 1,
          },
          {
            title: 'views.recharges.anomalyType',

            field: 'issue_type',
            span: 1,
          },
          {
            title: '',
            button: {
              title: 'restore',

              class: 'bg-gray-400',
              field_returned: 'session_id',
              function: restoreAnomaly,
            },
            span: 1,
          },
        ],
      };
      return {
        data: completeRechargeList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
