<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive, onMounted } from "vue";
import { Utils } from "@/Utils/utils";

export default {
  setup() {
    const store = useStore();
    //init selects
    onMounted(() => store.dispatch("getManagers"));
    //get selected manager
    const selectedBike = computed(() => {
      return store.getters.selectedBike;
    });
    const bikeTypes = computed(() => {
      return Utils.getNameArrayFromData(store.getters.bikeTypes);
    });
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    const locations = computed(() => {
      return Utils.getNameArrayFromData(store.getters.locations);
    });
    const zoneGroups = computed(() => {
      return Utils.getNameArrayFromData(store.getters.zoneGroups);
    });
    const trackerPosition = [
      "",
      "INTERNO_CENTRALINA",
      "ESTERNO_SOTTO_CENTRALINA",
      "ESTERNO_TELAIO",
    ];
    const changeLocationByManager = function (event) {
      console.log("here", event.target.value);
      store.dispatch("getLocations", {
        manager: event.target.value,
      });
    };
    //create structure
    const structure = reactive({
      title: "Info bicicletta",
      edit_endpoint: "updateBike",
      edit_title: "Modifica bicicletta",
      data: [
        reactive({
          title: "Tipo",
          name: "model_name",
          db_name: "model_name",
          select: bikeTypes,
          editable: true,
        }),
        reactive({
          title: "Gestore",
          name: "manager_name",
          db_name: "manager",
          select: managers,
          editable: true,
          on_change: changeLocationByManager,
        }),
        reactive({
          title: "Struttura",
          name: "location",
          select: locations,
          editable: true,
        }),
        reactive({
          title: "Gruppo di zone",
          name: "zone_group_name",
          db_name: "zone_group",
          select: zoneGroups,
          editable: true,
        }),
        {
          title: "Targa",
          name: "plate",
          editable: true,
        },
        {
          title: "Telaio",
          name: "vin",
          db_name: "telaio",
          editable: true,
        },
        {
          title: "Numero tracker",
          name: "tracker_imei",
          db_name: "tracker",
          editable: true,
        },
        {
          title: "Posizione tracker",
          name: "tracker_slot",
          editable: true,
          select: trackerPosition,
        },
        {
          title: "Latitudine",
          name: "lat",
        },
        {
          title: "Longitudine",
          name: "lng",
        },
        {
          title: "Manutenzione",
          name: "maintenance",
          editable: true,
          boolean: {
            if_false: "Disponibile",
            if_true: "In manutenzione",
          },
        },
        {
          title: "Data creazione",
          name: "created_at",
          format: "datetime",
        },
        {
          title: "Ultima modifica",
          name: "updated_at",
          format: "datetime",
        },
      ],
    });
    return {
      el: selectedBike,
      structure,
    };
  },
};
</script>

<style>
</style>