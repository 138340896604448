import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    rechargeInProgressList: [],
    eCarRecharges: [],
  },
  getters: {
    rechargeInProgressList: (state) => state.rechargeInProgressList,
    eCarRecharges: (state) => state.eCarRecharges,
  },
  mutations: {
    getRechargesInProgressList: (state, data) => (state.rechargeInProgressList = data),
    getEcarRecharges: (state, data) => (state.eCarRecharges = data),
  },
  actions: {
    getEcarRecharges: (ctx, payload) => {
      ApiCaller.GET(`/recharge/scip/ecar`, payload).then((data) => ctx.commit('getEcarRecharges', Utils.createFiltrableData(data)));
    },
    getRechargesInProgressList: (ctx, payload) => {
      ApiCaller.GET(`/rechargesInProgress`, payload).then((data) => ctx.commit('getRechargesInProgressList', Utils.createFiltrableData(data)));
    },
    forcedShutdownRecharge: (_, payload) => ApiCaller.POST(`/recharge/scip/remoteStop/${payload.evse_id}/${payload.session_id}`),
    fixAnomalies: (_, payload) => ApiCaller.PUT(`/recharge/saveAnomaly`, payload),
    updateRechargeParameters: (_, payload) => ApiCaller.PUT(`/recharge/updateRechargeData`, payload),
    getEcarRecharge: (_, payload) => ApiCaller.GET(`/recharge/scip/ecar/${payload.id}`, payload),
    sendCdr: (_, sessionId) => ApiCaller.POST(`/station/recharges/${sessionId}/sendCdr`, null),
    addSessionId: (_, payload) => ApiCaller.POST(`/station/recharges/${payload.evseId}/addSessionId`, payload),
  },
};
