import { ApiCaller, Utils } from '@/Utils/utils';
export default {
  state: {
    scooters: [],
    selectedScooter: null,
    scooterTypes: null,
  },
  getters: {
    scooters: (state) => state.scooters,
    selectedScooter: (state) => {
      if (state.selectedScooter) return state.selectedScooter[0];
      return state.selectedScooter;
    },
    scooterTypes: (state) => state.scooterTypes,
  },
  mutations: {
    getScooters: (state, data) => (state.scooters = data),
    setSelectedScooter: (state, data) => (state.selectedScooter = data),
    getScooterTypes: (state, data) => (state.scooterTypes = data),
  },
  actions: {
    getScooters: (ctx, payload) =>
      ApiCaller.GET('/vehicles/scooter', payload).then((data) => {
        ctx.commit('getScooters', Utils.createFiltrableData(data));
      }),
    setSelectedScooter: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/scooter/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedScooter', data);
      }),
    getScooterTypes: (ctx, payload) =>
      ApiCaller.GET('/vehicle_types', { vehicleType: 'scooter', ...payload }).then((data) => {
        ctx.commit('getScooterTypes', Utils.createFiltrableData(data));
      }),
    //QUERIES
    updateScooter: (_, payload) => ApiCaller.PUT(`/vehicles/scooter/${payload.id}`, payload.body),
    createScooter: (_, payload) => ApiCaller.POST(`/vehicles/scooter`, payload),
    createScooterType: (_, payload) => ApiCaller.POST(`/vehicles/scooter_type`, { type: 'scooter', ...payload }),
    updateScooterType: (_, payload) => ApiCaller.PUT(`/vehicles/type/${payload.id}`, { type: 'scooter', ...payload.body }),
  },
  modules: {},
};
