<template>
  <base-page>
    <base-list-table
      :data="data"
      :structure="structure"
      :refreshFunction="refreshPage"
    />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = (payload) =>
      store.dispatch("getLocationVehicles", {
        location: route.params.locationId,
        ...payload,
      });

    const vehicles = computed(() => store.getters.locationVehicles);

    const structure = {
      data: [
        {
          title: "user.name.first",

          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-400",
            },
            {
              name: "plate",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: { idField: "id", type: "vehicle" },
        },
        {
          title: "views.vehicles.type",

          fields: [
            {
              placeholder: "@",
              name: "model_id",
              class: "text-primary-400",
            },
            {
              name: "model_name",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: { idField: "id", type: "vehicle" },
        },
        {
          title: "views.vehicles.vin",

          field: "vin",
          span: 1,
        },
        {
          title: "views.vehicles.linkedManager",

          fields: [
            {
              placeholder: "@",
              name: "manager_id",
              class: "text-primary-400",
            },
            {
              name: "manager_name",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: { idField: "manager_id", path: "/sharing/managers/:id" },
        },
        {
          title: "status",

          field: "maintenance",
          boolean: {
            if_false: "Disponibile",
            if_true: "In manutenzione",
          },
          span: 1,
        },
      ],
    };

    return {
      data: vehicles,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>