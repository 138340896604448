<template>
  <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage() {
        store.dispatch('getAlarmStatusTypes', store.getters.currentPlatform.db);
      }

      const alarmStatusTypesList = computed(() => store.getters.alarmStatusTypes);

      const structure = {
        title: 'views.alarmStatusTypes.alarmStatusTypesList',
        creationLink: {
          title: 'buttons.createAlarmStatusTypes',
          namePath: 'menu.alarm.alarmStatesCreation',
          translate: false,
        },
        data: [
          {
            title: 'Id',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/alarm/alarmStates/:id' },
          },
          {
            title: 'user.name.first',
            fields: [
              {
                name: 'name',
              },
            ],
            span: 1,
          },
        ],
      };

      return {
        completeList: alarmStatusTypesList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
