<template>
  <div v-if="selectedDevice" class="flex flex-col justify-center items-start py-4">
    <h1 class="text-3xl font-bold text-gray-900">
      {{ selectedDevice.imei }}
      <text class="text-gray-400 text-base"></text>
    </h1>
    <h2 class="text-lg font-medium text-gray-400">{{ selectedDevice.configuration.name }} - [{{ selectedDevice.configuration.producer }}]</h2>
    <h3 class="text-lg font-medium text-blue-800">Revisione {{ selectedDevice.configuration.firmware_version }}</h3>
    <h3 class="text-lg font-medium text-gray-800 mt-6">
      Configurazione accessibile a
      <a :href="url" target="_blank" class="text-blue-600 underline">{{ url }}</a>
    </h3>
  </div>
  <base-subpage-bar :headers="headers" />
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      const store = useStore();
      const route = useRoute();

      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedDevice', selectedElID);

      const selectedDevice = computed(() => store.getters.selectedDevice);

      const headers = [
        { text: 'paths.configuration', name: 'menu.device.deviceConfiguration' },
        { text: 'paths.overall', name: 'menu.device.deviceGenerals' },
        { text: 'paths.commands', name: 'menu.device.deviceCommands' },
        { text: 'paths.messages', name: 'menu.device.deviceMessages' },
        { text: 'paths.events', name: 'menu.device.deviceEvents' },
      ];

      const url = computed(() => {
        if (!selectedDevice.value) return '';
        let u = `https://device.api.onme.cloud/device/${selectedDevice.value.imei}/configuration/file`;
        return u;
      });

      const headerStructure = {
        title: [{ title: 'imei' }],
        data: [
          { name: 'id', placeholder: '@' },
          {
            title: 'configuration',
            name: 'configuration',
            subName: 'name',
            link: { idField: 'id', subField: 'configuration', path: '/device/deviceConfigurations/details/:id' },
          },
        ],
      };

      return {
        url,
        selectedDevice,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
