<template>
  <div
    class="
      h-full
      w-full
      absolute
      top-0
      left-0
      flex
      place-content-center place-items-center
    "
    @click.self="onClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
    props:["onClick"],
};
</script>

<style>
</style>