<template>
  <span class="w-full my-2 flex flex-col">
    <h3 class="w-full text-left text-lg font-semibold text-white dark:text-white mt-6">{{ $t('settings.language.title') }}</h3>
    <h3 class="w-full text-left text-base font-semibold text-white dark:text-white my-2">{{ $t('settings.language.languageSelect') }}</h3>
    <base-select title="" v-model="locale" :data="locales" />
  </span>
</template>

<script>
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  export default {
    emits: ['focus', 'blur'],
    setup() {
      const i18n = useI18n();
      const locale = computed(() => i18n.locale);
      watch(locale.value, (nv) => localStorage.setItem('locale', nv));
      const store = useStore();
      const locales = computed(() => store.getters.localesList);
      return {
        locales,
        locale: i18n.locale,
      };
    },
  };
</script>

<style></style>
