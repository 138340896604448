<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      //init store
      const store = useStore();
      const selectedAlarmType = computed(() => {
        return store.getters.selectedAlarmType;
      });
      const structure = {
        title: 'Info Tipo Allarme',
        data: [
          {
            title: 'user.name.first',
            name: 'name',
            statusOK: 'name',
            placeholder: '--',
          },
        ],
      };
      return {
        el: selectedAlarmType,
        structure,
      };
    },
  };
</script>

<style></style>
