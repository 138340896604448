<template>
  <div>
    <base-button @click="panels.openSendInvoiceModel = true">{{ $t('billing.resendInvoice') }}</base-button>
    <div class="flex justify-center">
      <!-- card info utente -->
      <div class="w-1/5 p-4 m-2 overflow-hidden hover:overflow-visible hover:z-50">
        <div class="font-bold text-lg p-2">
          <p>{{ $t('billing.userInfo') }}</p>
        </div>
        <div class="border-t border-t-gray-400">
          <div class="mb-2">
            <div class="text-gray-500">{{ $t('billing.accountDetails') }}</div>
            <div>{{ payment?.user?.name }}</div>
            <div>{{ payment?.user?.surname }}</div>
          </div>
          <div class="mb-2">
            <div class="text-gray-500">{{ $t('billing.contactDetails') }}</div>
            <div>{{ payment?.user?.email }}</div>
            <div>{{ payment?.user?.cellphone }}</div>
          </div>
        </div>
      </div>
      <!-- card info profilo fatturazione -->
      <div class="w-4/5 p-4 m-2">
        <div class="font-bold text-lg p-2 flex justify-between">
          <p>{{ $t('billing.billingProfile') }}</p>
          <button
            class="material-icons text-gray-500 shadow-md px-1 rounded-lg"
            @click="panels.changeBillingProfilePanel = true">
            edit
          </button>
        </div>
        <div class="border-t border-t-gray-400">
          <div class="grid grid-cols-2">
            <div class="text-gray-500">{{ $t('billing.type') }}</div>
            <div>{{ payment?.billingProfile?.profile_type }}</div>
          </div>
          <div class="grid grid-cols-2">
            <div class="text-gray-500">{{ $t('billing.name') }}</div>
            <div>{{ payment?.billingProfile?.name }}</div>
          </div>
          <div class="grid grid-cols-2">
            <div class="text-gray-500">{{ $t('billing.fiscalCode') }}</div>
            <div>{{ payment?.billingProfile?.fiscal_code }}</div>
          </div>
          <div class="grid grid-cols-2">
            <div class="text-gray-500">{{ $t('billing.address') }}</div>
            <div>
              {{ payment?.billingProfile?.country }}, {{ payment?.billingProfile?.city }},
              {{ payment?.billingProfile?.line1 }} ({{ payment?.billingProfile?.state }})
            </div>
          </div>
          <div class="grid grid-cols-2">
            <div class="text-gray-500">{{ $t('billing.postalCode') }}</div>
            <div>{{ payment?.billingProfile?.postal_code }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- card info pagamento -->
    <div class="p-4 m-2">
      <div class="font-bold text-lg p-2">
        <p>{{ $t('billing.paymentDetails') }}</p>
      </div>
      <div class="border-t border-t-gray-400">
        <div class="grid grid-cols-2">
          <div class="text-gray-500">{{ $t('billing.invoiceNumber') }}</div>
          <div>{{ payment?.invoice_number }}</div>
        </div>
        <div class="grid grid-cols-2 my-2">
          <div class="text-gray-500">{{ $t('billing.description') }}</div>
          <div>{{ payment?.description }}</div>
        </div>
        <div class="grid grid-cols-2 my-2">
          <div class="text-gray-500">{{ $t('billing.createdAt') }}</div>
          <div>{{ formatData(payment?.created_at, 'datetime_ignore&parse') }}</div>
        </div>
        <!-- products -->
        <div class="my-2">
          <div class="text-gray-500">{{ $t('billing.products') }}</div>
          <div class="w-3/4 my-2">
            <div class="border-b border-b-gray-300 grid grid-cols-5 text-xs uppercase font-bold">
              <p>{{ $t('billing.description') }}</p>
              <p>{{ $t('billing.quantity') }}</p>
              <p>{{ $t('billing.price') }}</p>
              <p>{{ $t('billing.total') }}</p>
              <p>{{ $t('billing.createdAt') }}</p>
            </div>
            <div v-for="prod in payment?.products" :key="prod" class="py-2 px-4">
              <div class="border-b border-b-gray-200 grid grid-cols-5">
                <p class="overflow-hidden hover:overflow-visible hover:z-50 hover:bg-white">
                  {{ prod?.description }}
                </p>
                <p>{{ prod?.quantity }} {{ prod?.measure_unit }}</p>
                <p>{{ prod?.price }}</p>
                <p>{{ prod?.total }}</p>
                <p>{{ formatData(prod?.created_at, 'datetime_ignore&parse') }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- card info transaction -->
        <div class="my-2">
          <div class="text-gray-500">{{ $t('billing.transaction') }}</div>
          <div class="w-3/4 my-2">
            <div class="border-b border-b-gray-300 grid grid-cols-4 text-xs uppercase font-bold">
              <p>{{ $t('billing.quantity') }}</p>
              <p>{{ $t('billing.success') }}</p>
              <p>{{ $t('billing.typology') }}</p>
              <p>{{ $t('billing.createdAt') }}</p>
            </div>
            <div v-for="transaction in payment?.transactions" :key="transaction" class="py-2 px-4">
              <div class="border-b border-b-gray-200 grid grid-cols-4">
                <p>{{ transaction?.amount }}</p>
                <p>{{ transaction?.success == 1 ? 'OK' : 'NEGATA' }}</p>
                <p>{{ transaction?.payment_method_type }}</p>
                <p>{{ formatData(transaction?.created_at, 'datetime_ignore&parse') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pannello di invio fattura -->
    <div
      v-if="panels.openSendInvoiceModel"
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div class="bg-white rounded-lg shadow-lg z-50">
        <div class="p-4">
          <!-- Inserisci qui il contenuto del pannello di invio fattura -->
          <div>
            <div class="flex justify-center mb-4">
              <p class="text-lg font-bold">{{ $t('billing.sendInvoiceModelHeader') }}</p>
            </div>
            <p class="text-sm">{{ $t('billing.sendInvoiceModelBody') }}</p>
          </div>
        </div>
        <div class="p-4 flex justify-end">
          <button @click="panels.openSendInvoiceModel = false" class="px-2 py-1 rounded-lg border shadow-sm">
            {{ $t('billing.close') }}
          </button>
          <button
            @click="sendInvoice"
            class="px-2 py-1 ml-2 rounded-lg border border-primary-800 shadow-sm text-gray-100 bg-primary-600">
            {{ $t('billing.send') }}
          </button>
        </div>
      </div>
    </div>

    <!-- pannello di cambio profilo di fatturazione -->
    <div
      v-if="panels.changeBillingProfilePanel"
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div class="bg-white rounded-lg shadow-lg z-50 w-full md:max-w-md sm:max-w-sm max-w-xs">
        <div>
          <!-- Inserisci qui il contenuto del pannello di invio fattura -->
          <div class="p-4">{{ $t('billing.updateBillingProfile') }}</div>
          <div class="bg-gray-100 border p-4">
            <div>
              <p class="my-2">{{ $t('billing.name') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.name"
                v-model="billingProfileForm.name" />
            </div>
            <div>
              <p class="my-2">{{ $t('billing.fiscalCode') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.fiscal_code"
                v-model="billingProfileForm.fiscal_code" />
            </div>
            <div>
              <p class="my-2">{{ $t('billing.street') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.line1"
                v-model="billingProfileForm.line1" />
            </div>

            <div>
              <p class="my-2">{{ $t('billing.city') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.city"
                v-model="billingProfileForm.city" />
            </div>
            <div>
              <p class="my-2">{{ $t('billing.postalCode') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.postal_code"
                v-model="billingProfileForm.postal_code" />
            </div>
            <div>
              <p class="my-2">{{ $t('billing.state') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.state"
                v-model="billingProfileForm.state" />
            </div>
            <div>
              <p class="my-2">{{ $t('billing.country') }}</p>
              <input
                type="text"
                class="text-sm rounded-lg border-gray-400 w-full py-1"
                :placeholder="payment?.billingProfile?.country"
                v-model="billingProfileForm.country" />
            </div>
          </div>
        </div>
        <div class="p-4 flex justify-end text-sm">
          <button @click="panels.changeBillingProfilePanel = false" class="px-2 py-1 rounded-lg border shadow-sm">
            {{ $t('billing.cancel') }}
          </button>
          <button
            @click="updateBillingProfile"
            class="px-2 py-1 ml-2 rounded-lg border border-primary-800 shadow-sm text-gray-100 bg-primary-600">
            {{ $t('billing.update') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { Utils } from '@/Utils/utils';
  import { useRoute } from 'vue-router';
  import router from '@/router/router';

  export default {
    setup() {
      const route = useRoute();
      const store = useStore();
      const payment = computed(() => store.getters.selectedPayment);

      const formatData = Utils.formatData;

      const panels = reactive({
        sendInvoicePanel: false,
        changeBillingProfilePanel: false,
      });

      const sendInvoice = () => store.dispatch('sendInvoice', { id: route.params?.id });

      const billingProfileForm = reactive({
        name: '',
        fiscal_code: '',
        line1: '',
        city: '',
        postal_code: '',
        state: '',
        country: '',
      });

      const updateBillingProfile = () => {
        store
          .dispatch('updateBillingProfile', { id: payment.value?.billingProfile?.id, body: billingProfileForm })
          .then(() => {
            panels.changeBillingProfilePanel = false;
            router.go();
          });
      };

      return { payment, panels, billingProfileForm, updateBillingProfile, sendInvoice, formatData };
    },
  };
</script>
