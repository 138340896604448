<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();
      const selectedVehicle = computed(() => store.getters.selectedVehicle);
      function refreshPage() {
        if (selectedVehicle.value && selectedVehicle.value.device) store.dispatch('getEvents', { id: selectedVehicle.value.device.id });
      }
      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);

      const events = computed(() => store.getters.events);
      const structure = {
        title: 'vehicles.events',
        data: [
          {
            title: 'Etichetta',
            field: 'label',
            span: 1,
          },
          {
            title: 'Viaggi',
            field: 'trips',
            span: 1,
          },
          {
            title: 'Creazione',
            field: 'created_at',
            format: 'datetime',
            span: 1,
            class: 'text-style',
          },
          {
            title: 'ID',
            field: 'event_id',
            span: 1,
            class: 'text-style',
          },
        ],
      };

      return {
        data: events,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
