<template>
  <base-page>
    <base-generals-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { computed, reactive } from 'vue';
  export default {
    setup() {
      const store = useStore();
      //get selected location
      const selectedStation = computed(() => {
        return store.getters.selectedLocation;
      });
      const managers = computed(() => Utils.getNameArrayFromData(store.getters.managers));

      const structure = {
        title: 'views.locations.infos',

        edit_endpoint: 'updateLocation',
        edit_title: 'Modifica struttura',
        data: [
          {
            title: 'user.name.first',

            name: 'name',
            editable: true,
            placeholder: '--',
            statusOK: 'name',
          },
          reactive({
            title: 'user.types.manager',

            name: 'manager',
            select: managers,
            editable: true,
          }),
          {
            title: 'address.body',

            name: 'address',
            editable: true,
            placeholder: '--',
          },
          {
            title: 'address.latitude',

            name: 'latitude',
            editable: true,
            placeholder: '0.000 NB. NON USARE LA VIRGOLA',
          },
          {
            title: 'address.longitude',

            name: 'longitude',
            editable: true,
            placeholder: '0.000 NB. NON USARE LA VIRGOLA',
          },
          {
            title: 'Email',
            name: 'email',
            editable: true,
            placeholder: '--',
          },
          {
            title: 'user.cellphone',

            name: 'cellphone',
            editable: true,
            placeholder: '--',
          },
          {
            title: 'address.zipcode',

            name: 'zipcode',
            editable: true,
            placeholder: '--',
          },
          {
            title: 'user.vat',

            name: 'vat',
            editable: true,
            placeholder: '--',
          },
          {
            title: 'views.timeEntries.creationDate',

            name: 'created_at',
            format: 'datetime',
            placeholder: '--',
          },
          {
            title: 'views.timeEntries.lastUpdateDate',

            name: 'update_timestamp',
            format: 'datetime',
            placeholder: '--',
          },
          {
            title: 'user.notes',

            name: 'notes',
            placeholder: '--',
            editable: true,
          },
          {
            title: 'status',

            name: 'open',
            editable: true,
            boolean: {
              if_true: 'views.locations.opened',
              if_false: 'views.locations.closed',
            },
          },
        ],
      };
      return {
        el: selectedStation,
        structure,
      };
    },
  };
</script>

<style></style>
