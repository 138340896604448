<template>
  <base-subpage-bar :headers="headers" />
  <router-view></router-view>
</template>
<script>
  export default {
    setup() {
      return {
        headers: [
          { text: 'views.recharge.stationsList', name: 'menu.recharge.stationsList' },
          { text: 'views.recharge.stationsSiemensList', name: 'menu.recharge.siemensList' },
          { text: 'views.recharge.stationsDismissed', name: 'menu.recharge.dismissedList' },
        ],
      };
    },
  };
</script>
