<template>
  <div class="select-none relative transition duration-500 ease-in-out flex flex-col justify-center items-center h-10 bg-white dark:bg-gray-900 shadow-sm rounded-lg w-full">
    <input type="search" class="h-full rounded-lg py-2 pl-4 pr-20 w-full text-ellipsis overflow-x-hidden text-sm text-gray-900 dark:text-white font-semibold placeholder-gray-700 dark:placeholder-gray-300 bg-white dark:bg-gray-700 focus:placeholder-gray-400 dark:focus:placeholder-white outline-none border border-gray-300 dark:border-gray-700 focus:border-primary-300 dark:border-gray-700 focus:ring-0" id="default-search" :placeholder="t('search')" :value="searchString" @input="updateValue" />
    <div class="absolute right-2 top-0 h-10 w-10 flex justify-center items-center text-gray-500 dark:text-white material-icons">search</div>
    <div @click="toggleCaseSensitivity" class="text-xl absolute transition-all duration-500 ease-in-out right-12 rounded-md cursor-pointer top-0 h-6 my-2 w-6 flex justify-center items-center material-icons" alt="Clicca per abilitare il controllo case-sensitive" :class="{ 'text-primary-600 dark:text-white bg-primary-100 dark:bg-gray-50 dark:bg-gray-8000': caseSensitive, 'text-gray-500 dark:text-white bg-white  dark:bg-gray-700': !caseSensitive }">spellcheck</div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      searchString: String,
      caseSensitive: Boolean,
    },
    setup(props, ctx) {
      const updateValue = (event) => {
        ctx.emit('update:searchString', event.target.value);
      };

      const toggleCaseSensitivity = () => {
        ctx.emit('update:caseSensitive', !props.caseSensitive);
      };

      return { updateValue, toggleCaseSensitivity, t: useI18n().t };
    },
  };
</script>

<style></style>
