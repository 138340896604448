<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
  export default {
    setup() {
      const insertData = {
        title: 'Inserimento nuova traduzione',
        redirect_path: '/languages/add/',
        create_endpoint: 'createLocales',
        creation_title: 'Creazione Traduzione',
        data: [
          {
            title: 'Identificatore',
            placeholder: 'main.example.title',
            name: 'key',
            required: true,
            class: 'col-span-4',
          },
          {
            title: 'Italiano',
            placeholder: 'Esempio',
            name: 'it',
            required: true,
          },
          {
            title: 'English',
            placeholder: 'Example',
            name: 'en',
            required: true,
          },
          {
            title: 'Français',
            placeholder: 'Exemple',
            name: 'fr',
            required: false,
          },
          {
            title: 'Deutsch',
            placeholder: 'Beispiel',
            name: 'de',
            required: false,
          },
          {
            title: '简体中文',
            placeholder: '例子',
            name: 'zh',
            required: false,
          },
          {
            title: 'Variabili',
            placeholder: 'Casi eccezionali',
            name: 'variables',
            required: false,
          },
        ],
      };
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
