import { ApiCaller } from '@/Utils/utils';
/* eslint-disable */
export default {
  state: {
    locales: {},
    sublocales: {},
    localesList: [],
    sublocalesList: [],
    platformsLocales: {},
    currentPlatformLocales: '',
    currentLocale: localStorage.locale || 'it',
  },
  getters: {
    locales: (state) => state.locales,
    sublocales: (state) => state.sublocales,
    currentLocale: (state) => state.currentLocale,
    platformsLocales: (state) => state.platformsLocales,
    localesList: (state) => state.localesList,
    sublocalesList: (state) => state.sublocalesList,
    currentPlatformLocales: (state) => state.currentPlatformLocales,
  },
  mutations: {
    setLocales: (state, payload) => (state.locales = payload),
    setSublocales: (state, payload) => (state.sublocales = payload),
    setPlatformsLocales: (state, payload) => (state.platformsLocales = payload),
    localesList: (state, payload) => {
      const localesList = [];
      Object.keys(payload).forEach((key) => {
        let lang = '';
        switch (key) {
          case 'it':
            lang = 'Italiano';
            break;
          case 'en':
            lang = 'English';
            break;
          case 'fr':
            lang = 'Français';
            break;
          case 'de':
            lang = 'Deutsch';
            break;
          case 'zh':
            lang = '简体中文';
            break;
          case 'dev':
            lang = 'Sviluppo (provvisoria)';
            break;
          default:
            lang = key;
            break;
        }
        localesList.push({
          text: lang,
          value: key,
        });
      });
      state.localesList = localesList;
    },
    sublocalesList: (state, payload) => {
      const sublocalesList = [];
      if (payload !== undefined) {
        Object.keys(payload).forEach((key) => {
          sublocalesList.push(key);
        });
        state.sublocalesList = sublocalesList;
      }
    },
    currentPlatformLocale: (state, payload) => {
      state.currentPlatformDB = state.platformList.filter((platform) => {
        platform.id = payload;
      })[0];
    },
  },
  actions: {
    retrieveLocales: async (context, payload) => {
      const platform = payload ? (typeof payload === 'object' ? payload.platform : payload) : 'ON';
      const platformString = typeof payload === 'object' ? payload.platform : platform;
      if (platformString !== undefined) {
        return new Promise((resolve, reject) => {
          ApiCaller.GET(process.env.VUE_APP_SERVER_PATH + `/locales/${platformString}`).then((data) => {
            if (data !== undefined && !parseInt(data.error_code)) {
              if (typeof payload === 'object' && Object.keys(payload).includes('type') && payload['type'] === 'sub') {
                context.commit('setSublocales', data);
                context.commit('sublocalesList', data);
              } else {
                context.commit('setLocales', data);
                context.commit('localesList', data);
              }
              resolve(true);
            } else reject(false);
          });
        });
      }
    },
    retrievePlatformsLocales: (context) => {
      ApiCaller.GET(`/platforms-locales`).then((data) => context.commit('setPlatformsLocales', data));
    },
    createLocales: (_, payload) => {
      ApiCaller.POST(process.env.VUE_APP_SERVER_PATH + `/locales`, payload);
    },
    updateLocale: (_, payload) => {
      const platformId = payload.platformId;
      const locale = payload.locale;
      if (platformId && locale) {
        ApiCaller.PATCH(process.env.VUE_APP_SERVER_PATH + `/locale/${platformId}/${locale}`, payload.body).then((data) => {
          console.log(data);
        });
      }
    },
    deleteLocaleCustomizations: (_, payload) => {
      const platformId = payload.id;
      const locale = payload.locale;
      ApiCaller.DELETE(process.env.VUE_APP_SERVER_PATH + `/locale-customizations/${platformId}` + (locale !== undefined ? `/${locale}` : ``), payload.body).then((data) => {
        console.log('deleteLocaleCustomizations', data);
      });
    },
    deleteLocale: (_, payload) => {
      const platformId = payload.id;
      const locale = payload.locale;
      ApiCaller.DELETE(process.env.VUE_APP_SERVER_PATH + `/locale/${platformId}/${locale}`, payload.body).then((data) => {
        console.log('deleteLocale', data);
      });
    },
  },
};
