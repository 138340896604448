<template>
  <div v-if="data && data.length > 0" class="flex">
    <div>
      <div
        v-for="command in data"
        :key="command.id"
        class="bg-white p-4 m-2 rounded-lg"
      >
        <div class="text-center">
          <p class="text-xs text-gray-500 font-thin">{{ command.name }}</p>
          <p class="text-lg">{{ command.description }}</p>
        </div>
        <div class="mt-4">
          <base-button class="w-full">{{ t("buttons.send") }}</base-button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>{{ t("commands.zeroResult") }}</p>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    //  INIT
    const store = useStore();
    const route = useRoute();

    //  ASSIGN ELEMENTS
    const selectedElID = { id: route.params.id };
    const selectedDevice = computed(() => store.getters.selectedDevice);
    onMounted(() => {
      if (selectedDevice.value && selectedDevice.value.configuration) {
        store.dispatch("getDeviceCommands", {
          id: selectedDevice.value.configuration.id,
        });
      }
    });

    watch(selectedDevice, () => {
      if (selectedDevice.value && selectedDevice.value.configuration) {
        store.dispatch("getDeviceCommands", {
          id: selectedDevice.value.configuration.id,
        });
      }
    });

    const commands = computed(() => store.getters.deviceCommands);

    const sendCommand = () =>
      store.dispatch("sendCommand", { ...selectedElID });

    return {
      data: commands,
      sendCommand,
      t: useI18n().t,
    };
  },
};
</script>

<style>
.blue {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
.gray {
  color: #000;
}
.text-select {
  text-transform: lowercase;
}
</style>
