import { ApiCaller, Utils } from "@/Utils/utils";

export default {
  state: {
    trackers: [],
    selectedTracker: null,
    assignableTrackers: [],
  },
  getters: {
    trackers: (state) => state.trackers,
    selectedTracker: (state) =>
      state.selectedTracker ? state.selectedTracker[0] : null,
    assignableTrackers: (state) => state.assignableTrackers,
  },
  mutations: {
    setTrackers: (state, data) => (state.trackers = data),
    selectedTracker: (state, data) => (state.selectedTracker = data),
    setAssignableTrackers: (state, data) => (state.assignableTrackers = data),
  },
  actions: {
    getTrackers: (ctx, payload) =>
      ApiCaller.GET("/tracker", payload).then((data) => {
        ctx.commit("setTrackers", Utils.createFiltrableData(data));
      }),
    getAssignableTrackers: (ctx, payload) => {
      ctx.commit("setAssignableTrackers", []);
      ApiCaller.GET("/assignableTracker", payload).then((data) => {
        ctx.commit("setAssignableTrackers", data);
      });
    },
    createTracker: (_, payload) => ApiCaller.POST("/tracker", payload),
    updateTracker: (_, payload) =>
      ApiCaller.PUT(`/tracker/${payload.id}`, payload.body),

    //cappadonna api
    setSelectedTracker: (ctx, payload) =>
      ApiCaller.GET(`/tracker/scip/${payload.id}/getLog`, payload).then(
        (data) => ctx.commit("selectedTracker", data)
      ),
    trackerOn: (_, payload) =>
      ApiCaller.PUT(`/tracker/scip/${payload.id}/trackerOn`),
    trackerOff: (_, payload) =>
      ApiCaller.PUT(`/tracker/scip/${payload.id}/trackerOff`),
    openTopbox: (_, payload) =>
      ApiCaller.PUT(`/tracker/scip/${payload.id}/openTopbox`),
    openDoor: (_, payload) =>
      ApiCaller.PUT(`/tracker/scip/${payload.id}/openDoor`),
    closeDoor: (_, payload) =>
      ApiCaller.PUT(`/tracker/scip/${payload.id}/closeDoor`),
  },
};
