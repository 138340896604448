<template>
  <div class="mr-2">
    <router-link v-if="selected" class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 dark:text-gray-300 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" :to="name ? { name: name } : path" :class="{ 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500': selected }">
      <slot></slot>
    </router-link>
    <router-link v-else class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 dark:text-gray-300 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" :to="name ? { name: name } : path" :class="{ 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500': selected }">
      <slot></slot>
    </router-link>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: false,
      },
      path: {
        required: false,
      },
      selected: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<style></style>
