<template>
  <div class="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
    <dl class="mt-6 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-6 sm:grid-cols-5">
      <div v-if="message?.IMEI">
        <dt class="text-sm font-semibold leading-2 text-gray-600">IMEI</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">
          {{ message.IMEI }}
        </dd>
      </div>
      <div v-if="message?.message?.timestamp" class="col-span-2">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Timestamp</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">
          {{ formatDateTime(message?.message?.timestamp) }}
        </dd>
      </div>
      <div v-if="message?.message?.priority">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Priority</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">
          {{ message?.message?.priority }}
        </dd>
      </div>
    </dl>
    <dl class="mt-6 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-6 sm:grid-cols-5">
      <div v-if="message?.message?.gps">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Coordinate</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">
          {{ parseFloat(message?.message?.gps.latitude).toFixed(7) }}, {{ parseFloat(message?.message?.gps.longitude).toFixed(7) }}
        </dd>
      </div>
      <div v-if="message?.message?.gps">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Satelliti</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">
          {{ message?.message?.gps.satellites }}
        </dd>
      </div>
      <div v-if="message?.message?.gps">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Angle</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">{{ parseInt(message?.message?.gps.angle * 0.00390625) }} °</dd>
      </div>
      <div v-if="message?.message?.gps">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Altitudine</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">{{ parseInt(message?.message?.gps.altitude / 100) }} m</dd>
      </div>
      <div v-if="message?.message?.gps">
        <dt class="text-sm font-semibold leading-2 text-gray-600">Velocità</dt>
        <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900">{{ parseInt(message?.message?.gps.speed * 0.00390625) }} km/h</dd>
      </div>
    </dl>
    <dl class="mt-6 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-6 sm:grid-cols-4">
      <template v-for="(element, idx) in message?.message?.ioElements || []">
        <div v-if="element.id" :key="idx">
          <dt class="text-sm font-semibold leading-2 text-gray-600">{{ element.label || 'ID:' + element.id }} [{{ element.id }}]</dt>
          <dd class="mt-1 text-2xl font-bold leading-6 tracking-tight text-gray-900" v-html="parseData(element)"></dd>
        </div>
      </template>
    </dl>
    <div class="mt-1 hidden">{{ message }}</div>
  </div>
</template>

<script>
  import { Utils } from '@/Utils/utils';
  export default {
    props: {
      message: {
        type: Object,
        default: () => null,
      },
    },
    setup() {
      const parseData = (element) => {
        switch (element.id) {
          case 239: // Ignition
          case 179: // Dout1
          case 180: // Dout2
          case 1000: // Funzione DPF attiva
          case 1007: // MemoryRPMStatus
          case 1009: // MemoCruiseControlStatus
          case 1011: // InstantCruiseControl
            return element.value ? '<span class="text-green-500">ON</span>' : '<span class="text-red-500">OFF</span>';
          case 1006: // Engine Load
            return parseInt(element.value) + ' %';
          case 1004: // Engine RPM
          case 1008: // Engine RPM Cruise
            return parseInt(element.value * 0.125) + ' RPM';
          case 1010: // Memo Cruise Control Value
            return parseFloat(element.value * 0.00390625).toFixed(0);
          case 1012: // Vehicle Speed
            return parseFloat(element.value * 0.00390625).toFixed(0) + ' km/h';
          case 1005: // Consumption
            return parseFloat(element.value * 0.05).toFixed(1) + ' l/h';
          case 1002: // Tensione batteria, 2 decimali
            return parseFloat(element.value * 0.05).toFixed(2) + ' V';
          case 1003: // Temperatura refigerante, intero
            return parseInt(element.value) + ' °C';
          case 9: // Analogico 1
            return parseFloat(element.value * 0.05).toFixed(2);
          default:
            return `<span class="text-gray-200">${element.value + ' ' + (element.dimension ? element.dimeion : '')}</span>`;
        }
      };

      return { parseData, formatDateTime: Utils.formatDate };
    },
  };
</script>
