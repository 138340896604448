<template>
  <base-page>
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </base-page>
</template>

<script>
  import { watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const refreshPage = () => store.dispatch('getTripCommands', { id: route.params.tripId });
      watch(() => route.params.tripId, refreshPage);
      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);
      const commands = computed(() => store.getters.tripCommands);
      const structure = {
        title: 'Lista Comandi',
        data: [
          {
            title: 'ID',
            fields: [
              {
                placeholder: '@',
                name: '_id',
                class: 'text-primary-400',
              },
              {
                name: 'IMEI',
                class: 'font-bold ',
              },
            ],
            span: 1,
          },
          {
            title: 'Comando',
            field: 'command',
            span: 2,
          },
          {
            title: 'Creazione',
            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Ultimo invio',
            field: 'last_sent',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Ricevuto',
            field: 'ack',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Stato',
            field: 'status',
            format: 'commandStatus',
            span: 2,
          },
        ],
      };

      return {
        data: commands,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
