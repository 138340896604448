import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    managerLocations: [],
  },
  getters: {
    managerLocations: (state) => state.managerLocations,
  },
  mutations: {
    getManagerLocations: (state, payload) => (state.managerLocations = payload),
  },
  actions: {
    getManagerLocations: (ctx, payload) => ApiCaller.GET(`/manager/${payload.id}/locations`, null).then((data) => ctx.commit('getManagerLocations', Utils.createFiltrableData(data))),
  },
  modules: {},
};
