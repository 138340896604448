<template>
  <div class="bg-white dark:bg-gray-900 rounded-lg shadow-md p-6 w-full">
    <slot></slot>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
