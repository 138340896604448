<template>
  <base-page>
    <base-list-table
      :data="data"
      :structure="structure"
      :refreshFunction="refreshPage"
    />
  </base-page>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = () =>
      store.dispatch("getManagerVehicles", { manager: route.params.managerId });

    const vehicles = computed(() => store.getters.managerVehicles);

    const structure = {
      data: [
        {
          title: "user.name.first",
          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-400",
            },
            {
              name: "plate",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: {
            idField: "id",
            type: "vehicle",
          },
        },
        {
          title: "views.vehicles.type",
          fields: [
            {
              placeholder: "@",
              name: "model_id",
              class: "text-primary-400",
            },
            {
              name: "model_name",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: {
            idField: "model_id",
            type: "vehicle_type",
          },
        },
        {
          title: "views.releaseZonesGroups.zonesGroup",

          fields: [
            {
              placeholder: "@",
              name: "zone_group_id",
              class: "text-primary-400",
            },
            {
              name: "zone_group_name",
              class: "font-bold ",
            },
          ],
          span: 1,
          link: { idField: "zone_group_id", path: "/sharing/zones/:id" },
        },
        {
          title: "status",

          field: "maintenance",
          boolean: {
            if_false: "views.vehicles.available",
            if_true: "views.vehicles.underMaintenance",
          },
          span: 1,
        },
      ],
    };

    return {
      data: vehicles,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>