<template>
  <base-subpage-bar :headers="headers" routeName="menu.user.list" />
  <router-view />
</template>

<script>
  import { useStore } from 'vuex';
  import { onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  export default {
    setup() {
      const router = useRouter();
      const children = computed(() => {
        return [router.currentRoute];
      });
      const store = useStore();
      onMounted(() => {
        store.dispatch('getCompleteRechargesList');
        store.dispatch('getRechargesInProgressList');
        store.dispatch('getAnomalies');
      });

      const headers = computed(() => {
        const plat = store.getters?.currentPlatform?.db;

        if (plat && plat == 'VIB') {
          return [
            { text: 'views.user.usersList', name: 'menu.user.usersList' },
            { text: 'views.user.unconfirmedUsersList', name: 'menu.user.usersUnconfirmed' },
            { text: 'views.user.insolventUsersList', name: 'menu.user.insolventUsers' },
            { text: 'views.user.couponUsersList', name: 'menu.user.couponUsers' },
          ];
        } else
          return [
            { text: 'views.user.usersList', name: 'menu.user.usersList' },
            { text: 'views.user.unconfirmedUsersList', name: 'menu.user.usersUnconfirmed' },
            { text: 'views.user.insolventUsersList', name: 'menu.user.insolventUsers' },
          ];
      });

      return {
        children,
        headers,
      };
    },
  };
</script>

<style></style>
