<template>
  <nav aria-label="Progress">
    <ol role="list" class="overflow-hidden">
      <li
        v-for="(step, stepIdx) in steps"
        :key="step.name"
        :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
        <!-- Step completato -->
        <template v-if="step.status === 'complete'">
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600 dark:bg-indigo-400"
            aria-hidden="true" />
          <a :href="step.href" class="group relative flex items-start">
            <span class="flex h-9 items-center">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 dark:bg-indigo-400 group-hover:bg-indigo-800 dark:group-hover:bg-indigo-600">
                <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col text-gray-200">
              <span class="text-sm font-medium">{{ step.name }}</span>
              <span class="text-sm text-gray-500 dark:text-gray-400">{{ step.description }}</span>
            </span>
          </a>
        </template>

        <!-- Step corrente -->
        <template v-else-if="step.status === 'current'">
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300 dark:bg-gray-700"
            aria-hidden="true" />
          <a :href="step.href" class="group relative flex items-start" aria-current="step">
            <span class="flex h-9 items-center" aria-hidden="true">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 dark:border-indigo-400 bg-white dark:bg-gray-800">
                <span class="h-2.5 w-2.5 rounded-full bg-indigo-600 dark:bg-indigo-400" />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span class="text-sm font-medium text-indigo-600 dark:text-indigo-400">{{ step.name }}</span>
              <span class="text-sm text-gray-500 dark:text-gray-400">{{ step.description }}</span>
            </span>
          </a>
        </template>

        <!-- Step non ancora completato -->
        <template v-else>
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300 dark:bg-gray-700"
            aria-hidden="true" />
          <a :href="step.href" class="group relative flex items-start">
            <span class="flex h-9 items-center" aria-hidden="true">
              <span
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 group-hover:border-gray-400 dark:group-hover:border-gray-500">
                <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300 dark:group-hover:bg-gray-600" />
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">{{ step.name }}</span>
              <span class="text-sm text-gray-500 dark:text-gray-400">{{ step.description }}</span>
            </span>
          </a>
        </template>
      </li>
    </ol>
  </nav>
</template>


<script>
  import { CheckIcon } from '@heroicons/vue/20/solid';
  export default {
    props: ['steps'],
    components: { CheckIcon },
  };
</script>
