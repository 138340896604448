<template>
  <div class="w-full">
    <label v-if="title" class="block text-sm font-medium text-gray-900 dark:text-white w-full">
      {{ $t(title) }}
    </label>
    <select
      @change="vmodel"
      :value="modelValue"
      class="bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full"
      :class="status()"
      @focus="$emit('focus')"
      @blur="$emit('blur')">
      <template v-for="el in data.array ? data.array : data">
        <option v-if="mapper" :key="el[mapper.value]" :value="el[mapper.value]">
          {{ extractText(el, mapper.text) }}
        </option>
        <option
          v-else-if="typeof el === 'object'"
          :key="data.elReturned ? el[data.elReturned] : el.value"
          :value="data.elReturned ? el[data.elReturned] : el.value">
          {{ data.name ? el[data.name] : el.text }}
        </option>
        <option v-else :key="el">
          {{ el }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
  import { computed } from 'vue';
  export default {
    emits: ['update:modelValue', 'focus', 'blur'],
    props: {
      modelValue: {},
      title: {
        type: String,
      },
      data: {
        type: Object,
        required: true,
      },
      mapper: {
        type: Object,
        required: false,
      },
      statusOK: { type: Boolean, required: false, default: true },
    },
    setup(props, { emit }) {
      function vmodel(event) {
        emit('update:modelValue', event.target.value);
      }
      function status() {
        if (props.statusOK) return '';
        else return 'bg-red-100';
      }
      const extractText = computed(() => {
        return (el, text) => {
          if (el === undefined || text === undefined) return '';
          if (typeof text === 'string') return el[text];
          let ret = '';
          text.forEach((elmt) => {
            ret += el[elmt] + ' ';
          });
          ret = ret.slice(0, -1);
          return ret;
        };
      });
      return {
        vmodel,
        status,
        extractText,
      };
    },
  };
</script>

<style></style>
