<template>
  <vehicle-box @change="refreshData" />
  <div v-if="selectedTrip" class="flex justify-center items-center mb-8">
    <base-button
      onlyicon
      v-if="tripController && tripController.pre"
      class="z-30 -mr-4 shadow-md w-14 h-14 rounded-full"
      icon="chevron_left"
      @click="changeTrip(tripController.pre)" />
    <div v-else class="opacity-0 z-30 -ml-4 shadow-md w-14 h-14 rounded-full" />
    <div
      class="z-20 relative flex w-11/12 h-min shadow-md px-8 py-2 bg-gray-900 rounded-2xl border border-gray-300 dark:border-gray-700 items-center justify-start">
      <div class="flex flex-col h-min mb-4 text-left">
        <span class="text-primary-400 text-xs mt-4">@{{ selectedTrip._id }}</span>
        <h1 class="text-left font-semibold text-white leading-none p-0 text-2xl">{{ selectedTrip.type }}</h1>
      </div>
      <div class="flex flex-col h-min ml-10 justify-center border-l-2 pl-2 border-white">
        <h1 class="text-left font-semibold text-gray-400 leading-none p-0 text-sm">{{ selectedTrip.IMEI }}</h1>
        <h1 class="text-left font-bold text-gray-200 leading-none p-0 text-base">{{ new Date(selectedTrip.started_at).toDateString() }}</h1>
      </div>
      <div class="flex flex-col h-min items-center justify-center">
        <text
          v-if="duration"
          class="ml-8 px-2 h-6 w-min inline-flex flex-row justify-center items-center rounded-lg whitespace-nowrap bg-primary-100 text-primary-800">
          <span class="text-base material-icons mr-2">timelapse</span>
          <text class="whitespace-nowrap">{{ duration }}</text>
        </text>
      </div>
      <div class="flex flex-col h-min items-center justify-center">
        <text
          v-if="selectedTrip.ongoing"
          class="ml-8 px-2 h-6 w-min inline-flex flex-row justify-center items-center rounded-lg whitespace-nowrap bg-green-100 text-green-800">
          <span class="text-base material-icons mr-2">fiber_manual_record</span>
          <text class="whitespace-nowrap">In corso</text>
        </text>
      </div>
    </div>
    <base-button
      onlyicon
      v-if="tripController && tripController.next"
      class="z-30 -ml-4 shadow-md w-14 h-14 rounded-full"
      icon="chevron_right"
      @click="changeTrip(tripController.next)" />
    <div v-else class="opacity-0 z-30 -ml-4 shadow-md w-14 h-14 rounded-full" />
  </div>

  <div hidden>{{ tripController }}</div>

  <base-subpage-bar :headers="headers" />

  <template v-if="selectedVehicle">
    <router-view />
  </template>
</template>

<script>
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import VehicleBox from '../vehicle/VehicleBox';
  export default {
    components: {
      VehicleBox,
    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const selectedTrip = computed(() => store.getters.selectedTrip);
      const selectedVehicle = computed(() => store.getters.selectedVehicle);

      const refreshData = (selectedVehicle) => {
        store.dispatch('getTrips', { id: selectedVehicle.device.id });
      };

      const changeTrip = (newId) => {
        router.push(route.path.replace(route.params.tripId, newId));
        store.dispatch('getSelectedTrip', { id: route.params.tripId });
      };

      function getDifference(date1, date2) {
        date2 = new Date(date2);
        date1 = new Date(date1);
        var diffMs = date2 - date1; // milliseconds between now & date1
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return (diffDays ? diffDays + 'd ' : '') + (diffHrs ? diffHrs + 'h ' : '') + diffMins + 'm';
      }
      const duration = computed(() => {
        if (selectedTrip.value) {
          const e = selectedTrip.value;
          return getDifference(e.started_at, e.ended_at ? e.ended_at : new Date().toISOString());
        }
        return '';
      });
      const trips = computed(() => store.getters.trips);
      const tripController = computed(() => {
        const temp = {};
        let j = trips.value.length;
        let pre = null;
        for (const i in trips.value) {
          if (pre && i < j) temp.pre = pre;
          const t = trips.value[i];
          if (i > j) {
            temp.next = t._id;
            return temp;
          }
          if (t._id == route.params.tripId) {
            j = i;
            temp.current = t._id;
          }
          pre = t._id;
        }
        return temp;
      });

      const headers = computed(() => {
        return [
          { text: 'Percorso', name: 'menu.mobility.tripSubMap' },
          { text: 'Eventi', name: 'menu.mobility.tripSubEvents' },
          { text: 'Messaggi', name: 'menu.mobility.tripSubMessages' },
          { text: 'Comandi', name: 'menu.mobility.tripSubCommands' },
        ];
      });
      return { selectedVehicle, selectedTrip, headers, tripController, trips, route, changeTrip, duration, refreshData };
    },
  };
</script>
