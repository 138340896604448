<template>
  <base-generals-page :el="el" :structure="structure" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    setup() {
      const store = useStore();
      const selectedSubscription = computed(() => store.getters.selectedSubscription);

      const structure = {
        data: [
          {
            title: 'subscription.pm_token',
            name: 'pm_token',
            placeholder: '--',
          },
          {
            title: 'subscription.stripeId',
            name: 'stripe_id',
            placeholder: '--',
          },
          {
            title: 'subscription.created_at',
            name: 'created_at',
            format: 'datetime',
          },
          {
            title: 'subscription.updated_at',
            name: 'updated_at',
            format: 'datetime',
          },
          {
            title: 'subscription.current_period_start',
            name: 'stripe',
            sub_name: 'current_period_start',
            format: 'unix_to_date',
          },
          {
            title: 'subscription.current_period_end',
            name: 'stripe',
            sub_name: 'current_period_end',
            format: 'unix_to_date',
          },
        ],
      };
      return {
        el: selectedSubscription,
        structure,
      };
    },
  };
</script>

<style></style>
