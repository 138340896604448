<template>
  <div class="w-full h-full flex flex-col justify-center font-semibold items-center text-primary-900 dark:text-gray-700">
    <h1 class="text-9xl font-thin animate-pulse">404</h1>
    <router-link to="/dashboard">{{ $t('general.messages.backToHome') }}</router-link>
  </div>
</template>

<script>
  export default {
    name: 'PageNotFound',
    setup() {},
  };
</script>

<style></style>
