import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    events: [],
    messages: [],
    lastMessage: [],
    trips: [],
    commands: [],
    ongoingTrips: [],
  },
  getters: {
    events: (state) => state.events,
    messages: (state) => state.messages,
    lastMessage: (state) => state.lastMessage,
    trips: (state) => state.trips,
    commands: (state) => state.commands,
    ongoingTrips: (state) => state.ongoingTrips,
  },
  mutations: {
    setEvents: (state, data) => (state.events = data),
    setMessages: (state, data) => (state.messages = data),
    setLastMessage: (state, data) => (state.lastMessage = data),
    setTrips: (state, data) => (state.trips = data),
    setCommands: (state, data) => (state.commands = data),
    setOngoingTrips: (state, data) => (state.ongoingTrips = data),
  },
  actions: {
    getEvents: (ctx, payload) => ApiCaller.GET(`/device/${payload.id}/events`).then((data) => ctx.commit('setEvents', Utils.createFiltrableData(data))),
    getMessages: (ctx, payload) => ApiCaller.GET(`/device/${payload.id}/messages`).then((data) => ctx.commit('setMessages', Utils.createFiltrableData(data))),
    getLastMessages: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}/lastMessage`).then((data) => ctx.commit('setLastMessages', Utils.createFiltrableData(data))),
    getTrips: (ctx, payload) => ApiCaller.GET(`/device/${payload.id}/trips`).then((data) => ctx.commit('setTrips', Utils.createFiltrableData(data))),
    getCommands: (ctx, payload) => ApiCaller.GET(`/device/${payload.id}/commands`).then((data) => ctx.commit('setCommands', Utils.createFiltrableData(data))),
    getOngoingTrips: (ctx, payload) =>
      ApiCaller.GET(`/device/${payload.id}/ongoingTrips`).then((data) => ctx.commit('setOngoingTrips', Utils.createFiltrableData(data))),
    sendDeviceCommand: async (_, payload) => {
      return await ApiCaller.POST(`/device/${payload.id}/sendCommand/${payload.command}`);
    },
  },
};
