<template>
  <base-details-header :structure="headerStructure" :el="selectedItm" />
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedProduct', selectedElID);

      const selectedProduct = computed(() => store.getters.selectedProduct);

      const headers = [
        {
          text: 'views.subscriptions.generals',
          name: 'menu.recharge.subscriptionsProductGenerals',
        },
      ];
      const headerStructure = {
        title: [
          {
            title: 'name',
          },
        ],
        data: [
          {
            title: 'views.subscriptions.amount',
            name: 'price',
            subName: 'unit_amount_decimal',
            type: 'decimal_euro',
          },
          {
            title: 'views.subscriptions.recurring_billing_type',
            name: 'price',
            subName: 'interval',
          },
          {
            title: 'views.subscriptions.recurring_billing_time',
            name: 'price',
            subName: 'interval_count',
          },
        ],
      };
      return {
        selectedItm: selectedProduct,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
