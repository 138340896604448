<template>
  <div class="flex py-0 justify-end space-x-4">
    <base-button
      v-if="edit"
      icon="cancel"
      class="opacity-60"
      @click="
        edit = false;
        values = { ...savedValues };
      ">
      {{ $t('buttons.cancel') }}
    </base-button>
    <base-button v-if="edit" icon="save" @click="save">{{ $t('buttons.save') }}</base-button>
    <base-button v-else icon="edit" @click="edit = true">{{ $t('buttons.edit') }}</base-button>
  </div>
  <div v-if="el && el.configuration && el.configuration.configurations && values" class="w-full h-full flex flex-col font-medium pb-48" style="width: 100%">
    <div v-for="(configuration, i) in el.configuration.configurations" :key="i">
      <div class="grid grid-cols-12 justify-start items-center">
        <div class="col-span-12 text-2xl font-bold py-4 px-3 mt-6">{{ configuration.title }}</div>

        <div class="col-span-1 text-gray-400 py-0 px-3 text-left font-bold">#</div>
        <div class="col-span-3 text-gray-400 py-0 px-3 text-left font-bold">Etichetta</div>
        <div class="col-span-1 text-gray-400 py-0 px-3 text-left font-bold">Default</div>
        <div class="col-span-2 text-gray-400 py-0 px-3 text-left font-bold">Valore attuale</div>
        <div v-if="edit" class="col-span-3 text-gray-400 py-0 px-3 text-left font-bold">Modifica</div>
        <div v-if="edit" class="col-span-2 text-gray-400 py-0 px-3 text-left font-bold">Nuovo valore</div>
        <!-- <div v-if="edit" class="col-span-2 text-gray-400 py-0 px-3 text-left font-bold">HEX</div> -->
      </div>

      <div v-for="(conf, j) in configuration.fields" :key="j" class="grid grid-cols-12 border-b border-gray-200 py-6 justify-start items-center">
        <div class="col-span-1 text-gray-400 py-0 px-3 text-left font-bold">#{{ conf.id / 1000 }}</div>
        <div class="col-span-3 px-3 font-semibold flex flex-col justify-center items-start">
          <span class="text-base">{{ conf.label }}</span>
          <span class="text-sm text-gray-500">{{ conf.desc }}</span>
          <span class="text-xs text-blue-600 font-semibold">
            <b>{{ conf.type.length }}</b>
            {{ conf.type.unit }}
          </span>
        </div>
        <div
          class="col-span-1 px-3 font-semibold cursor-pointer text-gray-300"
          :class="{ 'text-blue-800 underline': conf.default != values[conf.id] }"
          @click="if (edit) values[conf.id] = conf.default;"
          v-html="conf.default"></div>
        <div
          class="col-span-2 px-3 font-semibold cursor-pointer text-gray-900"
          :class="{ 'text-blue-800 underline': savedValues[conf.id] != values[conf.id] }"
          @click="if (edit) values[conf.id] = savedValues[conf.id];"
          v-html="savedValues[conf.id]"></div>

        <template v-if="edit">
          <div class="col-span-3 px-3 font-semibold flex flex-col justify-center items-start">
            <!-- BOOLEAN BIT -->
            <template v-if="conf.type.type == 'boolean'">
              <input type="checkbox" v-model="values[conf.id]" />
              <span class="text-xs mt-2 text-gray-500">{{ conf.desc }}</span>
            </template>
            <template v-else-if="conf.type.type == 'number'">
              <div v-if="conf.type.unit == 'bit'" class="flex w-4/5">
                <span class="text-gray-400">0</span>
                <input :disabled="!edit" class="w-full mx-3" type="range" step="1" v-model="values[conf.id]" min="0" :max="2 ** conf.type.length - 1" />
                <span class="text-gray-400">{{ 2 ** conf.type.length - 1 }}</span>
              </div>
              <div v-else class="flex w-4/5">
                <span class="text-gray-400">0</span>
                <input :disabled="!edit" class="w-full mx-3" type="range" step="60" v-model="values[conf.id]" min="0" :max="2 ** (conf.type.length * 8) - 1" />
                <span class="text-gray-400">{{ 2 ** (conf.type.length * 8) - 1 }}</span>
              </div>
              <span class="text-xs mt-2 text-gray-500">{{ conf.desc }}</span>
            </template>
            <template v-else>
              <input
                v-model="values[conf.id]"
                :placeholder="conf.default"
                :disabled="!edit || conf.id == 2000"
                type="text"
                :maxlength="conf.type.length"
                class="block w-4/5 h-10 p-4 text-gray-900 disabled:opacity-30 dark:text-white border border-gray-300 dark:border-gray-700 rounded-lg bg-gray-50 dark:bg-gray-800 md:text-md focus:ring-primary-500 focus:border-primary-500"
                :class="{ 'bg-yellow-50 border-yellow-300 focus:ring-yellow-500 focus:border-yellow-500': values[conf.id] != savedValues[conf.id] }" />
              <span class="text-xs mt-2 text-gray-500">Massimo {{ conf.type.length }} caratteri.</span>
            </template>
          </div>
          <div class="col-span-2 px-3 font-semibold flex flex-col">
            <span>
              {{ values[conf.id] }}
              <text v-if="values[conf.id] != savedValues[conf.id]" class="text-red-600">*</text>
            </span>
            <span class="text-xs mt-1 text-gray-500">{{ converted[conf.id] }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedDevice', selectedElID);
      const selectedDevice = computed(() => store.getters.selectedDevice);
      const savedValues = computed(() => (selectedDevice.value && selectedDevice.value.configuration_values ? selectedDevice.value.configuration_values : {}));
      const values = ref({});
      watch(savedValues, (nv) => {
        values.value = { ...nv };
      });

      const edit = ref(false);

      const save = async () => {
        store.dispatch('saveDeviceConfiguration', { id: selectedElID.id, values: values.value }).then(() => {
          store.dispatch('getSelectedDevice', selectedElID);
          edit.value = false;
        });
      };

      const converted = computed(() => {
        let ref = {};
        if (!selectedDevice.value || !selectedDevice.value.configuration) return [];
        for (let i in selectedDevice.value.configuration.configurations) {
          for (let j in selectedDevice.value.configuration.configurations[i].fields) {
            const conf = selectedDevice.value.configuration.configurations[i].fields[j];

            let v = values.value[conf.id];
            switch (conf.type.type) {
              case 'string':
                if (conf.type.unit == 'byte') v = ascii_to_hexa(v, conf.type.length);
                else v = ascii_to_hexa(v, conf.type.length / 8);
                break;
              case 'number':
                if (conf.type.unit == 'bit') v = decimal_to_hex(v, conf.type.length / 8);
                else v = ascii_to_hexa(v, conf.type.length);
                break;
              case 'boolean':
                v = v ? 'BIN 1' : 'BIN 0';
                break;
            }

            ref[conf.id] = v;
          }
        }
        return ref;
      });

      const decimal_to_hex = (dec, bit) => {
        let s = parseInt(dec).toString(16).toUpperCase();
        s = s.padStart(bit * 2, '0');
        s = s.match(/.{1,2}/g).join(' ');
        return s;
      };

      const ascii_to_hexa = (str, len) => {
        var arr1 = [];
        var n;
        const l = str.length;
        for (n = 0; n < l; n++) {
          var hex = Number(str.charCodeAt(n)).toString(16);
          arr1.push(hex.toUpperCase());
        }
        while (n++ < len) arr1.push('00');
        return 'HEX ' + arr1.join(' ');
      };

      return {
        el: selectedDevice,
        savedValues,
        values,
        edit,
        converted,
        save,
      };
    },
  };
</script>

<style></style>
