import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    rentals: [],
    rentalInProgress: [],
    selectedRental: null,
  },
  getters: {
    rentals: (state) => state.rentals,
    rentalInProgress: (state) => state.rentalInProgress,
    selectedRental: (state) =>
      state.selectedRental ? state.selectedRental[0] : null,
  },
  mutations: {
    setRentals: (state, data) => (state.rentals = data),
    setRentalInProgress: (state, data) => (state.rentalInProgress = data),
    setSelectedRental: (state, data) => (state.selectedRental = data),
  },
  actions: {
    getRentals: (ctx, payload) => {
      ctx.commit("setRentals", []);
      ApiCaller.GET(`/rentals`, payload).then((data) => {
        ctx.commit("setRentals", Utils.createFiltrableData(data));
      });
    },
    getRentalInProgress: (ctx, payload) => {
      ctx.commit("setRentalInProgress", []);
      ApiCaller.GET(`/rentalInProgress`, payload).then((data) => {
        ctx.commit("setRentalInProgress", Utils.createFiltrableData(data));
      });
    },
    getSelectedRental: (ctx, payload) => {
      ApiCaller.GET(`/rentalInProgress/${payload.id}`, payload).then((data) => {
        ctx.commit("setSelectedRental", data);
      });
    },

    //external api
    endRental: (_, payload) => ApiCaller.PUT(`/rentals/endRental`, payload),
    startRental: (_, payload) => ApiCaller.PUT(`/rentals/startRental`, payload),
  },
};
