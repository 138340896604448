<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
export default {
  setup() {
    //structure of page
    const insertData = {
      title: "Inserimento nuovo gestore",
      redirect_path: "/sharing/managers",
      create_endpoint: "createManager",
      creation_title: "Creazione gestore",
      dispatchName: "getManagers",
      data: [
        {
          title: "user.name.first",
          
          name: "name",
          class: "col-span-2",
          required: true,
        },
        {
          title: "address.body",
          
          name: "address",
          placeholder: "Via, numero, cap, città",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Email",
          name: "email",
        },

        {
          title: "user.cellphone",
          
          name: "cellphone",
        },
        {
          title: "user.vat",
          
          name: "vat",
        },
        {
          title: "address.zipcode",
          
          name: "zipcode",
          placeholder: "es. 00100",
        },
        {
          title: "Username per il login",
          name: "username",
          required: true,
        },
        {
          title: "Password per il login",
          name: "password",
          placeholder: "NB. Non sarà più visibile una volta creato il gestore.",
          required: true,
        },
        {
          title: "user.notes",
          
          name: "notes",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>