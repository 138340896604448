<template>
  <div class="flex items-center justify-end h-9 text-sm">
    <div class="self-end w-min text-gray-700 dark:text-gray-100 border-l-0 border border-gray-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
      <select v-model="selectedTimeField" class="bg-white dark:bg-gray-900 font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none">
        <option v-for="(timeField, i) in timeFields" :key="i" :value="timeField.key">{{ t(timeField.label) }}</option>
      </select>
    </div>

    <div class="self-end w-min text-gray-700 dark:text-gray-100 border border-gray-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
      <select v-model="selectValue" class="bg-white dark:bg-gray-900 font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none">
        <option v-for="(option, i) in options" :key="i" :value="option.key">{{ t(option.label) }}</option>
      </select>
    </div>

    <div v-show="selectValue == 'selectMonth'" class="w-min text-gray-700 dark:text-gray-100 border border-gray-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
      <select v-model="selectedYear" class="bg-white dark:bg-gray-900 font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none">
        <option v-for="(year, i) in years" :key="i" :value="year.key">{{ year.label }}</option>
      </select>
    </div>
    <div v-show="selectValue == 'selectMonth'" class="w-min text-gray-700 dark:text-gray-100 border border-gray-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
      <select v-model="selectedMonth" class="bg-white dark:bg-gray-900 font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none">
        <option v-for="(month, i) in months" :key="i" :value="month.key">{{ t(month.label) }}</option>
      </select>
    </div>

    <template v-if="timestamps && timestamps.dateFrom">
      <div class="w-full text-primary-700 dark:text-gray-300 bg-primary-50 dark:bg-gray-800 border border-primary-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
        <input disabled class="bg-transparent w-full font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none overflow-visible" :value="t('views.timeEntries.from') + ' ' + timestamps.dateFrom + ' ' + t('views.timeEntries.to') + ' ' + timestamps.dateTo" />
      </div>
    </template>
    <template v-else>
      <div class="w-full text-primary-700 dark:text-gray-300 bg-primary-50 dark:bg-gray-800 border border-primary-300 dark:border-gray-700 cursor-pointer pl-2 pr-4 flex justify-center items-center h-full">
        <input disabled class="bg-transparent w-full font-semibold px-2 focus:border-transparent focus:ring-0 focus:outline-none overflow-visible" value="-" />
      </div>
    </template>
  </div>
</template>

<script>
  import { ref, computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    emits: ['timestamps'],
    props: {
      timeFields: { type: Array, required: true },
    },
    setup(props, { emit }) {
      const selectedTimeField = ref(props.timeFields[0].key);
      const options = [
        { label: 'views.timeEntries.today', key: 'today' },
        { label: 'views.timeEntries.yesterday', key: 'yesterday' },
        { label: 'views.timeEntries.lastSevenDays', key: 'lastweek' },
        { label: 'views.timeEntries.lastFourWeeks', key: 'last4weeks' },
        { label: 'views.timeEntries.lastThreeMonths', key: 'last3months' },
        { label: 'views.timeEntries.lastTwelveMonths', key: 'last12months' },
        { label: 'views.timeEntries.currentMonth', key: 'currentMonth' },
        { label: 'views.timeEntries.currentQuarter', key: 'current3Months' },
        { label: 'views.timeEntries.currentYear', key: 'currentYear' },
        { label: 'views.timeEntries.always', key: 'ever' },
        { label: 'views.timeEntries.startMonthSelection', key: 'selectMonth' },
      ];
      const selectValue = ref(options[9].key);

      const months = [
        { label: 'views.timeEntries.months.january', key: 0 },
        { label: 'views.timeEntries.months.february', key: 1 },
        { label: 'views.timeEntries.months.march', key: 2 },
        { label: 'views.timeEntries.months.april', key: 3 },
        { label: 'views.timeEntries.months.may', key: 4 },
        { label: 'views.timeEntries.months.june', key: 5 },
        { label: 'views.timeEntries.months.july', key: 6 },
        { label: 'views.timeEntries.months.august', key: 7 },
        { label: 'views.timeEntries.months.september', key: 8 },
        { label: 'views.timeEntries.months.october', key: 9 },
        { label: 'views.timeEntries.months.november', key: 10 },
        { label: 'views.timeEntries.months.december', key: 11 },
      ];
      const selectedMonth = ref(0);

      const years = [
        { label: '2020', key: 2020 },
        { label: '2021', key: 2021 },
        { label: '2022', key: 2022 },
        { label: '2023', key: 2023 },
        { label: '2024', key: 2024 },
        { label: '2025', key: 2025 },
        { label: '2026', key: 2026 },
      ];
      const selectedYear = ref(2022);

      const timestamps = computed(() => {
        let start = new Date();
        let end = new Date();

        start.setHours(0, 0, 0, 0);
        start.setDate(end.getDate() - 1);

        end.setHours(23, 59, 0, 0);

        switch (selectValue.value) {
          case 'today':
            break;
          case 'yesterday':
            start.setDate(start.getDate() - 1);
            end.setDate(end.getDate() - 1);
            break;
          case 'lastweek':
            start.setDate(start.getDate() - 7);
            break;
          case 'last4weeks':
            start.setDate(start.getDate() - 28);
            break;
          case 'last3months':
            start.setMonth(start.getMonth() - 3);
            break;
          case 'last12months':
            start.setMonth(start.getMonth() - 12);
            break;
          case 'currentMonth':
            start.setDate(1);
            break;
          case 'current3Months':
            start.setMonth(start.getMonth() - 3);
            start.setDate(1);
            break;
          case 'currentYear':
            start.setMonth(0);
            start.setDate(1);
            break;
          case 'ever':
            return {};
          case 'selectMonth':
            console.log('MESE:', selectedMonth.value);
            start.setYear(selectedYear.value);
            end.setYear(selectedYear.value);
            start.setDate(1);
            start.setMonth(selectedMonth.value);
            end.setMonth(selectedMonth.value + 1);
            end.setDate(0);
            break;
        }

        return {
          dateField: selectedTimeField.value,
          dateFrom: start.toISOString().split('T')[0],
          dateTo: end.toISOString().split('T')[0],
        };
      });

      watch(timestamps, (newVal) => {
        console.log('NEW TIMESTAMPS:', timestamps.value);
        emit('timestamps', newVal);
      });

      return { selectedTimeField, selectValue, options, months, selectedMonth, years, selectedYear, timestamps, t: useI18n().t };
    },
  };
</script>
