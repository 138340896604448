import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    rfids: [],
    completeRfidList: [],
  },
  getters: {
    rfids: (state) => state.rfids,
    completeRfidList: (state) => state.completeRfidList,
  },
  mutations: {
    setCompleteRfidList: (state, payload) => (state.completeRfidList = payload),
    setRfid: (state, payload) => (state.rfids = payload),
  },
  actions: {
    getCompleteRfidList: (ctx, payload) =>
      ApiCaller.GET("/user/rfid", payload).then((data) => {
        ctx.commit("setCompleteRfidList", Utils.createFiltrableData(data));
      }),
    getRfid: (ctx, payload) =>
      ApiCaller.GET("/user/rfid", payload).then((data) => {
        ctx.commit("setRfid", data);
      }),
    updateRfid: (_, payload) => ApiCaller.PUT("/user/rfid/update", payload),
    createRfid: (_, payload) => ApiCaller.POST("/user/rfid", payload),
  },
};
