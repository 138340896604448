<template>
  <base-generals-page v-if="el" :el="el" :structure="structure" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();
      const el = computed(() => store.getters.releaseStation);

      const structure = {
        title: '',
        /*  edit_endpoint: 'updateManager',
        edit_title: 'Modifica gestore', */
        data: [
          {
            title: 'bricks.name',
            name: 'name',
            editable: true,
            placeholder: ' ',
          },
          {
            title: 'bricks.type',
            name: 'type',
            editable: true,
            placeholder: ' ',
          },
          {
            title: 'bricks.remote_id',
            name: 'remote_id',
            editable: true,
            placeholder: ' ',
          },
          {
            title: 'bricks.latitude',
            name: 'latitude',
            editable: true,
            placeholder: ' ',
          },
          {
            title: 'bricks.longitude',
            name: 'longitude',
            editable: true,
            placeholder: ' ',
          },
          {
            title: 'bricks.created_at',
            name: 'timestamp',
            format: 'datetime',
            placeholder: ' ',
          },
          {
            title: 'bricks.updated_at',
            name: 'update_timestamp',
            format: 'datetime',
            placeholder: ' ',
          },
        ],
      };
      return {
        el,
        structure,
      };
    },
  };
</script>

<style></style>
