<template>
  <base-page>
    <generals-multititle-page v-if="el" :el="el" :structure="structure" />
  </base-page>
</template>

<script>
  /* eslint-disable */
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const route = useRoute();
      const localeDetails = computed(() => {
        if (!Object.keys(store.getters.sublocales).length) return [];
        const locales = store.getters.sublocales;
        for (const key in locales) {
          if (Object.hasOwnProperty.call(locales, key)) {
            const element = locales[key];
            if (key === route.params.locale) return element;
          }
        }
      });
      const localeToArray = (toUnroll, innerKey = '', level = 0) => {
        const out = [];

        for (const key in toUnroll) {
          if (Object.hasOwnProperty.call(toUnroll, key)) {
            const main = {};
            const element = toUnroll[key];
            const type = typeof element;
            if (type === 'object' || type === 'array' || type === 'proxy') {
              out.push({
                title: key,
                level,
                editable: false,
                isMain: true,
                class: 'text-green-300',
                //data: localeToArray(element, (innerKey!=='')?innerKey+'.'+key:key, level+1),
              });
              out.push(...localeToArray(element, innerKey !== '' ? innerKey + '.' + key : key, level + 1));
            } else if (key !== 'namespaced') {
              main.isMain = false;
              main.title = key;
              main.editable = true;
              main.level = level;
              main.name = innerKey !== '' ? innerKey + '.' + key : key;
              main.placeholder = '--';
              out.push(main);
            }
          }
        }
        return out;
      };

      const localesData = computed(() => localeToArray(localeDetails));
      const structure = {
        title: 'views.languages.translations',

        edit_endpoint: 'updateLocale',
        edit_title: 'Modifica traduzione',
        sections: localesData,
      };
      return {
        localeDetails,
        el: localeDetails,
        localesData: localesData,
        structure,
      };
    },
  };
</script>

<style></style>
