<template>
  <base-page>
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </base-page>
</template>

<script>
  import { watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const refreshPage = () => store.dispatch('getTripEvents', { id: route.params.tripId });

      const events = computed(() => store.getters.tripEvents);
      watch(() => route.params.tripId, refreshPage);
      const lastHeartbeat = computed(() => store.getters.lastHeartbeat);
      watch(lastHeartbeat, refreshPage);

      const structure = {
        title: 'Lista Eventi',
        data: [
          {
            title: 'Etichetta',
            field: 'label',
            span: 1,
          },
          {
            title: 'Viaggi',
            field: 'trips',
            span: 1,
          },
          {
            title: 'Creazione',
            field: 'created_at',
            format: 'datetime',
            span: 1,
            class: 'text-style',
          },
          {
            title: 'ID',
            field: 'event_id',
            span: 1,
            class: 'text-style',
          },
        ],
      };

      return {
        data: events,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
