<template>
  <base-page>
    <base-subpage-bar :headers="headers" />
    <div class="flex justify-end pr-6">
      <base-button @click="maintenanceAll">{{ $t('buttons.maintenanceAll') }}</base-button>
      <base-button class="ml-4 bg-red-600" @click="unMaintenanceAll">{{ $t('buttons.unMaintenanceAll') }}</base-button>
    </div>
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </base-page>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const router = useRouter();

      function refreshPage(payload) {
        store.dispatch('getBikes', payload);
      }

      const bikes = computed(() => store.getters.bikes);

      const structure = {
        title: 'Lista biciclette',
        creationLink: {
          title: 'Aggiungi nuova bicicletta',
          namePath: 'menu.sharing.createBike',
        },
        data: [
          {
            title: 'Nome',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-blue-300',
              },
              {
                name: 'plate',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/sharing/vehicles/bikes/:id' },
          },
          {
            title: 'Tipo',
            fields: [
              {
                placeholder: '@',
                name: 'model_id',
                class: 'text-blue-300',
              },
              {
                name: 'model_name',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: {
              idField: 'model_id',
              path: '/sharing/vehicles/bikes/types/:id',
            },
          },
          {
            title: 'Tracker',
            fields: [
              {
                placeholder: '@',
                name: 'tracker_imei',
                class: 'text-blue-300',
              },
              {
                name: 'tracker_imei',
              },
            ],
            span: 1,
            link: { idField: 'tracker_imei', path: '/sharing/trackers/:id' },
          },
          {
            title: 'Gruppo zone',
            fields: [
              {
                placeholder: '@',
                name: 'zone_group_id',
                class: 'text-blue-300',
              },
              {
                name: 'zone_group_name',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'zone_group_id', path: '/sharing/zones/:id' },
          },
          {
            title: 'Gestore associato',
            fields: [
              {
                placeholder: '@',
                name: 'manager_id',
                class: 'text-blue-300',
              },
              {
                name: 'manager_name',
                class: 'font-bold ',
              },
              {
                name: 'location',
                class: 'text-sm ',
              },
            ],
            span: 1,
            link: { idField: 'manager_id', path: '/sharing/managers/:id' },
          },
          {
            title: 'Stato',
            field: 'maintenance',
            boolean: {
              if_false: 'Disponibile',
              if_true: 'In manutenzione',
            },
            span: 1,
          },
        ],
      };

      const headers = [
        {
          text: 'Biciclette',
          name: 'menu.sharing.bikesList',
        },
        {
          text: 'Lista Tipologie',
          name: 'menu.sharing.bikeTypesList',
        },
      ];

      const maintenanceAll = () =>
        store
          .dispatch('maintenanceAll', {
            type: 'bike',
            action: 'set',
          })
          .then(() => router.go());
      const unMaintenanceAll = () =>
        store
          .dispatch('maintenanceAll', {
            type: 'bike',
            action: 'unset',
          })
          .then(() => router.go());
      return {
        data: bikes,
        structure,
        headers,
        maintenanceAll,
        unMaintenanceAll,
        refreshPage,
      };
    },
  };
</script>

<style></style>
