import { Utils, ApiCaller } from '@/Utils/utils';
import router from '../../../router/router';

export default {
  state: {
    alarms: [],
    alarmTypes: [],
    alarmStatuses: [],
    alarmStatusTypes: [],
    activeAlarms: [],
    deletedAlarms: [],
    closedAlarms: [],
    selectedAlarm: '',
    selectedAlarmType: '',
    selectedAlarmStatusType: '',
  },
  getters: {
    alarms: (state) => state.alarms,
    activeAlarms: (state) => state.activeAlarms,
    deletedAlarms: (state) => state.deletedAlarms,
    closedAlarms: (state) => state.closedAlarms,
    alarmTypes: (state) => state.alarmTypes,
    alarmStatuses: (state) => state.alarmStatuses,
    alarmStatusTypes: (state) => state.alarmStatusTypes,
    selectedAlarm: (state) => state.alarms.filter((al) => al.id === state.selectedAlarm)[0],
    selectedAlarmType: (state) => state.alarmTypes.filter((al) => al.id === state.selectedAlarmType)[0],
    selectedAlarmStatusType: (state) =>
      state.alarmStatusTypes.filter((al) => al.id === state.selectedAlarmStatusType)[0],
  },
  mutations: {
    setAlarms: (state, payload) => (state.alarms = Utils.formatAlarmArray(payload)),
    setAlarmTypes: (state, payload) => (state.alarmTypes = payload),
    setAlarmStatuses: (state, payload) => (state.alarmStatuses = payload),
    setAlarmStatusTypes: (state, payload) => (state.alarmStatusTypes = payload),
    setActiveAlarms: (state) =>
      (state.activeAlarms = state.alarms.filter(
        (alarm) =>
          alarm.activeStatus.statusType.name !== 'DELETED' &&
          alarm.activeStatus.statusType.name !== 'CLOSED' &&
          alarm.activeStatus.statusType.name !== 'SOLVED' &&
          alarm.activeStatus.statusType.name !== 'UNSOLVED',
      )),
    setDeletedAlarms: (state) =>
      (state.deletedAlarms = state.alarms.filter((alarm) => alarm.activeStatus.statusType.name === 'DELETED')),
    setClosedAlarms: (state) =>
      (state.closedAlarms = state.alarms.filter(
        (alarm) =>
          alarm.activeStatus.statusType.name === 'CLOSED' ||
          alarm.activeStatus.statusType.name === 'SOLVED' ||
          alarm.activeStatus.statusType.name === 'UNSOLVED',
      )),
    setSelectedAlarm: (state, payload) => (state.selectedAlarm = payload),
    setSelectedAlarmType: (state, payload) => (state.selectedAlarmType = payload),
    setSelectedAlarmStatusType: (state, payload) => (state.selectedAlarmStatusType = payload),
    updateAlarm: async (state, payload) => {
      state.alarms.forEach((a) => {
        if (a.id === payload.id) a.assignedTo = state.getters.selectedUser;
      });
    },
    updateAlarmType: (state, payload) =>
      state.alarmTypes.forEach((at) => {
        if (at.id === payload.id) at.name = payload.name;
      }),
    updateStatusAlarmType: (state, payload) =>
      state.alarmStatusTypes.forEach((ast) => {
        if (ast.id === payload.id) ast.name = payload.name;
      }),
    addAlarmType: (state, payload) => state.alarmTypes.push(payload),
    deleteAlarm: (state, payload) => state.alarms.filter((a) => a.id !== payload.id),
    deleteAlarmType: (state, payload) => state.alarmTypes.filter((at) => at.id !== payload.id),
    addAlarmStatusType: (state, payload) => state.alarmStatusTypes.push(payload),
    deleteAlarmStatusType: (state, payload) => state.alarmStatusTypes.filter((ast) => ast.id !== payload.id),
  },
  actions: {
    // GETTERS
    getAlarms: async (ctx, payload) => {
      ApiCaller.GET('/alarms/' + payload).then((data) => {
        ctx.commit('setAlarms', Utils.createFiltrableData(data));
        ctx.commit('setActiveAlarms', Utils.createFiltrableData(data));
        ctx.commit('setDeletedAlarms', Utils.createFiltrableData(data));
        ctx.commit('setClosedAlarms', Utils.createFiltrableData(data));
      });
    },
    getAlarmStatuses: async (ctx, payload) => {
      ApiCaller.GET('/alarmStatuses/' + payload).then((data) => {
        ctx.commit('setAlarmStatuses', Utils.createFiltrableData(data));
      });
    },
    getAlarmTypes: async (ctx, payload) => {
      ApiCaller.GET('/alarmTypes/' + payload).then((data) => {
        ctx.commit('setAlarmTypes', Utils.createFiltrableData(data));
      });
    },
    getAlarmStatusTypes: async (ctx, payload) => {
      ApiCaller.GET('/alarmStatusTypes/' + payload).then((data) => {
        ctx.commit('setAlarmStatusTypes', Utils.createFiltrableData(data));
      });
    },

    //SETTERS

    setSelectedAlarm: async (context, payload) => {
      if (!context.state.alarms.length) await context.dispatch('getAlarms');
      context.commit('setSelectedAlarm', payload.id);
    },
    setSelectedAlarmType: async (context, payload) => {
      if (!context.state.alarmTypes.length) context.dispatch('getAlarmTypes');
      context.commit('setSelectedAlarmType', payload.id);
    },
    setSelectedAlarmStatusType: async (context, payload) => {
      if (!context.state.alarmStatusTypes.length) context.dispatch('getAlarmStatusTypes');
      context.commit('setSelectedAlarmStatusType', payload.id);
    },

    // UPDATERS
    updateAlarm: async (context, payload) => {
      if (payload.body.assignedTo) {
        if (payload.body.assignedTo !== context.getters.selectedAlarm.assignedTo.id) {
          ApiCaller.PATCH(`/alarm/${payload.id}`, payload.body).then(async () => {
            await context.dispatch('setSelectedUser', { id: payload.body.assignedTo });
            context.commit('updateAlarm', { id: payload.id, assignedTo: payload.body.assignedTo });
            context.commit('setActiveAlarms');
            context.commit('setDeletedAlarms');
          });
        }
      }
      if (payload.body.currentStatusType) {
        if (payload.body.currentStatusType !== context.getters.selectedAlarm.currentStatusType.id) {
          const body = {
            alarmId: payload.id,
            platform: context.getters.currentPlatform.db,
            active: true,
            statusTypeId: payload.body.currentStatusType,
          };
          ApiCaller.POST(`/alarmStatus`, body).then(async () => {
            await context.dispatch('setSelectedUser', { id: payload.body.assignedTo });
            context.commit('updateAlarm', { id: payload.id, assignedTo: payload.body.assignedTo });
            context.commit('setActiveAlarms');
            context.commit('setDeletedAlarms');
          });
        }
      }
    },
    updateAlarmType: async (ctx, payload) => {
      const name = Utils.cleanupString(payload.body.name);
      payload.body.name = name;
      ApiCaller.PATCH(`/alarmType/${payload.id}`, payload.body).then(() => {
        ctx.commit('updateAlarmType', { id: payload.id, name: payload.body.name });
      });
    },
    updateAlarmStatusType: async (ctx, payload) => {
      const name = Utils.cleanupString(payload.body.name);
      payload.body.name = name;
      ApiCaller.PATCH(`/alarmStatusType/${payload.id}`, payload.body).then(() => {
        ctx.commit('updateAlarmStatusType', { id: payload.id, name: payload.body.name });
      });
    },

    // POSTERS
    createAlarmType: async (context, payload) => {
      payload.body = {};
      payload.body.platform = context.getters.currentPlatform.db;
      const name = Utils.cleanupString(payload.name);
      payload.body.name = name;
      delete payload.name;
      ApiCaller.POST(`/alarmType`, payload.body).then((data) => {
        const id = data.id;
        const newType = {
          id,
          name: payload.body.name,
        };
        context.commit('addAlarmType', newType);
      });
    },
    createAlarmStatusType: async (context, payload) => {
      payload.body = {};
      payload.body.platform = context.getters.currentPlatform.db;
      const name = Utils.cleanupString(payload.name);
      payload.body.name = name;
      delete payload.name;
      ApiCaller.POST(`/alarmStatusType`, payload.body).then((data) => {
        const id = data.id;
        const newType = {
          id,
          name: payload.body.name,
        };
        context.commit('addAlarmStatusType', newType);
      });
    },

    // DELETERS
    deleteAlarm: async (context, payload) => {
      ApiCaller.DELETE(`/alarm/${payload.id}`).then(() => {
        context.commit('deleteAlarm', { id: payload.id });
        router.push('/alarm');
      });
    },
    deleteAlarmType: async (context, payload) => {
      ApiCaller.DELETE(`/alarmType/${payload.id}`).then(() => {
        context.commit('deleteAlarmType', { id: payload.id });
        router.push('/alarm-type');
      });
    },

    deleteAlarmStatusType: async (context, payload) => {
      ApiCaller.DELETE(`/alarmStatusType/${payload.id}`).then(() => {
        context.commit('deleteAlarmStatusType', { id: payload.id });
        router.push('/alarm-status-type');
      });
    },
  },
  modules: {},
};
