<template>
  <div v-if="selectedTracker" class="px-16 pt-8">
    <base-details-header :structure="headerStructure" :el="selectedTracker" />
    <base-subpage>
      <div class="">
        <div class="mb-4">
          <base-button @click="updateTrackerLog">Aggiorna dati</base-button>
        </div>
        <div>
          <h1 class="text-2xl font-bold mb-6">Pannello di controllo tracker</h1>
        </div>
        <div class="mb-4">
          <div>
            <div class="mb-2 text-xl">
              <h1>Generico</h1>
            </div>
            <div class="flex">
              <base-button class="mr-2" @click="trackerOn">Accendi</base-button>
              <base-button @click="trackerOff">Spegni</base-button>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <div class="mb-2 text-xl">
              <h1>Automobili</h1>
            </div>
            <div class="flex">
              <base-button class="mr-2" @click="openDoor(item.url)">Apri Portiere</base-button>
              <base-button @click="closeDoor">Chiudi Portiere</base-button>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <div class="mb-2 text-xl">
              <h1>Scooter</h1>
            </div>
            <div class="flex">
              <base-button @click="openTopbox">Apri Bauletto</base-button>
            </div>
          </div>
        </div>
      </div>
    </base-subpage>
  </div>
  <div v-else>
    <base-subpage class="flex justify-center">
      <div>
        <p>Tracker disabilitato o non in uso</p>
        <base-router-link path="/sharing/trackers" class="flex justify-center mt-4">Torna indietro</base-router-link>
      </div>
    </base-subpage>
  </div>
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('setSelectedTracker', selectedElID);
      });
      const selectedTracker = computed(() => {
        return store.getters.selectedTracker;
      });
      const headerStructure = {
        title: [
          {
            title: 'NAME',
          },
        ],
        data: [
          {
            class: 'text-sm font-normal text-gray-700',
            title: 'views.timeEntries.lastUpdate',

            name: 'FIXTIME',
          },
          {
            class: 'text-sm font-normal text-gray-700',
            title: 'Ultima risposta ',
            name: 'LASTUPDATE',
          },
          {
            title: 'Posizione',
            name: 'LONGITUDE',
            placeholder: 'LATITUDE',
            separator: ', ',
          },

          {
            title: 'Voltaggio',
            name: 'INPUT_VOLTAGE',
            footer: ' kW',
          },
          {
            title: 'Segnale GSM',
            name: 'GSM',
            footer: ' / 5',
          },
          {
            title: 'GPS Valido',
            name: 'VALID',
          },
        ],
      };

      const updateTrackerLog = () => store.dispatch('setSelectedTracker', selectedElID).then(() => router.go());
      const trackerOn = () => store.dispatch('trackerOn', selectedElID);
      const trackerOff = () => store.dispatch('trackerOff', selectedElID);
      const openTopbox = () => store.dispatch('openTopbox', selectedElID);
      const openDoor = () => store.dispatch('openDoor', selectedElID);
      const closeDoor = () => store.dispatch('closeDoor', selectedElID);

      return {
        selectedTracker,
        headerStructure,
        updateTrackerLog,
        trackerOn,
        trackerOff,
        openTopbox,
        openDoor,
        closeDoor,
      };
    },
  };
</script>
<style></style>
