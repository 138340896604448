<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => store.dispatch('getSubscriptionsProducts', payload);

      const subscriptions = computed(() => store.getters.subscriptionsProducts);

      // table
      const structure = {
        title: 'views.subscriptions.productList',
        creationLink: {
          title: '',
          namePath: 'menu.recharge.productCreation',
        },
        data: [
          {
            title: 'views.subscriptions.id',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/recharge/subscriptions/product/:id' },
          },
          {
            title: 'views.subscriptions.description',
            field: 'description',
            span: 1,
          },
          {
            title: 'views.subscriptions.amount',
            fields: [
              {
                name: 'price',
                subName: 'unit_amount_decimal',
                format: 'decimal_euro',
              },
              {
                name: 'price',
                subName: 'interval',
              },
            ],
            span: 1,
          },
          {
            title: 'views.subscriptions.active',
            field: 'active',
            boolean: {
              if_true: 'Disponibile',
              if_false: 'Disabilitato',
            },
            span: 1,
          },
        ],
      };

      return {
        data: subscriptions,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
