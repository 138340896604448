<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => store.dispatch('getInvoices', payload);

      const invoices = computed(() => store.getters.invoices);

      // table
      const structure = {
        data: [
          {
            title: 'views.billings.id',
            fields: [
              {
                placeholder: '@',
                name: 'payment_id',
                class: 'text-primary-400',
              },
              {
                name: 'payment_id',
              },
            ],
            span: 1,
            link: { idField: 'payment_id', path: '/billings/payments/:id' },
          },
          {
            title: 'views.billings.name',
            field: 'Denominazione',
            span: 1,
          },
          {
            title: 'views.billings.description',
            field: 'fgen_Causale',
            span: 1,
          },
          {
            title: 'views.billings.cf',
            field: 'CodiceFiscaleDestinatario',
            span: 1,
          },
          {
            title: 'views.billings.total',
            field: 'fgen_ImportoTotaleDocumento',
            format: 'euro',
            span: 1,
          },
          {
            title: 'views.billings.state',
            field: 'StatoDocumento',
            format: 'invoiceStatus',
            span: 1,
          },
        ],
      };

      return {
        data: invoices,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
