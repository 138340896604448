<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    setup() {
      return {
        headers: [
          { text: 'views.recharges.rechargesInProgress', name: 'menu.recharge.rechargesInProgress' },
          { text: 'views.recharges.zeusRecharges', name: 'menu.recharge.rechargesEcar' },
          { text: 'views.recharges.endedRecharges', name: 'menu.recharge.rechargesList' },
          { text: 'views.recharges.rechargeAnomaliesHistory', name: 'menu.recharge.rechargesAnomalies' },
          { text: 'views.recharges.rechargesAnalytics', name: 'menu.recharge.rechargesAnalytics' },
          { text: 'views.recharge.rechargesWhithoutCDR', name: 'menu.recharge.rechargesWhithoutCDR' },
        ],
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
