<template>
  <div v-if="selectedDeviceConfiguration" class="flex flex-col justify-center items-start py-4">
    <h1 class="text-3xl font-bold text-gray-900">
      {{ selectedDeviceConfiguration.name }}
      <text class="text-gray-400 text-base"></text>
    </h1>
    <h2 class="text-lg font-medium text-gray-400">{{ selectedDeviceConfiguration.description }} - [{{ selectedDeviceConfiguration.producer }}]</h2>
    <h3 class="text-lg font-medium text-blue-800">Revisione {{ selectedDeviceConfiguration.firmware_version }}</h3>
  </div>

  <base-subpage-bar :headers="headers" />
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => store.dispatch('getSelectedDeviceConfiguration', selectedElID));
      const selectedDeviceConfiguration = computed(() => store.getters.selectedDeviceConfiguration);

      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        { text: 'paths.overall', name: 'menu.device.deviceConfigurationGenerals' },
        { text: 'paths.vehicles', name: 'menu.device.deviceConfigurationVehicles' },
        { text: 'paths.devices', name: 'menu.device.deviceConfigurationDevices' },
      ];

      return {
        selectedDeviceConfiguration,
        headers,
      };
    },
  };
</script>
<style></style>
