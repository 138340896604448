import { Utils, ApiCaller } from '@/Utils/utils';
import Evse from './evse/evseIndex';
import Siemens from './siemens/index';
export default {
  state: {
    stations: [],
    stationLastRecharge: [],
    completeStationList: [],
    selectedStation: null,
    stationTypes: [],
  },
  getters: {
    stations: (state) => state.stations,
    stationLastRecharge: (state) => state.stationLastRecharge,
    selectedStation: (state) => {
      if (state.selectedStation !== null) return state.selectedStation[0];
      return state.selectedStation;
    },
    completeStationList: (state) => state.completeStationList,
    stationTypes: (state) => state.stationTypes,
  },
  mutations: {
    getStationTypes: (state, data) => (state.stationTypes = data),
    setLastRecharge: (state, data) => (state.stationLastRecharge = data),
    setSelectedStation: (state, data) => (state.selectedStation = data),
    getCompleteStationList: (state, data) => (state.completeStationList = data),
    getStations: (state, data) => (state.stations = data),
  },
  actions: {
    getStationTypes: (ctx, payload) =>
      ApiCaller.GET(`/stationTypes`, payload).then((data) => {
        ctx.commit('getStationTypes', data);
      }),
    setSelectedStation: (ctx, payload) =>
      ApiCaller.GET(`/station/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedStation', data);
      }),
    getCompleteStationList: (ctx, payload) =>
      ApiCaller.GET('/stationList', payload).then((data) => {
        ctx.commit('getCompleteStationList', Utils.createFiltrableData(data));
      }),
    getStations: (ctx, payload) =>
      ApiCaller.GET('/stationList', payload).then((data) => {
        ctx.commit('getStations', data);
      }),
    getLastRecharge: (ctx, payload) =>
      ApiCaller.GET(`/stations/lastRecharge/${payload.id}`, payload).then((data) => {
        ctx.commit('setLastRecharge', data);
      }),
    //QUERIES
    updateStation: (_, payload) => ApiCaller.PUT(`/updateStation/${payload.id}`, payload.body),
    createBaseStation: (_, payload) => ApiCaller.POST('/station', payload),
    changeStatus: (_, payload) => ApiCaller.PUT(`/station/${payload.id}/changeState/${payload.stato}`, null),
    changeEvseStatus: (_, payload) => ApiCaller.PUT(`/station/changeEvseState/${payload.evseId}`, payload),
    updateEvseStatus: () => ApiCaller.PUT(`https://scip.oncharge.it/recharge/scip/updateEvseStatus`, null),
    syncEcar: () => ApiCaller.PUT(`/siemens/syncWithEcar`, null),
    setCommittingDate: () => ApiCaller.PUT(`/siemens/setCommittingDate`, null),
  },
  modules: {
    Evse,
    Siemens,
  },
};
