<template>
  <button @click="getRoutes" class="hidden w-min whitespace-nowrap px-4 bg-red-200 mx-4 rounded-2xl">{{ selectedPlatformOrganisationRole }}</button>
</template>

<script>
  import { ref, computed, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { ApiCaller } from '@/Utils/utils';
  export default {
    setup() {
      const router = useRouter();
      const store = useStore();

      const menu = computed(() => store.getters.menu);

      const { components } = require('@/Utils/components');

      const loadingRoutes = ref(false);
      const routesCallbacks = ref([]);

      const getRoutes = async () => {
        // ROUTER
        loadingRoutes.value = true;
        store.dispatch('setMenu', null);
        const replaceComponentsInRoutes = (routes) => {
          var array = [];
          for (const i in routes) {
            let obj = { ...routes[i] };
            if (routes[i] && routes[i].children && routes[i].children) obj.children = replaceComponentsInRoutes(routes[i].children);
            if (routes[i] && routes[i].component) array.push({ ...obj, component: components[routes[i].component] });
          }
          return array;
        };

        const setRouter = (routes) => {
          return new Promise((resolve) => {
            let rts = replaceComponentsInRoutes(routes);
            for (const j in routesCallbacks.value) routesCallbacks.value[j]();
            routesCallbacks.value = [];
            for (const i in rts) {
              const route = rts[i];
              if (route && route.component) routesCallbacks.value.push(router.addRoute(route));
            }
            resolve();
          });
        };

        return new Promise((resolve) => {
          ApiCaller.GET('/platform/menu').then((data) => {
            store.dispatch('setMenu', data);
            setTimeout(async () => {
              await setRouter(data);
              loadingRoutes.value = false;
              router.replace(window.location.pathname);
              resolve(false);
            }, 5);
          });
        });
      };

      const selectedPlatformOrganisationRole = computed(
        // Only Organisation and Role
        () => `${store.getters.selectedRole}+${store.getters.selectedOrganisation}`,
      );
      watch(selectedPlatformOrganisationRole, getRoutes);

      watch(loadingRoutes, (nv) => {
        if (nv) store.dispatch('loaderShow');
        else store.dispatch('loaderHide');
      });

      router.beforeEach(async () => {
        if (menu.value == null && !loadingRoutes.value) {
          if (!store.getters.selectedPlatform || !store.getters.selectedRole || !store.getters.selectedOrganisation) return false;
          await getRoutes();
        }
      });

      return { selectedPlatformOrganisationRole, getRoutes };
    },
  };
</script>
