<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshFunction" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const refreshFunction = (payload) => store.dispatch('getConsumers', payload);

      const data = computed(() => store.getters.consumers);

      const structure = {
        title: 'menu.user.consumers',
        creationLink: {
          title: 'consumers.newConsumer',
          namePath: 'menu.consumers.consumersCreation',
        },
        data: [
          {
            title: 'consumers.name',
            fields: [
              {
                placeholder: '@',
                name: 'nickname',
              },
              {
                name: 'email',
              },
            ],
            link: { idField: 'auth0_id', path: '/user/consumers/consumers/:id' },
            span: 1,
          },
          {
            title: 'consumers.organisation',
            fields: [
              {
                name: 'organisation',
                subName: 'name',
              },
              {
                name: 'organisation',
                subName: 'type',
              },
            ],

            span: 1,
          },
          {
            title: 'views.timeEntries.lastUpdateDate',
            field: 'updated_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.timeEntries.creationDate',
            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      return { data, structure, refreshFunction };
    },
  };
</script>

<style></style>
