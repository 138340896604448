<template>
  <div class="mb-10 w-full mx-auto">
    <a
      :href="'https://oc.zeus.on-charge.it/OCWeb2/#/cuDetails/' + el.cuCode"
      target="_blank"
      class="text-blue-600 bg-gray-200 rounded-2xl shadow-xl px-2 py-1 hover:bg-gray-400 hover:text-blue-800">
      {{ $t('views.stations.zeusLink') }}
    </a>
    <a
      :href="`/recharge/stations/siemens/${el.cuCode}/generals`"
      target="_blank"
      class="text-blue-600 ml-4 bg-gray-200 rounded-2xl shadow-xl px-2 py-1 hover:bg-gray-400 hover:text-blue-800">
      {{ $t('views.stations.selfZeusLink') }}
    </a>
    <!-- PLATE, STATO -->
    <div class="flex my-4 items-center">
      <div class="text-lg font-bold dark:text-white">{{ el.plate }}</div>
      <div class="bg-primary-50 dark:bg-gray-800 rounded ml-4 px-2 py-0.5 flex items-center">
        <p class="text-xs text-primary-500">{{ el.stato }}</p>
      </div>
      <div class="flex">
        <station-select @change="changeStatus" :data="data" />
      </div>
    </div>

    <!-- ID, INDIRIZZO, COORDINATE -->
    <div class="text-gray-400">
      <p class="text-primary-400 text-sm mb-2">@{{ el.id }}</p>
      <p class="text-lg text-gray-900 dark:text-white font-semibold leading-none">
        {{ el.indirizzo }}
      </p>
      <p class="text-base text-gray-500 leading-none mt-1">{{ t('address.coordinates') }} {{ el.lat }}, {{ el.lng }}</p>
    </div>

    <div class="mt-4">
      <div>
        <span>Ultima ricarica</span>
        <span class="flex p-2 bg-gray-200 rounded-2xl">
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Presa</p>
            <p class="ml-2 font-bold text-primary-700">{{ lastRecharge?.numero_presa }}</p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Inizio ricarica</p>
            <p class="ml-2 font-bold text-primary-700">
              {{ parseField(lastRecharge?.inizio_ricarica, 'datetime_ignore&parse') }}
            </p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Fine ricarica</p>
            <p class="ml-2 font-bold text-primary-700">
              {{ parseField(lastRecharge?.fine_ricarica, 'datetime_ignore&parse') }}
            </p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Energia consumata</p>
            <p class="ml-2 font-bold text-primary-700">{{ lastRecharge?.kw_consumati }} kW</p>
          </span>
        </span>
      </div>
      <div v-if="rechargeInProgress?.length > 0" class="mt-4">
        <span>In corso</span>
        <span v-for="recharge in rechargeInProgress" :key="recharge?.id" class="flex mb-2 p-2 bg-gray-200 rounded-2xl">
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Presa</p>
            <p class="ml-2 font-bold text-primary-700">{{ recharge?.numero_presa }}</p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Tempo trascorso</p>
            <p class="ml-2 font-bold text-primary-700">{{ parseField(recharge?.inizio_ricarica, 'timePassed') }}</p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Energia consumata</p>
            <p class="ml-2 font-bold text-primary-700">{{ recharge?.consumed_energy }} kW</p>
          </span>
          <span class="ml-2 bg-gray-100 rounded-xl px-2 py-1 flex">
            <p class="text-primary-400 text-sm self-end">Sessione</p>
            <p class="ml-2 font-bold text-primary-700">{{ recharge?.id }}</p>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import StationSelect from './StationSelect.vue';
  import { useI18n } from 'vue-i18n';
  import { computed } from 'vue';
  import { Utils } from '@/Utils/utils';
  export default {
    components: {
      StationSelect,
    },
    props: {
      el: {
        type: Object,
        required: true,
      },
    },
    setup() {
      //init vue things
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      const parseField = Utils.formatData;

      const stationID = route.params.id;
      store.dispatch('getLastRecharge', { id: stationID });
      store.dispatch('getStationRechargeInProgress', stationID);

      const lastRecharge = computed(() => {
        if (store.getters.stationLastRecharge?.length > 0) return store.getters.stationLastRecharge[0];
        return [];
      });
      const rechargeInProgress = computed(() => store.getters.stationRechargeInProgress);
      //change stato
      const changeStatus = (event) => {
        const stato = event.target.value;
        const id = route.params.id;
        store.dispatch('changeStatus', { id: id, stato: stato }).then(() => router.go());
      };
      const data = ['', 'PLANNED', 'INSTALLED', 'DISMISSED', 'MAINTENANCE', 'OUTOFSERVICE', 'AVAILABLE'];
      return { data, lastRecharge, rechargeInProgress, parseField, changeStatus, t: useI18n().t };
    },
  };
</script>

<style></style>
