<template>
  <div>
    <div class="flex space-x-4 w-full justify-end items-center h-24">
      <span class="isolate inline-flex rounded-md shadow-sm">
        <button
          v-for="(button, i) in commandButtons"
          :key="i"
          @click="selectCommand = button"
          type="button"
          class="relative first:rounded-l-md last:rounded-r-md -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
          {{ button.label }}
        </button>
      </span>
    </div>

    {{ selectCommand }}

    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />

    <TransitionRoot as="template" :show="!(selectCommand === null)">
      <Dialog as="div" class="relative z-10" @close="selectCommand = null">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              :show="!(selectCommand === null)"
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ selectCommand?.label }}</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        <b>{{ selectCommand?.hex }}</b>
                        - {{ selectCommand?.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    @click="sendCommand(selectCommand.command)">
                    Invia
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    @click="selectCommand = null"
                    ref="cancelButtonRef">
                    Annulla
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
  import { computed, watch, ref } from 'vue';
  import { useStore } from 'vuex';

  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

  export default {
    components: {
      Dialog,
      DialogPanel,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      ExclamationTriangleIcon,
    },
    setup() {
      const store = useStore();
      function refreshPage() {
        if (selectedVehicle.value && selectedVehicle.value.device) store.dispatch('getCommands', { id: selectedVehicle.value.device.id });
      }
      const selectedVehicle = computed(() => store.getters.selectedVehicle);
      watch(selectedVehicle, refreshPage);

      const commands = computed(() => store.getters.commands);
      const structure = {
        title: 'vehicles.commands',
        data: [
          {
            title: 'ID',
            fields: [
              {
                placeholder: '@',
                name: '_id',
                class: 'text-primary-300',
              },
              {
                name: 'serial',
                class: 'font-bold ',
              },
            ],
            span: 1,
          },
          {
            title: 'Comando',
            field: 'command',
            span: 2,
          },
          {
            title: 'HEX',
            field: 'hex',
            span: 1,
          },
          {
            title: 'status',
            field: 'status',
            format: 'commandStatus',
            span: 2,
          },
          {
            title: 'Ricevuto',
            field: 'received',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Creazione',
            field: 'createdAt',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Ultimo aggiornamento',
            field: 'updatedAt',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      const selectCommand = ref(null);
      const commandButtons = [
        {
          label: 'Chiudi relais immobilizzatore',
          description: "Chiude il relais dell'immobilizzatore e sblocca il veicolo.",
          command: 'IMMOBILIZER_ON',
          hex: '5AE0AA',
        },
        {
          label: 'Apri relais immobilizzatore',
          description: "Apre il relais dell'immobilizzatore e blocca il veicolo.",
          command: 'IMMOBILIZER_OFF',
          hex: '5AE1AA',
        },
        {
          label: 'Richiedi info BT300',
          description: 'Richiede le informazioni del dispositivo BT300.',
          command: 'BT300_INFO',
          hex: '5AF0AA',
        },
        {
          label: 'Richiedi configurazione',
          description: 'Richiede la configurazione del dispositivo BT300.',
          command: 'BT300_CONFIG',
          hex: '5AF1AA',
        },
        {
          label: 'Richiedi stato I/O',
          description: 'Richiede lo stato delle uscite digitali del dispositivo BT300.',
          command: 'BT300_IO',
          hex: '5AF2AA',
        },
        {
          label: 'Chiudi uscita digital 1',
          description: "Chiude l'uscita digitale 1 del dispositivo BT300.",
          command: 'BT300_DO1_ON',
          hex: '5AE201AA',
        },
        {
          label: 'Apri uscita digital 1',
          description: "Apre l'uscita digitale 1 del dispositivo BT300.",
          command: 'BT300_DO1_OFF',
          hex: '5AE200AA',
        },
        {
          label: 'Chiudi uscita digital 2',
          description: "Chiude l'uscita digitale 2 del dispositivo BT300.",
          command: 'BT300_DO2_ON',
          hex: '5AE301AA',
        },
        {
          label: 'Apri uscita digital 2',
          description: "Apre l'uscita digitale 2 del dispositivo BT300.",
          command: 'BT300_DO2_OFF',
          hex: '5AE300AA',
        },
        {
          label: 'ELIMINA TUTTI I DATI',
          description: 'Cancellerai tutti i dati del dispositivo BT300, come comandi, lavorazioni, messaggi ed eventi. SEI VERAMENTE SICURO?',
          command: 'DELETE_DATA',
          hex: 'DELETE_DATA',
        },
      ];
      const sendCommand = async (command) => {
        const result = await store.dispatch('sendDeviceCommand', { id: selectedVehicle.value.device.id, command: command });
        console.log('RESULT', result);
        selectCommand.value = null;
      };

      return {
        data: commands,
        structure,
        refreshPage,
        selectCommand,
        commandButtons,
        sendCommand,
      };
    },
  };
</script>

<style></style>
