<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
export default {
  setup() {
    //structure of page
    const insertData = {
      title: "Inserimento nuovo tracker",
      redirect_path: "/sharing/trackers",
      create_endpoint: "createTracker",
      creation_title: "Creazione tracker",
      dispatchName: "getTrackers",
      data: [
        {
          title: "IMEI",
          name: "IMEI",
          required: true,
        },
        {
          title: "user.notes",
          
          name: "notes",
        },
        {
          title: "Visibile ai test",
          name: "testable",
          boolean: true,
          class: "self-center",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>