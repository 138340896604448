<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    setup() {
      return {
        headers: [
          { text: 'views.rentals.endedRentals', name: 'menu.sharing.rentalsConcluded' },
          { text: 'views.rentals.ongoingRentals', name: 'menu.sharing.rentalsInProgress' },
        ],
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
