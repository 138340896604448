<template>
  <span hidden></span>
</template>
<script>
  import { useStore } from 'vuex';
  import { watch, computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  export default {
    setup() {
      const store = useStore();
      const i18n = useI18n();

      const localesSetup = async (pfm) => {
        console.log('LOCALES', pfm);
        if (pfm == null) return;
        await store.dispatch('retrieveLocales', pfm);
        const locales = store.getters.locales;
        for (const key in locales) {
          if (Object.hasOwnProperty.call(locales, key)) {
            const locale = locales[key];
            const msg = {};
            for (const k in locale) {
              if (Object.hasOwnProperty.call(locale, k)) {
                const message = locale[k];
                msg[k] = message;
              }
            }
            i18n.setLocaleMessage(key, msg);
          }
        }
      };
      const db = computed(() => store.getters.currentPlatform.db);
      watch(db, async (pfm) => await localesSetup(pfm), { immediate: true });
      return {};
    },
  };
</script>

<style></style>
