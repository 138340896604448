<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshFunction" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, watch } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const refreshFunction = (payload) => store.dispatch('getOrganisations', payload);

      const data = computed(() => store.getters.organisations);

      const selectedOrganisation = computed(() => store.getters.selectedOrganisation);
      watch(selectedOrganisation, refreshFunction);

      const structure = {
        title: 'menu.user.organisation',
        data: [
          {
            title: 'consumers.name',
            fields: [
              {
                name: 'name',
              },
              {
                name: 'vat',
              },
            ],
            span: 1,
          },
          {
            title: 'consumers.referent',
            fields: [
              {
                name: 'referent_name',
              },
              {
                name: 'referent_email',
              },
              {
                name: 'referent_cellphone',
              },
            ],
            span: 1,
          },
          {
            title: 'consumers.type',
            field: 'type',
            span: 1,
          },
        ],
      };

      return { data, structure, refreshFunction };
    },
  };
</script>

<style></style>
