<template>
  <div
    v-for="field in buttons.fields"
    :key="field"
    v-show="field.condition_val === el[buttons.condition]"
    @click="field.function(field.returned_val ? el[field.returned_val] : el)"
    class="cursor-pointer text-primary-500 font-semibold">
    <a v-if="field.title">
      {{ t(field.title) }}
    </a>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      buttons: {
        type: Object,
        required: true,
      },
      el: {
        type: Object,
        required: false,
      },
    },
    setup() {
      return { t: useI18n().t };
    },
  };
</script>

<style></style>
