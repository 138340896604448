<template>
  <base-list-element class="grid grid-cols-2">
    <div v-for="el in editableFields.data" :key="el">
      <base-checkbox
        v-if="el.boolean"
        :title="t(el.title)"
        v-model="queryObj[el.name]"
        :class="' ' + el.class"
        :checked="el.placeholder"
      />
      <base-select
        v-else-if="el.select"
        :title="t(el.title)"
        :model-value="queryObj[el.name]"
        v-model="queryObj[el.name]"
        :data="el.select"
        :mapper="el.mapper"
        :statusOK="el.required ? queryObj[el.name] !== '' : true"
        @change="el.on_change"
      />
      <base-input-field
        v-else
        :title="t(el.title)"
        :placeholder="el.placeholder"
        v-model="queryObj[el.name]"
        :class="'mx-2 ' + el.class"
      />
    </div>
  </base-list-element>
  <base-button icon="save" @click="showModal = true">Salva</base-button>
  <Teleport to="body">
    <base-confirm-field :show="showModal" @close="showModal = false">
      <template #header>
        <h3>{{ editableFields.edit_title }}</h3>
      </template>
      <template #body>
        <p>{{ bodyModel() }}</p>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <p>Continuare?</p>
          <div>
            <button
              class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2"
              type="button"
              @click="sendForm"
            >
              Si
            </button>
            <button
              class="w-6 h-6 hover:bg-red-200 text-sm rounded-full"
              type="button"
              @click="showModal = false"
            >
              No
            </button>
          </div>
        </div>
      </template>
    </base-confirm-field>
  </Teleport>
</template>

<script>
import { i18n } from "@/i18n";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  props: {
    editableFields: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const t = i18n.global.t;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const queryObj = reactive(createObj(props.editableFields.data));

    function createObj(obj) {
      let newObj = {};
      for (let el in obj) {
        if (obj[el].select && obj[el].mapper) {
          newObj[obj[el].name] =
            obj[el].mapper.current !== undefined ? obj[el].mapper.current : "";
        } else {
          newObj[obj[el].name] = "";
        }
      }
      return newObj;
    }
    //check if field are changed
    function checkFields() {
      let finalObj = {};
      for (const itm in queryObj) {
        if (queryObj[itm] === "") continue;
        finalObj[itm] = parseField(queryObj[itm]);
      }
      if (finalObj === {}) return;
      return finalObj;
    }
    function sendForm() {
      const body = checkFields();
      if (!body || body === {}) return;
      let id;
      if (props.editableFields.edit_id_route_name)
        id = route.params[props.editableFields.edit_id_route_name];
      else id = route.params.id;
      store
        .dispatch(props.editableFields.edit_endpoint, {
          id,
          body,
        })
        .then(() => (showModal.value = false))
        .then(() => setTimeout(() => router.go(), 1500));
    }
    //check fields
    function parseField(itm) {
      if (itm === true) return "1";
      else if (itm === false) return "0";
      return itm;
    }
    //confirm modal
    const showModal = ref(false);
    function bodyModel() {
      let finalString = "Stai per modificare ";
      for (let el in queryObj) {
        if (queryObj[el] === "") continue;
        finalString += ` '${el}' in '${queryObj[el]}'`;
      }
      return finalString;
    }
    return {
      queryObj,
      showModal,
      sendForm,
      bodyModel,
      t,
    };
  },
};
</script>

<style></style>
