<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => store.dispatch('getSubscriptions', payload);

      const subscriptions = computed(() => store.getters.subscriptions);

      // table
      const structure = {
        title: 'views.subscriptions.title',
        data: [
          {
            title: 'views.subscriptions.id',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'id',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/recharge/subscription/:id' },
          },
          {
            title: 'views.subscriptions.user',
            fields: [
              {
                placeholder: '@',
                name: 'user',
                subName: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'user',
                subName: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', subField: 'user', path: '/user/users/:id' },
          },
          {
            title: 'views.subscriptions.tot_recharges',
            field: 'tot_recharges',
            placeholder: '0',
            span: 1,
          },
          {
            title: 'views.subscriptions.tot_kw',
            field: 'tot_kw',
            placeholder: '0',
            span: 1,
          },
          {
            title: 'views.subscriptions.tot_amount',
            field: 'tot_amount',
            placeholder: '0',
            span: 1,
          },
          {
            title: 'views.subscriptions.created_at',
            field: 'created_at',
            format: 'date',
            span: 1,
          },
          {
            title: 'views.subscriptions.ended_at',
            field: 'ended_at',
            format: 'date',
            span: 1,
          },
          {
            title: 'views.subscriptions.status',
            field: 'stripe',
            subField: 'status',
            span: 1,
          },
        ],
      };

      return {
        data: subscriptions,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
