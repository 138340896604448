<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const refreshPage = (payload) => store.dispatch('getDeviceConfigurations', payload);
      const devicesConfiguration = computed(() => store.getters.deviceConfigurations);

      // table
      const structure = {
        title: 'deviceConfiguration.configurationList',
        creationLink: {
          title: 'deviceConfiguration.newConfiguration',
          namePath: 'menu.device.deviceConfigurationsCreation',
        },
        data: [
          {
            title: 'name',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/device/deviceConfigurations/details/:id' },
          },
          {
            title: 'description',
            toTranslate: true,
            field: 'description',
            span: 1,
          },
          {
            title: 'deviceConfiguration.firmwareVersion',
            field: 'firmware_version',
            span: 1,
          },
          {
            field: 'producer',
            title: 'deviceConfiguration.productor',
            span: 1,
          },
          {
            title: 'creationDate',
            field: 'created_at',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'lastUpdateDate',
            field: 'updated_at',
            format: 'datetime',
            span: 1,
          },
        ],
      };

      return {
        data: devicesConfiguration,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
