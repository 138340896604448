<template>
  <base-page>
    <div class="relative bg-white dark:bg-gray-900 flex w-full justify-between items-start rounded-3xl mx-auto h-full">
      <div v-if="selectedZone" class="relative w-3/5 h-full p-6">
        <div class="flex flex-col items-start">
          <div @click="resetSelectedAndUpdate" class="text-lg text-gray-900 dark:text-white material-icons mb-3 cursor-pointer">arrow_back_ios_new</div>
          <h1 class="font-bold text-3xl whitespace-nowrap">
            {{ selectedZone.name }}
          </h1>
          <h3 class="font-medium text-lg text-gray-400 whitespace-nowrap mb-4">
            {{ selectedZone.description ? selectedZone.description : 'Nessuna descrizione' }}
          </h3>

          <span class="w-full mb-2">
            <base-input-field title="Nome" v-model="updatePayload.name" />
            <base-input-field title="Descrizione" v-model="updatePayload.description" />
            <base-input-field title="Latitudine centro" v-model="updatePayload.center_lat" />
            <base-input-field title="Longitudine centro" v-model="updatePayload.center_lon" />
            <base-input-field title="Raggio" type="number" v-model="updatePayload.radius" />
          </span>

          <span class="flex justify-end items-center w-full">
            <base-button icon="delete" v-if="updatePayload.id != 'new'" class="ml-2 bg-red-500 hover:bg-red-800">
              <span class="px-12" @click="deleteZone">{{ t('buttons.deleteZone') }}</span>
            </base-button>
            <base-button icon="save" v-if="updatePayload.id != 'new'" class="ml-2"><span class="px-12" @click="updateZone">Salva</span></base-button>
            <base-button icon="add" v-else class="ml-2"><span class="ml-2 px-12" @click="addZone">Crea</span></base-button>
          </span>

          <span class="opacity-30 mt-24 hidden">{{ updatePayload }}</span>
        </div>
      </div>
      <div v-else class="relative w-3/5 h-full px-6 py-10">
        <template v-if="selectedZoneGroup && selectedZoneGroup.zones && selectedZoneGroup.zones.length">
          <base-list-table
            @rowClick="
              selectedZone = $event;
              updatePayload = $event;
            "
            @add="addZoneRequest"
            :data="selectedZoneGroup.zones"
            :structure="structure"
            :compact="true"
            :force-pagination-num="5"
            :refreshFunction="refreshPage"
          />
        </template>
        <template v-else>
          <!-- HEADER -->
          <div class="flex flex-col items-start justify-between p-5 pt-10 w-full">
            <div class="flex flex-col items-start">
              <h1 class="font-bold text-3xl my-2 md:my-0 whitespace-nowrap">Nessuna zona associata</h1>
            </div>

            <span class="flex flex-row w-full items-center justify-center mt-24">
              <div class="ml-4">
                <base-button @click="addZoneRequest">
                  <span class="material-icons">add</span>
                  <span class="ml-2 hidden whitespace-nowrap md:block">Aggiungi la prima zona</span>
                </base-button>
              </div>
            </span>
          </div>
          <!-- END HEADER -->
        </template>
      </div>

      <!-- MAPPA -->
      <div class="relative w-2/5 h-full rounded-3xl overflow-hidden mx-6 my-24 max-h-96">
        <GMapMap :center="center" :zoom="zoom" map-type-id="roadmap" :options="{ mapId: 'roadmap' }">
          <!-- MARKER AND CIRCLE FOR SINGLE ZONE -->
          <GMapMarker v-if="updatePayload" :key="updatePayload.id" draggable :position="updatePayloadCenter" @dragend="updateCenter($event.latLng)" />
          <GMapCircle v-if="updatePayload" :key="updatePayload.id" :radius="parseInt(updatePayload.radius)" :center="updatePayloadCenter" :options="circleOptions" />

          <!-- MARKERS AND CIRCLES FOR EVERY ZONE -->
          <template v-else-if="selectedZoneGroup && selectedZoneGroup.zones">
            <GMapCircle v-for="(zone, i) in selectedZoneGroup.zones" :key="i" :radius="parseInt(zone.radius)" :center="{ lat: zone.center_lat, lng: zone.center_lon }" :options="circleOptions" />
            <GMapCluster :minimumClusterSize="200">
              <GMapMarker
                v-for="(zone, i) in selectedZoneGroup.zones"
                :key="i"
                :position="{ lat: zone.center_lat, lng: zone.center_lon }"
                @click="
                  selectedZone = zone;
                  updatePayload = zone;
                "
              >
                <GMapInfoWindow>
                  <div>
                    <b>{{ zone.name }}</b>
                  </div>
                </GMapInfoWindow>
              </GMapMarker>
            </GMapCluster>
          </template>
        </GMapMap>
      </div>
      <!-- FINE MAPPA -->
    </div>
  </base-page>
</template>
<script>
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  export default {
    name: 'App',
    setup() {
      const store = useStore();

      const center = computed(() => {
        if (updatePayload.value && Object.prototype.hasOwnProperty.call(updatePayload.value, 'center_lat'))
          return {
            lat: parseFloat(updatePayload.value.center_lat),
            lng: parseFloat(updatePayload.value.center_lon),
          };

        if (selectedZoneGroup.value != null && Object.prototype.hasOwnProperty.call(selectedZoneGroup.value, 'zones') && selectedZoneGroup.value.zones.length > 0) {
          let temp = { lat: 0, lng: 0 };
          let i = 0;
          selectedZoneGroup.value.zones.forEach((zone) => {
            temp.lat += parseFloat(zone.center_lat);
            temp.lng += parseFloat(zone.center_lon);
            i++;
          });
          temp.lat = parseFloat(temp.lat / i);
          temp.lng = parseFloat(temp.lng / i);

          return temp;
        }
        return { lat: 41.92046786992858, lng: 12.471292825927419 };
      });

      const zoom = computed(() => {
        return 14;
      });

      const updateCenter = (payload) => {
        if (updatePayload.value != null) {
          updatePayload.value.center_lat = parseFloat(payload.lat());
          updatePayload.value.center_lon = parseFloat(payload.lng());
        }
      };

      const circleOptions = {
        strokeColor: '#00FF00',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#00FF00',
        fillOpacity: 0.35,
      };

      // Zone
      const selectedZone = ref(null); // Zona selezionata
      const updatePayload = ref(null); // Dati per modifica / update

      // Reset selectedZone and updatePayload
      const resetSelectedAndUpdate = () => {
        selectedZone.value = null;
        updatePayload.value = null;
      };

      const updatePayloadCenter = computed(() => {
        return {
          lat: parseFloat(updatePayload.value.center_lat),
          lng: parseFloat(updatePayload.value.center_lon),
        };
      });

      // Add Zone
      const addZone = () => {
        const payload = {
          id: selectedZoneGroup.value.id,
          name: updatePayload.value.name,
          description: updatePayload.value.description,
          center_lat: parseFloat(updatePayload.value.center_lat),
          center_lon: parseFloat(updatePayload.value.center_lon),
          radius: parseInt(updatePayload.value.radius),
          shape: 'CIRCLE',
        };
        console.log('Aggiungo', payload);
        store.dispatch('createZone', payload).then(() => {
          resetSelectedAndUpdate();
        });
      };

      // Update Zone
      const updateZone = () => {
        const payload = {
          id: selectedZoneGroup.value.id,
          zone_id: updatePayload.value.id,
          name: updatePayload.value.name,
          description: updatePayload.value.description,
          center_lat: parseFloat(updatePayload.value.center_lat),
          center_lon: parseFloat(updatePayload.value.center_lon),
          radius: parseInt(updatePayload.value.radius),
        };
        store.dispatch('updateZone', payload).then(resetSelectedAndUpdate);
      };

      // Delete Zone
      const deleteZone = () => {
        const payload = {
          id: selectedZoneGroup.value.id,
          zone_id: updatePayload.value.id,
        };
        store.dispatch('removeZone', payload).then(resetSelectedAndUpdate);
      };

      const addZoneRequest = () => {
        selectedZone.value = {
          name: 'Nuova zona',
          description: 'Crea nuova zona circolare di seguito',
        };
        updatePayload.value = {
          id: 'new',
          name: '',
          description: '',
          center_lat: 41.92054952190749,
          center_lon: 12.471263217329394,
          radius: 100,
        };
      };

      //zoneGroup
      const selectedZoneGroup = computed(() => {
        let zg = store.getters.selectedZoneGroup;
        if (zg == null || zg == 'undefined') return null;
        if (Object.prototype.hasOwnProperty.call(zg, 'zones'))
          zg.zones.map((z) => {
            z.searchString = '';
            z.searchString += z.name;
            z.searchString += z.description;
          });
        return zg;
      });

      const refreshPage = () =>
        store.dispatch('getSelectedZoneGroup', {
          id: selectedZoneGroup.value.id,
        });

      const clone = (zoneId) =>
        store
          .dispatch('cloneZone', {
            id: selectedZoneGroup.value.id,
            zone_id: zoneId,
          })
          .then(() => resetSelectedAndUpdate());

      // Zone table
      const structure = computed(() => {
        const title = 'Zone per ' + selectedZoneGroup.value.name;
        return {
          title: title,
          creationLink: {
            title: 'Aggiungi',
            namePath: '',
          },
          data: [
            {
              title: 'user.name.first',
              toTranslate: true,
              field: 'name',
              span: 1,
            },
            {
              title: 'description',
              toTranslate: true,
              field: 'description',
              span: 1,
            },
            {
              title: '',
              button: {
                title: 'Clona',
                function: clone,
                field_returned: 'id',
                class: 'bg-gray-300 text-gray-600 dark:text-gray-300',
              },
              span: 1,
            },
          ],
        };
      });

      return {
        selectedZoneGroup,
        selectedZone,
        updatePayload,
        center,
        zoom,
        circleOptions,
        structure,
        updatePayloadCenter,
        resetSelectedAndUpdate,
        updateCenter,
        addZoneRequest,
        addZone,
        updateZone,
        deleteZone,
        refreshPage,

        t: useI18n().t,
      };
    },
  };
</script>

<style scoped>
  .vue-map-container,
  .vue-map {
    width: 100% !important;
    height: 100% !important;
  }
</style>
