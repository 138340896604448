<template>
  <div v-if="data">
    <!-- header -->
    <div class="flex justify-center">
      <a :href="'https://oc.zeus.on-charge.it/OCWeb2/#/cuDetails/' + data.cuCode" target="_blank" class="text-blue-600">
        {{ $t('views.stations.zeusLink') }}
      </a>
    </div>
    <div class="flex justify-center">
      <div class="text-center">
        <div :class="'flex justify-center ' + parseState(data.statusId)">
          <span class="material-icons mr-2">online_prediction</span>
          <p>{{ parseState(data.statusId, 'text') }}</p>
          <button
            class="bg-gray-200 px-1 rounded-lg shadow-lg text-sm ml-2"
            :class="readParametersClass"
            @click="readParameters">
            <svg
              v-show="readParametersLoader"
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 mr-2 text-black animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor" />
            </svg>
            {{ $t('stations.readParameters') }}
          </button>
          <div v-if="lastUpdate" class="text-black text-xs self-center ml-4">
            <p>{{ $t('stations.lastUpdated') }}</p>
            <p>{{ lastUpdate }}</p>
          </div>
        </div>
        <p class="text-lg font-bold mt-2">{{ data.name }} - {{ data.sn }}</p>
        <p class="mt-2">
          {{ data.cuModelDesc }} - SIM {{ data.connected ? $t('station.connected') : $t('station.notConnected') }}
        </p>
      </div>
    </div>
    <div class="flex justify-center mt-2">
      <button class="bg-gray-200 px-1 rounded-lg shadow-lg text-sm mt-2 mr-2" @click="reloadStation">
        {{ $t('stations.reloadData') }}
      </button>
      <button
        class="bg-gray-200 px-1 rounded-lg shadow-lg text-sm mt-2 ml-2"
        @click="
          openPanel = true;
          modelData.cuCode = data.cuCode;
          modelData.operation = 'reboot';
        ">
        {{ $t('stations.rebootStation') }}
      </button>
    </div>
    <!-- body -->
    <div class="flex justify-center mt-4">
      <div class="flex" v-for="plug in data.plugList" :key="plug.plugId">
        <!-- inizio card -->
        <div
          class="mr-8 bg-gray-100 p-2 rounded-2xl hover:bg-gray-300 focus:bg-gray-300 shadow-md"
          :class="plug.rechargeStatus === 'ACTIVE' ? 'bg-gray-400 animate-pulse hover:animate-none ' : ''">
          <div>
            <div class="text-center">
              <div class="flex justify-center">
                <p class="text-sm">
                  {{ plug.faulted != '2' ? $t('stations.enabled') : $t('stations.notEnabled') }}
                </p>
                <button
                  @click="
                    openPanel = true;
                    modelData.cuCode = data.cuCode;
                    modelData.cpID = plug.plugId;
                    modelData.plugStatus = plug.faulted != '2' ? '2' : '0';
                    modelData.operation = 'changeVisibility';
                  "
                  :class="
                    (plug.faulted != '2' ? 'bg-green-900 ' : 'bg-red-900 ') +
                    ' ml-2 self-center w-4 h-4  border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500'
                  " />
              </div>
              <p class="font-bold">Presa {{ plug.plugId }} - {{ plug.plugTypeDesc ? plug.plugTypeDesc : '-' }}</p>
              <button
                class="text-xs text-gray-400 hover:text-gray-500"
                @click="
                  openPanel = true;
                  modelData.plugStatus = null;
                  modelData.cuCode = data.cuCode;
                  modelData.cpID = plug.plugId;
                  modelData.operation = 'unlockPlug';
                ">
                {{ $t('stations.unlockPlug') }}
              </button>
              <p>{{ plug.locked ? 'Chiusa' : 'Aperta' }}</p>
            </div>
          </div>
          <div v-if="!plug.locked" class="mt-4">
            <div class="flex justify-center font-bold text-lg">
              <p>{{ $t('stations.sessionInProgress') }}</p>
            </div>
            <div class="flex justify-between text-sm">
              <div class="text-left text-gray-600">
                <p>{{ $t('stations.state') }}</p>
                <p>{{ $t('stations.id') }}</p>
                <p>{{ $t('stations.duration') }}</p>
              </div>
              <div class="overflow-x-hidden hover:overflow-x-visible font-bold ml-2 w-16">
                <p>{{ plug.rechargeStatus }}</p>
                <p>{{ plug.rechargeSessionId ? plug.rechargeSessionId : '-' }}</p>
                <div class="flex">
                  <p>{{ plug.rechargeTime ? parseField((dateNow - plug.rechargeTime) * 0.001, 'timePassed') : '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-6" v-if="openPanel">
      <div class="bg-gray-300 p-3 rounded-xl">
        <div class="flex justify-between">
          <base-input-field :title="$t('stations.motivation')" v-model="modelData.reason" />
        </div>
        <div class="flex justify-between">
          <button
            @click="openPanel = false"
            class="rounded-full flex justify-center items-center bg-gray-200 p-1 shadow-md border border-gray-300 hover:bg-gray-300">
            {{ $t('stations.cancel') }}
          </button>
          <button class="hover:text-gray-500 ml-2" @click="sendModule" v-if="modelData.reason != ''">
            {{ $t(returnSendCommandValue(modelData.plugStatus)) }}
            {{ modelData.cpID }}
          </button>
        </div>
      </div>
    </div>
    <!-- footer -->
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { Utils } from '@/Utils/utils';
  import { ref, reactive } from 'vue';

  export default {
    setup() {
      //  INIT

      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      store.dispatch('getSelectedSiemensStation', selectedElID);
      const selectedSiemensStation = computed(() => {
        return store.getters.selectedSiemensStation;
      });
      const reloadStation = () => store.dispatch('getSelectedSiemensStation', selectedElID);

      const parseState = (state, operation) => {
        switch (state) {
          case 2:
            return operation == 'text' ? 'Installata' : 'text-gray-400';
          case 4:
            return operation == 'text' ? 'Dismessa' : '';
          case 1:
            return operation == 'text' ? 'Operativa - Disponibile' : 'text-green-400';
          case 101:
            return operation == 'text' ? 'Operativa - Occupata' : 'text-purple-700';
          case 102:
            return operation == 'text' ? 'Operativa - Parzialmente occupata' : 'text-blue-600';
          case 104:
            return operation == 'text' ? 'Parzialmente operativa' : 'text-yellow-600';
          case 3:
            return operation == 'text' ? 'Non disponibile - Non funzionante' : 'text-red-700';
          case 21:
            return operation == 'text' ? 'Non disponibile - Non raggiungibile' : 'text-red-900';
          default:
            return operation == 'text' ? 'Stato non riconosciuto' : '';
        }
      };

      const parseField = (field, format) => Utils.formatData(field, format);
      const dateNow = ref(Date.now());
      setInterval(() => (dateNow.value = Date.now()), 1000);

      const headers = [
        {
          text: 'paths.generals',
          name: 'menu.recharge.stationSiemensGenerals',
        },
      ];

      const modelData = reactive({
        cuCode: '',
        cpID: '',
        plugStatus: '',
        reason: '',
        operation: '',
      });

      const sendModule = () => {
        if (modelData.operation == 'changeVisibility') {
          const data = {
            cuCode: modelData.cuCode,
            cpID: modelData.cpID,
            plugStatus: modelData.plugStatus,
            reason: modelData.reason,
          };
          store.dispatch('changeVisibility', data).then(() => router.go());
        } else if (modelData.operation == 'unlockPlug') {
          const data = {
            cuCode: modelData.cuCode,
            cpID: modelData.cpID,
            reason: modelData.reason,
          };
          store.dispatch('unlockPlug', data).then(() => router.go());
        } else if (modelData.operation == 'reboot') {
          const data = {
            cuCode: modelData.cuCode,
            reason: modelData.reason,
          };
          store.dispatch('rebootStation', data).then(() => router.go());
        }
      };
      const openPanel = ref(false);

      const returnSendCommandValue = (plugStatus = null) => {
        switch (modelData.operation) {
          case 'unlockPlug':
            return 'stations.unlockPlug';
          case 'changeVisibility':
            return plugStatus != '2' ? 'stations.enablePlug' : 'stations.disablePlug';
          case 'reboot':
            return 'stations.reboot';
          default:
            return 'missing value';
        }
      };

      const readParametersLoader = ref(false);
      const readParametersClass = ref('text-gray-600');
      const lastUpdate = ref('');
      const readParameters = () => {
        readParametersLoader.value = true;
        store
          .dispatch('readParameters', selectedElID)
          .then((response) => {
            if (response.message) {
              readParametersLoader.value = false;
              readParametersClass.value = 'text-red-700 bg-red-100';
              lastUpdate.value = new Date(Date.now()).toLocaleString('it-IT');
              return;
            }
            readParametersLoader.value = false;
            readParametersClass.value = 'text-green-400 bg-green-100';
            lastUpdate.value = new Date(Date.now()).toLocaleString('it-IT');
          })
          .catch((err) => {
            console.log(err);
          });
      };

      return {
        data: selectedSiemensStation,
        dateNow,
        headers,
        modelData,
        openPanel,
        readParametersLoader,
        readParametersClass,
        lastUpdate,
        readParameters,
        returnSendCommandValue,
        reloadStation,
        parseField,
        parseState,
        sendModule,
      };
    },
  };
</script>

<style></style>
