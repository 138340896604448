<template>
  <div v-if="size === 'extreme'">
    <label
      v-if="title && title !== ''"
      for="message"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ $t(title) }}
    </label>
    <textarea
      id="message"
      @input="updateModel"
      :value="modelValue"
      rows="4"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :class="status()"
      :placeholder="placeholder" />
  </div>

  <div v-else-if="size === 'large'" class="mb-6">
    <label
      v-if="title && title !== ''"
      for="large-input"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ $t(title) }}
    </label>
    <div v-else class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><br /></div>
    <input
      @input="updateModel"
      :placeholder="placeholder"
      :type="type"
      id="large-input"
      class="block w-full p-4 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-700 rounded-lg bg-gray-50 dark:bg-gray-800 md:text-md focus:ring-primary-500 focus:border-primary-500"
      :value="modelValue"
      :class="status()" />
  </div>

  <div v-else-if="size === 'small'">
    <label
      v-if="title && title !== ''"
      for="small-input"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ $t(title) }}
    </label>
    <input
      @input="updateModel"
      :placeholder="placeholder"
      :type="type"
      id="small-input"
      class="block w-full p-2 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-700 rounded-lg bg-gray-50 dark:bg-gray-800 md:text-xs focus:ring-primary-500 focus:border-primary-500"
      :class="status()"
      :value="modelValue" />
  </div>
  <div v-else class="mb-6">
    <label
      v-if="title && title !== ''"
      for="base-input"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ $t(title) }}
    </label>
    <div v-else class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><br /></div>
    <input
      @input="updateModel"
      :placeholder="placeholder"
      :type="type"
      id="base-input"
      class="bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
      :class="status()"
      :value="modelValue" />
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {},
      size: {},
      title: {},
      statusOK: { type: Boolean, required: false, default: true },
      type: { type: String, required: false, default: 'text' },
      placeholder: {},
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const updateModel = (event) => emit('update:modelValue', event.target.value);
      const status = () => (props.statusOK ? 'bg-gray-100' : 'bg-red-100');
      return { updateModel, status };
    },
  };
</script>

<style></style>
