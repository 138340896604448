<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
import { Utils } from "@/Utils/utils";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //init vue stuffs
    const store = useStore();

    //declare selects
    const statoList = [
      "",
      "PLANNED",
      "INSTALLED",
      "DISMISSED",
      "MAINTENANCE",
      "OUTOFSERVICE",
      "AVAILABLE",
    ];

    //init selects
    store.dispatch("getManagers");
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });

    store.dispatch("getStationTypes");
    const station_types = computed(() => {
      return Utils.getNameArrayFromData(store.getters.stationTypes);
    });

    //structure of page
    const insertData = {
      title: "Inserimento colonnina",
      redirect_path: "/stations",
      create_endpoint: "createBaseStation",
      creation_title: "Creazione stazione",
      dispatchName: "getCompleteStationList",
      data: [
        {
          title: "Seleziona stato",
          name: "stato",
          select: statoList,
          required: true,
        },
        reactive({
          title: "Seleziona manager",
          name: "manager",
          select: managers,
          required: true,
        }),
        reactive({
          title: "Seleziona tipo",
          name: "station_type",
          select: station_types,
          required: true,
        }),
        {
          title: "Antenna addizionale",
          name: "additional_antenna",
          placeholder: "--",
          boolean: true,
          class: "self-end",
        },
        {
          title: "address.body",
          
          name: "indirizzo",
          required: true,
          placeholder: "Via, numero, cap, città",
          class: "col-span-2",
        },
        {
          title: "Latitudine",
          name: "lat",
          required: true,
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
        },
        {
          title: "Longitudine",
          name: "lng",
          required: true,
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
        },
        {
          title: "Seriale Colonnina",
          name: "plate",
          placeholder: "--",
        },
        {
          title: "Evse_id",
          name: "evse_id",
          placeholder: "--",
        },
        {
          title: "IMEI",
          name: "IMEI",
          placeholder: "--",
        },
        {
          title: "Bollato interno",
          name: "bollato_interno",
          placeholder: "--",
        },
        {
          title: "Descrizione/Note",
          name: "description",
          placeholder: "--",
          class: "col-span-4",
        },
        {
          title: "Numero SIM",
          name: "numero_sim",
          placeholder: "--",
          class: "col-span-2",
        },
        {
          title: "PIN",
          name: "pin",
          placeholder: "--",
        },
        {
          title: "PUK",
          name: "puk",
          placeholder: "--",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>