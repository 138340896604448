<template>
  <div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800">
        <slot name="header"></slot>
      </thead>
      <tbody class="bg-white dark:bg-gray-900">
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
