<template>
  <div v-if="selectedUser" class="px-16 pt-8">
    <details-header :el="selectedUser" />
    <base-subpage-bar :headers="headers" />
  </div>
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import DetailsHeader from './DetailsHeader.vue';

  export default {
    components: {
      DetailsHeader,
    },
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('getSelectedUser', selectedElID);
        store.dispatch('getRentals', { user_id: route.params.id });
      });
      const selectedUser = computed(() => {
        return store.getters.selectedUser;
      });
      //  CREATE OBJ FOR DETAILS HEADER

      const headers = computed(() => {
        const plat = store.getters?.currentPlatform?.db;

        if (plat && plat == 'VIB') {
          return [
            {
              text: 'paths.overall',
              name: 'menu.user.userGenerals',
            },
            {
              text: 'paths.recharges',
              name: 'menu.user.userRecharges',
            },
            {
              text: 'paths.rentals',
              name: 'menu.user.userRentals',
            },
            {
              text: 'paths.documents',
              name: 'menu.user.userDocuments',
            },
            {
              text: 'paths.coupons',
              name: 'menu.user.userCoupons',
            },
          ];
        } else
          return [
            {
              text: 'paths.overall',
              name: 'menu.user.userGenerals',
            },
            {
              text: 'paths.recharges',
              name: 'menu.user.userRecharges',
            },
            {
              text: 'paths.rentals',
              name: 'menu.user.userRentals',
            },
            {
              text: 'paths.documents',
              name: 'menu.user.userDocuments',
            },
          ];
      });

      return {
        selectedUser,
        headers,
      };
    },
  };
</script>

<style></style>
