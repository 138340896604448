<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  export default {
    setup() {
      return {
        headers: [
          { text: 'views.recharges.dashboard', name: 'menu.recharge.dashboard' },
          { text: 'views.recharges.endedRecharges', name: 'menu.recharge.v2.ended' },
          { text: 'views.recharges.rechargesAnalytics', name: 'menu.recharge.v2.analytics' },
        ],
      };
    },
  };
</script>

<style></style>
