import { ApiCaller, Utils } from "@/Utils/utils";

export default {
  state: {
    locations: [],
    selectedLocation: null,
    locationVehicles: [],
  },
  getters: {
    locations: (state) => state.locations,
    selectedLocation: (state) => {
      if (state.selectedLocation !== null) return state.selectedLocation[0];
      return state.selectedLocation;
    },
    locationVehicles: (state) => state.locationVehicles,
  },
  mutations: {
    setLocations: (state, payload) => (state.locations = payload),
    setSelectedLocation: (state, payload) => (state.selectedLocation = payload),
    setLocationVehicles: (state, payload) => (state.locationVehicles = payload),
  },
  actions: {
    getLocations: (ctx, payload) => {
      ctx.commit("setLocations", Utils.createFiltrableData([]));
      ApiCaller.GET(`/location`, payload).then((data) =>
        ctx.commit("setLocations", Utils.createFiltrableData(data))
      );
    },
    getSelectedLocation: (ctx, payload) => {
      ctx.commit("setSelectedLocation", []);
      ApiCaller.GET(`/location/${payload.id}`, null).then((data) =>
        ctx.commit("setSelectedLocation", data)
      );
    },
    getLocationVehicles: (ctx, payload) => {
      ctx.commit("setLocationVehicles", Utils.createFiltrableData([]));
      ApiCaller.GET("/location/list/vehicle", payload).then((data) =>
        ctx.commit("setLocationVehicles", Utils.createFiltrableData(data))
      );
    },
    //QUERIES
    updateLocation: (_, payload) =>
      ApiCaller.PUT(`/location/${payload.id}`, payload.body),
    createLocation: (_, payload) => ApiCaller.POST("/location", payload),
  },
};
