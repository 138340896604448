<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { ref, computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const refreshPage = (payload) => store.dispatch('getCoupon', payload);

      const coupons = computed(() => store.getters.coupons);

      const show = ref(true);
      // const forcePayment = (user_id) => store.dispatch('forcePayment', { id: user_id });
      // table
      const structure = {
        title: 'views.user.couponUsersList',
        timeFields: [
          { label: 'creationDate', key: 'created_at' },
          { label: 'updateDate', key: 'updated_at' },
        ],
        creationLink: {
          title: 'user.createCoupon',
          namePath: 'views.user.couponUsersCreate',
        },
        data: [
          {
            title: 'user.name.first',
            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-400',
              },
              {
                name: 'user_name',
              },
            ],
            link: { idField: 'user_id', path: '/user/users/:id/coupon' },
            span: 1,
          },
          {
            title: 'views.user.coupon.name',
            field: 'name',
            span: 1,
          },
          {
            title: 'views.user.coupon.discount',
            field: 'discount',
            format: 'percentage',
            span: 1,
          },
          {
            title: 'views.user.coupon.expireOn',
            field: 'expire_on',
            format: 'date',
            span: 1,
          },
        ],
      };

      return {
        data: coupons,
        structure,
        show,
        refreshPage,
      };
    },
  };
</script>

<style></style>
