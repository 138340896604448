import { Utils, ApiCaller } from "@/Utils/utils";

export default {
  state: {
    authUsers: [],
    selectedAuthUser: [],
    authRoles: [],
  },
  getters: {
    authUsers: (state) => state.authUsers,
    selectedAuthUser: (state) => state.selectedAuthUser,
    authRoles: (state) => state.authRoles,
  },
  mutations: {
    setAuth0Users: (state, data) =>
      (state.authUsers = Utils.createFiltrableData(data)),

    setSelectedAuthUser: (state, data) => (state.selectedAuthUser = data),

    setAuthRoles: (state, data) => (state.authRoles = data),
  },
  actions: {
    getAuth0Users: (context, payload) => {
      context.commit("setAuth0Users", []);
      ApiCaller.GET("/authUsers", payload).then((data) =>
        context.commit("setAuth0Users", data)
      );
    },

    getSelectedAuthUser: (context, payload) => {
      context.commit("setSelectedAuthUser", []);
      ApiCaller.GET(`/authUsers/${payload.id}`).then((data) =>
        context.commit("setSelectedAuthUser", data)
      );
    },

    getAuthRoles: (context, payload) => {
      context.commit("setAuthRoles", []);
      ApiCaller.GET(`/authRoles`, payload).then((data) =>
        context.commit("setAuthRoles", data)
      );
    },

    addRole: (_, payload) => ApiCaller.PUT(`/assignAuthRole/${payload.id}`, payload),
    removeRole: (_, payload) => ApiCaller.PUT(`/deleteAuthRole/${payload.id}`, payload),
  },
};
