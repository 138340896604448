<template>
  <transition name="">
    <!-- <div class="fixed bottom-8 left-6 w-2/5 h-min flex flex-col justify-end items-start z-50"> -->
    <div class="fixed bottom-8 right-6 w-2/5 h-min flex flex-col justify-end items-start z-50">
      <div
        v-for="message in messages"
        :key="message.id"
        class="flex justify-between items-center px-4 py-1 mb-3 rounded-lg shadow-sm w-full text-ellipsis overflow-hidden"
        :class="customClass(message.type)">
        <div v-if="message.type == 'info'" class="material-icons text-lg">info</div>
        <div v-else-if="message.type == 'success'" class="material-icons text-lg">check_circle</div>
        <div v-else-if="message.type == 'error'" class="material-icons text-lg">error</div>
        <div v-else class="material-icons text-lg">check_circle</div>

        <div class="mx-3 flex justify-between w-full text-sm text-left">
          <div class="text-left font-black mr-2 w-min">{{ message.title }}:</div>
          <div class="text-left font-medium w-full whitespace-nowrap text-ellipsis overflow-hidden">{{ message.description }}</div>
          <div class="text-left font-medium opacity-20 ml-2 w-8 whitespace-nowrap text-ellipsis overflow-hidden">[{{ message.id }}]</div>
        </div>

        <div class="material-icons text-lg cursor-pointer w-12 text-right" @click="dismissMessage(message.id)">close</div>
      </div>

      <button hidden @click="createMessages">Aggiungi messaggio</button>
    </div>
  </transition>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      const messages = computed(() => {
        return store.getters.getMessages;
      });

      const createMessages = () => {
        store.dispatch('pushMessage', {
          title: 'Titolo',
          description: 'Questa è una descrizione bella e buona.',
        });
      };
      const dismissMessage = (id) => store.dispatch('dismissMessage', { id });

      const customClass = (type) => {
        switch (type) {
          case 'info':
            return 'text-primary-800 bg-primary-100';
          case 'error':
            return 'text-red-800 bg-red-100';
          case 'success':
            return 'text-green-800 bg-green-100';
        }
        return 'text-primary-800 bg-primary-100';
      };

      return { messages, customClass, dismissMessage, createMessages };
    },
  };
</script>
