<template>
  <div v-if="selectedType" class="px-16 pt-8">
    <base-details-header :structure="headerStructure" :el="selectedType" />
    <base-subpage-bar :headers="headers" />
  </div>
  <router-view />
</template>

<script>
  import { computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();
      //  ASSIGN ELEMENTS
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('getSelectedType', { ...selectedElID, type: 'car' });
        store.dispatch('setSelectedCarTypeVehicles', selectedElID);
      });
      const selectedType = computed(() => {
        return store.getters.selectedType;
      });
      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'Generali',
          name: 'menu.sharing.carTypeGenerals',
        },
        {
          text: 'Veicoli associati',
          name: 'menu.sharing.carTypeVehicles',
        },
      ];
      const headerStructure = {
        title: [
          {
            title: 'name',
          },
        ],
        data: [
          {
            name: 'id',
            placeholder: '@',
          },
          {
            name: 'name',
          },
        ],
      };
      return {
        selectedType,
        headerStructure,
        headers,
      };
    },
  };
</script>
<style></style>
