<template>
  <base-page>
    <base-subpage-bar :headers="headers" />
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </base-page>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getCarTypes', payload);
      }

      //change users value with searchString
      const carTypes = computed(() => store.getters.carTypes);

      // table
      const structure = {
        title: 'Lista tipologie autovetture',
        creationLink: {
          title: 'Aggiungi nuova tipologia',
          namePath: 'menu.sharing.createCarType',
        },
        data: [
          {
            title: 'Nome',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-blue-300',
              },
              {
                name: 'name',
                class: 'font-bold ',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/sharing/vehicles/cars/types/:id' },
          },
          {
            title: 'prezzo al Km',
            field: 'per_km',
            span: 1,
          },
          {
            title: 'prezzo al minuto',
            field: 'per_minute',
            span: 1,
          },
          {
            title: 'prezzo fino a 3h',
            field: '1_3',
            span: 1,
          },
          {
            title: 'prezzo da 3h a 6h',
            field: '3_6',
            span: 1,
          },
          {
            title: 'Prezzo al giorno',
            field: 'per_day',
            span: 1,
          },
          {
            title: 'gestore',
            field: 'manager',
            span: 1,
          },
        ],
      };
      //header list
      const headers = [
        {
          text: 'Macchine',
          name: 'menu.sharing.carList',
        },
        {
          text: 'Lista Tipologie',
          name: 'menu.sharing.carTypesList',
        },
      ];
      return {
        data: carTypes,
        structure,
        headers,
        refreshPage,
      };
    },
  };
</script>

<style></style>
