<template>
  <div class="w-full h-full flex flex-col justify-center items-center text-gray-900 dark:text-white text-3xl pb-36">
    <h1 class="mb-8">{{ $t('menu.hello') }} {{ currentUser.nickname }}!</h1>
    <ul class="grid grid-cols-3 w-auto mx-auto justify-center">
      <template v-for="(section, i) in sections" :key="i">
        <li v-if="section.name">
          <div @click="goTo(section)" class="cursor-pointer w-full flex flex-col justify-center items-center p-4 py-2">
            <span class="rounded-3xl w-36 h-36 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 flex flex-col justify-center items-center text-7xl material-icons">
              {{ section.icon }}
            </span>
            <h2 class="text-base mt-2 mb-2">{{ $t(section.name) }}</h2>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router'; 
      const store = useStore();
      const currentUser = computed(() => store.getters.user);
      const sections = computed(() => store.getters.menu);
      const router = useRouter(); 
      const goTo = (sec) => { 
        store.dispatch('setSelectedSection', { section: sec });
        router.push( sec.path );
      };       
</script>