import { ApiCaller } from '@/Utils/utils';

export default {
  state: {
    evses: null,
    haveEvses: false,
  },
  getters: {
    evses: (state) => state.evses,
    haveEvses: (state) => state.haveEvses,
  },
  mutations: {
    getEvses: (state, data) => {
      if (data && data.length > 0) state.haveEvses = true;
      else state.haveEvses = false;
      state.evses = data;
    },
  },
  actions: {
    getEvses: (ctx, payload) =>
      ApiCaller.GET(`/station/${payload}/evse`, null).then((data) => {
        ctx.commit('getEvses', data);
      }),
    createEvses: (_, payload) => ApiCaller.POST(`/station/${payload.id}/createEvses`, payload.bodyObj),
    changePlugStatus: (_, payload) => ApiCaller.POST(`/station/evse/plug/${payload.id}/changeStatus`, payload.status),
    deleteEvses: (_, payload) => ApiCaller.DELETE(`/station/${payload.id}/deleteEvse`, payload),
  },
};
