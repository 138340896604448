<template>
  <div class="p-4 my-6 border-b border-gray-400">
    <div class="flex justify-center">
      <p>
        Scegli la tua configurazione e scarica il report annuale o scegli un mese poi clicca il bottone qui sotto per
        visualizzare le statistiche selezionate.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <BaseButton @click="sendAnaliticsData" :disabled="sendFormData.year == '' || sendFormData.cuCode == ''">
        Scarica dati
      </BaseButton>
    </div>
  </div>
  <div class="flex">
    <VerticalSteps :steps="steps" />
    <div class="mr-8">
      <!-- header -->
      <div class="ml-4">
        <!-- bottoni selezione colonnina e anno -->
        <div class="flex">
          <SimpleSelect :options="{ array: years }" v-model="sendFormData.year" />
          <div v-show="sendFormData.year" class="self-center ml-4 animate-bounce">
            <CircularButton @click="togglePanel" />
          </div>
        </div>
        <div v-show="sendFormData.year" class="mt-10">
          <SelectWithSearch :data="stations" :returned-field="'cuCode'" v-model="sendFormData.cuCode" />
        </div>
        <div v-show="sendFormData.cuCode" class="mt-10">
          <SimpleSelect
            :options="{ array: months, label: 'label', value: 'name' }"
            v-model="sendFormData.selectedMonth" />
        </div>
      </div>

      <!-- Overlay & Pannello -->
      <div v-if="downloadInfos.showPanel" class="fixed inset-0 flex items-center justify-center z-50">
        <!-- Overlay: cliccare qui chiuderà il pannello -->
        <div
          class="absolute inset-0 bg-black opacity-50 blur-md"
          @click.prevent="if (!downloadInfos.inProgress || downloadInfos.endDownload) resetDownloadInfos();"></div>

        <!-- Pannello al centro dello schermo -->
        <div class="bg-white dark:bg-gray-800 p-8 w-1/2 rounded shadow-lg z-10 flex justify-evenly">
          <!-- Contenuto del pannello -->
          <base-input-field
            v-if="!downloadInfos.inProgress && !downloadInfos.error"
            class="mr-4"
            title="Scarta le ricariche sotto i (valore in kWh):"
            size="small"
            :placeholder="rechargeOffset"
            v-model="rechargeOffset" />
          <div class="text-center" v-if="!downloadInfos.inProgress && !downloadInfos.error">
            <div class="flex justify-center">
              <p
                class="font-bold underline text-lg text-blue-800 dark:text-blue-400 hover:cursor-pointer animate-pulse"
                @click="downloadReport">
                Scarica il report
              </p>
            </div>
            <p class="text-xs text-gray-500 dark:text-gray-400 mt-2">L'operazione potrebbe durare diversi minuti</p>
          </div>
          <div v-else-if="downloadInfos.error" class="text-center">
            <p class="text-red-800 dark:text-red-500 font-bold text-lg">Errore nell'ottenere il file!!</p>
            <p class="text-gray-700 dark:text-gray-300">Contattare l'assistenza.</p>
          </div>
          <div v-else>
            <div v-if="!downloadInfos.endDownload" class="text-center">
              <p class="text-bold dark:text-gray-300">Download in corso</p>
              <p class="text-xs text-gray-500 dark:text-gray-400">L'operazione potrebbe durare diversi minuti</p>
              <BaseSpinLoader class="my-4" />
            </div>
            <div v-else-if="downloadInfos.endDownload" class="my-8">
              <div class="text-center">
                <p class="text-bold dark:text-gray-300">Download completato</p>
                <p class="text-xs dark:text-gray-400">
                  Il file è stato scaricato nella cartella download del tuo computer
                </p>
              </div>
            </div>
            <HorizontalSteps :steps="loadingStep" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loadingScreen && data && data?.recharges?.length" class="m-2 p-2 bg-gray-100 rounded-xl w-full">
      <StatsWithTrending title="Anno corrente" :stats="getStats(data)" />
      <div class="border-t-gray-300 border-t">
        <StatsWithTrending title="Anno precedente" :stats="getStats(data, 'lastY')" />
      </div>
      <div class="border-t-gray-300 border-t">
        <StatsWithTrending title="Due anni fa" :stats="getStats(data, '2lastY')" />
      </div>
    </div>
    <div v-else-if="loadingScreen" class="animate-pulse w-full">
      <div class="bg-gray-200 h-1/4 rounded mb-4"></div>
      <div class="bg-gray-200 h-1/2 rounded mb-4"></div>
    </div>
  </div>
  <div v-if="!loadingScreen && data && data?.recharges?.length">
    <base-list-table :data="data?.recharges" :structure="structure" />
  </div>
  <div v-else-if="loadingScreen" class="mt-8 animate-pulse h-52 w-full">
    <div class="bg-gray-200 h-2/6 rounded mb-4"></div>
    <div class="bg-gray-200 h-1/6 rounded mb-4"></div>
    <div class="bg-gray-200 h-1/6 rounded mb-4"></div>
    <div class="bg-gray-200 h-1/6 rounded mb-4"></div>
    <div class="bg-gray-200 h-2/6 rounded mb-4"></div>
  </div>
  <div
    v-else-if="!loadingScreen && data?.recharges && data?.recharges?.length <= 0"
    class="flex justify-center font-bold text-lg">
    0 Ricariche trovate
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { reactive, computed, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import SimpleSelect from '@/components/Standard/TailwindUI/SimpleSelect.vue';
  import CircularButton from '@/components/Standard/TailwindUI/CircularButton.vue';
  import VerticalSteps from '@/components/Standard/TailwindUI/VerticalSteps.vue';
  import StatsWithTrending from '@/components/Standard/TailwindUI/StatsWithTrending.vue';
  import HorizontalSteps from '@/components/Standard/TailwindUI/HorizontalSteps.vue';
  import SelectWithSearch from '@/components/Standard/TailwindUI/SelectWithSearch.vue';
  const ExcelJS = require('exceljs');

  export default {
    components: {
      SimpleSelect,
      CircularButton,
      VerticalSteps,
      StatsWithTrending,
      HorizontalSteps,
      SelectWithSearch,
    },
    setup() {
      const store = useStore();
      const t = useI18n().t;

      //dichiaro la variabile computed che conterrà i dati delle ricariche
      const data = computed(() => {
        return store.getters.monthStatistics;
      });

      //dichiaro la funzione che mi permette di prendere i dati delle ricariche
      const loadingScreen = ref(false);
      const sendAnaliticsData = () => {
        if (sendFormData.cuCode == '') return;
        if (sendFormData.selectedMonth == '' && sendFormData.year == '') return;

        const data = {
          id: sendFormData.cuCode,
          month: months.filter((month) => month.name === sendFormData.selectedMonth)?.[0]?.num,
          year: sendFormData.year,
        };

        loadingScreen.value = true;
        store.dispatch('getMonthStatistics', data).then(() => (loadingScreen.value = false));
      };

      //prendo le colonnine disponibili
      store.dispatch('getStationForRechargesAnalytics');
      const stations = computed(() => {
        let finalArray = [];
        const tmpStations = store.getters.stationForRechargesAnalytics;
        if (!tmpStations || tmpStations.length <= 0) return;
        tmpStations.forEach((el) => {
          if (el.cuCode && el.cuCode != '')
            finalArray.push({ primaryField: el.indirizzo, secondaryField: el.plate, cuCode: el.cuCode });
        });
        return finalArray;
      });

      //configurazioni utilizzate dal template
      const structure = {
        title: 'Totale ricariche anno selezionato',
        data: [
          {
            title: 'views.recharges.session',
            field: 'rechargeSessionId',
            span: 1,
          },
          {
            title: 'views.recharges.address',
            field: 'localization',
            subField: 'address',
            span: 2,
          },
          {
            title: 'views.recharges.evse',
            field: 'evseId',
            span: 1,
          },
          {
            title: 'views.recharges.motivation',
            field: 'reasonAcronym',
            span: 1,
          },
          {
            title: 'Totale KWh',
            field: 'totalEnergy',
            span: 1,
          },
        ],
      };
      const years = computed(() => {
        let finalArray = [];
        const currentYear = new Date().getFullYear();
        for (let i = 2021; i <= currentYear; i++) {
          finalArray.push(i);
        }
        return finalArray;
      });

      const months = [
        {
          name: 'month.january',
          label: 'Gennaio',
          num: '01',
        },
        {
          name: 'month.february',
          label: 'Febbraio',
          num: '02',
        },
        {
          name: 'month.march',
          label: 'Marzo',
          num: '03',
        },
        {
          name: 'month.april',
          label: 'Aprile',
          num: '04',
        },
        {
          name: 'month.may',
          label: 'Maggio',
          num: '05',
        },
        {
          name: 'month.june',
          label: 'Giugno',
          num: '06',
        },
        {
          name: 'month.july',
          label: 'Luglio',
          num: '07',
        },
        {
          name: 'month.august',
          label: 'Agosto',
          num: '08',
        },
        {
          name: 'month.september',
          label: 'Settembre',
          num: '09',
        },
        {
          name: 'month.october',
          label: 'Ottobre',
          num: '10',
        },
        {
          name: 'month.november',
          label: 'Novembre',
          num: '11',
        },
        {
          name: 'month.december',
          label: 'Dicembre',
          num: '12',
        },
      ];

      const sendFormData = reactive({
        selectedMonth: '',
        cuCode: '',
        year: '',
      });
      const getStats = (data, year = 'current') => {
        if (!data) return;
        if (year == 'current') {
          const kW = data.totKwh;
          const totRecharges = data?.totRecharges;
          const averageKwPerCharge = ((kW * 0.001) / totRecharges).toFixed();
          return [
            { name: 'Totale Ricariche', value: totRecharges },
            { name: 'Totale kW', value: (kW * 0.001).toFixed() },
            { name: 'Media kW/Ricarica', value: isNaN(averageKwPerCharge) ? 0 : averageKwPerCharge},
          ];
        }
        else if (year == 'lastY') {
          const kW = data.totKwhLastYear;
          const totRecharges = data?.totRechargesLastYear;
          const averageKwPerCharge = ((kW * 0.001) / totRecharges).toFixed();
          return [
            { name: 'Totale ricariche', value: totRecharges },
            { name: 'Totale kW', value: (kW * 0.001).toFixed() },
            { name: 'Media kW/Ricarica', value: isNaN(averageKwPerCharge) ? 0 : averageKwPerCharge },
          ];
         }
        else if (year == '2lastY') { 
          const kW = data.totKwhTwoYearsAgo;
          const totRecharges = data?.totRechargesTwoYearsAgo;
          const averageKwPerCharge = ((kW * 0.001) / totRecharges).toFixed();
          return [
            { name: 'Totale ricariche', value: totRecharges },
            { name: 'Totale kW', value: (kW * 0.001).toFixed() },
            { name: 'Media kW/Ricarica', value: isNaN(averageKwPerCharge) ? 0 : averageKwPerCharge },
          ];
        }
      };
      const showPanel = ref(false);
      const downloadInfos = reactive({
        inProgress: false,
        showPanel: false,
        endDownload: false,
        error: false,
      });
      const togglePanel = () => {
        downloadInfos.showPanel = !downloadInfos.showPanel;
      };
      const resetDownloadInfos = () => {
        console.log('resetting');
        downloadInfos.inProgress = false;
        downloadInfos.showPanel = false;
        downloadInfos.endDownload = false;
        downloadInfos.error = false;

        loadingStep[0].status = 'current';
        loadingStep[1].status = 'upcoming';
        loadingStep[2].status = 'upcoming';
      };

      const steps = reactive([
        { name: "Scegli l'anno", status: 'current' },
        {
          name: 'Scegli la colonnina',
          status: 'upcoming',
        },
        { name: 'Scegli il mese', status: '' },
      ]);
      watch(sendFormData, (newVal) => {
        if (newVal.year == '') {
          steps[0].status = 'current';
          steps[1].status = 'upcoming';
          steps[2].status = 'upcoming';
        } else if (newVal.year != '' && newVal.cuCode == '') {
          steps[0].status = 'complete';
          steps[1].status = 'current';
          steps[2].status = 'upcoming';
        } else if (newVal.year != '' && newVal.cuCode != '' && newVal.selectedMonth == '') {
          steps[1].status = 'complete';
          steps[2].status = 'upcoming';
        } else {
          steps[2].status = 'complete';
        }
      });

      //gestione excell
      const rechargeOffset = ref(0.5); //TODO: METTERLO CONFIGURABILE DA FRONTEND

      const loadingStep = reactive([
        { id: 'Step 1', name: 'Inizio sincronizzazione dei dati con zeus', href: '#', status: 'current' },
        {
          id: 'Step 2',
          name: 'Download e manipolazione dei dati',
          href: '#',
          status: 'upcoming',
        },
        {
          id: 'Step 3',
          name: 'Assemblo il file excel',
          href: '#',
          status: 'upcoming',
        },
      ]);

      const downloadReport = () => {
        if (!sendFormData.year) return;
        //loading steps
        downloadInfos.inProgress = true;
        //chiamata per settare cuCode
        store
          .dispatch('syncEcar')
          .catch((error) => {
            console.log('error', error);
          })
          .then(() => {
            //loading steps
            loadingStep[0].status = 'complete';
            loadingStep[1].status = 'current';

            // chiamata per settare activationDate

            store
              .dispatch('getYearStatistics', { year: sendFormData.year, data: { offset: rechargeOffset.value } })
              .catch((error) => {
                console.log('error', error);
              })
              .then((response) => {
                //loading steps
                loadingStep[1].status = 'complete';
                loadingStep[2].status = 'current';

                const data = response;
                const workbook = new ExcelJS.Workbook();
                let tmpWorksheet = null;
                //la media ricarica va fatta dal giorno di attivazione della stazione

                //creo un foglio iniziale con i dati generali
                tmpWorksheet = workbook.addWorksheet('Riepilogo');
                // Impostazioni comuni per A1 e B1
                const commonSettings = {
                  fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF80' },
                  },
                  font: {
                    bold: true,
                  },
                  border: {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } },
                  },
                  alignment: {
                    vertical: 'middle',
                    horizontal: 'center',
                  },
                };

                function formatDateToItalian(date) {
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 perché i mesi in JavaScript iniziano da 0
                  const year = date.getFullYear();

                  const hours = String(date.getHours()).padStart(2, '0');
                  const minutes = String(date.getMinutes()).padStart(2, '0');

                  return `${day}/${month}/${year} alle ${hours}:${minutes}`;
                }

                // Imposta le proprietà per A1
                tmpWorksheet.getColumn('A').width = 30;
                tmpWorksheet.getCell('A1').value = 'Report del ' + formatDateToItalian(new Date());
                Object.assign(tmpWorksheet.getCell('A1'), commonSettings);

                // Imposta le proprietà per B1
                tmpWorksheet.getColumn('B').width = 30;
                tmpWorksheet.getCell('B1').value = 'Utente: ' + store.getters.user.email;
                Object.assign(tmpWorksheet.getCell('B1'), commonSettings);

                // Imposta le proprietà per C1
                tmpWorksheet.getColumn('C').width = 35;
                tmpWorksheet.getCell('C1').value = 'Offset utilizzato: ' + data.generals.offset;
                Object.assign(tmpWorksheet.getCell('C1'), commonSettings);

                // Imposta le proprietà per D1
                tmpWorksheet.getColumn('D').width = 20;
                tmpWorksheet.getCell('D1').value = 'Data di riferimento: ' + data.generals.year;
                Object.assign(tmpWorksheet.getCell('D1'), commonSettings);

                tmpWorksheet.addRow([]);
                const summaryInputHeaders = [
                  'Mese',
                  'Stazioni attive/mese',
                  'Stazioni che hanno ricaricato/mese',
                  'Percentuale utilizzo rete',
                  'Stazioni attive/mese anno precedente',
                  'Stazioni che hanno ricaricato/mese anno precedente',
                  '% utilizzo rete anno precedente',
                  'Var.% anno precedente',
                  'Stazioni attive 2 anni fa',
                  'Stazioni che hanno ricaricato 2 anni fa',
                  '% utilizzo rete 2 anni fa',
                  'Var.% 2 anni fa',
                  'Totale ricariche anno corrente',
                  'Totale ricariche anno precedente',
                  'Var.% anno precedente',
                  'Totale ricariche 2 anni fa',
                  'Var.% 2 anni fa',
                  'Totale kWh anno corrente',
                  'Totale kWh anno precedente',
                  'Var.% anno precedente',
                  'Totale kWh 2 anni fa',
                  'Var.% 2 anni fa',
                  'Media kWh/Ricarica anno corrente',
                  'Media kWh/ricarica anno precedente',
                  'Var.% anno precedente',
                  'Media kWh/ricarica 2 anni fa',
                  'Var.% 2 anni fa',
                  'Media kWh/Giorno anno corrente',
                  'Media kWh/giorno anno precedente',
                  'Var.% anno precedente',
                  'Media kWh/giorno 2 anni fa',
                  'Var.% 2 anni fa',
                ];
                tmpWorksheet.addRow(summaryInputHeaders);

                // dichiaro e aggiungo le colonne
                const summaryColumnKeys = [
                  { key: 'month', width: 30 },
                  { key: 'chargedStations', width: 30 },
                  { key: 'activeStationsOnDate', width: 30 },
                  { key: 'networkUsagePercent', width: 30 },
                  { key: 'chargedStationsLastYear', width: 30 },
                  { key: 'activeStationsLastYear', width: 30 }, //da aggiugnere
                  { key: 'networkUsagePercentLastYear', width: 30 },
                  { key: 'varLastYear', width: 30 },
                  { key: 'chargedStationsTwoYearsAgo', width: 30 },
                  { key: 'activeStationsTwoYearsAgo', width: 30 }, //da aggiugnere
                  { key: 'networkUsagePercentTwoYearsAgo', width: 30 },
                  { key: 'varTwoYearsAgo', width: 30 },
                  { key: 'recharges', width: 30 },
                  { key: 'rechargesLastYear', width: 30 },
                  { key: 'varRechargesLastYear', width: 30 },
                  { key: 'rechargesTwoYearsAgo', width: 30 },
                  { key: 'varRechargesTwoYearsAgo', width: 30 },
                  { key: 'totalKwh', width: 30, numFmt: '#,##0.00' },
                  { key: 'totalKwhLastYear', width: 30 },
                  { key: 'varKwhLastYear', width: 30 },
                  { key: 'totalKwhTwoYearsAgo', width: 30 },
                  { key: 'varKwhTwoYearsAgo', width: 30 },
                  { key: 'avgKwhPerCharge', width: 30, numFmt: '#,##0.00' },
                  { key: 'avgKwhPerChargeLastYear', width: 30 },
                  { key: 'varKwhPerChargeLastYear', width: 30 },
                  { key: 'avgKwhPerChargeTwoYearsAgo', width: 30 },
                  { key: 'varKwhPerChargeTwoYearsAgo', width: 30 },
                  { key: 'avgDailyCharge', width: 30 },
                  { key: 'avgDailyChargeLastYear', width: 30 },
                  { key: 'varDailyChargeLastYear', width: 30 },
                  { key: 'avgDailyChargeTwoYearsAgo', width: 30 },
                  { key: 'varDailyChargeTwoYearsAgo', width: 30 },
                ];
                tmpWorksheet.columns = summaryColumnKeys;

                const monthColumnKeys = [
                  { key: 'serialNumber', width: 30, header: 'Serial Number' },
                  { key: 'address', width: 35, header: 'Indirizzo' },
                  { key: 'totalKwh', width: 30, header: 'kWh erogati anno corrente' },
                  { key: 'totalKwhLastYear', width: 30, header: 'kWh erogati anno precedente' },
                  { key: 'varKwhLastYear', width: 30, header: '%incremento anno precedente', numFmt: '0.00%' },
                  { key: 'totalKwhTwoYearsAgo', width: 30, header: 'kWh erogati 2 anni fa' },
                  { key: 'varKwhTwoYearsAgo', width: 30, header: '%incremento erogati 2 anni fa' },
                  { key: 'totalCharges', width: 30, header: 'Tot. ricariche anno corrente' },
                  { key: 'totalChargesLastYear', width: 30, header: 'Tot. ricariche anno precedente' },
                  { key: 'varChargesLastYear', width: 30, header: '%incremento anno precedente' },
                  { key: 'totalChargesTwoYearsAgo', width: 30, header: 'Tot. ricariche 2 anni fa' },
                  { key: 'varChargesTwoYearsAgo', width: 30, header: '%incremento 2 anni fa' },
                  { key: 'avgKwhPerCharge', width: 25, header: 'Media kWh/Ricarica anno corrente' },
                  { key: 'avgKwhPerChargeLastYear', width: 30, header: 'Media kWh/Ricarica anno precedente' },
                  { key: 'varKwhPerChargeLastYear', width: 30, header: '%incremento anno precedente' },
                  { key: 'avgKwhPerChargeTwoYearsAgo', width: 30, header: 'Media kWh/Ricarica 2 anni fa' },
                  { key: 'varKwhPerChargeTwoYearsAgo', width: 30, header: '%incremento 2 anni fa' },
                  { key: 'avgDailyCharge', width: 20, header: 'Media kWh/Giorno anno corrente' },
                  { key: 'avgDailyChargeLastYear', width: 30, header: 'Media kWh/Giorno anno precedente' },
                  { key: 'varDailyChargeLastYear', width: 30, header: '%incremento anno precedente' },
                  { key: 'avgDailyChargeTwoYearsAgo', width: 30, header: 'Media kWh/Giorno 2 anni fa' },
                  { key: 'varDailyChargeTwoYearsAgo', width: 30, header: '%incremento 2 anni fa' },
                ];

                //riempio i fogli dei mesi
                const monthKeys = Object.keys(data.months);
                function parseKwh(val) {
                  if (isNaN(val)) {
                    return 0;
                  }
                  return (parseFloat(val) * 0.001).toFixed(2).replace('.', ',');
                }
                function percentageChange(newVal, oldVal) {
                  if (
                    typeof newVal !== 'number' ||
                    isNaN(newVal) ||
                    typeof oldVal !== 'number' ||
                    isNaN(oldVal) ||
                    oldVal === 0 ||
                    newVal === 0
                  ) {
                    return 0 + '%'; // oppure potresti ritornare null o 0, a seconda delle tue esigenze
                  }
                  return (((newVal - oldVal) / oldVal) * 100).toFixed(2) + '%';
                }
                function average(a, b) {
                  if (b === 0 || isNaN(b) || isNaN(a)) {
                    return 0; // oppure potresti ritornare null o 0, a seconda delle tue esigenze
                  }
                  return (parseFloat(a) / parseFloat(b)).toFixed(2);
                }

                function fillMonthlySummary(workbook, summary) {
                  const tmpWorksheet = workbook.getWorksheet('Riepilogo');
                  tmpWorksheet.addRow({
                    month: summary.monthName,
                    chargedStations: summary.totalActiveCharges,
                    chargedStationsLastYear: summary.totalActiveChargesLastYear,
                    chargedStationsTwoYearsAgo: summary.totalActiveChargesTwoYearsAgo,
                    activeStationsOnDate: summary.uniqueChargers,
                    activeStationsLastYear: summary.uniqueChargersLastYear,
                    activeStationsTwoYearsAgo: summary.uniqueChargersTwoYearsAgo,
                    recharges: summary.totalCharges,
                    rechargesLastYear: summary.totalChargesLastYear,
                    rechargesTwoYearsAgo: summary.totalChargesTwoYearsAgo,
                    totalKwh: parseKwh(summary.totalKwh),
                    totalKwhLastYear: parseKwh(summary.totalKwhLastYear),
                    totalKwhTwoYearsAgo: parseKwh(summary.totalKwhTwoYearsAgo),
                    //inizo funzioni
                    networkUsagePercent: average(summary.uniqueChargers * 100, summary.totalActiveCharges) + '%',
                    networkUsagePercentLastYear:
                      average(summary.uniqueChargersLastYear * 100, summary.totalActiveChargesLastYear) + '%',
                    varLastYear: percentageChange(
                      average(summary.uniqueChargers * 100, summary.totalActiveCharges),
                      average(summary.uniqueChargersLastYear * 100, summary.totalActiveChargesLastYear),
                    ),
                    networkUsagePercentTwoYearsAgo:
                      average(summary.uniqueChargersTwoYearsAgo * 100, summary.totalActiveChargesTwoYearsAgo) + '%',
                    varTwoYearsAgo: percentageChange(
                      average(summary.uniqueChargers * 100, summary.totalActiveCharges),
                      average(summary.uniqueChargersTwoYearsAgo * 100, summary.totalActiveChargesTwoYearsAgo),
                    ),
                    varRechargesLastYear: percentageChange(summary.totalCharges, summary.totalChargesLastYear),
                    varRechargesTwoYearsAgo: percentageChange(summary.totalCharges, summary.totalChargesTwoYearsAgo),
                    varKwhLastYear: percentageChange(summary.totalKwh, summary.totalKwhLastYear),
                    varKwhTwoYearsAgo: percentageChange(summary.totalKwh, summary.totalKwhTwoYearsAgo),
                    avgKwhPerCharge: parseKwh(average(summary.totalKwh, summary.totalCharges)),
                    avgKwhPerChargeLastYear: parseKwh(average(summary.totalKwhLastYear, summary.totalChargesLastYear)),
                    varKwhPerChargeLastYear: percentageChange(
                      summary.totalKwh / summary.totalCharges,
                      summary.totalKwhLastYear / summary.totalChargesLastYear,
                    ),
                    avgKwhPerChargeTwoYearsAgo: parseKwh(
                      average(summary.totalKwhTwoYearsAgo, summary.totalChargesTwoYearsAgo),
                    ),
                    varKwhPerChargeTwoYearsAgo: percentageChange(
                      summary.totalKwh / summary.totalCharges,
                      summary.totalKwhTwoYearsAgo / summary.totalChargesTwoYearsAgo,
                    ),
                    avgDailyCharge: average(summary.totalCharges, summary.maxDaysInMonth),
                    avgDailyChargeLastYear: average(summary.totalChargesLastYear, summary.maxDaysInMonthLastYear),
                    varDailyChargeLastYear: percentageChange(
                      summary.totalCharges / summary.maxDaysInMonth,
                      summary.totalChargesLastYear / summary.maxDaysInMonthLastYear,
                    ),
                    avgDailyChargeTwoYearsAgo: average(
                      summary.totalChargesTwoYearsAgo,
                      summary.maxDaysInMonthTwoYearsAgo,
                    ),
                    varDailyChargeTwoYearsAgo: percentageChange(
                      summary.totalCharges / summary.maxDaysInMonth,
                      summary.totalChargesTwoYearsAgo / summary.maxDaysInMonthTwoYearsAgo,
                    ),
                  });
                }

                function fillStationsForMonth(workbook, monthName, monthData, summary) {
                  const tmpWorksheet = workbook.addWorksheet(monthName);
                  tmpWorksheet.columns = monthColumnKeys; // Assumo che monthColumnKeys sia definito altrove nel tuo codice

                  for (const station of Object.values(monthData)) {
                    tmpWorksheet.addRow({
                      serialNumber: station.serialNumber,
                      address: station.address,
                      totalKwh: parseKwh(station.deliveredKwh),
                      totalKwhLastYear: parseKwh(station.deliveredKwhLastYear),
                      totalKwhTwoYearsAgo: parseKwh(station.deliveredKwhTwoYearsAgo),
                      totalCharges: station.totalCharges,
                      totalChargesLastYear: station.totalChargesLastYear,
                      totalChargesTwoYearsAgo: station.totalChargesTwoYearsAgo,
                      varKwhLastYear: percentageChange(station.deliveredKwh, station.deliveredKwhLastYear),
                      varKwhTwoYearsAgo: percentageChange(station.deliveredKwh, station.deliveredKwhTwoYearsAgo),
                      varChargesLastYear: percentageChange(station.totalCharges, station.totalChargesLastYear),
                      varChargesTwoYearsAgo: percentageChange(station.totalCharges, station.totalChargesTwoYearsAgo),
                      avgKwhPerCharge: parseKwh(average(station.deliveredKwh, station.totalCharges)),
                      avgKwhPerChargeLastYear: parseKwh(
                        average(station.deliveredKwhLastYear, station.totalChargesLastYear),
                      ),
                      varKwhPerChargeLastYear: percentageChange(
                        station.deliveredKwh / station.totalCharges,
                        station.deliveredKwhLastYear / station.totalChargesLastYear,
                      ),
                      avgKwhPerChargeTwoYearsAgo: parseKwh(
                        average(station.deliveredKwhTwoYearsAgo, station.totalChargesTwoYearsAgo),
                      ),
                      varKwhPerChargeTwoYearsAgo: percentageChange(
                        station.deliveredKwh / station.totalCharges,
                        station.deliveredKwhTwoYearsAgo / station.totalChargesTwoYearsAgo,
                      ),
                      avgDailyCharge: average(station.totalCharges, summary.maxDaysInMonth), // cambiare con totRicarche / giorni di quel mese
                      avgDailyChargeLastYear: average(station.totalChargesLastYear, summary.maxDaysInMonthLastYear), // cambiare con totRicarche / giorni di quel mese
                      varDailyChargeLastYear: percentageChange(
                        station.totalCharges / summary.maxDaysInMonth,
                        station.totalChargesLastYear / summary.maxDaysInMonthLastYear,
                      ),
                      avgDailyChargeTwoYearsAgo: average(
                        station.totalChargesTwoYearsAgo,
                        summary.maxDaysInMonthTwoYearsAgo,
                      ), // cambiare con totRicarche / giorni di quel mese
                      varDailyChargeTwoYearsAgo: percentageChange(
                        station.totalCharges / summary.maxDaysInMonth,
                        station.totalChargesTwoYearsAgo / summary.maxDaysInMonthTwoYearsAgo,
                      ),
                    });
                  }
                }

                //dichiaro la funzione per calcolare la media
                let totalAverageKwhRecharge = { total: 0, count: 0 };

                // Il tuo ciclo principale diventerebbe:
                for (const monthKey of monthKeys) {
                  const monthData = data.months[monthKey];
                  const summary = data.summary[monthKey];

                  fillMonthlySummary(workbook, summary);
                  fillStationsForMonth(workbook, summary.monthName, monthData, summary);

                  const tmpAverage = average(summary.totalKwh, summary.totalCharges);
                  if (tmpAverage > 0) {
                    totalAverageKwhRecharge.total += parseFloat(tmpAverage);
                    totalAverageKwhRecharge.count++;
                  }
                }

                tmpWorksheet = workbook.getWorksheet('Riepilogo');
                //imposto i formati delle celle
                let sum = 'SUM(';

                for (let i = 4; i <= 15; i++) {
                  sum += `VALUE(R${i}),`;
                }
                sum = sum.slice(0, -1) + ')'; // Rimuovi l'ultima virgola e aggiungi la parentesi chiusa

                tmpWorksheet.getCell('R17').value = 'Totale kWh';
                tmpWorksheet.getCell('R18').value = { formula: sum };

                tmpWorksheet.getCell('W17').value = 'Media Anno';
                tmpWorksheet.getCell('W18').value = parseKwh(
                  totalAverageKwhRecharge.total / totalAverageKwhRecharge.count,
                );

                Object.assign(tmpWorksheet.getCell('R17'), commonSettings);
                Object.assign(tmpWorksheet.getCell('W17'), commonSettings);

                workbook.xlsx
                  .writeBuffer()
                  .then((buffer) => {
                    const blob = new Blob([buffer], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Report ${sendFormData.year}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                  })
                  .catch((error) => {
                    console.error(error);
                  });

                //loading steps
                loadingStep[2].status = 'complete';
                downloadInfos.endDownload = true;
              })
              .catch((error) => {
                console.log('error', error);
                downloadInfos.error = true;
                downloadInfos.inProgress = false;
              });
          })
          .catch((error) => {
            console.log('error', error);
            downloadInfos.error = true;
            downloadInfos.inProgress = false;
          });
      };

      const rechargeTitle = ref('');
      watch(sendFormData, (newVal) => {
        if (newVal.selectedMonth != '') rechargeTitle.value = 'Ricariche mese ' + t(newVal.selectedMonth);
        else if (newVal.year != '') rechargeTitle.value = 'Ricariche anno ' + newVal.year;
      });

      return {
        loadingScreen,
        rechargeTitle,
        data,
        stations,
        loadingStep,
        steps,
        months,
        years,
        sendFormData,
        structure,
        downloadInfos,
        showPanel,
        rechargeOffset,
        resetDownloadInfos,
        togglePanel,
        getStats,
        downloadReport,
        sendAnaliticsData,
      };
    },
  };
</script>

<style></style>
