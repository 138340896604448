<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
import {Utils} from "@/Utils/utils";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //init vue stuffs
    const store = useStore();
    //init selects
    store.dispatch("getAssignableTrackers");
    const trackers = computed(() =>
      Utils.getNameArrayFromData(store.getters.assignableTrackers, "imei")
    );

    store.dispatch("getManagers");
    store.dispatch("getScooterTypes");
    const scooterTypes = computed(() => {
      return Utils.getNameArrayFromData(store.getters.scooterTypes);
    });
    const managers = computed(() => {
      return Utils.getNameArrayFromData(store.getters.managers);
    });
    store.dispatch("getZoneGroups");
    const zoneGroups = computed(() => {
      return Utils.getNameArrayFromData(store.getters.zoneGroups);
    });
    //structure of page
    const insertData = {
      title: "Inserimento nuovo scooter",
      redirect_path: "/sharing/vehicles/scooter",
      create_endpoint: "createScooter",
      creation_title: "Creazione nuovo scooter",
      dispatchName: "getScooters",
      data: [
        reactive({
          title: "Tipo",
          name: "type",
          select: scooterTypes,
          required: true,
        }),
        reactive({
          title: "Numero tracker",
          name: "id_veicolo",
          select: trackers,
          required: true,
          class: "col-span-2",
        }),
        reactive({
          title: "Gestore",
          name: "manager",
          select: managers,
        }),
        reactive({
          title: "Gruppo di zone",
          name: "zone_group",
          select: zoneGroups,
        }),
        {
          title: "Targa",
          name: "plate",
          required: true,
          class: "col-span-2",
        },
        {
          title: "Telaio",
          name: "telaio",
          required: true,
          class: "col-span-2",
        },
        {
          title: "Manutenzione",
          name: "maintenance",
          boolean: true,
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>