<template>
  <div class="p-4 m-2 bg-white/20 dark:bg-gray-900/20 bg-opacity-150 rounded-full">
    <slot></slot>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
