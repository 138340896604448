<template>
<div class="p-10">
  <!--:previewformat="format" -->
  <Datepicker 
    v-model="date" 
    locale="it" 
    :format="format" 
    cancelText="annulla" 
    selectText="selezionare"
  ></Datepicker>
</div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'

export default {
  name: 'BaseDatePicker',
  components: { Datepicker },
    setup() {
      const date = ref(new Date());
      const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${year}/${month}/${day} - ${hours}:${minutes}`;
      }
      return {
        date,
        format
      }
  }
}
</script>


<style>
  .dp__select {
    color: black!important;
  }
</style>