import { ApiCaller } from '@/Utils/utils';
export default {
  state: {
    APIToken: '',
    isAuthenticated: false,
    user: null,
    selectedPlatform: null,
    selectedOrganisation: null,
    selectedRole: null,
    useAuth0: () => {},
  },
  getters: {
    APIToken: (state) => state.APIToken,
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    selectedPlatform: (state) => state.selectedPlatform,
    selectedOrganisation: (state) => state.selectedOrganisation,
    selectedRole: (state) => state.selectedRole,
    useAuth0: (state) => state.useAuth0,
  },
  mutations: {
    setAPIToken: (state, token) => (state.APIToken = token),
    setIsAuthenticated: (state, data) => (state.isAuthenticated = data),
    setUser: (state, user) => (state.user = user),
    setSelectedPlatform: (state, id) => (state.selectedPlatform = id),
    setSelectedOrganisation: (state, id) => (state.selectedOrganisation = id),
    setSelectedRole: (state, id) => (state.selectedRole = id),
    setUseAuth0: (state, data) => (state.useAuth0 = data),
  },
  actions: {
    setAPIToken: (context, token) => context.commit('setAPIToken', token),
    setIsAuthenticated: (ctx, payload) => ctx.commit('setIsAuthenticated', payload),
    emptyUser: (ctx) => ctx.commit('setUser', null),
    setUser: (ctx) => ApiCaller.GET(`/auth/me`).then((data) => ctx.commit('setUser', data)),
    setSelectedPlatform: (ctx, id) => ctx.commit('setSelectedPlatform', id),
    setSelectedOrganisation: (ctx, id) => ctx.commit('setSelectedOrganisation', id),
    setSelectedRole: (ctx, id) => ctx.commit('setSelectedRole', id),
    setUseAuth0: (ctx, payload) => ctx.commit('setUseAuth0', payload),
  },
};
