<template>
  <base-list-table v-if="completeList" :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();
      function refreshPage() {
        store.dispatch('getAlarms', store.getters.currentPlatform.db);
      }

      const alarmsList = computed(() => store.getters.activeAlarms);

      const structure = {
        title: 'views.alarms.alarmsList',
        timeFields: [{ label: 'views.timeEntries.creationDate', key: 'createdAt' }],
        data: [
          {
            title: 'Id',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'title',
              },
            ],
            span: 1,
            link: { idField: 'id', path: '/alarm/alarms/:id' },
          },
          {
            title: 'views.alarms.assignedTo',
            fields: [
              {
                placeholder: '@',
                name: 'assignedTo',
                subName: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'assignedTo',
                subName: 'name',
              },
              {
                name: 'assignedTo',
                subName: 'surname',
              },
            ],
            link: {
              idField: 'id',
              subField: 'assignedTo',
              path: '/user/users/:id',
            },
            span: 1,
          },
          {
            title: 'views.alarms.alarmType',
            fields: [
              {
                name: 'alarmType',
                subName: 'name',
              },
            ],
            span: 1,
            link: {
              idField: 'id',
              subField: 'alarmType',
              path: '/alarm-type/:id',
            },
          },
          {
            title: 'views.alarms.alarmStatusType',
            fields: [
              {
                name: 'currentStatusType',
                subName: 'name',
              },
            ],
            span: 1,
            link: {
              idField: 'id',
              subField: 'currentStatusType',
              path: '/alarm-status-type/:id',
            },
          },
          {
            title: 'views.timeEntries.creationDate',
            format: 'datetime',
            field: 'createdAt',
            span: 1,
          },
        ],
      };

      return {
        completeList: alarmsList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
