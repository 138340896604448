import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    cars: [],
    selectedCar: null,
    carTypes: [],
  },
  getters: {
    cars: (state) => state.cars,
    selectedCar: (state) => {
      if (state.selectedCar) return state.selectedCar[0];
      return state.selectedCar;
    },
    carTypes: (state) => state.carTypes,
  },
  mutations: {
    getCars: (state, data) => (state.cars = data),
    setSelectedCar: (state, data) => (state.selectedCar = data),
    getCarTypes: (state, data) => (state.carTypes = data),
  },
  actions: {
    getCars: (ctx, payload) =>
      ApiCaller.GET('/vehicles/car', payload).then((data) => {
        ctx.commit('getCars', Utils.createFiltrableData(data));
      }),
    setSelectedCar: (ctx, payload) =>
      ApiCaller.GET(`/vehicles/car/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedCar', data);
      }),
    getCarTypes: (ctx, payload) =>
      ApiCaller.GET('/vehicle_types', { vehicleType: 'car', ...payload }).then((data) => {
        ctx.commit('getCarTypes', Utils.createFiltrableData(data));
      }),
    //QUERIES
    updateCar: (_, payload) => ApiCaller.PUT(`/vehicles/car/${payload.id}`, payload.body),
    createCar: (_, payload) => ApiCaller.POST(`/vehicles/car`, payload),
    createCarType: (_, payload) => ApiCaller.POST(`/vehicles/car_type`, { type: 'car', ...payload }),
    updateCarType: (_, payload) => ApiCaller.PUT(`/vehicles/type/${payload.id}`, { type: 'car', ...payload.body }),
  },
  modules: {},
};
