<template>
  <div class="p-2 border border-gray-300 dark:border-gray-700 rounded-2xl shadow-md bg-gradient-to-r">
    <div class="grid grid-cols-5 font-bold text-lg text-gray-700">
      <p class="col-span-1">{{ t('user.title') }}</p>
      <p class="col-span-1">{{ t('views.stations.rechargeStart') }}</p>
      <p>{{ t('station.socket') }}</p>
      <p>kWh</p>
      <p>Penalty time</p>
    </div>
    <div class="grid grid-cols-5 text-gray-600 dark:text-gray-300 items-center">
      <base-router-link
        :path="{ name: 'menu.user.userGenerals', params: { id: `${el.user_id}` } }"
        class="text-gray-600 dark:text-gray-300 col-span-1">
        <p class="">@{{ el.user_id }}</p>
        <p>{{ el.name }} {{ el.surname }}</p>
      </base-router-link>
      <div class="col-span-1">
        <p>{{ el.inizio_ricarica }}</p>
      </div>
      <div>{{ el.numero_presa }}</div>
      <div>{{ el.consumed_energy }}</div>
      <div>{{ el.penalty_time }}</div>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      el: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return { t: useI18n().t };
    },
  };
</script>

<style></style>
