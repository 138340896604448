<template>
  <base-list-table
    :data="data"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const selectedDevice = computed(() => store.getters.selectedDevice);
    const refreshPage = () => {
      if (selectedDevice.value)
        store.dispatch("getDeviceEvents", { id: selectedDevice.value.id });
    };

    const events = computed(() => store.getters.deviceEvents);

    const structure = {
      title: "Lista Eventi",
      data: [
        {
          title: "Etichetta",
          field: "label",
          span: 1,
        },
        {
          title: "Viaggi",
          field: "trips",
          span: 1,
        },
        {
          title: "Creazione",
          field: "created_at",
          format: "datetime",
          span: 1,
          class: "text-style",
        },
        {
          title: "ID",
          field: "event_id",
          span: 1,
          class: "text-style",
        },
      ],
    };

    return {
      data: events,
      structure,
      refreshPage,
    };
  },
};
</script>

<style></style>
