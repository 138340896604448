<template>
  <button @click="$router.back()" class="material-icons rounded-lg text-gray-600 px-3">arrow_back</button>
  <div class="flex justify-center items-center">
    <div v-if="el" class="relative p-6 bg-gray-100 shadow w-1/2 rounded-2xl">
      <!-- <div
        class="transition-all duration-100 absolute h-2 bg-blue-500 top-0 left-0 rounded-t-2xl"
        :style="progressWidth"></div> -->
      <!-- card infos -->
      <div class="flex justify-center">
        <div class="bg-gray-200 rounded-lg p-1">
          <div v-if="!editName" class="flex justify-center">
            <p class="self-center text-xl font-bold mx-4">{{ slot?.name }}</p>
            <div class="flex border p-1 border-gray-300 rounded-md bg-white">
              <button @click="editName = !editName" class="material-icons text-gray-600 hover:text-yellow-700">
                edit
              </button>
            </div>
          </div>
          <div v-else class="flex justify-center">
            <div class="flex pr-2 py-2 rounded-xl">
              <input
                type="text"
                v-model="inputValue"
                @keydown.enter="submitInput"
                class="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400 mx-4" />
              <div class="border border-gray-300 flex rounded-md bg-white">
                <button @click="submitInput" class="material-icons text-gray-600 ml-2 mr-1 hover:text-blue-600">
                  send
                </button>
                <button @click="editName = !editName" class="material-icons text-gray-600 hover:text-red-600">
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-between rounded-2xl bg-gray-200 p-4 mt-4">
        <div class="flex">
          <button @click="reloadPage" class="self-start mr-4">
            <span
              class="material-icons border border-gray-400 bg-gray-200 rounded-lg text-gray-400 p-1 ml-4 hover:bg-gray-600 hover:text-gray-200">
              refresh
            </span>
          </button>
          <div class="text-left">
            <p class="text-sm text-gray-500 leading-none">{{ $t('sharing.lastUpdate') }}</p>
            <p class="font-bold text-lg leading-none">{{ el?.Ultimo_Aggiornamento }}</p>
          </div>
        </div>
        <div v-if="slot?.qrUrl" class="ml-8">
          <base-qr-code :value="slot?.qrUrl" :imageName="slot?.name" />
        </div>
      </div>
      <div class="mt-6">
        <div class="rounded-xl shadow bg-white p-4 text-sm">
          <div class="grid grid-cols-2 justify-between">
            <p class="text-gray-400">{{ $t('sharing.slotState') }}</p>
            <p class="font-bold text-right">
              {{ el?.Bici_Presente == 1 ? $t('sharing.slotBusy') : $t('sharing.slotAvailable') }}
            </p>

            <p class="text-gray-400">{{ $t('sharing.jackState') }}</p>
            <p class="font-bold text-right">{{ stall?.status }}</p>

            <p class="text-gray-400">{{ $t('sharing.assignedBike') }}</p>
            <div class="flex justify-end">
              <div class="w-full flex justify-end space-x-2">
                <button class="text-sm font-semibold underline text-red-500" @click="removeBike">Rimuovi</button>
                <button
                  class="ml-2 text-sm font-semibold underline text-red-500"
                  v-if="changeBikeForm"
                  @click="changeBikeForm = !changeBikeForm">
                  Annulla
                </button>
                <button
                  class="text-sm font-semibold underline text-blue-500"
                  v-else
                  @click="changeBikeForm = !changeBikeForm">
                  Cambia
                </button>
                <p class="font-bold">
                  {{ slot?.bike_plate && slot?.bike_plate != -1 ? slot?.bike_plate : 'Nessuna' }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="changeBikeForm" class="border rounded-xl border-gray-400 p-2 mt-4 flex justify-between">
            <base-input-field size="small" placeholder="nuova targa" v-model="newPlate" />
            <button class="self-center ml-4 text-gray-400 hover:text-gray-600" @click="changeBike">
              {{ $t('sharing.update') }}
            </button>
          </div>
        </div>
      </div>
      <!-- commands -->
      <div class="flex justify-center mt-2">
        <button
          @click="changeStatus('resetStatus')"
          class="ml-4"
          :class="[{ 'line-through ': el?.rental }, { 'hover:text-gray-500': !el.rental }]"
          :disabled="el?.rental">
          {{ $t('sharing.resetAll') }}
        </button>
      </div>
      <div class="border border-black rounded-xl p-2 text-center mt-6">
        <a
          v-if="el?.rental"
          :href="'/sharing/rentals/inProgress/' + el?.bike_public_id"
          target="_blank"
          class="hover:text-gray-400">
          {{ $t('sharing.endRental') }}
        </a>
        <span v-else>
          <p>{{ $t('sharing.noRentalInProgress') }}...</p>
        </span>
      </div>
      <div class="flex justify-between text-gray-500 text-sm mt-4">
        <button @click="changeStatus('forceOpen')" class="hover:text-gray-800">
          {{ $t('sharing.forceJackOpen') }}
        </button>
        <button @click="changeStatus('forceClose')" class="hover:text-gray-800">
          {{ $t('sharing.forceJackClose') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { i18n } from '@/i18n';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
  export default {
    setup() {
      const t = i18n.global.t;
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      //LASCIARE PRELIEVO E DEPOSITO SENZA LA CONFERMA DELL'OPERAZIONE DI MEZZO

      const el = computed(() => store.getters.selectedStall);
      const slot = computed(() => {
        if (!el.value) return;
        const tmpSlot = store.getters.releaseStation?.slots;
        return tmpSlot?.find((s) => s.remote_id == el.value.num_box);
      });
      const loader = ref(false);

      const elapsed = ref(0);
      const progressWidth = computed(() => {
        let percent = (elapsed.value / 7000) * 100;
        if (percent > 100) percent = 100;
        return 'width:' + (100 - percent) + '%!important;';
      });

      const reloadPage = () => {
        store.dispatch('getSelectedStall', { id: route.params.id, stallId: route.params.stallId });
        elapsed.value = 0;
      };

      reloadPage();
      const stepInterval = 100;
      const interval = ref(null);

      const setCustomInterval = () => {
        if (interval.value) clearInterval(interval.value);
        interval.value = setInterval(() => {
          elapsed.value += stepInterval;
          if (elapsed.value > 7000) reloadPage();
        }, stepInterval);
      };

      onMounted(setCustomInterval);
      onBeforeUnmount(() => {
        if (interval.value) clearInterval(interval.value);
      });

      const stall = computed(() => {
        let status = '';
        switch (el.value.Stato_Lock) {
          case '0':
            status = t('sharing.jackClosed');
            break;
          case '200':
          case '15':
            status = t('sharing.opening');
            break;
          case '20':
            status = t('sharing.jackOpened');
            break;
          case '300':
            status = t('sharing.closing');
            break;
          default:
            status = 'undefined';
            break;
        }

        return {
          ...el,
          status: status,
        };
      });

      const changeStatus = (type) => {
        store.dispatch('sendStallOperation', {
          slotId: slot.value?.id,
          remote_id: el.value?.id_brick,
          slot_remote_id: el.value?.num_box,
          type: type,
        });
      };

      const changeBikeForm = ref(false);

      const newPlate = ref('');
      const changeBike = () => {
        if (newPlate.value && newPlate.value != '')
          store
            .dispatch('changeBike', { id: route.params.id, slotId: route.params.stallId, plate: newPlate.value })
            .then(() => router.go());
      };
      const removeBike = () => {
        store
          .dispatch('changeBike', { id: route.params.id, slotId: route.params.stallId, plate: '2' })
          .then(() => router.go());
      };

      const endSlotRental = () => {
        store
          .dispatch('endSlotRental', {
            bike_id: el.value.bike_id,
            id: route.params.id,
            slotId: route.params.stallId,
          })
          .then(() => router.go());
      };

      const editName = ref(false);
      const inputValue = ref('');
      const submitInput = () => {
        if (inputValue.value && inputValue.value != '')
          store
            .dispatch('updateSlot', { id: route.params.id, slotId: route.params.stallId, name: inputValue.value })
            .then(() => router.go());
      };

      return {
        el,
        loader,
        stall,
        slot,
        changeBikeForm,
        newPlate,
        progressWidth,
        editName,
        inputValue,
        removeBike,
        submitInput,
        endSlotRental,
        changeBike,
        reloadPage,
        changeStatus,
      };
    },
  };
</script>
