<template>
  <base-creation-page v-if="insertData" :insertData="insertData" />
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //init vue stuffs
    const store = useStore();
    //init selects
    onMounted(() => {
      store.dispatch("getManagers");
    });
    //selectUtils:
    function getNameArray(data) {
      let finalArray = [""];
      for (const el in data) {
        finalArray.push(data[el].name);
      }
      return finalArray;
    }
    const managers = computed(() => {
      const mgr = store.getters.managers;
      return getNameArray(mgr);
    });
    //structure of page
    const insertData = {
      title: "Inserimento nuova struttura",
      redirect_path: "/sharing/locations",
      create_endpoint: "createLocation",
      creation_title: "Creazione struttura",
      dispatchName: "getLocations",
      data: [
        {
          title: "Seleziona gestore",
          name: "manager",
          select: managers.value,
          required: true,
        },
        {
          title: "user.name.first",

          name: "name",
          class: "col-span-2",
          required: true,
        },
        {
          title: "Aperto",
          name: "open",
          boolean: true,
          class: "self-center",
        },
        {
          title: "address.body",

          name: "address",
          placeholder: "Via, numero, cap, città",
          class: "col-span-2",
          required: true,
        },

        {
          title: "address.latitude",

          name: "latitude",
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
          required: true,
        },
        {
          title: "address.longitude",

          name: "longitude",
          placeholder: "0.000 NB. NON USARE LA VIRGOLA",
          required: true,
        },
        {
          title: "Email",
          name: "email",
        },

        {
          title: "user.cellphone",

          name: "cellphone",
        },
        {
          title: "user.vat",

          name: "vat",
        },
        {
          title: "address.zipcode",

          name: "zipcode",
          placeholder: "es. 00100",
        },
        {
          title: "user.notes",

          name: "notes",
          class: "col-span-3",
        },
      ],
    };
    //return fields
    return {
      insertData,
    };
  },
};
</script>

<style>
</style>