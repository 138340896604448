<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();
      const route = useRoute();

      const refreshPage = (payload) =>
        store.dispatch('getDeviceConfigurationsDevices', {
          device_configuration: route.params.id,
          ...payload,
        });

      const devices = computed(() => store.getters.deviceConfigurationsDevices);

      // table
      const structure = {
        title: 'Device',
        data: [
          {
            title: 'imei',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'imei',
              },
            ],
            span: 2,
            link: { idField: 'id', path: '/device/devices/:id' },
          },
          {
            title: 'sim imei',
            field: 'sim',
            subField: 'imei',
            span: 1,
          },
          {
            title: 'operatore',
            field: 'sim',
            subField: 'operator',
            span: 1,
          },
          {
            title: 'Proprietario sim',
            field: 'sim',
            subField: 'owner',
            span: 1,
          },
        ],
      };

      return {
        data: devices,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
