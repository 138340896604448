<template>
  <base-page>
    <base-list-table :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
  </base-page>
</template>

<script>
  /* 
import { Utils } from "@/Utils/utils"; */
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed /* , reactive, onBeforeMount */ } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      const route = useRoute();

      function refreshPage() {
        store.dispatch('getAlarmStatuses', route.params.id);
      }

      const alarmsList = computed(() => store.getters.alarmStatuses);

      const structure = {
        title: 'Storico stati',
        timeFields: [
          { label: 'views.timeEntries.creationDate', key: 'menu.alarm.timestamp' },
          { label: 'views.timeEntries.lastUpdate', key: 'menu.alarm.lastUpdate' },
        ],
        data: [
          {
            title: 'Id',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-gray-300',
              },
              {
                name: 'statusType',
                subName: 'name',
                classTest: '(props.el.active===1)?"text-gray-700":"text-gray-400"',
              },
            ],
            span: 1,
          },
          {
            title: 'Effettuato da',
            fields: [
              {
                placeholder: '@',
                name: 'reporter',
                subName: 'id',
                class: 'text-primary-400',
              },
            ],
            link: {
              idField: 'id',
              subField: 'reporter',
              path: '/user/users/:id',
            },
            span: 1,
          },
          /*
        {
          title: "views.alarms.alarmType",
          fields: [
            {
              name: "alarmType",
              subName: "name",
            },
          ],
          span: 1,
          link: { idField: "id", subField:"alarmType", path: "/alarm-type/:id" },
        },
        {
          title: "views.alarms.alarmStatusType",
          fields: [
            {
              name: "currentStatusType",
              subName: "name",
            },
          ],
          span: 1,
          link: { idField: "id", subField:"currentStatusType", path: "/alarm-status-type/:id" },
        }, */
          {
            title: 'views.timeEntries.creationDate',
            format: 'datetime',
            field: 'timestamp',
            span: 1,
          },
          {
            title: 'views.timeEntries.lastUpdate',
            format: 'datetime',
            field: 'lastUpdate',
            span: 1,
          },
        ],
      };

      return {
        completeList: alarmsList,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
