<template>
  <base-list-table :data="summary" :structure="structure" :refreshFunction="refreshPage"></base-list-table>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    name: 'SummaryAnalytics',
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getSummary', payload);
      }
      const summary = computed(() => store.getters.summary);

      const structure = {
        title: 'Totali per stazione',
        /* timeFields: [
        { label: "Inizio ricarica", key: "charging_start" },
        { label: "Fine ricarica", key: "charging_end" },
      ], */
        summaryFields: [
          {
            type: 'SUM',
            label: 'kWh erogati',
            field: 'kwh_delivered',
            unit: ' kWh',
          },
          {
            type: 'SUM',
            label: 'Incassi totali',
            field: 'total_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'Incassi Stripe',
            field: 'card_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'Incassi wallet',
            field: 'wallet_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'Proiezione penalty',
            field: 'penalty_amount',
            unit: ' €',
          },
          /*{
          type: "COUNT_DISTINCT",
          label: "Utenti distinti",
          field: "user_id",
          unit: " clienti",
        },
        {
          type: "COUNT_DISTINCT",
          label: "Stazioni distinte",
          field: "station_id",
          unit: " stazioni",
        }, */
          {
            type: 'SUM',
            label: 'Totale ricariche',
            field: 'count',
            unit: ' sessioni',
          },
        ],
        data: [
          {
            title: 'Colonnina',
            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-blue-300',
              },
              {
                name: 'station_name',
              },
              {
                name: 'station_address',
              },
            ],
            span: 2,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          /*  {
           title: "Prima",
           field: "charging_start",
           format: "datetime",
           span: 1,
         }, */
          {
            title: 'Ultima',
            field: 'charging_end',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'Energia erogata',
            format: 'kwh',
            field: 'kwh_delivered',
            span: 1,
          },
          {
            title: 'Penalty Time',
            format: 'secondsToDuration',
            field: 'penalty_time',
            span: 1,
          },
          {
            title: 'Stripe',
            field: 'card_amount',
            format: 'euro',
            span: 1,
          },
          /* {
          title: "Wallet",
          field: "wallet_amount",
          format: "euro",
          span: 1,
        }, */
          {
            title: 'Totale',
            field: 'total_amount',
            format: 'euro',
            span: 1,
          },
          {
            title: 'Rendimento',
            field: 'kw_on_count',
            format: 'kwh/recharges',
            span: 2,
          },
          {
            title: 'N° Ricariche',
            field: 'count',
            span: 1,
          },
        ],
      };
      return {
        summary,
        structure,
        refreshPage,
      };
    },
  };
</script>
