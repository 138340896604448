<template>
  <div class="relative flex justify-between items-center">
    <div class="h-full shrink-0 flex">
      <button id="platform-button" @focus="select = true" @blur="select = false" class="relative w-full flex justify-start items-center">
        <div class="cursor-pointer relative flex w-10 h-10 p-1 overflow-hidden justify-center items-center bg-white dark:bg-gray-900 rounded-xl">
          <img v-if="currentPlatform.logo" :src="currentPlatform.logo" />
          <span v-else class="material-icons animate-spin">refresh</span>
        </div>
      </button>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, watch, ref } from 'vue';

  export default {
    setup() {
      const store = useStore();
      const currentPlatform = computed(() => store.getters.currentPlatform);
      const select = ref(false);

      // COLORS

      const setColor = () => {
        const colors = require('tailwindcss/colors');
        let color = colors.indigo;
        switch (currentPlatform.value.db) {
          case 'VIB':
            color = colors.amber;
            break;
          case 'TQN':
            color = colors.orange;
            break;
          case 'AC':
            color = colors.red;
            break;
          case 'ON':
            color = colors.indigo;
            break;
          case 'LAKE_ENERGY':
            color = colors.sky;
            break;
          case 'ANG':
            color = colors.lime;
            break;
          case 'TEST':
            color = colors.emerald;
            break;
          default:
            color = colors.slate;
        }
        var r = document.querySelector(':root');
        r.style.setProperty('--primary-color-50', color[50]);
        r.style.setProperty('--primary-color-100', color[100]);
        r.style.setProperty('--primary-color-200', color[200]);
        r.style.setProperty('--primary-color-300', color[300]);
        r.style.setProperty('--primary-color-400', color[400]);
        r.style.setProperty('--primary-color-500', color[500]);
        r.style.setProperty('--primary-color-600', color[600]);
        r.style.setProperty('--primary-color-700', color[700]);
        r.style.setProperty('--primary-color-800', color[800]);
        r.style.setProperty('--primary-color-900', color[900]);
      };

      const favicon = computed(() => {
        let f = 'ON';
        switch (currentPlatform.value.db) {
          case 'AC':
          case 'ON':
          case 'LAKE_ENERGY':
          case 'VIB':
          case 'TQN':
          case 'ANG':
            f = currentPlatform.value.db;
            break;
          default:
            f = 'ON';
        }
        return `https://prod.onme.cloud/applicazione/style/imgs/favicons/favicon_${f}.ico`;
      });
      watch(favicon, (newVal) => {
        console.log('FAVICON', newVal);
        const fav = document.getElementById('favicon');
        fav.href = newVal;
      });

      const assignPlatformForReal = (platform) => {
        const btn = document.getElementById('platform-button');
        if (btn) btn.blur();
        console.log('NUOVA PIATTAFORMA', platform);
        store.dispatch('setCurrentPlatform', platform);
        setColor();
      };

      const selectedPlatform = computed(() => store.getters.selectedPlatform);
      const platforms = computed(() => {
        if (store.getters.user && store.getters.user.platforms) return store.getters.user.platforms;
        return [];
      });
      watch(
        selectedPlatform,
        (nv) => {
          let platform = null;
          if (nv) platform = platforms.value.find((p) => p.id == nv);
          if (platform) assignPlatformForReal(platform);
        },
        { immediate: true },
      );

      return {
        select,
        currentPlatform,
        assignPlatformForReal,
      };
    },
  };
</script>

<style>
  :root {
    --primary-color-50: #eef2ff;
    --primary-color-100: #e0e7ff;
    --primary-color-200: #c7d2fe;
    --primary-color-300: #a5b4fc;
    --primary-color-400: #818cf8;
    --primary-color-500: #6366f1;
    --primary-color-600: #4f46e5;
    --primary-color-700: #4338ca;
    --primary-color-800: #3730a3;
    --primary-color-900: #312e81;
  }
</style>
