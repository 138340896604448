import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    anomalies: [],
  },
  getters: {
    anomalies: (state) => state.anomalies,
  },
  mutations: {
    getAnomalies: (state, data) => (state.anomalies = data),
  },
  actions: {
    getAnomalies: (ctx, payload) =>
      ApiCaller.GET(`/anomalies`, payload).then((data) => {
        ctx.commit('getAnomalies', Utils.createFiltrableData(data));
      }),
    restoreAnomaly: (_, payload) => ApiCaller.PUT(`/restore_anomalies`, payload),
  },
};
