<template>
  <template v-if="platformsLocales">
    <base-list-table v-if="completeList" :data="completeList" :structure="structure" :refreshFunction="refreshPage" />
  </template>
  <!-- 
  locales: {{$store.getters.locales}} <br><br><hr><br><br>
  sublocales: {{$store.getters.sublocales}} <br><br><hr><br><br>
  currentLocale: {{$store.getters.currentLocale}} <br><br><hr><br><br>
  platformsLocales: {{$store.getters.platformsLocales}} <br><br><hr><br><br>
  localesList: {{$store.getters.localesList}} <br><br><hr><br><br>
  sublocalesList: {{$store.getters.sublocalesList}} <br><br><hr><br><br>
  currentPlatformLocales: {{$store.getters.currentPlatformLocales}} <br><br><hr><br><br> -->
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, onBeforeMount } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    components: {},
    setup() {
      const store = useStore();
      function refreshPage() {
        store.dispatch('retrievePlatformsLocales');
      }
      onBeforeMount(() => store.dispatch('retrievePlatformsLocales'));
      const platformsLocales = computed(() => {
        const locales = store.getters.platformsLocales;
        let out = [];
        if (!locales) return out;
        Object.keys(locales).forEach((key, index) => {
          out[index] = {};
          if (out[index]['platformDb'] === undefined || out[index]['platformDb'] === null) out[index]['platformDb'] = key;
          const locale = locales[key];
          Object.keys(locale).forEach((locKey) => {
            out[index][locKey] = locale[locKey];
          });
        });
        return out;
      });

      const structure = {
        title: 'views.languages.platformsList',

        creationLink: {
          title: 'buttons.createTranslation',

          namePath: 'menu.languages.languagesCreate',
          translate: false,
        },
        data: [
          {
            title: 'Id',
            fields: [
              {
                placeholder: '@',
                name: 'platformDb',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 1,
            link: { idField: 'platformDb', path: '/languages/language/:id' },
          },
          {
            title: 'views.languages.languages',

            field: 'locales',
            span: 1,
          },
        ],
      };

      return {
        completeList: platformsLocales,
        structure,
        platformsLocales,
        refreshPage,
        i18n: useI18n(),
      };
    },
  };
</script>

<style></style>
