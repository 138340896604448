<template>
  <base-list-element class="grid grid-cols-2">
    <template v-for="el in editableFields.data" :key="el">
      <template v-if="!el.editable">
        <span class="col-span-2" :class="getClass(el.isMain, el.level)" v-html="el.title"></span>
      </template>
      <base-input-field v-else :title="el.title" :placeholder="el.placeholder" v-model="queryObj[el.name].value" :class="'mx-2 ' + el.class" />
      <base-input-field v-if="!el.isMain" title="" v-model="queryObj[el.name].label" :placeholder="el.name" :class="'mx-2 ' + el.class" />
    </template>
  </base-list-element>
  <base-button icon="save" @click="showModal = true">Salva</base-button>
  <Teleport to="body">
    <base-confirm-field :show="showModal" @close="showModal = false">
      <template #header>
        <h3>{{ editableFields.edit_title }}</h3>
      </template>
      <template #body>
        <p v-html="bodyModel()"></p>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <p v-if="canSend">Continuare?</p>
          <div>
            <button v-if="canSend" class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2" type="button" @click="sendForm">Si</button>
            <button class="w-6 h-6 hover:bg-red-200 text-sm rounded-full" type="button" @click="showModal = false">
              {{ canSend ? 'No' : 'Ok' }}
            </button>
          </div>
        </div>
      </template>
    </base-confirm-field>
  </Teleport>
</template>

<script>
  /* eslint-disable */
  import { useStore } from 'vuex';
  import { ref, computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  export default {
    props: {
      editableFields: {
        type: Object,
        required: true,
      },
      element: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const queryObj = computed(() => {
        let obj = props.editableFields.data;
        let newObj = {};
        for (let el in obj) {
          const elmt = obj[el];
          if (elmt === undefined) continue;
          if (!elmt.editable) continue;
          if (elmt.name === undefined) {
            newObj[elmt.title] = {
              value: elmt.value,
              label: elmt.title,
              oldValue: elmt.value,
              oldLabel: elmt.title,
            };
          } else {
            newObj[elmt.name] = {
              value: elmt.value,
              label: elmt.name,
              oldValue: elmt.value,
              oldLabel: elmt.name,
            };
          }
        }
        return newObj;
      });

      watch(
        queryObj,
        async () => {
          //await store.dispatch('loaderShow', 0);
        },
        {
          flush: 'pre',
        },
      );
      watch(
        queryObj,
        async () => {
          //await store.dispatch('loaderHide');
        },
        {
          flush: 'post',
        },
      );
      const getClass = computed(() => (main, level) => {
        if (!main) return 'text-md text-primary-400';
        switch (level) {
          case 1:
            return 'pl-2 text-xl text-primary-500 pt-2';
          case 2:
            return 'pl-2 font-bold text-lg text-primary-500';
          case 3:
            return 'pl-2 text-lg text-primary-500';
          case 4:
            return 'pl-2 text-md';
          default:
            return 'font-bold text-xl text-primary-500 py-2';
        }
      });
      //check if field are changed
      function checkFields() {
        let finalObj = {};
        let i = 0;
        for (let el in queryObj.value) {
          if (queryObj.value[el].value === queryObj.value[el].oldValue && queryObj.value[el].label === queryObj.value[el].oldLabel) continue;
          finalObj[i++] = parseField(queryObj.value[el]);
        }
        if (finalObj === {}) return;
        return finalObj;
      }
      function sendForm() {
        const body = checkFields();
        if (!body || body === {}) return;
        store
          .dispatch(props.editableFields.edit_endpoint, {
            id: route.params.id,
            ...route.params,
            body,
          })
          .then(() => (showModal.value = false))
          .then(() => setTimeout(() => router.go(), 1500));
      }
      //check fields
      function parseField(itm) {
        if (itm === true) return '1';
        else if (itm === false) return '0';
        return itm;
      }
      //confirm modal
      const showModal = ref(false);
      const canSend = ref(false);
      function bodyModel() {
        let found = false;
        canSend.value = false;
        let finalString = 'Stai per modificare: <br><ul>';
        for (let el in queryObj.value) {
          if (queryObj.value[el].value === queryObj.value[el].oldValue && queryObj.value[el].label === queryObj.value[el].oldLabel) continue;
          canSend.value = true;
          found = true;
          let prev;
          let next;

          if (queryObj.value[el].value !== queryObj.value[el].oldValue) {
            prev = queryObj.value[el].oldValue;
            next = queryObj.value[el].value;
            finalString += ` <li>'${prev}' in '${next}'</li>`;
          }
          if (queryObj.value[el].label !== queryObj.value[el].oldLabel) {
            prev = queryObj.value[el].oldLabel;
            next = queryObj.value[el].label;
            finalString += ` <li>'${prev}' in '${next}'</li>`;
          }
        }
        finalString += '</ul>';
        return found ? finalString : 'Non hai modificato niente';
      }
      return {
        queryObj,
        showModal,
        sendForm,
        bodyModel,
        getClass,
        canSend,
      };
    },
  };
</script>

<style></style>
