<template>
  <div class="flex justify-end">
    <span class="mr-4 isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        class="relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.all,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.all,
        }"
        @click="setActiveFilter('all')">
        Mostra tutte
      </button>
    </span>

    <span class="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.dayBtn,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.dayBtn,
        }"
        @click="setActiveFilter('day')">
        Oggi
      </button>
      <button
        type="button"
        class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.monthBtn,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.monthBtn,
        }"
        @click="setActiveFilter('month')">
        Mese
      </button>
      <button
        type="button"
        class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.yearBtn,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.yearBtn,
        }"
        @click="setActiveFilter('year')">
        Anno
      </button>
    </span>

    <span class="mx-4 isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.zeroBtn,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.zeroBtn,
        }"
        @click="setActiveFilter('zero')">
        Solo ricariche a 0
      </button>
      <button
        type="button"
        class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'bg-primary-700 text-gray-200 hover:bg-primary-100 hover:text-gray-900': filterButtons.noZeroBtn,
          'text-gray-900 bg-white hover:bg-gray-50': !filterButtons.noZeroBtn,
        }"
        @click="setActiveFilter('noZero')">
        Escludi ricariche a 0
      </button>
    </span>
  </div>

  <base-list-table :data="data" :structure="structure"></base-list-table>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, reactive } from 'vue';
  export default {
    setup() {
      const store = useStore();
      const recharges = computed(() => store.getters.rechargesEnded);

      store.dispatch('getRechargesEnded');

      const structure = {
        title: 'views.recharges.endedRecharges',

        /*  summaryFields: [
          {
            type: 'SUM',
            label: 'views.recharges.providedKWH',
            field: 'kWh',
            unit: ' kWh',
          },
          {
            type: 'SUM',
            label: 'views.recharges.totalTakings',
            field: 'total_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'views.recharges.stripeTakings',
            field: 'card_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'views.recharges.walletTakings',
            field: 'wallet_amount',
            unit: ' €',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctUsers',
            field: 'userId',
            unit: 'users',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctStations',
            field: 'stationId',
            unit: 'station.plurals',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.totalRecharges',
            field: 'sessionId',
            unit: 'sessions',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.recharges.noKWHRecharges',
            field: 'sessionId',
            valueField: 'kWh',
            value: '0',
            unit: 'sessions',
          },
        ], */
        data: [
          {
            title: 'views.recharges.session',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'sessionId',
              },
              {
                placeholder: 'Presa: ',
                name: 'plugId',
              },
            ],
            link: { idField: 'id', path: '/recharge/recharges/:id' },
            span: 2,
          },
          {
            title: 'views.recharges.chargingStation',
            fields: [
              {
                placeholder: '@',
                name: 'stationId',
                class: 'text-primary-400',
              },
              {
                name: 'stationPlate',
              },
              {
                name: 'stationAddress',
              },
            ],
            span: 3,
            link: { idField: 'stationId', path: '/recharge/stations/:id' },
          },
          {
            title: 'user.title',

            fields: [
              {
                placeholder: '@',
                name: 'userId',
                class: 'text-primary-400',
              },
              {
                name: 'userName',
              },
            ],
            span: 2,
            link: { idField: 'userId', path: '/user/users/:id' },
          },
          {
            title: 'startDate',
            field: 'startTimestamp',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'endDate',
            field: 'endTimestamp',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'penaltyTime',
            field: 'penaltyTime',
            format: 'unix',
            span: 1,
          },
          {
            title: 'views.recharges.providedPower',

            field: 'kWh',
            format: 'kwh',
            span: 1,
          },
        ],
      };

      //buttons
      const filterButtons = reactive({
        dayBtn: false,
        monthBtn: false,
        yearBtn: false,
        all: true,
        zeroBtn: false,
        noZeroBtn: false,
      });

      const setActiveFilter = (filter) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        let dateStart, dateEnd, customDate, onlyZeroKwh, onlyGreaterZero;
        //setto a true il filtro selezionato e a false gli altri
        if (filter == 'day') {
          filterButtons.all = false;
          filterButtons.dayBtn = true;
          filterButtons.monthBtn = false;
          filterButtons.yearBtn = false;

          dateStart = `${year}-${month}-${today.getDate()}`;
          dateEnd = `${year}-${month}-${today.getDate()}`;
          customDate = true;
        } else if (filter == 'month') {
          filterButtons.all = false;
          filterButtons.dayBtn = false;
          filterButtons.monthBtn = true;
          filterButtons.yearBtn = false;

          dateStart = `${year}-${month}-01`;
          dateEnd = `${year}-${month}-31`;
        } else if (filter == 'year') {
          filterButtons.all = false;
          filterButtons.dayBtn = false;
          filterButtons.monthBtn = false;
          filterButtons.yearBtn = true;

          dateStart = `${year}-01-01`;
          dateEnd = `${year}-12-31`;
        }
        if (filter == 'all') {
          filterButtons.all = true;
          filterButtons.dayBtn = false;
          filterButtons.monthBtn = false;
          filterButtons.yearBtn = false;
          filterButtons.zeroBtn = false;
          filterButtons.noZeroBtn = false;
        }
        if (filter == 'zero') {
          filterButtons.all = false;
          filterButtons.zeroBtn = true;
          filterButtons.noZeroBtn = false;

          onlyZeroKwh = true;
        } else if (filter == 'noZero') {
          filterButtons.all = false;
          filterButtons.zeroBtn = false;
          filterButtons.noZeroBtn = true;

          onlyGreaterZero = true;
        }

        store
          .dispatch('getRechargesEnded', {
            dateStart,
            dateEnd,
            customDate,
            onlyZeroKwh,
            onlyGreaterZero,
          })
      };
      return {
        data: recharges,
        structure,
        filterButtons,
        setActiveFilter,
      };
    },
  };
</script>
