<template>
  <base-list-table
    v-if="completeList"
    :data="completeList"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
  <base-spinner v-else />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = () =>
      store.dispatch("getCompleteUsersList", {
        user_type: "Cliente",
        manager: route.params.managerId,
      });

    const completeUserList = computed(() => {
      return store.getters.completeUsersList;
    });

    const structure = {
      title: "views.user.users",
      
      data: [
        {
          title: "user.name.first",
          
          fields: [
            {
              placeholder: "@",
              name: "id",
              class: "text-primary-400",
            },
            {
              name: "name",
            },
          ],
          span: 1,
          link: { idField: "id", path: "/user/users/:id" },
        },
        {
          title: "user.name.second",
          
          field: "surname",
          span: 1,
        },
        {
          title: "user.cellphone",
          
          field: "cellphone",
          span: 1,
        },
        {
          title: "Email",
          field: "email",
          span: 1,
        },
        {
          title: "user.notes",
          
          field: "notes",
          span: 3,
        },
      ],
    };
    return {
      completeList: completeUserList,
      structure,
      refreshPage,
    };
  },
};
</script>

<style>
</style>