<template>
  <div class="w-full h-min flex flex-col p-12">
    <ul role="list" class="w-1/2 grid grid-cols-1 gap-6">
      <li
        v-if="coupon && coupon.id && !newCoupon"
        :key="coupon.id"
        class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div class="flex w-full items-center justify-between space-x-6 p-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="truncate text-xl font-bold text-gray-900">{{ coupon.name }}</h3>
            </div>
            <p class="mt-1 truncate text-sm text-gray-500">
              Valido fino al
              <b>{{ coupon.expire_on.split('T')?.[0] }}</b>
            </p>
          </div>
          <span
            class="inline-flex flex-shrink-0 items-center rounded-lg bg-green-50 px-2.5 py-1.5 text-2xl font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {{ coupon.discount }} %
          </span>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="flex w-0 flex-1">
              <button
                @click="newCoupon = true"
                class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                <PencilIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('buttons.edit') }}
              </button>
            </div>
            <div class="-ml-px flex w-0 flex-1">
              <button
                @click="deleteCoupon(coupon?.id)"
                class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-red-400">
                <BackspaceIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                {{ $t('buttons.delete') }}
              </button>
            </div>
          </div>
        </div>
      </li>

      <li v-else-if="!newCoupon">
        <button
          type="button"
          @click="newCoupon = true"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
          </svg>
          <span class="mt-2 block text-sm font-semibold text-gray-900">{{ $t('Crea nuovo coupon') }}</span>
        </button>
      </li>
      <li v-else-if="newCoupon" class="pb-96">
        <form>
          <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-base font-semibold leading-7 text-gray-900">Nuovo coupon</h2>
              <p class="mt-1 text-sm leading-6 text-gray-600">
                Seleziona la tipologia di cupon. Il costo del noleggio di ogni mezzo verrà annullato, lo sconto ha una
                data di scadenza ed è valido fino a tale data su tutti i mezzi.
              </p>

              <fieldset>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <!-- <div class="sm:col-span-4">
                    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Nome</label>
                    <div class="mt-2">
                      <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autocomplete="name"
                          class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          v-model="form.name"
                          placeholder="nomecognome" />
                      </div>
                    </div>
                  </div> -->

                  <div class="sm:col-span-4">
                    <label for="expiration" class="block text-sm font-medium leading-6 text-gray-900">
                      Valido fino al
                    </label>
                    <div class="mt-2">
                      <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="date"
                          name="expiration"
                          id="expiration"
                          autocomplete="expiration"
                          class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          v-model="form.expire_on"
                          placeholder="2024-05-23" />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-12">
                <!-- <legend class="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
                <p class="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p> -->
                <div class="mt-6 space-y-6">
                  <div class="flex items-center gap-x-3">
                    <input
                      id="discount-5"
                      name="push-notifications"
                      type="radio"
                      @click="
                        form.discount = 100;
                        form.name = 'Tari';
                      "
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="discount-5" class="block text-sm font-medium leading-6 text-gray-900">Tari</label>
                  </div>
                  <div class="flex items-center gap-x-3">
                    <input
                      id="discount-10"
                      name="push-notifications"
                      type="radio"
                      @click="
                        form.discount = 100;
                        form.name = 'Soci CNL VV - Aziende';
                      "
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="discount-10" class="block text-sm font-medium leading-6 text-gray-900">
                      Soci CNL VV - Aziende
                    </label>
                  </div>
                  <div class="flex items-center gap-x-3">
                    <input
                      id="discount-architect-100"
                      name="push-notifications"
                      type="radio"
                      @click="
                        form.discount = 100;
                        form.name = 'Convenzione Stakeholder';
                      "
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="discount-architect-100" class="block text-sm font-medium leading-6 text-gray-900">
                      Convenzione Stakeholder (ordini professionali, consigli, associazioni, sindacati)
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div class="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" class="text-sm font-semibold leading-6 text-gray-900" @click="newCoupon = false">
              {{ $t('buttons.cancel') }}
            </button>
            <button
              v-if="coupon && coupon.id"
              type="submit"
              @click.prevent="updateCoupon(coupon?.id)"
              class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Modifica
            </button>
            <button
              v-else
              type="submit"
              @click.prevent="saveCoupon"
              class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
          </div>
        </form>
      </li>
    </ul>
  </div>
</template>

<script>
  import { ref, computed, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { BackspaceIcon, PencilIcon } from '@heroicons/vue/20/solid';
  import { useRouter } from 'vue-router';
  export default {
    components: {
      BackspaceIcon,
      PencilIcon,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const coupon = computed(() => {
        return store.getters.selectedUser?.coupon;
      });
      const newCoupon = ref(false);

      const form = reactive({
        name: '',
        expire_on: '',
        discount: '',
      });

      //prendo l'id dell'utente dall'id della route
      const user = router.currentRoute.value.params.id;
      const saveCoupon = () => store.dispatch('saveCoupon', { user, ...form }).then(router.go());
      const updateCoupon = (id) => store.dispatch('updateCoupon', { id, ...form }).then(router.go());
      const deleteCoupon = (id) => store.dispatch('deleteCoupon', { id }).then(router.go());

      return {
        coupon,
        newCoupon,
        form,
        saveCoupon,
        deleteCoupon,
        updateCoupon,
      };
    },
  };
</script>

<style></style>
