import { ApiCaller, Utils } from '@/Utils/utils';
import Device from './device/Device';
import Trip from './trip/Trip';

export default {
  state: {
    vehicles: [],
    vehiclesTypeList: [],
    selectedVehicle: null,
    selectedMessage: null,
  },
  getters: {
    vehicles: (state) => state.vehicles,
    vehiclesTypeList: (state) => state.vehiclesTypeList,
    selectedVehicle: (state) => {
      if (state.selectedVehicle) return state.selectedVehicle[0];
      return state.selectedVehicle;
    },
    selectedMessage: (state) => state.selectedMessage,
    lastHeartbeat: (state) => {
      if (state.selectedVehicle) if (state.selectedVehicle[0].last_heartbeat) return state.selectedVehicle[0].last_heartbeat;
      return null;
    },
  },
  mutations: {
    setVehicles: (state, data) => (state.vehicles = Utils.createFiltrableData(data)),
    setSelectedMessage: (state, data) => (state.selectedMessage = data),
    setVehiclesTypeList: (state, data) => (state.vehiclesTypeList = data),
    setSelectedVehicle: (state, data) => (state.selectedVehicle = data),
  },
  actions: {
    getVehicles: (ctx, payload) => ApiCaller.GET('/vehicle', payload).then((data) => ctx.commit('setVehicles', Utils.createFiltrableData(data))),
    getVehiclesTypeList: (ctx, payload) => ApiCaller.GET('/vehicle/getVehicleHeaderList', payload).then((data) => ctx.commit('setVehiclesTypeList', data)),
    getSelectedVehicle: (ctx, payload) => ApiCaller.GET(`/vehicle/${payload.id}`).then((data) => ctx.commit('setSelectedVehicle', data)),
    //APIES
    createVehicle: (_, payload) => ApiCaller.POST(`/vehicle`, payload),
    updateVehicle: (_, payload) => ApiCaller.PUT(`/vehicle/${payload.id}`, payload.body),
    selectMessage: (ctx, payload) => ctx.commit('setSelectedMessage', payload),
  },
  modules: { Device, Trip },
};
