<template>
  <!-- Small Modal -->
  <div id="small-modal" tabindex="-1" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen h-screen flex justify-center items-center">
    <div @click="$emit('backdrop')" class="backdrop-blur-sm bg-white/40 absolute top-0 left-0 w-full h-full"></div>
    <!-- Modal content -->
    <div class="relative bg-white dark:bg-gray-900 rounded-lg shadow m-auto max-w-xl">
      <!-- Modal header -->
      <div class="flex justify-between items-center p-5 rounded-t border-b">
        <slot name="header"></slot>
      </div>
      <!-- Modal body -->
      <div class="p-6 space-y-6">
        <slot name="body"></slot>
      </div>
      <!-- Modal footer -->
      <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
