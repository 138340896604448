<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  <!-- Shipping modal -->
  <Teleport to="body">
    <base-confirm-field :show="showShipModal" @close="showShipModal = false">
      <template #header>
        <h3>Spedizione RFID</h3>
      </template>
      <template #body>
        <p>Inserisci nome, cognome e indirizzo utilizzati nella spedizione per andare avanti</p>
        <base-input-field title="Nome e indirizzo" v-model="shipping_address" />
      </template>
      <template #footer>
        <div class="flex justify-between">
          <p>Confermare?</p>
          <div>
            <button v-show="shipping_address !== ''" class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2" type="button" @click="shipCard">Si</button>
            <button class="w-6 h-6 hover:bg-red-200 text-sm rounded-full" type="button" @click="resetField">No</button>
          </div>
        </div>
      </template>
    </base-confirm-field>
  </Teleport>
  <!-- Unshipping modal -->
  <Teleport to="body">
    <base-confirm-field :show="showUnshipModal" @close="showUnshipModal = false">
      <template #header>
        <h3>Annullamento spedizione</h3>
      </template>
      <template #body>
        <p>Andando avanti annullerai la spedizione e cancellerai l'indirizzo</p>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <p>Confermare?</p>
          <div>
            <button class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2" type="button" @click="unshipCard">Si</button>
            <button class="w-6 h-6 hover:bg-red-200 text-sm rounded-full" type="button" @click="resetField">No</button>
          </div>
        </div>
      </template>
    </base-confirm-field>
  </Teleport>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';
  export default {
    setup() {
      const store = useStore();
      function refreshPage(payload) {
        store.dispatch('getCompleteRfidList', payload);
      }
      const completeRfidList = computed(() => store.getters.completeRfidList);

      const structure = {
        title: 'views.rfid.title',

        creationLink: {
          title: 'buttons.createRfid',
          namePath: 'menu.user.rfidCreation',
        },
        data: [
          {
            title: 'rfid.code',

            field: 'codice',
            span: 1,
          },
          {
            title: 'rfid.tag',
            field: 'id_tag',
            span: 1,
          },
          {
            title: 'views.rfid.cardOwner',
            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-400',
              },
              {
                name: 'user_name',
              },
            ],
            span: 1,
            link: { idField: 'user_id', path: '/user/users/:id' },
          },
          {
            title: 'status',

            field: 'shipped',
            boolean: {
              if_true: 'rfid.shipped',
              if_false: 'rfid.toShip',
            },
            span: 1,
          },
          {
            title: 'views.rfid.bonus',

            field: 'euro_bonus',
            format: 'euro',
            span: 1,
          },
          {
            title: 'shippingAddress',

            field: 'shipping_address',
            span: 1,
          },
          {
            title: 'views.rfid.actions',

            buttons: {
              fields: [
                {
                  title: 'buttons.ship',

                  function: showShippingModal,
                  returned_val: 'rfid_id',
                  condition_val: 0,
                },
                {
                  title: 'buttons.cancel',

                  function: showUnshippingModal,
                  returned_val: 'rfid_id',
                  condition_val: 1,
                },
              ],
              condition: 'shipped',
            },
            span: 1,
          },
        ],
      };
      const showShipModal = ref(false);
      const showUnshipModal = ref(false);
      const shipping_address = ref('');
      const selected_rfid = ref(null);
      //showModal functions
      function showShippingModal(el) {
        showShipModal.value = true;
        selected_rfid.value = el;
      }
      function showUnshippingModal(el) {
        showUnshipModal.value = true;
        selected_rfid.value = el;
      }
      //method for send update query
      function shipCard() {
        const payload = {
          id: selected_rfid.value,
          address: shipping_address.value,
          shipped: 1,
        };
        store.dispatch('updateRfid', payload);
        resetField();
      }
      function unshipCard() {
        const payload = {
          id: selected_rfid.value,
          address: ' ',
          shipped: '0',
        };
        store.dispatch('updateRfid', payload);
        resetField();
      }
      function resetField() {
        showShipModal.value = false;
        showUnshipModal.value = false;
        shipping_address.value = '';
        selected_rfid.value = null;
      }
      return {
        data: completeRfidList,
        structure,
        showShipModal,
        showUnshipModal,
        shipping_address,
        shipCard,
        unshipCard,
        resetField,
        refreshPage,
      };
    },
  };
</script>

<style></style>
