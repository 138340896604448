<template>
  <BaseSpinLoader v-show="readParametersLoader" />
  <div v-if="documentsThree && !readParametersLoader">
    <div class="flex">
      <VueDatePicker v-model="selectedDate" :range="true" :enable-time-picker="false" :format="format" />
    </div>
    <BaseFolderThree
      :items="documentsThree"
      :getDownloadObjFunction="downloadObjFunct"
      zipName="invoices"
      :enableDownloadView="true"
      :enableDownloadFile="true" />
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';

  export default {
    setup() {
      const store = useStore();

      store.dispatch('getDocumentsThree').then(() => (readParametersLoader.value = false));

      const selectedDate = ref(null);
      const selectedDateParsed = computed(() => format(selectedDate.value, 'array'));
      const documentsThree = computed(() => {
        if (selectedDateParsed.value) {
          return filterFolder(store.getters.documentsThree, selectedDateParsed.value);
        }
        return store.getters.documentsThree;
      });

      const filterFolder = (obj, date) => {
        if (Array.isArray(date)) {
          const startDate = date[0];
          const endDate = date[1];

          let filteredData = {};
          let currentDate = new Date(startDate);
          const endDateObj = new Date(endDate);

          let tmpData = [];
          while (currentDate <= endDateObj) {
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = (currentDate.getDate() + 1).toString().padStart(2, '0');
            if (obj[year] && obj[year][month] && obj[year][month][day]) {
              tmpData.push(obj[year][month][day]);
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }

          for (const i in tmpData) {
            for (const j in tmpData[i]) {
              filteredData[j] = tmpData[i][j];
            }
          }
          return filteredData;
        } else {
          const year = date.split('-')[0];
          const month = date.split('-')[1];
          const day = date.split('-')[2];
          for (const key in obj) {
            if (key === year) {
              for (const key in obj[year]) {
                if (key === month) {
                  for (const key in obj[year][month]) {
                    if (key === day) {
                      return obj[year][month][day];
                    }
                  }
                }
              }
            }
          }
        }
      };

      const format = (newDate, format = 'string') => {
        if (!newDate) return;
        if (newDate.length && newDate.length > 1 && newDate[1] !== null) {
          let finalArray = [];
          newDate.forEach((tmp) => {
            const date = new Date(tmp);
            const timezoneOffset = date.getTimezoneOffset();
            date.setTime(date.getTime() - timezoneOffset * 60 * 1000); // Imposta lo sfasamento
            finalArray.push(date.toISOString().split('T')[0]);
          });
          if (format == 'array') return finalArray;
          else return `Dal ${finalArray[0]} al ${finalArray[1]}`;
        }

        const date = new Date(newDate);
        const timezoneOffset = date.getTimezoneOffset();
        date.setTime(date.getTime() - timezoneOffset * 60 * 1000); // Imposta lo sfasamento
        return date.toISOString().split('T')[0];
      };

      const readParametersLoader = ref(true);

      const downloadObjFunct = (key) => store.dispatch('getObjForDownload', { key: key });

      return { documentsThree, selectedDate, selectedDateParsed, readParametersLoader, format, downloadObjFunct };
    },
  };
</script>
