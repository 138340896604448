import { ApiCaller } from '@/Utils/utils';

export default {
  state: {
    mobilityDocumentsThree: {}
  },
  getters: {
    mobilityDocumentsThree: (state) => state.mobilityDocumentsThree
  },
  mutations: {
    setMobilityDocumentsThree: (state, data) => state.mobilityDocumentsThree = data,
  },
  actions: {
    getMobilityDocumentsThree: (ctx, payload) => {
      ctx.commit("setMobilityDocumentsThree", null)
      ApiCaller.GET("/mobility/documentation/folders", payload).then((data) => ctx.commit("setMobilityDocumentsThree", data))
    },
    getMobilityObjForDownload: (_, payload) => ApiCaller.POST("/mobility/documentation/download", payload),
  },
};
