<template>
  <base-generals-page :el="el" :structure="structure" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref, reactive } from 'vue';

  export default {
    setup() {
      const store = useStore();
      const selectedProduct = computed(() => store.getters.selectedProduct);
      const intervalCountArray = ['', 'day', 'week', 'month', 'year'];
      const recurringBillingTimeArray = ref(['']);

      let recurringTime = (val) => {
        if (!val) recurringBillingTimeArray.value = [''];
        const test = val.target.value;

        let count = 0;
        if (test == 'day') count = 30;
        else if (test == 'week') count = 52;
        else if (test == 'month') count = 12;
        else if (test == 'year') count = 1;

        let tmp = [];
        for (let i = 0; i <= count; i++) {
          tmp.push(i);
        }
        recurringBillingTimeArray.value = tmp;
      };

      const structure = {
        edit_endpoint: 'updateProduct',
        edit_title: 'views.subscriptions.edit',
        data: [
          {
            title: 'views.subscriptions.id',
            name: 'name',
            placeholder: '--',
            editable: true,
          },
          {
            title: 'views.subscriptions.description',
            name: 'description',
            placeholder: '--',
            editable: true,
          },
          {
            title: 'views.subscriptions.amount',
            name: 'price',
            sub_name: 'unit_amount_decimal',
            db_name: 'unit_amount_decimal',
            format: 'decimal_euro',
            editable: true,
          },
          {
            title: 'views.subscriptions.recurring_billing_type',
            name: 'price',
            placeholder: 'required for chage billing type/time',
            sub_name: 'interval',
            db_name: 'interval',
            select: intervalCountArray,
            on_change: recurringTime,
            editable: true,
          },
          reactive({
            title: 'views.subscriptions.recurring_billing_time',
            name: 'price',
            sub_name: 'interval_count',
            db_name: 'interval_count',
            select: recurringBillingTimeArray,
            editable: true,
          }),
          {
            title: 'views.subscriptions.active',
            name: 'active',
            boolean: {
              if_true: 'views.subscriptions.isActive',
              if_false: 'views.subscriptions.isNotActive',
            },
            checkbox: true,
            editable: true,
          },
          {
            title: 'views.subscriptions.created_at',
            name: 'created',
            format: 'unix_to_datetime',
          },
          {
            title: 'views.subscriptions.updated_at',
            name: 'updated',
            format: 'unix_to_datetime',
          },
        ],
      };
      return {
        el: selectedProduct,
        structure,
      };
    },
  };
</script>

<style></style>
