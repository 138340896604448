<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { computed, reactive } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();

      store.dispatch('getDeviceConfigurations');
      const devicesConfiguration = computed(() => {
        return Utils.getNameArrayFromData(store.getters.deviceConfigurations);
      });
      const devicesConfigurationOwner = computed(() => {
        return Utils.getProducerArrayFromData(store.getters.deviceConfigurations);
      });

      const insertData = {
        title: 'device.createDevice',
        redirect_path: '/device/devices',
        create_endpoint: 'createDevice',
        creation_title: 'Creazione Device',
        dispatchName: 'getDevices',
        data: [
          {
            title: 'IMEI',
            name: 'imei',
            class: 'col-span-2',
            required: true,
          },

          {
            title: 'imei sim',
            name: 'sim_imei',
            required: true,
          },
          {
            title: 'operatore',
            name: 'sim_operator',
            required: true,
          },
          reactive({
            title: 'proprietario sim',
            name: 'sim_owner',
            select: devicesConfigurationOwner,
          }),
          reactive({
            title: 'configurazione',
            name: 'device_configuration',
            select: devicesConfiguration,
          }),
        ],
      };

      return {
        insertData,
      };
    },
  };
</script>

<style></style>
