<template>
  <base-list-table
    :data="data"
    :structure="structure"
    :refreshFunction="refreshPage"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshPage = (payload) => {
      store.dispatch("getRentals", { manager: route.params.id, ...payload });
    };

    const rentals = computed(() => store.getters.rentals);
    // table
    const structure = {
      title: "views.rentals.rentalsList",

      timeFields: [
        { label: "views.rentals.rentalEnd", key: "end_timestamp" },
        { label: "views.rentals.rentalStart", key: "start_timestamp" },
      ],
      data: [
        {
          title: "user.title",
          fields: [
            {
              placeholder: "@",
              name: "user",
              subName: "id",
              class: "text-primary-400",
            },
            {
              name: "user",
              subName: "name",
            },
          ],
          span: 1,
          link: {
            idField: "id",
            subField: "user",
            path: "/user/users/:id",
          },
        },
        {
          title: "views.vehicles.type",
          field: "device",
          subField: "type",
          span: 1,
        },
        {
          title: "views.vehicles.plate",
          toTranslate: true,
          fields: [
            {
              placeholder: "@",
              class: "text-primary-400",
              name: "device",
              subName: "id",
            },
            {
              name: "device",
              subName: "plate",
            },
          ],
          link: {
            idField: "id",
            subField: "device",
            type: "vehicle",
          },
          span: 1,
        },
        {
          title: "views.rentals.rentalStart",
          field: "start_timestamp",
          format: "datetime",
          span: 1,
        },
        {
          title: "views.rentals.rentalEnd",
          field: "end_timestamp",
          format: "datetime",
          span: 1,
        },
        {
          title: "payments.totalAmount",
          field: "total_amount",
          format: "euro",
          span: 1,
        },
      ],
    };
    return {
      data: rentals,
      structure,
      refreshPage,
    };
  },
};
</script>

<style></style>
