<template>
  <span class="w-full my-2 flex flex-col">
    <h3 class="w-full text-left text-lg font-semibold text-white dark:text-white mt-6">{{ $t('settings.time.title') }}</h3>
    <h3 class="w-full text-left text-base font-semibold text-white dark:text-white my-2">{{ $t('settings.time.hour12') }}</h3>
    <base-select title="" v-model="hourFormat" :data="hours" />
    <h3 class="w-full text-left text-base font-semibold text-white dark:text-white my-2">{{ $t('settings.time.timeFormat') }}</h3>
    <base-select title="" v-model="timeZone" :data="timeZones" />
  </span>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'TimeSettings',
    setup() {
      const hourFormat = ref('12');
      const timeZone = ref('Europe/Rome');

      const hours = [
        {
          value: '12',
          text: '12h',
        },
        {
          value: '24',
          text: '24h',
        },
      ];
      const timeZones = [
        {
          value: 'UTC',
          text: 'Coordinated Universal Time (UTC)',
        },
        {
          value: 'Europe/London',
          text: 'UTC+00:00 - London (United Kingdom), Dublin (Ireland), Lisbon (Portugal)',
        },
        {
          value: 'Europe/Amsterdam',
          text: 'UTC+01:00 - Amsterdam (Netherlands), Brussels (Belgium), Berlin (Germany), Paris (France), Madrid (Spain), Rome (Italy), Vienna (Austria), Zurich (Switzerland)',
        },
        {
          value: 'Europe/Athens',
          text: 'UTC+02:00 - Athens (Greece), Bucharest (Romania), Istanbul (Turkey)',
        },
        {
          value: 'Europe/Moscow',
          text: 'UTC+03:00 - Moscow (Russia), St. Petersburg (Russia)',
        },
        {
          value: 'Asia/Dubai',
          text: 'UTC+04:00 - Dubai (United Arab Emirates), Abu Dhabi (United Arab Emirates)',
        },
        {
          value: 'Asia/Kolkata',
          text: 'UTC+05:30 - Mumbai (India), New Delhi (India), Kolkata (India)',
        },
        {
          value: 'Asia/Hong_Kong',
          text: 'UTC+08:00 - Hong Kong (Hong Kong), Singapore (Singapore), Taipei (Taiwan)',
        },
        {
          value: 'Asia/Tokyo',
          text: 'UTC+09:00 - Tokyo (Japan), Seoul (South Korea)',
        },
        {
          value: 'Australia/Sydney',
          text: 'UTC+10:00 - Sydney (Australia), Melbourne (Australia)',
        },
        {
          value: 'Pacific/Auckland',
          text: 'UTC+12:00 - Auckland (New Zealand), Wellington (New Zealand)',
        },
      ];

      watch([hourFormat, timeZone], ([hourFormatVal, timeZoneVal]) => {
        const timeFormat = { hour12: hourFormatVal === '12', timeZone: timeZoneVal };
        localStorage.setItem('timeFormat', JSON.stringify(timeFormat));
      });

      const savedTimeFormat = JSON.parse(localStorage.getItem('timeFormat'));
      if (savedTimeFormat) {
        hourFormat.value = savedTimeFormat.hour12 === '24' ? '12' : '24';
        timeZone.value = savedTimeFormat.timeZone;
      } else {
        hourFormat.value = '24';
        timeZone.value = 'Europe/Amsterdam';
      }

      return {
        hourFormat,
        timeZone,
        hours,
        timeZones,
      };
    },
  };
</script>
