<template>
  <div class="w-full mx-auto justify-start items-center flex flex-col">
    <div v-if="structure.edit_endpoint" class="flex justify-end py-3 w-full">
      <base-button icon="edit" @click="changeEdit()">{{ editButtonString() }}</base-button>
    </div>
    <base-subpage>
      <keep-alive>
        <div v-if="!editModel">
          <the-title textClass="text-primary-500" :name="structure.title">
            <template v-for="data in sections" :key="data">
              <base-list-element class="w-full grid" :class="data.isMain ? '' : 'grid-cols-2'">
                <the-field :name="data.title" :statusOK="isOk(el, data.statusOK)" :class="data.class" :inputClass="getClass(data.isMain, data.level)" :border="!data.isMain" :hasTitle="data.isMain">
                  <span class="text-gray-500" v-html="customField(data)"></span>
                </the-field>
                <the-field v-if="!data.isMain" :name="'message'" :statusOK="isOk(el, data.statusOK)" :class="data.class" :inputClass="getClass(data.isMain, data.level)" :border="!data.isMain || !data.level" :hasTitle="false">
                  <span class="text-gray-500" v-html="data.name"></span>
                </the-field>
              </base-list-element>
            </template>
          </the-title>
        </div>

        <the-editor v-else :editableFields="editableFields" :element="el" />
      </keep-alive>
    </base-subpage>
  </div>
</template>

<script>
  /* eslint-disable */
  import { ref, computed, reactive } from 'vue';
  import { Utils } from '@/Utils/utils';
  import { i18n } from '@/i18n';

  import TheEditor from './TheEditor.vue';
  import TheTitle from './TheTitle.vue';
  import TheField from './TheField.vue';

  export default {
    components: {
      TheEditor,
      TheTitle,
      TheField,
    },
    props: {
      el: {
        type: Object,
        required: true,
      },
      structure: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const t = i18n.global.t;
      const extractText = (el, ref, text) => {
        if (el === undefined || text === undefined) return '';
        if (typeof text === 'string') return el[ref.name][text];
        let ret = '';
        text.forEach((elmt) => {
          ret += el[ref.name][elmt] + ' ';
        });
        ret = ret.slice(0, -1);
        return ret;
      };
      const sections = reactive(props.structure.sections);
      const isOk = computed(() => {
        return (elmt, statusOK) => {
          if (statusOK === undefined) return statusOK;
          if (!statusOK.includes('.')) return elmt[statusOK];
          let ret = elmt;
          const fieldsArray = statusOK.split('.');

          fieldsArray.forEach((el) => {
            if (!ret[el]) {
              ret = null;
            } else {
              ret = ret[el];
            }
          });
          return ret;
        };
      });
      const getClass = computed(() => (main, level) => {
        if (!main) return 'text-md text-primary-400';
        switch (level) {
          case 1:
            return 'font-bold text-xl text-primary-500 pt-2';
          case 2:
            return 'text-xl text-primary-500';
          case 3:
            return 'font-bold text-lg text-primary-500';
          case 4:
            return 'text-lg';
          default:
            return 'font-bold text-2xl text-primary-500 py-2';
        }
      });
      const getValue = (data, field) => {
        const el = props.el;
        if (data[field] !== undefined) {
          const fieldPH = data[field];
          if (fieldPH.includes('.')) {
            const nameArray = data[field].split('.');
            if (nameArray[0] === 'variables') return '';
            let elem = el[nameArray[0]];
            for (let i = 1; i < nameArray.length; i++) {
              elem = elem[nameArray[i]];
            }
            return elem;
          } else {
            return el[fieldPH];
          }
        }
        return '';
      };
      function customField(data) {
        if (data.mapper !== undefined && data.mapper !== null) return extractText(props.el, data, data.mapper.text);
        let finalFirstString = '';
        let finalSecondString = '';
        //init first and second fields
        let field = '';
        let toFormat = '';
        if (data.sub_name) {
          toFormat = props.el[data.name][data.sub_name];
          field = formatData(toFormat, data.format);
        } else {
          if (data.name !== undefined && data.name.includes('.') && data.name.indexOf('variables') !== 0) {
            const dataArray = data.name.split('.');
            let elemt = props.el[dataArray[0]];
            for (let i = 1; i < dataArray.length; i++) {
              elemt = elemt[dataArray[i]];
            }
            toFormat = elemt;
            field = formatData(toFormat, data.format);
          } else {
            toFormat = props.el[data.name];
            field = formatData(toFormat, data.format);
          }
        }

        toFormat = props.el[data.concat];
        let secondField = formatData(toFormat);

        //create second string
        if (data.concat) {
          if (data.concat.format) {
            toFormat = props.el[data.concat.name];
            finalSecondString = formatData(toFormat, data.concat.format);
          } else {
            if (secondField) {
              finalSecondString = secondField;
            } else finalSecondString = data.concatPlaceholder ? data.concatPlaceholder : '';
          }
        }
        //create first string
        if (data.boolean) {
          if (field) finalFirstString = data.boolean.if_true;
          else finalFirstString = data.boolean.if_false;
          return t(`${finalFirstString}`) + `${finalSecondString}`;
        }
        if (field) finalFirstString = field;
        else finalFirstString = data.placeholder ? data.placeholder : '';
        return `${finalFirstString} ${finalSecondString}`;
      }

      const formatData = Utils.formatData;
      //edit field
      const editModel = ref(false);
      function changeEdit() {
        editModel.value = !editModel.value;
      }
      function editButtonString() {
        return editModel.value ? t('buttons.backToDetails') : t('buttons.enableEditing');
      }
      //take editable field
      let editableFields = computed(() => {
        let newObj = {
          edit_endpoint: props.structure.edit_endpoint,
          edit_title: props.structure.edit_title,
          selects: props.structure.selects,
          data: [],
        };
        const type = typeof props.structure.sections;
        const c1 = type !== 'proxy';
        const c2 = !c1 && props.structure.sections.value !== undefined;
        const c3 = !c1 && props.structure.sections._value !== undefined;
        const itms = c3 ? props.structure.sections._value : c2 ? props.structure.sections.value : props.structure.sections;
        for (let itm in itms) {
          if (!itms[itm] === undefined) continue;
          newObj.data.push({
            editable: itms[itm].editable,
            title: itms[itm].title,
            level: itms[itm].level ? itms[itm].level : 0,
            isMain: itms[itm].isMain ? itms[itm].isMain : false,
            name: itms[itm].db_name ? itms[itm].db_name : itms[itm].name,
            placeholder: getValue(itms[itm], 'name'),
            value: getValue(itms[itm], 'name'),
            boolean: itms[itm].boolean,
            select: itms[itm].select,
            on_change: itms[itm].on_change,
            mapper: itms[itm].mapper ? itms[itm].mapper : null,
          });
        }
        return newObj;
      });

      return {
        sections,
        editModel,
        editableFields,
        editButtonString,
        changeEdit,
        customField,
        isOk,
        getClass,
      };
    },
  };
</script>

<style></style>
