<template>
  <div class="flex justify-between">
    <base-button icon="delete" class="bg-red-300 text-red-900" @click="deleteAlarmStatusType">
      {{ t('buttons.delete') }}
    </base-button>
  </div>
  <router-view></router-view>
</template>

<script>
  import { useStore } from 'vuex';
  import { onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  export default {
    setup() {
      //init store
      const store = useStore();
      const route = useRoute();
      const selectedElID = { id: route.params.id };
      onMounted(() => {
        store.dispatch('setSelectedAlarmStatusType', selectedElID);
      });
      const deleteAlarmStatusType = () => store.dispatch('deleteAlarmStatusType', selectedElID);
      return {
        deleteAlarmStatusType,
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
