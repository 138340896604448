import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    payments: [],
    selectedPayment: null,
    suffixes: []
  },
  getters: {
    payments: (state) => state.payments,
    selectedPayment: (state) => state.selectedPayment,
    suffixes: (state) => state.suffixes
  },
  mutations: {
    setPayments: (state, data) => state.payments = Utils.createFiltrableData(data),
    setSelectedPayment: (state, data) => state.selectedPayment = data,
    setSuffixes: (state, data) => state.suffixes = data
  },
  actions: {
    getPayments: (ctx, payload) => ApiCaller.GET("/billing/payments", payload).then((data) => ctx.commit("setPayments", data)),
    getSelectedPayment: (ctx, payload) => ApiCaller.GET(`/billing/payments/${payload.id}`, payload).then((data) => ctx.commit("setSelectedPayment", data)),
    getSuffixes: (ctx, payload) => ApiCaller.GET("/billing/payments/suffixes", payload).then((data) => ctx.commit("setSuffixes", data)),
    setSelectedSuffix: (_, payload) => ApiCaller.PUT(`/billing/payments/setSelectedSuffix/${payload.id}`),
    updateBillingProfile: (_, payload) => ApiCaller.PUT(`/billing/payments/updateBillingProfile/${payload.id}`, payload.body),
    createSuffix: (_, payload) => ApiCaller.POST("/billing/payments/suffixes", payload),
    sendInvoice: (_, payload) => ApiCaller.POST(`/billing/invoices/${payload.id}/send`)
  },
};
