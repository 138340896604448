<template>
  <button type="button" :disabled="disabled" class="cursor-pointer flex justify-center items-center text-white font-medium rounded-lg px-3 md:px-5 py-1 focus:outline-none whitespace-nowrap h-10 bg-primary-700 hover:bg-primary-800" :class="[className, { 'opacity-50 shadow-sm hover:bg-primary-700': disabled }]">
    <span v-if="icon" class="material-icons text-base" :class="{ 'mr-2': !onlyicon }">{{ icon }}</span>
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      className: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      onlyicon: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
  };
</script>

<style></style>
