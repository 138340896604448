import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';

import { i18n } from './i18n';

import store from '@/store';
import router from '@/router/router';
//  COMPONENTS
import BaseChart from './components/Standard/Charts/BaseChart.vue';
import BaseQrCode from './components/Standard/BaseQrCode.vue';
import BaseCard from './components/Standard/BaseCard.vue';
import BaseButton from '@/modules/UI/components/Miscellanea/BaseButton.vue';
import BaseMenuButton from './components/Standard/BaseMenuButton.vue';
import BaseListField from './components/Standard/BaseListField.vue';
import BaseListTitle from './components/Standard/BaseListTitle.vue';
import BaseListElement from './components/Standard/BaseListElement.vue';
import BaseDetailsButton from './components/Standard/BaseDetailsButton.vue';
import BaseSpinner from './components/Standard/BaseSpinner.vue';
import BaseListTable from '@/modules/UI/components/Table/BaseListTable.vue';
import BaseRouteBar from './components/Standard/BaseRouteBar.vue';
import BaseRouterLink from './components/Standard/BaseRouterLink.vue';
import BasePage from './components/Standard/BasePage.vue';
import BaseSubpage from './components/Standard/BaseSubpage.vue';
import BaseSelect from './components/Standard/BaseSelect.vue';
import BaseInputField from './components/Standard/BaseInputField.vue';
import BaseCheckbox from './components/Standard/BaseCheckbox.vue';
import BaseSubpageBar from './components/Standard/BaseSubpageBar.vue';
import BaseErrorList from './components/Standard/BaseErrorList.vue';
import BaseConfirmField from './components/Standard/BaseConfirmField.vue';
import BaseDropdown from './components/Standard/BaseDropdown.vue';
import BaseGeneralsPage from './components/Standard/GeneralsPage/BaseGeneralsPage.vue';
import BaseCreationPage from './components/Standard/CreationPage/BaseCreationPage';
import BaseDetailsHeader from './components/Standard/Header/BaseDetailsHeader';
import BaseDataField from './components/Standard/BaseDataField';
import BaseLoader from './components/Standard/BaseLoader';
import BaseAlert from './components/Standard/Alert/BaseAlert';
import BaseTextArea from './components/Standard/BaseTextArea';
import BaseUploadImage from './components/Standard/UploadImage/BaseUploadImage.vue';
import BaseDatePicker from './components/Standard/DatePicker/BaseDatePicker.vue';
import GeneralsMultititlePage from './modules/languages/CommonComponents/GeneralsMultititlePage/GeneralsMultititlePage.vue';
import BaseCheckboxDropdown from './components/Standard/BaseCheckboxDropdown.vue';
import FlowModal from './components/CustomFlowbite/FlowModal.vue';
import BaseFileupload from './components/Standard/BaseFileupload.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import BaseSpinLoader from './components/Standard/BaseSpinLoader';
import BaseFolderThree from './components/Standard/BaseFolderThree';

import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.component('BaseFolderThree', BaseFolderThree);
app.component('BaseSpinLoader', BaseSpinLoader);
app.component('VueDatePicker', VueDatePicker);
app.component('BaseFileupload', BaseFileupload);
app.component('BaseQrCode', BaseQrCode);
app.component('BaseChart', BaseChart);
app.component('BaseCheckboxDropdown', BaseCheckboxDropdown);
app.component('FlowModal', FlowModal);
app.component('BaseTextArea', BaseTextArea);
app.component('BaseDetailsHeader', BaseDetailsHeader);
app.component('BaseCreationPage', BaseCreationPage);
app.component('BaseGeneralsPage', BaseGeneralsPage);
app.component('GeneralsMultititlePage', GeneralsMultititlePage);
app.component('BaseDropdown', BaseDropdown);
app.component('BaseConfirmField', BaseConfirmField);
app.component('BaseErrorList', BaseErrorList);
app.component('BaseCard', BaseCard);
app.component('BaseButton', BaseButton);
app.component('BaseMenuButton', BaseMenuButton);
app.component('BaseListField', BaseListField);
app.component('BaseListTitle', BaseListTitle);
app.component('BaseListElement', BaseListElement);
app.component('BaseDetailsButton', BaseDetailsButton);
app.component('BaseSpinner', BaseSpinner);
app.component('BaseListTable', BaseListTable);
app.component('BaseRouteBar', BaseRouteBar);
app.component('BaseRouterLink', BaseRouterLink);
app.component('BasePage', BasePage);
app.component('BaseSubpage', BaseSubpage);
app.component('BaseSelect', BaseSelect);
app.component('BaseInputField', BaseInputField);
app.component('BaseCheckbox', BaseCheckbox);
app.component('BaseSubpageBar', BaseSubpageBar);
app.component('BaseDataField', BaseDataField);
app.component('BaseLoader', BaseLoader);
app.component('BaseAlert', BaseAlert);
app.component('BaseUploadImage', BaseUploadImage);
app.component('BaseDatePicker', BaseDatePicker);

// Import Google Maps
import VueGoogleMaps from '@fawmi/vue-google-maps';
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
});

const auth0config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin + '/dashboard',
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

// Import auth0
import { createAuth0 } from '@auth0/auth0-vue';

app.use(createAuth0(auth0config));
app.use(i18n);
app.mount('#app');
