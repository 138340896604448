<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { useStore } from 'vuex';
  import { onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    setup() {
      onMounted(async () => {
        const store = useStore();
        store.dispatch('getAlarms', store.getters.currentPlatform.db);
      });
      return {
        headers: [
          { text: 'views.alarms.activeAlarms', name: 'menu.alarm.alarmsActive' },
          { text: 'views.alarms.deletedAlarms', name: 'menu.alarm.alarmsDeleted' },
          { text: 'views.alarms.closedAlarms', name: 'menu.alarm.alarmsClosed' },
        ],
        t: useI18n().t,
      };
    },
  };
</script>

<style></style>
