export default {
  state: {
    selectedSection: null,
    selectedSubSection: null,
  },
  getters: {
    selectedSection: (state) => state.selectedSection,
    selectedSubSection: (state) => state.selectedSubSection,
  },
  mutations: {
    setSelectedSection: (state, data) => (state.selectedSection = data),
    setSelectedSubSection: (state, data) => (state.selectedSubSection = data),
  },
  actions: {
    setSelectedSection: (ctx, payload) => {
      if (payload.section) ctx.commit('setSelectedSection', payload.section);
      if (payload.subSection) ctx.commit('setSelectedSubSection', payload.subSection);
      else if (payload.section.children && payload.section.children.length) ctx.commit('setSelectedSubSection', payload.section.children[0]);
      else ctx.commit('setSelectedSubSection', null);
    },
  },
};
