import { ApiCaller } from '@/Utils/utils';

export default {
    state: {
        userCashbackStatistics: null,
    },
    getters: {
        userCashbackStatistics: (state) => state.userCashbackStatistics,
    },
    mutations: {
        setUserCashbackStatistics: (state, data) => (state.userCashbackStatistics = data),
    },
    actions: {
        getUserCashbackStatistics: (ctx, payload) =>
            ApiCaller.GET(`/recharges/analytics/userSummary`, payload).then((data) => ctx.commit('setUserCashbackStatistics', data)),
    },
};

