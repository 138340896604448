import { Utils, ApiCaller } from '@/Utils/utils';

export default {
  state: {
    fleets: [],
    selectedFleet: null,
    fleetVehicles: null,
  },
  getters: {
    fleets: (state) => state.fleets,
    selectedFleet: (state) => {
      if (state.selectedFleet !== null) return state.selectedFleet[0];
      return state.selectedFleet;
    },
    fleetVehicles: (state) => state.fleetVehicles,
  },
  mutations: {
    setFleets: (state, payload) => (state.fleets = payload),
    setSelectedFleet: (state, payload) => (state.selectedFleet = payload),
    setFleetVehicles: (state, payload) => (state.fleetVehicles = payload),
  },
  actions: {
    getFleets: (ctx, payload) => {
      ApiCaller.GET(`/fleet`, payload).then((data) => ctx.commit('setFleets', Utils.createFiltrableData(data)));
    },
    getSelectedFleet: (ctx, payload) => {
      ApiCaller.GET(`/fleet/${payload.id}`, null).then((data) => {
        ctx.commit('setSelectedFleet', data);
      });
    },
    getFleetVehicles: (ctx, payload) => {
      ApiCaller.GET('/vehicle', payload).then((data) => {
        ctx.commit('setFleetVehicles', Utils.createFiltrableData(data));
      });
    },
    // QUERIES
    updateFleet: (_, payload) => ApiCaller.PUT(`/fleet/${payload.id}`, payload.body),
    createFleet: (_, payload) => ApiCaller.POST('/fleet', payload),
    deleteVehicle: (_, payload) => ApiCaller.PUT(`/vehicle/${payload.id}/removeFleet`, payload),
  },
};
