<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getFleets', {
          ...payload,
        });
      }

      const fleets = computed(() => store.getters.fleets);

      // table
      const structure = {
        title: 'Lista flotte',
        creationLink: {
          title: 'Aggiungi nuova flotta',
          namePath: 'menu.mobility.createFleet',
        },
        data: [
          {
            title: 'Flotta dipendenti On Charge',
            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            span: 2,
            link: { idField: 'id', path: '/mobility/fleets/:id' },
          },
          {
            title: 'Organizzazione',
            field: 'organisation',
            subField: 'name',
            span: 1,
          },
          {
            title: 'Organizzazione Type',
            field: 'organisation',
            subField: 'type',
            span: 1,
          },
          {
            title: 'Referent',
            field: 'referent',
            subField: 'email',
            span: 1,
          },
          {
            title: 'Data di aggiornato',
            field: 'updated_at',
            span: 1,
          },
        ],
      };
      return {
        data: fleets,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
