<template>
  <base-subpage-bar :headers="headers" />
  <router-view />
</template>

<script>
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  export default {
    setup() {
      //  INIT
      const store = useStore();
      const route = useRoute();

      store.dispatch('getSelectedVehicleModel', {
        id: route.params.vehicleModelId,
      });

      //  CREATE OBJ FOR DETAILS HEADER
      const headers = [
        {
          text: 'paths.generals',
          name: 'menu.mobility.vehicleModelGenerals',
        },
        {
          text: 'paths.vehicles',
          name: 'menu.mobility.vehicleModelVehicles',
        },
      ];

      return {
        headers,
      };
    },
  };
</script>
<style></style>
