<template>
  <div class="h-full w-full bg-red-500/0 flex justify-end items-center">
    <template v-if="selectedUser">
      <select
        v-if="selectedUser.platforms && selectedUser.platforms.length > 1"
        v-model="selectedPlatform"
        class="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option v-for="(platform, i) in selectedUser.platforms" :key="i" :value="platform.id">{{ platform.name }} ({{ platform.db }})</option>
      </select>
      <select
        v-if="selectedUser.organisations"
        v-model="selectedOrganisation"
        class="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option v-for="(organisation, i) in selectedUser.organisations" :key="i" :value="organisation.id">
          {{ organisation.name }} ({{ organisation.address }})
        </option>
      </select>
      <select
        v-if="selectedUser.roles"
        v-model="selectedRole"
        class="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option v-for="(role, i) in selectedUser.roles" :key="i" :value="role.id">{{ $t(role.name) }}</option>
      </select>
    </template>

    <template v-if="isAuthenticated">
      <base-button icon="logout" @click="logout">Logout</base-button>
    </template>
    <template v-else>
      <base-button icon="login" @click="login">Login</base-button>
    </template>
  </div>
</template>
<script>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useAuth0 } from '@auth0/auth0-vue';
  export default {
    setup() {
      const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

      const store = useStore();
      const selectedUser = computed(() => store.getters.user);

      const selectedOrganisation = ref('');
      const selectedRole = ref('');
      const selectedPlatform = ref('');

      watch(selectedUser, (nv) => {
        const { OrganisationId = '', RoleId = '', PlatformId = '' } = localStorage;
        console.log('USER:', OrganisationId, RoleId, PlatformId, selectedUser);
        if (nv && nv.organisations && nv.organisations.length) {
          if (OrganisationId && nv.organisations.find((el) => el.id === OrganisationId)) selectedOrganisation.value = OrganisationId;
          else selectedOrganisation.value = nv.organisations[0].id;
        }
        if (nv && nv.roles && nv.roles.length) {
          if (RoleId && nv.roles.find((el) => el.id === RoleId)) selectedRole.value = RoleId;
          else selectedRole.value = nv.roles[0].id;
        }
        if (nv && nv.platforms && nv.platforms.length) {
          if (PlatformId && nv.platforms.find((el) => el.id === PlatformId)) selectedPlatform.value = PlatformId;
          else selectedPlatform.value = nv.platforms[0].id;
        }
      });

      watch(selectedOrganisation, (organisationId) => {
        store.dispatch('setSelectedOrganisation', organisationId);
        localStorage.setItem('OrganisationId', organisationId);
      });
      watch(selectedRole, (roleId) => {
        store.dispatch('setSelectedRole', roleId);
        localStorage.setItem('RoleId', roleId);
      });
      watch(selectedPlatform, (platformId) => {
        store.dispatch('setSelectedPlatform', platformId);
        localStorage.setItem('PlatformId', platformId);
        store.dispatch('setUser');
      });

      return {
        selectedUser,
        selectedPlatform,
        selectedOrganisation,
        selectedRole,
        isAuthenticated,
        login: () => loginWithRedirect(),
        logout: () => logout({ returnTo: window.location.origin }).then(() => (localStorage.pathken = undefined)),
      };
    },
  };
</script>
