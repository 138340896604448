import { ApiCaller, Utils } from '@/Utils/utils';

export default {
  state: {
    subscriptions: [],
    subscriptionsProducts: [],
    selectedProduct: null,
    selectedSubscription: null,
  },
  getters: {
    subscriptions: (state) => state.subscriptions,
    subscriptionsProducts: (state) => state.subscriptionsProducts,
    selectedSubscription: (state) => state.selectedSubscription,
    selectedProduct: (state) => state.selectedProduct,
  },
  mutations: {
    setSubscriptions: (state, data) => (state.subscriptions = Utils.createFiltrableData(data)),
    setSubscriptionsProducts: (state, data) => (state.subscriptionsProducts = Utils.createFiltrableData(data)),
    setSelectedSubscription: (state, data) => (state.selectedSubscription = data),
    setSelectedProduct: (state, data) => (state.selectedProduct = data),
  },
  actions: {
    getSubscriptions: (ctx, payload) =>
      ApiCaller.GET(`/recharges/subscriptions`, payload).then((data) => ctx.commit('setSubscriptions', data)),
    getSelectedSubscription: (ctx, payload) =>
      ApiCaller.GET(`/recharges/subscriptions/${payload.id}`).then((data) =>
        ctx.commit('setSelectedSubscription', data),
      ),
    getSubscriptionsProducts: (ctx, payload) =>
      ApiCaller.GET(`/recharges/subscriptions/products`, payload).then((data) =>
        ctx.commit('setSubscriptionsProducts', data),
      ),
    getSelectedProduct: (ctx, payload) =>
      ApiCaller.GET(`/recharges/subscriptions/products/${payload.id}`).then((data) =>
        ctx.commit('setSelectedProduct', data),
      ),
    updateProduct: (_, payload) => ApiCaller.PUT(`/recharges/subscriptions/product/${payload.id}`, payload.body),
    createProduct: (_, payload) => ApiCaller.POST(`/recharges/subscriptions/product`, payload),
  },
};
