<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { Utils } from '@/Utils/utils';
  import { useStore } from 'vuex';
  import { reactive, computed, onMounted } from 'vue';
  export default {
    setup() {
      const store = useStore();

      //reset fields da resettare al cambio di pagina
      onMounted(() => {
        store.commit('setVehicleModels', []);
        store.commit('setFleets', []);
      });

      //init selects
      const getType = (event) =>
        store.dispatch('getVehicleModels', {
          type: event.target.value,
          forCreation: 'true',
        });
      const getFleets = (event) => store.dispatch('getFleets', { organisation: event.target.value });

      store.dispatch('getDevices', { unassigned: true });
      store.dispatch('getOrganisations');

      //selects
      const typeSelect = [' ', 'TRACTOR'];
      const vehicleModels = computed(() => Utils.getNameArrayFromData(store.getters.vehicleModels));
      const devices = computed(() => Utils.getNameArrayFromData(store.getters.devices, 'imei'));
      const organisations = computed(() => Utils.getNameArrayFromData(store.getters.organisations));
      const fleets = computed(() => Utils.getNameArrayFromData(store.getters.fleets));

      //structure of page
      const insertData = {
        title: 'Pagina creazione veicolo',
        redirect_path: '/mobility/vehicles/list/all',
        create_endpoint: 'createVehicle',
        creation_title: 'Creazione veicolo',
        data: [
          {
            title: 'Targa',
            name: 'plate',
            class: 'col-span-2',
            required: true,
          },
          {
            title: 'Telaio',
            name: 'vin',
            required: true,
          },
          reactive({
            title: 'Tipo veicolo',
            name: 'type',
            on_change: getType,
            select: typeSelect,
            required: true,
            class: 'col-span-2',
          }),
          reactive({
            title: 'Modello veicolo',
            name: 'model',
            select: vehicleModels,
            class: 'col-span-2',
          }),
          reactive({
            title: 'Device veicolo',
            name: 'device',
            select: devices,
          }),
          reactive({
            title: 'Organizzazione',
            name: 'organisation',
            select: organisations,
            on_change: getFleets,
            class: 'col-span-2 col-start-1',
          }),
          reactive({
            title: 'Flotta',
            name: 'fleet',
            select: fleets,
          }),
        ],
      };
      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
