<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();
      const route = useRoute();

      function refreshPage(payload) {
        store.dispatch('getUnpaid', { id: route.params.id, ...payload });
      }

      const unpaid = computed(() => store.getters.unpaid);

      const forcePayment = (user_id) => store.dispatch('forcePayment', { id: user_id });
      // table
      const structure = {
        title: 'views.user.insolventUsersList',

        timeFields: [
          { label: 'creationDate', key: 'created_at' },
          { label: 'views.user.lastAttemptTimestamp', key: 'last_forced_timestamp' },
        ],
        summaryFields: [
          {
            type: 'SUM',
            label: 'views.user.totalUnpaidAmount',
            field: 'amount',
            unit: ' €',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.user.insolventUsersLabel',
            field: 'user_id',
            unit: 'views.user.insolventUsersNumber',
          },
        ],
        data: [
          {
            title: 'user.name.first',

            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-400',
              },
              {
                name: 'name',
              },
            ],
            link: { idField: 'user_id', path: '/user/users/:id' },
            span: 1,
          },
          {
            title: 'views.user.unpaidAmount',

            field: 'amount',
            format: 'euro',
            span: 1,
          },
          {
            title: 'description',

            field: 'description',
            span: 2,
          },
          {
            title: 'creationDate',

            field: 'created_at',
            format: 'date',
            span: 1,
          },
          {
            title: 'views.user.refundAttemptsNumber',

            field: 'force_payment_count',
            span: 1,
          },
          {
            title: 'views.user.lastAttemptTimestamp',

            field: 'last_forced_timestamp',
            format: 'datetime',
            span: 1,
          },
          {
            button: {
              title: 'buttons.forcePayment',

              function: forcePayment,
              field_returned: 'user_id',
            },
            span: 1,
          },
        ],
      };

      return {
        data: unpaid,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
