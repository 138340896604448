<template>
  <div class="mt-6">
    <div class="flex justify-between">
      <h1 v-if="insertData.title" class="text-lg font-bold mb-8">{{ t(insertData.title) }}</h1>
      <base-checkbox :title="$t('creation.stayInPage')" @change="restInPage = !restInPage" />
    </div>
    <base-error-list :missingFields="missingFields" />
    <base-list-element class="grid grid-cols-4">
      <div
        v-for="el in insertData.data || insertData.data.value || insertData.data._value"
        :key="el"
        :class="'m-2 ' + (el ? el.class : '')">
        <template v-if="isRenderable(el) && el">
          <base-checkbox
            v-if="el.boolean"
            :title="el.title"
            v-model="queryObj[el.name]"
            :checked="queryObj[el.name]"
            @change="el.on_change" />
          <base-select
            v-else-if="el.select"
            :title="el.title"
            v-model="queryObj[el.name]"
            :data="el.select"
            :statusOK="el.required ? queryObj[el.name] !== '' : true"
            @change="el.on_change" />
          <template v-else-if="isElArray(el)" v-for="e in elValue(el)" :key="e">
            <base-input-field
              :title="e.title"
              :placeholder="e.placeholder"
              v-model="queryObj[e.name]"
              :statusOK="e.required ? queryObj[e.name] !== '' : true" />
          </template>
          <base-input-field
            v-else
            :title="el.title"
            :placeholder="el.placeholder"
            v-model="queryObj[el.name]"
            :statusOK="el.required ? queryObj[el.name] !== '' : true" />
        </template>
      </div>
    </base-list-element>
    <div class="flex">
      <base-button class="ml-4 mt-2" @click="showModal = true">{{ t('send') }}</base-button>
      <base-button class="ml-4 mt-2" @click="$router.back()">
        {{ t('cancel') }}
      </base-button>
    </div>
    <Teleport to="body">
      <FlowModal v-if="showModal" @backdrop="showModal = false">
        <template #header>
          <h1 class="text-2xl text-gray-900 dark:text-white font-bold pr-36">{{ insertData.creation_title }}</h1>
        </template>
        <template #body>
          <text v-if="bodyModel" class="whitespace-pre" v-html="bodyModel"></text>
        </template>
        <template #footer>
          <div class="flex justify-between w-full">
            <p>Continuare?</p>
            <div>
              <button class="w-6 h-6 hover:bg-primary-200 text-sm rounded-full mr-2" type="button" @click="sendForm">
                Si
              </button>
              <button class="w-6 h-6 hover:bg-red-200 text-sm rounded-full" type="button" @click="showModal = false">
                No
              </button>
            </div>
          </div>
        </template>
      </FlowModal>
    </Teleport>
  </div>
</template>

<script>
  /* eslint-disable */
  import { useStore } from 'vuex';
  import { reactive, ref, watch, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  export default {
    props: {
      insertData: {
        type: Object,
        required: true,
      },
      toWatch: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore();
      const router = useRouter();
      //create obj for query
      //create obj from other obj funct
      const queryObj = reactive(createObj(props.insertData.data));
      function createObj(obj) {
        let newObj = {};
        for (let el in obj) {
          if (obj[el] !== undefined && obj[el] !== null && obj[el].name && !obj[el].isInvisible)
            if (obj[el].currentValue) {
              newObj[obj[el].name] = obj[el].currentValue;
            } else {
              newObj[obj[el].name] = obj[el].boolean !== undefined && obj[el].boolean ? false : '';
            }
        }

        return newObj;
      }
      if (props.toWatch) {
        watch(props.insertData.data, (nv) => {
          for (const key in nv) {
            if (Object.hasOwnProperty.call(nv, key)) {
              const element = nv[key];
              if (element.currentValue !== undefined) {
                queryObj[element.name] = element.currentValue;
              }
            }
          }
          const toDelete = [];
          for (const key in queryObj) {
            if (!Object.hasOwnProperty.call(nv, key)) {
              if (!toDelete.includes(key)) toDelete.push(key);
            }
          }
          for (const key in toDelete) {
            delete queryObj[key];
          }
        });
      }
      const isElArray = (el) => typeof el === 'array';
      const isRenderable = (el) => {
        let toCheck;
        const check1 = el !== undefined && el !== null;
        const check2 = check1 && el.value !== undefined && el.value !== null;
        const check3 = check1 && el._value !== undefined && el._value !== null;
        if (check2) {
          toCheck = el.value;
        } else if (check3) {
          toCheck = el._value;
        } else if (check1) {
          toCheck = el;
        } else {
          return false;
        }
        const check4 = !toCheck.select || toCheck.select.length || typeof toCheck.select == 'object';
        const check5 = !!!toCheck.isInvisible;
        return check4 && check5;
      };
      function checkFields() {
        let fieldArray = [];
        for (const i in props.insertData.data) {
          if (props.insertData.data[i].required && queryObj[props.insertData.data[i].name] === '')
            fieldArray.push(props.insertData.data[i].name);
        }
        return fieldArray;
      }
      const missingFields = ref([]);
      const restInPage = ref(false);

      function sendForm() {
        showModal.value = false;
        const fieldsArray = checkFields();
        if (fieldsArray.length > 0) missingFields.value = fieldsArray;
        else {
          store
            .dispatch(props.insertData.create_endpoint, {
              ...queryObj,
              ...props.insertData.additionalBody,
            })
            .then(() => {
              if (restInPage.value == true) return;

              if (props.insertData.dispatchName) store.dispatch(props.insertData.dispatchName);
              router.push(props.insertData.redirect_path);
            });
        }
      }
      //confirm modal
      const showModal = ref(false);
      const bodyModel = computed(() => {
        let finalString = {};
        for (let key in queryObj) {
          if (queryObj[key] === '') continue;
          finalString[key] = queryObj[key];
        }
        finalString = JSON.stringify(finalString, null, '\t');
        return finalString;
      });
      function status(itm) {
        if (itm !== '') return true;
        return false;
      }
      const elValue = (el) => (el._value !== undefined ? el._value : el.value ? el.value : el);
      return {
        queryObj,
        showModal,
        missingFields,
        sendForm,
        bodyModel,
        status,
        isRenderable,
        isElArray,
        elValue,
        t: useI18n().t,
        restInPage,
      };
    },
  };
</script>

<style></style>
