<template>
  <base-generals-page v-if="el" :el="el" :structure="structure" />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();

    const unitBattery = [" ", "%", "V"];
    const selectedVehicleModel = computed(
      () => store.getters.selectedVehicleModel
    );

    const structure = {
      title: "Modello veicolo",
      edit_endpoint: "updateVehicleModel",
      edit_id_route_name: "vehicleModelId",
      edit_title: "Modifica",
      data: [
        {
          title: "user.name.first",

          name: "name",
          placeholder: "_",
          editable: true,
        },
        {
          title: "description",

          name: "description",
          editable: true,
          placeholder: "_",
        },
        {
          title: "Livello massimo batteria",
          name: "max_battery_level",
          placeholder: " 0 ",
          editable: true,
        },
        {
          title: "Livello minimo batteria",
          name: "min_battery_level",
          placeholder: " 0 ",
          editable: true,
        },
        {
          title: "Unità di misura batteria",
          name: "min_battery_unit",
          select: unitBattery,
          placeholder: "_",
          editable: true,
        },
        {
          title: "Elettrico",
          name: "is_electric",
          boolean: {
            if_true: "Si",
            if_false: "No",
          },
          editable: true,
        },
        {
          title: "Path immagine",
          name: "picture",
          editable: true,
          placeholder: "_",
          class: "col-span-2",
        },
        {
          title: "Produttore",
          name: "producer",
          editable: true,
          placeholder: "_",
          class: "col-span-2",
        },
        {
          title: "user.notes",
          name: "additional_information",
          placeholder: "_",
          editable: true,
        },
      ],
    };
    return {
      el: selectedVehicleModel,
      structure,
    };
  },
};
</script>

<style></style>
