<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();
      const refreshPage = (payload) => store.dispatch('getPerUser', { ...payload });
      const perUser = computed(() => store.getters.perUser);

      const structure = {
        title: 'menu.recharge.analytics.peruser',
        timeFields: [
          { label: 'fields.start_recharge', key: 'inizio_ricarica' },
          { label: 'fields.end_recharge', key: 'fine_ricarica' },
        ],
        data: [
          {
            fields: [{ name: 'email', class: 'text-primary-300' }, { name: 'name' }, { name: 'surname' }],
            field: 'email',
            span: 2,
          },
          { field: 'consumed_kwh', format: 'kwh' },
          { field: 'recharges_count' },
          { field: 'average_kwh_per_recharge', format: 'kwh' },
          { field: 'total_amount', format: 'euro' },
          { field: 'distinct_stations' },
          { field: 'first_recharge', format: 'date' },
          { field: 'last_recharge', format: 'date' },
          { field: 'month_difference', placeholder: '0' },
        ],
      };

      return { data: perUser, structure, refreshPage };
    },
  };
</script>
