<template>
  <base-creation-page :insertData="insertData" />
</template>

<script>
  import { useStore } from 'vuex';
  import { reactive, computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      store.dispatch('getRequestTypes');
      const requestTypes = computed(() => store.getters.requestTypes);

      const operator = store.getters.user;

      //structure of page
      const insertData = {
        redirect_path: '/tickets',
        create_endpoint: 'createIssue',
        additionalBody: { operator },
        data: [
          reactive({
            title: 'tickets.type',
            name: 'requestTypeId',
            select: {
              array: reactive(requestTypes),
              name: 'name',
              elReturned: 'id',
            },
          }),
          {
            title: 'tickets.summary',
            name: 'summary',
            required: true,
            class: 'col-span-3',
          },
          {
            title: 'tickets.description',
            name: 'description',
            class: 'col-span-4',
          },
        ],
      };

      //return fields
      return {
        insertData,
      };
    },
  };
</script>

<style></style>
