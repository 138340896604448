<template>
  <div class="flex justify-center items-center w-full h-96 pulse">
    <span></span>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>