<template>
  <button
    class="flex justify-evenly items-center bg-white dark:bg-gray-900 px-2 h-8 text-xs rounded-lg text-gray-900 dark:text-white border-gray-400 dark:border-gray-700 border dark:border-2">
    <slot></slot>
  </button>
</template>

<script>
  export default {};
</script>

<style></style>
