import { ApiCaller } from '@/Utils/utils';

export default {
  state: {
    rechargesEnded: [],
  },
  getters: {
    rechargesEnded: (state) => state.rechargesEnded,
  },
  mutations: {
    setRechargesEnded(state, rechargesEnded) {
      state.rechargesEnded = rechargesEnded;
    },
  },
  actions: {
    getRechargesEnded: (ctx, payload) => ApiCaller.GET(`/recharges/v2/ended`, payload).then((data) => ctx.commit('setRechargesEnded', data)),
  },
};
