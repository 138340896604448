<template>
  <div class="flex flex-row justify-between w-full">
    <div class="w-full h-full p-12 text-gray-900 dark:text-white">
      <h1>ROUTES</h1>
      <template v-if="routes">
        <route-list :routes="routes"></route-list>
      </template>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import RouteList from './RouteList.vue';
  export default {
    components: {
      RouteList,
    },
    setup() {
      const store = useStore();
      const routes = computed(() => store.getters.menu);

      return { routes };
    },
  };
</script>
