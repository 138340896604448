module.exports = {
  components: {
    Error404: () => require('@/modules/UI/components/404/PageNotFound.vue'),
    ...require('@/modules/router/components'),
    ...require('@/modules/dashboard/components'),
    ...require('@/modules/user/components'),
    ...require('@/modules/recharge/components'),
    ...require('@/modules/mobility/components'),
    ...require('@/modules/sharing/components'),
    ...require('@/modules/device/components'),
    ...require('@/modules/languages/components'),
    ...require('@/modules/alarm/components'),
    ...require('@/modules/ticket/components'),
    ...require('@/modules/billing/components'),
  },
};
