<template>
  <base-generals-page :el="el" :structure="structure" />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const selectedStation = computed(() => store.getters.selectedDevice);

    const structure = {
      title: "",
      edit_endpoint: "updateDevice",
      edit_title: "Modifica",
      data: [
        {
          title: "device.imei",
          name: "imei",
          editable: true,
          placeholder: "--",
        },
        {
          title: "device.simImei",
          name: "sim",
          sub_name: "imei",
          db_name: "sim_imei",
          editable: true,
          placeholder: "--",
        },
        {
          title: "device.simOperator",
          name: "sim",
          sub_name: "operator",
          db_name: "sim_operator",
          editable: true,
          placeholder: "--",
        },
        {
          title: "device.simOwner",
          name: "sim",
          sub_name: "owner",
          db_name: "sim_owner",
          editable: true,
          placeholder: "--",
        },
        {
          title: "views.timeEntries.creationDate",
          name: "create",
          format: "datetime",
          placeholder: "--",
        },
        {
          title: "views.timeEntries.lastUpdateDate",
          name: "update",
          format: "datetime",
          placeholder: "--",
        },
      ],
    };
    return {
      el: selectedStation,
      structure,
    };
  },
};
</script>

<style>
</style>